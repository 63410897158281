<template>
    <div id="ContainerDailyNote">
       <div class="row page-height">
          <div class="col-12">
 
             <h4 v-if="this.Mode" class="page-title text-truncate">
               {{$t('Activities.EditActivity')}}
                      
             </h4>
             <h4 v-if="!this.Mode" class="page-title text-truncate">
                {{$t('Activities.AddActivity')}}
                  
             </h4>
             <MdTitle v-bind:Text="this.$t('Activities.Note')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
             <div class="row">
                <div class="col-12 mt-2">
                   <MdRichEdit
                      v-model="Activity.memoinfo"
                      v-bind:isReadOnly="isReadOnly"
                      v-bind:Height="'60vh'"
                      v-bind:id="'edit_activity'"
                   />
                </div>
             </div>
             <div class="row" v-bind:style="style">
                <div class="col-12 mt-2">
                <MdButton
                   v-bind:Label="$t('Activities.Activity')"
                   v-bind:Name="'btn_list_activity'"
                   v-bind:ClassProp="'secondary mx-2 btn-login'"
                   v-on:click="DiscardChanges"/>
             
                <MdButton
                   v-bind:Label="$t('Shared.Save')"
                   v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
                   v-on:click="SaveOrEdit"
                   v-bind:isReadOnly=this.isReadOnly
                   v-bind:Name="'btn_save_activity'"/>
                </div>
             </div>
          </div>
       </div>
       
    </div>
</template>
 
 <script>
 
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import DBFunctions from '@/common/DBFunctions.js'
 import { EventBus } from '@/event-bus.js';
 import ActivityService from '@/services/activities.service'
 import moment from "moment";
 import LocalStorage from '@/services/storage.service';
 
 let elemSaveBtn = '';
 export default {
    beforeRouteEnter(to, from, next) {
         next(async vm => {
             const iUA = await vm.$store.state.isUseDailyNotes;
             if(iUA) next()
             else next('/accessdenied');
         }) 
    },
 
    beforeRouteLeave(to, from, next) {
     if (!this.isReadOnly && this.pendingChanges) {
         this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
           title: this.$t('Activities.Activity'),
           okTitle: this.$t('Shared.BtnSave'),
           cancelTitle: this.$t('Shared.BtnDismiss'),
           footerClass: 'p-2',
           titleClass: 'modal-title modal-type-warning'
         })
           .then(value => {
             if(!value){
               next();
             } else {
               this.SaveOrEdit();
               next();
             }
           })		
     } else {
         next()
     }
     },
 
    mixins: [ShowMessage, DBFunctions],  
    name: 'Activity',
     data() {
       return { 
         Mode:this.$attrs.editMode,  
         Loading:true,
         isReadOnly: false,
         pendingChanges: false,
         OptionsRightMenu:{
          visible:true,
          ItemsList:[],
          btnvisible:true,
          itemdisabled: false,
          PatientCode:''         
         },
         Activity:{
            memoinfo:"",
            datechanged:null,
            Patientcode:this.$route.params.id,
         },
         RegistrationInfo: {},
       
         style:{
          boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important',
          width: '100%',
          backgroundImage: '-webkit-linear-gradient(268deg, #FAFAFA, #FAFAFAEE)',
          borderRadius: '0 0 14px 14px',
          borderTop: '1px solid #EEE',
          padding: '10px',
          textAlign: 'right'     
          },     
        
         UserId: LocalStorage.getUserId(),
       }
     },
 
 
     methods:{
       DiscardChanges() {
          this.$router.push({
             name: "schedulerdb-activitiesList",
             params: { id: this.$route.params.id, actId:this.$route.params.actId },
          });
       },
 
       getActivityById(){
        ActivityService.get(this.$route.params.code)
            .then((response) => {
               if(response.status == '200' && response.data){
                 this.Activity = response.data;
                 this.$emit('load', false);
               }
            })
            .catch((error) => {
               this.$emit('load', false);
               if (!error.response) {
                  // network error
                  this.showMessage(this.$t('Activities.Activity'),this.$t('Msg.retrieveInfo'),"error");
               } else {
                  this.showMessage(this.$t('Activities.Activity'),error,"error");
               }
            });
       },
  
       AddActivity(){
         this.$emit('load', true);
         ActivityService.add(this.Activity)
            .then((response) => {
               if(response.status == '200' && response.data){   
                 this.$emit('load', false);
                  this.pendingChanges = false;                          
               
                        this.$router.push({
                           name: "schedulerdb-activitiesList",
                           params: { id: this.$route.params.id, actId:this.$route.params.actId },
                        });
                                  
                    setTimeout(() => 
                        this.$bvToast.toast('aaaa', {
                         title: 'bbbb',
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
               }
            })
            .catch((error) => {
               this.$emit('load', false);
               if (!error.response) {
                  // network error
                  this.showMessage(this.$t('Activities.Activity'),this.$t('Msg.retrieveInfo'),"error");
               } else {
                  this.showMessage(this.$t('Activities.Activity'),error,"error");
               }
            });
       },
 
       UpdateActivity(){
         this.$emit('load', true);
         ActivityService.update(this.$route.params.code,this.Activity)
            .then((response) => {
               if(response.status == '200' && response.data){   
                 this.$emit('load', false);
                  this.pendingChanges = false; 
                        this.$router.push({
                           name: "schedulerdb-activitiesList",
                           params: { id: this.$route.params.id, actId:this.$route.params.actId },
                        });
                                  
                    setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Activities.Activity'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
               }
            })
            .catch((error) => {
               this.$emit('load', false);
               if (!error.response) {
                  // network error
                  this.showMessage(this.$t('Activities.Activity'),this.$t('Msg.retrieveInfo'),"error");
               } else {
                  this.showMessage(this.$t('Activities.Activity'),error,"error");
               }
            });
       },
 
       SaveOrEdit(){
         if(!this.isReadOnly) {
         // if (this.validate_entries()) {
             this.$emit('load', true);
             elemSaveBtn.setAttribute("disabled",'');
             this.pendingChanges = false         
             if (this.Mode) { 
                this.UpdateActivity()           
             } else {            
                this.AddActivity()
             }
         /* }      
          else {
             this.showMessage(this.$t('Activities.Activity'), this.$t('DailyNote.MandatoryValue'),"errorAutoHide");
          }*/
         }
       },
 
       beforeWindowUnload(e) {
             if (this.pendingChanges) {
                 e.preventDefault()
                 e.returnValue = ''	
             }
       },
       
      /* validate_entries() {
          if( this.DailyNote.comments ) {
             return true;
          } else {
             return false;   
          }
       },*/
       checkUserAction() {
         if (elemSaveBtn.disabled) {
           elemSaveBtn.removeAttribute('disabled');
         }
       },
  
     },
 
     async mounted(){
       this.NoteTypeName = this.$route.params.noteTypeName,
       this.isReadOnly = await this.$store.state.isDailyNotesEHRReadOnly;
       this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
       if(this.RegistrationInfo.dateFrom)
             this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
         if(this.RegistrationInfo.dateTo)
             this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');	
       if(this.RegistrationInfo.dob)
             this.RegistrationInfo.dob =  moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');
 
       this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;
       if(this.Mode) {
          this.$emit('load', true);
          this.getActivityById() 
       }
       setTimeout( function() {
          elemSaveBtn = document.getElementById('btn_save_activity');
          elemSaveBtn.setAttribute('disabled','');
       }, 50, this );
     },
 
     created () {
        EventBus.$on("onChanged", function (e) {       
           this.pendingChanges = e.Changed
           this.checkUserAction();
      }.bind(this));
      window.addEventListener('beforeunload', this.beforeWindowUnload)
     },
 
     beforeDestroy () {
       window.removeEventListener('beforeunload', this.beforeWindowUnload)
     },
   } 
 </script>
 
 
 