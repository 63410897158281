<template>
    <div id="ContainerCalendarResources">
          <h4 v-if="this.$attrs.editMode" class="page-title text-truncate">
            {{$t('calendarResources.Edit')}} 
          </h4>
          <h4 v-if="!this.$attrs.editMode" class="page-title text-truncate">
            {{$t('calendarResources.Add')}}           
          </h4>
            <b-row>
               <!-- <b-col lg="6" md="6" sm="12">
                    <MdMultiSelectListBox 
                    :source="source" 
                    :destination="destination" 
                    v-on:onChangeList="onChangeList"
                    v-bind:labelsource="'Available Employees'"
                    v-bind:labeldestination="'Selected Employees'"
                    />
                </b-col>
                <b-col lg="6" md="6" sm="12">
                  
                </b-col>-->
                <b-col md="12" sm="12" lg="12">
                    <MdTabList v-bind:TabList="tablist" v-bind:ShowTabList="false" v-bind:ShowTabEnd="false">
                        <div class="tab-content tab-content-custom-pill" id="pills-tabContent-custom">
                            <div class="tab-pane fade show active" id="general" role="tabpanel">
                                <b-row>
                                    <b-col lg="6" md="6" sm="12">
                                        <MdMultiSelectListBox 
                                        :source="source" 
                                        :destination="destination" 
                                        v-on:onChangeList="onChangeList"
                                        v-bind:labelsource="$t('calendarResources.aEmployees')"
                                        v-bind:labeldestination="$t('calendarResources.sEmployees')"
                                        />
                                    </b-col>
                                    <b-col lg="6" md="6" sm="12">
                                        <b-row>
                                            <div class="col-sm-12 col-md-3">                      
                                              <MdButton
                                                v-bind:Label="$t('calendarResources.aLimits')" 
                                                v-bind:ClassProp="isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
                                                v-bind:IconClass="'ti-plus pr-1'"              
                                                v-on:click="CallModalAppointmentLimits"
                                                v-bind:isReadOnly="this.isReadOnly">
                                              </MdButton>
                                            </div>
                                            <div class="col-sm-12 col-md-9 mb-4">
                                              <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                                                <div class="input-group">
                                                  <input 
                                                    type="text" 
                                                    class="form-control"
                                                    v-model="searchAppointmentLimits"   
                                                    @keyup.enter="FindAppointmentLimits">
                                                    <div class="input-group-append">
                                                      <div class="input-group-append">
                                                        <MdButton 
                                                          v-bind:Name="'btnsearchinputAppointmentLimits'"
                                                          v-bind:VariantProp="'btn btn-sm search-attached'"
                                                          v-bind:IconClass="'mdi mdi-magnify'"
                                                          v-on:click="FindAppointmentLimits">
                                                        </MdButton>
                                                      </div>
                                                    </div>
                                                  </div>
                                          </div>
                                        </div>
                                        </b-row>
                                      <div class="row">
                                            <div class="col-md-12">
                                                <MdTable 
                                                v-bind:fields="fields"
                                                v-bind:items="items"
                                                v-bind:totalRows="totalRows"
                                                v-bind:isReadOnly="false"
                                                v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                                                v-bind:currentPage="currentPage"
                                                v-bind:perPage="perPage"
                                                v-bind:pageOptions="pageOptions"
                                                :onFiltered="onFiltered"
                                                :pageChanged="pageChanged"
                                                :deleteIconEvent="Remove"
                                                v-bind:VisibleDeleteIcon="true"
                                                :onRowSelected="onRowSelected"
                                                :perPageFunction="this.PerPageFunction">
                                              </MdTable>
                                            </div>
                                      </div>
                                    </b-col>
                                </b-row>
                                <MdTitle v-bind:Text="$t('calendarResources.eEmplOpt')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
                                <b-row>
                                    <b-col lg="3" md="3" sm="12">
                                        <MdColor v-bind:Title="$t('calendarResources.tColor')" v-model="ColorTitle"/>
                                    </b-col>
                                    <b-col lg="3" md="3" sm="12">
                                        <MdSelect
                                            v-bind:Label="$t('calendarResources.sBillCode')"
                                            v-bind:Options="SuperBillList"
                                            v-bind:Model="SuperBillModel"
                                            v-bind:FieldDescription="'name'"
                                            v-bind:FieldCode="'code'"
                                            :IsDisabled="false"
                                            v-bind:Multiple="false"             
                                            :Input="ChangeDropDownSuperBill"/>
                                    </b-col>
                                    <b-col lg="3" md="3" sm="12">             
                                        <MdRadioGroup
                                            v-bind:Label="$t('calendarResources.dBooking')"
                                            v-model="DoubleBookingModel"
                                            v-bind:Options="DoubleBookingList"
                                            v-bind:Name="'doublebooking'"                                   
                                        />
                                    </b-col>
                                </b-row>
                            
                            </div>  
                            
                            <div class="tab-pane fade" id="appointmentbyemployeetype" role="tabpanel">                            
                 
    
                            </div>                            
                        </div>  
                    </MdTabList>
                </b-col>
            </b-row>

           
           
    
        <div class="page-actions">      
         <MdButton
                   v-bind:Label="$t('calendarResources.cLIST')"
                   v-bind:Name="'btnCalendarResourceslist'"
                   v-bind:ClassProp="'secondary mx-2 btn-login'"
                   v-on:click="DiscardChanges"/>    
            <MdButton
                v-bind:Label="$t('Shared.Save')"
                v-bind:ClassProp="(this.isReadOnlyCalendarResources)?'primary mx-2':'primary btn-login mx-2'"
                v-on:click="SaveOrEdit"
                v-bind:isReadOnly="isReadOnlyCalendarResources"
                v-bind:Name="'btn_save_CalendarResources'"/>
        </div>
    
    </div>
</template>
    
<script>
    
    
    import DBFunctions from '@/common/DBFunctions.js';
    import ShowMessage from '@/components/messages/ShowMessage.js'
    import CalendarResourcesService from '@/services/calendarresources.service'
    import { EventBus } from '../../../../../event-bus.js';
    //import Utils from '@/common/utils'
    
    let elemSaveBtn = '';
    
    export default {
        beforeRouteEnter(to, from, next) {
            next(async vm => {
                const iPI = await vm.CanView();
                if(iPI) next()
                else next('/accessdenied');
            }) 
      },
      name: 'CalendarResources',
     mixins: [ShowMessage, DBFunctions/*,EventBus*/],
      data(){
        return{
            parameter:{
                SearchCriteria:'',
                PageNumber : 1,
                PageSize:0,         
            },
            SuperBillList:[],
            ColorTitle:'#808080',
            SuperBillModel:"",
            fields:[
                { key: 'apptType', sortable: true, label: this.$t('MHisAppointment.apptType'), class: 'text-rigth'},
                { key: 'maxPerDay', sortable: true, label: this.$t('calendarResources.mPerDay') , class: 'text-rigth'},
            ],
            DoubleBookingList:[
              { value: 'A', text: this.$t('calendarResources.dbList.A') },
              { value: 'W', text: this.$t('calendarResources.dbList.W')  },
              { value: 'D', text: this.$t('calendarResources.dbList.D')  }],
            DoubleBookingModel:"",
            searchAppointmentLimits:"",
            items:[],
            isReadOnly:false,
            totalRows:0,
            perPage:10,
            pageOptions:[5,10,15],
            currentPage:1,
            ShowMessageIsEmpty:false,
            CalendarResources:{
            code:"",
            description:"",
            isInternal:false
           },
           tablist: [
         { title: this.$t('Shared.General'), link: '#general', active: true},
         { title: this.$t('calendarResources.aEmployeeT'), link: '#appointmentbyemployeetype'},          
         ], 
           source : [
            { label: this.$t('calendarResources.source.WHITE'), code: "#FFFFFF" },
            { label: this.$t('calendarResources.source.SILVER'), code: "#C0C0C0" },
            { label: this.$t('calendarResources.source.GRAY'), code: "#808080" },
            { label: this.$t('calendarResources.source.BLACK'), code: "#000000" },
            { label: this.$t('calendarResources.source.RED'), code: "#FF0000" },
            { label: this.$t('calendarResources.source.MAROON'), code: "#800000" },
            { label: this.$t('calendarResources.source.YELLOW'), code: "#FFFF00" },
            { label: this.$t('calendarResources.source.OLIVE'), code: "#808000" },
            { label: this.$t('calendarResources.source.LIME'), code: "#00FF00" },
            { label: this.$t('calendarResources.source.GREEN'), code: "#008000" },
            { label: this.$t('calendarResources.source.AQUA'), code: "#00FFFF" },
            { label: this.$t('calendarResources.source.TEAL'), code: "#008080" },
            { label: this.$t('calendarResources.source.BLUE'), code: "#0000FF" },
            { label: this.$t('calendarResources.source.NAVY'), code: "#000080" },
            { label: this.$t('calendarResources.source.FUCHSIA'), code: "#FF00FF" },
            { label: this.$t('calendarResources.source.PURPLE'), code: "#800080" }
            ],
            destination : [
            { label: this.$t('calendarResources.source.AQUA'), code: "#00FFFF" },
            { label: this.$t('calendarResources.source.TEAL'), code: "#008080" },
            { label: this.$t('calendarResources.source.BLUE'), code: "#0000FF" },
            { label: this.$t('calendarResources.source.NAVY'), code: "#000080" },
            { label: this.$t('calendarResources.source.FUCHSIA'), code: "#FF00FF" },
            { label: this.$t('calendarResources.source.PURPLE'), code: "#800080" }
            ],
           Menu : this.$store.state.leftMenu,
           permission:[],
           CodeExist:false,
           pendingChanges : false,
           isReadOnlyCalendarResources:false,
          isModifierValid:true,
            OptionsRightMenu:{
              visible:false,
              ItemsList:[],
              btnvisible:false,
              itemdisabled: false,
              PatientCode:'',
              Mode:this.edit_mode,
              favButtonAtion:false          
            },
    
        }
      },
    
      methods:{
    
        CanView(){
          return this.permission[0].roleCanView;
      },

      CallModalAppointmentLimits(){

      },

      FindAppointmentLimits(){

      },

      ChangeDropDownSuperBill(){

      },

      onFiltered(){

      },

      pageChanged(){

      },

      Remove(){

      },

      onRowSelected(){

      },

      PerPageFunction(){

      },

    
    
        DiscardChanges(){
           this.checkExistFavorite('/app/calendarresources');
           this.$router.push({name: "calendarresourcesList"});
        },

    onChangeList: function({ source, destination }) {
      this.source = source;
      this.destination = destination;
    },
    
         AddCalendarResources(){
           if(!this.CodeExist){
          this.$emit('load', true);
          CalendarResourcesService.Add(this.CalendarResources)
                    .then((response) => {
                        if(response.status == '200'){
                            this.CalendarResources = response.data; 
                            this.pendingChanges = false;   
                            this.$emit('load', false);
                            /*if(!this.OffRoute){
                               this.GotoAdmissionList();
                            }*/
                            this.$router.push({name: "calendarresourcesList"}).catch(()=>{});
                          
                            setTimeout(() => 
                            this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                            title: this.$t('Msg.Saving'),
                              autoHideDelay: 5000,
                              variant: "success"
                            })
                            , 50); 
                        }                             
                    })
                    .catch((error) => {   
                        if (!error.response) {
                       this.showMessage(this.$t('calendarResources.cResources'),this.$t('Msg.NotAbleToInsert'),"error");
                          }                      
                         else if(error.response.status === 400 ){
                            this.showMessage(this.$t('calendarResources.cResources'),error.response.data.errorMessage,"error");
                         } else if(error.response.status === 500){
                            this.showMessage(this.$t('calendarResources.cResources'),this.$t('Msg.ThereWereSomeError'),"error");
                         } else{
                             this.showMessage(this.$t('calendarResources.cResources'),error.response.data.errorMessage,"error");
                         }
                        elemSaveBtn.removeAttribute("disabled"); 
                        this.$emit('load', false);
                    }); 
           }else{
            this.showMessage(this.$t('calendarResources.cResources'),this.$t('calendarResources.cReExistMsg'),"error");
           }
        },
    
        OnCodeBlur(value){
                //if (this.patientZipChanged && value.trim()!== '') {
                   if(!this.$attrs.editMode){
                      if (value.trim()!== '') {
              
                var parameter = {
                   "code":value
                };
                CalendarResourcesService.getCalendarResourcesByCode(parameter)
                    .then((response) => {
                        if(response.status == '200' && response.data){
                          // this.$emit('load', false);  
                           if(response.data.length > 0){
                              this.showMessage(this.$t('calendarResources.cResources'),this.$t('calendarResources.cReExistMsg'),"error");
                              this.CodeExist = true;
                           }else{
                               this.CodeExist = false;
                           } 
                        }  
                         this.$emit('load', false);                          
                    })
                    .catch((error) => {
                        this.$emit('load', false); 
                      //  this.patient.city = '';
                       // this.patient.state = '';
                      if(error.response.status !== 404 && error.response.status !== 500){
                          if(error.response) {
                            this.showMessage(this.$t('calendarResources.cResources'),error,"error");
                        }
                      }
                        
                    });  
                }
                   }
        
           },
    
    
        EditCalendarResources(){
          this.$emit('load', true);
          CalendarResourcesService.Update(this.$route.params.id,this.CalendarResources)
                    .then((response) => {
                        if(response.status == '200'){
                            this.CalendarResources = response.data; 
                            this.pendingChanges = false;   
                            this.$emit('load', false);
                            /*if(!this.OffRoute){
                               this.GotoAdmissionList();
                            }*/
                            this.$router.push({name: "calendarresourcesList"}).catch(()=>{});
                          
                            setTimeout(() => 
                              this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                              title: this.$t('Msg.Saving'),
                                autoHideDelay: 5000,
                                variant: "success"
                              })
                            , 50); 
                        }                             
                    })
                    .catch((error) => {   
                        if (!error.response) {
                       this.showMessage(this.$t('calendarResources.cResources'),this.$t('Msg.NotAbleToInsert'),"error");
                          }                      
                         else if(error.response.status === 400 ){
                            this.showMessage(this.$t('calendarResources.cResources'),error.response.data.errorMessage,"error");
                         } else if(error.response.status === 500){
                            this.showMessage(this.$t('calendarResources.cResources'),this.$t('Msg.ThereWereSomeError'),"error");
                         } else{
                             this.showMessage(this.$t('calendarResources.cResources'),error.response.data.errorMessage,"error");
                         } 
                         elemSaveBtn.removeAttribute("disabled");
                        this.$emit('load', false);
                    }); 
        },
    
      getCalendarResources(){
          if(this.$attrs.editMode){
          this.$emit('load', true);     
          CalendarResourcesService.Get(this.$route.params.id)
            .then((response) => {
                if(response.status == '200' && response.data){
                  this.CalendarResources = response.data;  
                }   
                  this.$emit('load', false);                     
            })
            .catch((error) => {                    
                if (!error.response) {
                  this.showMessage(this.$t('calendarResources.cResources'),this.$t('Msg.NotAbleToSave'),"error");
                }                      
                else if(error.response.status === 400 ){
                  this.showMessage(this.$t('calendarResources.cResources'),error.response.data.errorMessage,"error");
                }
                else if(error.response.status === 405 ){             
                      this.$router.push({name: "Main"});    
                        setTimeout(() => 
                            this.showPermissionMessage()
                            , 50);            
                } 
                else if(error.response.status === 500){
                  this.showMessage(this.$t('calendarResources.cResources'),this.$t('Msg.ThereWereSomeError'),"error");
                } else{
                  this.showMessage(this.$t('calendarResources.cResources'),error.response.data.errorMessage,"error");
                } 
                this.$emit('load', false);            
            }); 
        
          }
        },
    
          DoAction(){     
            !this.$attrs.editMode ? this.AddCalendarResources() : this.EditCalendarResources(); 
          },
    
          SaveOrEdit(){ 
             if (this.validate_required()) {
                this.DoAction();
             } else {
                this.showMessage(this.$t('calendarResources.cResources'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");          
             }
    
          },
    
          validate_required() {
            this.isModifierValid = this.Modifier.code.length > 0 ? true : false;   
            return this.isModifierValid;
          },
          checkUserAction() {
            if (elemSaveBtn.disabled) {
              elemSaveBtn.removeAttribute('disabled');
            }
          }
      },
    
      created(){
         EventBus.$on("onChanged", function () {       
              this.checkUserAction();
        }.bind(this));
        this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
      },
    
      mounted(){
          this.OptionsRightMenu.ItemsList = [];
           let layoutStatus = this.$store.state.layoutStatus;
           switch(layoutStatus) {
            case 'L0R1':
              this.OptionsRightMenu.favButtonAtion = false;
              this.$emit('getVisibleMenu', this.OptionsRightMenu);
            break;
            case 'L1R0':
            case 'L1R1':
                this.OptionsRightMenu.btnvisible = false;
                this.$emit('getVisibleMenu', this.OptionsRightMenu);
            break;
            default:
                this.OptionsRightMenu.favButtonAtion = false;
                this.$emit('getVisibleMenu', this.OptionsRightMenu);
          }
          this.getCalendarResources();
          setTimeout( function() {
            elemSaveBtn = document.getElementById('btn_save_CalendarResources');
            elemSaveBtn.setAttribute('disabled','');
          }, 50, this ); 
        
      },
    
        destroyed(){
          this.OptionsRightMenu.ItemsList = [];
          this.OptionsRightMenu.Mode = false;
          this.OptionsRightMenu.visible = false; 
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        }
       
    };
    </script>
    
    
    