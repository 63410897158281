import ApiService from '@/services/api.service'


const MedicationService = {
    getAll( parameter) {          
      const requestData = {
        method: 'get',
        url: "/PatientMedications/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          PatientCode : parameter.PatientCode,
          Status : parameter.Status
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  
    get(id) {
      return ApiService.get(`/PatientMedications/${id}`);
    },
   
    getMedFreq() {
      return ApiService.get(`/VW_MdDrugFreqSetupHdrOnlyAdministrations`);
    },
    getMedIndications() {
      return ApiService.get(`/MdRxIndications`);
    },

    add(data){
      return ApiService.post(`/PatientMedications`,data)
    },

    delete(id){
      return ApiService.delete(`/PatientMedications/${id}`)
    },
    
    update(id,data){
      return ApiService.put(`/PatientMedications/${id}`,data)
    },

   
}
  
  export default MedicationService;