import ApiService from '@/services/api.service'

const PowerBIService = {
    getPowerBIReports(input) {
        return ApiService.get(`/PowerBIConfig/reportList?input=${input}`);
    },
    getPowerBIDashboards(input){
        return ApiService.get(`/PowerBIConfig/dashboardList?input=${input}`);
    },
    getPowerBIReportEmbed(id) {
        return ApiService.get(`/PowerBIEmbed/report?Id=${id}`);
    },
    getPowerBIDashboardEmbed(id) {
        return ApiService.get(`/PowerBIEmbed/dashboard?Id=${id}`)
    }
}

export default PowerBIService;