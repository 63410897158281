import ApiService from '@/services/api.service'

const CPTService = {
    getAll(parameter){
        const requestData = {
            method: 'get',
            url: "/cpts/",       
            params: {
              SearchCriteria: parameter.SearchCriteria,
              PageSize: parameter.PageSize,
              PageNumber: parameter.PageNumber,  
            }
          }
          if(parameter.Type && parameter.Type != 'All'){
            requestData.params.Type = parameter.Type;
          }
          return ApiService.customRequest(requestData);
    }, 
    AddCPT(data){
        return ApiService.post(`/cpts`, data);
    },
    DeleteCPT(Id){      
        return ApiService.delete(`/cpts/${Id}`);
    },
    UpdateCPT(Id,data){
        return ApiService.put(`/cpts/${Id}`,data);
    },   
    GetCPT(Id){     
        return ApiService.get(`/cpts/${Id}`);
    },
    getCPTByCode(parameter){
      const requestData = {
        method: 'get',
        url: "/cpts/",
        headers: {
        },
        params: {          
          code: parameter.code           
        }
      }     
      return ApiService.customRequest(requestData);       
    },
  }


  
  export default CPTService;