<template>
   <div>
    <b-modal :id="ModalId" :visible="true" no-close-on-backdrop no-close-on-esc size="md" :title="TitleWindow" ref="modalparticipantclient" @close="this.emitEventCancel" @ok="this.emitEventOK" @cancel="this.emitEventCancel"> 
      <b-row>
        <b-col md="6" lg="6" sm="12">
          <MdSelect
            v-bind:Label="$t('Incident.IncPartType')"
            v-bind:Options="ParticipantList"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'code'"            
            v-bind:Model="modelParticipantType"                       
            v-bind:Multiple="false"
            :Input="ChangeParticipantType"
            v-bind:isRequired="true"
            v-bind:isValid="isParticipantTypeValid"/>
        </b-col>
        <b-col md="6" lg="6" sm="12">
          <MdEdit 
            v-bind:Label="$t('Incident.IncPartStatementDate')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'date'"
            v-model="participantItems.statementDate"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-bind:isRequired="true" 
            v-bind:isValid="isStatementDateValid">                                     
          </MdEdit>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">         
          <MdLookup 
            v-bind:ModelCode="participantItems.entityCode"
            v-bind:ModelDescription="participantItems.entityName"
            v-bind:Label="$tc('Incident.IncPartClient')"
            v-bind:RefCode="'recordId'"   
            v-bind:Fields="FieldsClients" 
            v-bind:Api="'PatientRegistrations/Table?IsInpatient=true&ActiveAdmissionOn='+this.IncidentDate"
            v-bind:FieldCode="'recordId'"
            v-bind:FieldDescription="'patientName'"
            v-bind:isRequired="true"
            :SearchTitle="$tc('Incident.IncPartClient')"
            :GetValues="getValues"
            :AuxFields="AuxFields"
            :InvalidFeedback="'Invalid client'"
            :SearchFieldCode="'PatientCode'"
           
            :isValid="isValidClient"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">         
         <!--<MdSelect
            v-bind:Label="$tc('Shared.Program')"
            v-bind:Options="PatientRegistrations"
            v-bind:FieldDescription="'programFormated'"
            v-bind:FieldCode="'recordId'"            
            v-bind:ModelArray="modelProgram"                       
            v-bind:Multiple="false"
            v-bind:AllowEmpty="false"
            :Input="ChangeProgram"/>--> 
            <MdEdit 
            v-bind:Label="$tc('Shared.Program')"
            v-bind:Name="'Program'"
            v-bind:TypeProp="'text'"       
            v-model="ProgramName"
            v-bind:isReadOnly="true"
            v-bind:Id="'input_Parprogram_id'"
          /> 
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" lg="6" sm="12">
          <MdCheckBox
            v-bind:Label="$t('Incident.IncPartArrest')"
            v-bind:Name="'PoliceArrest2'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="participantItems.arrestMade"
            v-on:change="ChangePoliceArrest"/>
        </b-col>
        <b-col md="6" lg="6" sm="12">
          <MdCheckBox
            v-bind:Label="$t('Incident.IncPartVisit')"
            v-bind:Name="'VisittoER2'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="participantItems.ervisit"
            v-on:change="ChangeVisittoER"/>
        </b-col>
      </b-row>
      <MdTitle v-bind:Text="$t('Incident.IncPartPersonalSta')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
      <b-row>
        <b-col md="12" lg="12" sm="12">
          <MdMemo
            v-bind:Name="'textarea_personalstatement'"
            v-bind:Rows="'8'"
            v-bind:MaxRows="'6'"
            v-bind:isRequired="true"
            v-bind:isValid="isPersonalStatementValid"
            v-model="participantItems.personalStatement"/>
        </b-col>
      </b-row>
      <MdTitle v-bind:Text="$t('Incident.IncPartComments')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
      <b-row>
        <b-col md="12" lg="12" sm="12">
          <MdMemo
            v-bind:Name="'textarea_resourcecomments'"
            v-bind:Rows="'8'"
            v-bind:MaxRows="'6'"
            v-model="participantItems.observation"/>
        </b-col>
      </b-row>
    </b-modal>   
   </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import IncidentReport from '@/services/incidentreport.service'
import GlobalService from '@/services/global.service'
import Utils from '@/common/utils'

export default {
  name: 'ModalParticipantClient',
    mixins: [ShowMessage],  
   props: {
     DefaultDate:String,
     ModalId: String,
     TitleWindow: String,
     IncidentID: String,
     ModeEdit: Boolean,    
     ParticipantItems: Object,
     minObsevationStatement:Number,
     minPersonalStatement:Number,
     IncidentDate:String,
   },
   data(){
      return {
        ParticipantList: [],
        ProgramName:'',
        participantType: '',
        personalstatementdate: null,
        personalstatementdatenotes: '',
        resourcecomments: '',
        patientname: this.PatientName,
        modelParticipantType:{code:"",description:""},
        patientcode: '',
        firstname: '',
        FieldsClients:[ 
                        { key: 'patientName', sortable: true, label: 'Patient Name'},
                        { key: 'patientCode', sortable: true, label: this.$t('Shared.PatientCode')},   
                        { key: 'dob', sortable: true, label: this.$t('Shared.Dob'), formatter:  (value) => { return Utils.formatterDateToString(value)}},
                        { key: 'recordId', sortable: true, label: 'Record Id',class:'hidden' },                   
                        { key: 'programName', sortable: true, label: 'Program' },
                        { key: 'programCode', sortable: true, label: 'Program Code',class:'hidden' },
                        { key: 'dateFrom', sortable: true, label: 'Date From', formatter:  (value) => { return Utils.formatterDateToString(value)}},
                        { key: 'roomName', sortable: true, label: 'Room Name' },
                        { key: 'buildingName', sortable: true, label: 'Building Name' }
                        ],
         AuxFields:['patientName','recordId','programName','programCode','patientCode','dob'],
        participantItems: {
          dob:null,          
          isRequired:false,
          arrestMade: false,
          ervisit: false,
          observation: '',
          participationType: '',
          entityName: '',
          personalStatement: '',
          statementDate: null,
          entityCode: '',
          entityType: '',
          incidentId: '',
          id: 0,
          recordId: '',
          programName:''
        },
        isParticipantTypeValid: true,
        isStatementDateValid: true,
        isValidClient: true,
        isPersonalStatementValid: true,
        APIDateTime: null,
        PatientRegistrations: [],
        modelProgram: {}
      }
   },

   methods:{

    Message(min,text1,text2){
      return text1+" "+min+" "+text2; 
    },

      validateFields() {     
        let personalStatement = this.participantItems.personalStatement ? this.participantItems.personalStatement.replace(/<[^>]*>?/g, '') :"";
        let observation = this.participantItems.observation ? this.participantItems.observation.replace(/<[^>]*>?/g, ''):"";
        this.isParticipantTypeValid = this.participantItems.participationType != "" && this.participantItems.participationType != undefined ? true : false
        this.isStatementDateValid = Utils.ValidateDate(this.participantItems.statementDate) ? true : false
        this.isValidClient = this.participantItems.entityCode ? true : false
        this.isPersonalStatementValid = this.participantItems.personalStatement && this.participantItems.personalStatement.trim() != ''? true : false

        if (this.isPersonalStatementValid == false)
        this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncPartProvidPerStaMsg'),"errorAutoHide");

        let validpersonalStatement = false;
        let validobservation = false;
      
      if(this.minPersonalStatement !== null && this.minPersonalStatement !== ""){
        validpersonalStatement = personalStatement.length >= this.minPersonalStatement;
      }else{
        validpersonalStatement = true;
      }

      if(this.minObsevationStatement !== null && this.minObsevationStatement !== ""){
        validobservation = observation.length >= this.minObsevationStatement;
      }else{
        validobservation = true;
      }

      if(validpersonalStatement == false){        
        this.showMessage(this.$t('Incident.IncReportTitle'),this.Message(this.minPersonalStatement,this.$t('Incident.IncPartProvidPerStaC1Msg'),this.$t('Incident.IncPartProvidPerStaC2Msg')) ,"errorAutoHide");
      }

      if(validobservation == false){        
        this.showMessage(this.$t('Incident.IncReportTitle'),this.Message(this.minObsevationStatement,this.$t('Incident.IncPartProvidCC1Msg'), this.$t('Incident.IncPartProvidCC2Msg')) ,"errorAutoHide");
      }
       

        return this.isParticipantTypeValid && this.isStatementDateValid && this.isValidClient && this.isPersonalStatementValid && validpersonalStatement && validobservation
      },
      
      ChangePoliceArrest() {
      },

      ChangeVisittoER() {
      },

      async ChangeParticipantType(value) {
        this.modelParticipantType = value !== null ? value : {code:'',description:''};
        this.isParticipantTypeValid = value && value.code ? true : false;        
        this.participantItems.participationType = value ? value.code : null;
        this.participantItems.participationDescription = value ? value.description : null;      
        this.participantItems.isRequired = value && value.groupCreation && value.groupCreation.toUpperCase() === "REQUIRED" ? true : false;
      },
      
      ChangeProgram(value) {
        this.modelProgram = value;
        this.participantItems.programName = value !== null ? value.programName : "";
        this.participantItems.recordId = value.recordId;
      },

      /*getNameByCode(code){   
        for(var i = 0; i < this.ParticipantList.length; i++){
          if(this.ParticipantList[i].code === code){
            this.participantItems.participationDescription = this.ParticipantList[i].description;
          }
        }
      },*/
      
      emitEventOK(bvModalEvent){
        if (this.validateFields()) {
          if (this.ModeEdit == false) {
            this.participantItems.entityType = 'P'           
            this.participantItems.id = Utils.generateRecID()
          }                    
          this.$emit('getCodeConditionOK',this.participantItems);
        } else {
          bvModalEvent.preventDefault()
        }
      },
      
      emitEventCancel(){
        this.$emit('getCodeConditionCANCEL');
      }, 

      getParticipationTypeClients() {
        IncidentReport.getParticipationTypeClients()
          .then((response) => {
            if(response.status == '200') {  
              this.ParticipantList = response.data;
            }                             
          })
          .catch((error) => {
            if (error.response) {
              this.showMessage(this.$t('Incident.IncPartTypeCMsg'),error,"error");
            }
          });
      },
//Utils.formatterDateToString(value.AuxFields[5]);
      getValues: function (value) {
        if(value.code) {
          this.participantItems.entityName = `${value.AuxFields[0]}`
          this.participantItems.entityCode = `${value.AuxFields[4]}`;
          this.participantItems.dob = value.AuxFields[5] ? `${Utils.formatterDateToString(value.AuxFields[5])}` :null;
          this.participantItems.programName = value.AuxFields[2];
          this.ProgramName = value.AuxFields[2];
          this.participantItems.recordId = value.code;        
          this.loadFirstItem()
        } else {
          this.participantItems.entityName = ''
        }
      },

      loadFirstItem() {
        if (this.PatientRegistrations.length > 0) {
          this.modelProgram = this.PatientRegistrations[0]
          this.participantItems.recordId = this.PatientRegistrations[0].recordId
        }
      },

      loadProgramValue(recordId) {
        this.modelProgram = this.PatientRegistrations.find(el => el.recordId === recordId)
      },

      getDateTime() { 
         GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {
                this.APIDateTime = Utils.formatterDateTimeToString(response.data);
                this.participantItems.statementDate = Utils.formatterDate(response.data)
               }  
            })
            .catch(error => {
             if(error.response.status === 400 ){
                 this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
              }else{
                this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
              }
            });   
      },
   },

  async mounted(){ 
    this.$refs.modalparticipantclient.$on("hidden.bs.modal");
    await this.getParticipationTypeClients()
    if (this.ModeEdit) {
      let participantItems = {...this.ParticipantItems}
      this.modelParticipantType = {code:this.participantItems.participationType,description:participantItems.participationDescription}    
      this.participantItems.arrestMade = participantItems.arrestMade
      this.participantItems.isRequired = participantItems.isRequired
      this.participantItems.dob = participantItems.dob
      this.participantItems.ervisit = participantItems.ervisit
      this.participantItems.observation = participantItems.observation
      this.participantItems.participationType = participantItems.participationType
      this.participantItems.participationDescription = participantItems.participationDescription
      this.participantItems.entityName = participantItems.entityName
      this.participantItems.personalStatement = participantItems.personalStatement
      this.participantItems.statementDate = Utils.formatterDate(participantItems.statementDate)
      this.participantItems.entityCode = participantItems.entityCode
      this.participantItems.entityType = participantItems.entityType
      this.participantItems.incidentId = participantItems.incidentId
      this.participantItems.programName = participantItems.programName
      this.participantItems.id = participantItems.id
      this.participantItems.recordId = participantItems.recordId     
      this.ProgramName = participantItems.programName;
    }else{
       this.getDateTime();  
    }
  },
};
</script>