import ApiService from '@/services/api.service'

const AllergyProblemSeveritiesService = {
    getAll() { 
      const requestData = {
        method: 'get',
        url: "/AllergyProblemSeverities/",
        headers: {
        },
      }     
      return ApiService.customRequest(requestData);         
    },
  
    get( id) {
      return ApiService.get(`/AllergyProblemSeverities/${id}`);
    },

    add(data){
      return ApiService.post(`/AllergyProblemSeverities`,data)
    },

    delete(id){
      return ApiService.delete(`/AllergyProblemSeverities/${id}`)
    },

    update(RecordId,Allergy){
      return ApiService.put(`/AllergyProblemSeverities/${RecordId}`,Allergy)
    }
}
  
  export default AllergyProblemSeveritiesService;