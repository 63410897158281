import ApiService from '@/services/api.service'


const AppointmentCheckInsService = {
    getAll(parameter) {          
      const requestData = {
        method: 'get',
        url: "/AppointmentCheckIns",
        headers: {
        },
        params: {         
          Code: parameter.Code, 
        }
      }     
      return ApiService.customRequest(requestData);         
    },

  
 
    
  }
  
  export default AppointmentCheckInsService;