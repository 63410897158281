<template>
  <li :class="[isFolder ? 'nav-item1 folder' : 'nav-item file']">
    <a href="#" 
      class="nav-link"
      :class="{'open': open}" 
      @click="toggle">
      <span class="menu-title">
        <i class="menu-arrow"></i>
        {{ model.name }}
      </span>
    </a>
    <ul v-show="open" v-if="isFolder" class="nav1" :class="{'open': open}">
      <AppMenu
        v-for="(model, index) in model.children"
        :key="index"
        :model="model">
      </AppMenu>      
    </ul>
  </li>
</template>

<script>

export default {
  props: {
    model: Object
  },
  data: function() {
    return {
      open: false
    };
  },
  computed: {
    isFolder: function() {
      return this.model.children && this.model.children.length;
    }
  },
  methods: {
    toggle: function() {
      if (this.isFolder) {
        this.open = !this.open;
      } else {
        this.navigate(this.model.action);
      }
    },
    navigate: function(url) {
      this.$router.push(url).catch(()=>{});
    }
  }
}

</script>