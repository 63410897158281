import ApiService from '@/services/api.service'

const TherapistService = {

    getAllTherapist(code){
        return ApiService.get(`/NotesTherapists?Cosigner=false&OrderBy=FirstName&code=${code}`);  
    },

    getAllTherapistReport(){
        return ApiService.get(`/NotesTherapists?Cosigner=false&OrderBy=FirstName&ByPassEditRestriction=true`);  
    },

    getAllCOTherapist(code){
        return ApiService.get(`/NotesTherapists?Cosigner=true&OrderBy=FirstName&code=${code}`);  
    },

 

}

export default TherapistService;