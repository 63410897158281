import ApiService from '@/services/api.service'

const GroupNotesService = {

   /* getNotes(ProgramCodes,TherapyCode) {  
        return ApiService.get(`/GroupNotes/${ProgramCodes}:${TherapyCode}`); 
    },*/

    getNotes(ProgramCodes,TherapyCode) {      
        var _Url = '/GroupNotes/';    
    if(ProgramCodes){
        if(!_Url.includes("?")){
            _Url +="?ProgramCodes="+ProgramCodes;
        }else{
            _Url +="&ProgramCodes="+ProgramCodes;
        }
    }

    if(TherapyCode){
        if(!_Url.includes("?")){
            _Url +="?TherapyCode="+TherapyCode;
        }else{
            _Url +="&TherapyCode="+TherapyCode;
        }
    }
        
      const requestData = {
        method: 'get',
        url: _Url,
      //  params: param
      }
      return ApiService.customRequest(requestData);
      },


      getDates(parameter) { 
        var param = {};     
        var _Url = '/groupnotes/Dates/'; 
      /*  if(parameter.ProgramCodeList !== ''){
          _Url +="?"+parameter.ProgramCodeList;
        }*/

        param.NoteID = parameter.NoteID;
        param.NoteStatus = parameter.NoteStatus;
        param.DateFrom = parameter.DateFrom;
        param.DateTo = parameter.DateTo;
        param.ProgramCode = parameter.ProgramCode;
        param.TherapyCode = parameter.TherapyCode;
        param.TherapistCode = parameter.TherapistCode;
        param.PatientCode = parameter.PatientCode;  
        param.PageSize = parameter.PageSize;          
        param.PageNumber = parameter.PageNumber;
      
        
      const requestData = {
        method: 'get',
        url: _Url,
        params: param
      }
      return ApiService.customRequest(requestData);
      },

      getPatients(parameter) { 
        var param = {};     
        var _Url = '/groupnotes/Patients/'; 
        param.Status = parameter.Status;
        param.ProgramCode = parameter.ProgramCode;
        param.TherapyCode = parameter.TherapyCode;
        param.PatientCode = parameter.PatientCode;  
        param.GroupCreationID = parameter.GroupCreationID;
        param.TransDate = parameter.TransDate;
        
      const requestData = {
        method: 'get',
        url: _Url,
        params: param
      }
      return ApiService.customRequest(requestData);
      },

      getGroup(parameter) { 
        var param = {};     
        var _Url = '/groupnotes/Group/'; 
        param.Status = parameter.Status;
        param.TherapyCode = parameter.TherapyCode;
        param.NoteId = parameter.NoteId;  
        param.GroupCreationID = parameter.GroupCreationID;
        param.TransDate = parameter.TransDate;
        
      const requestData = {
        method: 'get',
        url: _Url,
        params: param
      }
      return ApiService.customRequest(requestData);
      },

      GroupNotesAttedancesInsert(data){
        return ApiService.post(`/GroupNotesAttendances/BulkInsert`, data);
      },

      GroupNotesAttedancesDelete(data) {
        return ApiService.delete(`/GroupNotesAttendances/ByPatients`,data);
      },
      
      deleteGroupNote(id){
        return ApiService.delete(`/groupnotes/${id}`)
      },

      finalizeGroupNote(id){
        return ApiService.post(`/groupnotes/finalize/${id}`)
      },

      unFinalizeGroupNote(id){
        return ApiService.put(`/groupnotes/unfinalize/${id}`)
      },

      deleteANote(GroupCreationId, PatientCode){
        return ApiService.delete(`/groupnotes/DeletePatientGroupNote/${GroupCreationId}:${PatientCode}`)
      },

      deleteAllPatientsNotes(GroupCreationId){
        return ApiService.delete(`/groupnotes/DeleteAllPatientsGroupNotes/${GroupCreationId}`)
      },

      getExitsPatientNote(parameter) { 
        return ApiService.get(`/groupnotes/ExistsPatientNoteInGroup/${parameter.GroupCreationID}:${parameter.PatientCode}`);
      }, 

      getExitsPatientsNotes(parameter) { 
        return ApiService.get(`/groupnotes/ExistsPatientsNotesInGroup/${parameter.GroupCreationID}`);
      }, 
         
}
  
  export default GroupNotesService;