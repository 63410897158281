<template>
    <div id="ContainerPatientChartReview">
        <ChartReviewBase v-bind:Origin="$tc('Shared.Patient')" />
    </div>
</template>

<script>
import ChartReviewBase from "@/components/pages/chartreview/ChartReview";
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '@/event-bus.js';
export default {
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  name: 'PatientChartReview',
   mixins: [ChartReviewBase,DBFunctions],
   data(){
    return {
        permission:[],
        Menu : this.$store.state.leftMenu,
    }
   },

   methods:{
    CanView(){
      return this.permission[0].roleCanView;
    },
   },

   created(){
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);    
    EventBus.$on("ShowWeel", function (isShow) { 
        isShow ? this.$emit('load', true) : this.$emit('load', false);     
     }.bind(this));
    },
};
</script>