<template>
  <fragment>
    <b-button v-show="btnShow"
       :id="Name"                                           
       :name="Name"  
       :disabled="isReadOnly"
       :variant="VariantProp"
       :weight="Weight"
       :class="ClassProp"
       v-on:click="OnClick">
       <i v-if="IconClass" :class="IconClass"></i>
       {{this.Label}}
    </b-button>  
  </fragment>
</template>

<script>
  export default {
    name: 'MdButton',
    props: {
      Label:         String,
      ClassProp:     String,
      isReadOnly:    Boolean,
      Name:          String,
      IconClass:     String,
      Weight:        String,
      VariantProp:   String
    },
    data() {
      return {
        btnShow: true
      }
    },

     methods:{
    OnClick(){
      this.$emit('click');
    },

    showButton(value) {
      this.btnShow = value
    }
  },
  }
</script>