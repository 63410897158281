<template>	
    <div id="DivModalClaimProviderSelector">
    <b-modal id="modalIdClaimProviderSelector" size="md" centered scrollable  :title="TitleWindow" 
    @ok="this.handleOk" 
    @cancel="this.emitEventCancel"
    @show="resetModal"
    @hidden="resetModal"    
    > 
    <form ref="form" @submit.stop.prevent="handleSubmit"> 
       <div class="row" style="height:200px;">
          <b-col lg="12" md="12" sm="12">
            <MdSelect
                v-bind:Label="this.$t('modalProviderSelector.Provider')"
                v-bind:Options="ProviderList"
                v-bind:FieldDescription="'providerName'"
                v-bind:FieldCode="'providerCode'"
                v-bind:isRequired="true" 
                v-bind:FeedBackText="this.$t('modalProviderSelector.ProvRequired')"  
                v-bind:isValid="isProviderValid"
                v-bind:Model="ProviderModel"  
                v-bind:Multiple="false" 
                :Input="ChangeDropDownProvider" 
            />
          </b-col>   
        </div>
        </form>
    </b-modal>   
    </div>   
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
import { EventBus } from '../../../event-bus.js';
import ClaimFileCreations from '@/services/claimfilecreations.service';
export default {
  name: 'ModalProviderSelector',
  props: {     
     TitleWindow:String,
     TemplateA:Boolean,
     TypeOfBill:Boolean       
   },
    mixins: [DBFunctions,EventBus],
    data() {
		return {
            RecId:null,
            selected: 0, 
            ProviderList:[], 
            doCall:false,
            ProviderModel:'',
            isProviderValid:true,
            modalIdTypeofBill:"modalIdClaimTypeOfBill",

		}
	},

	mounted() {
   // this.getProviders(); 
      /*this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if(modalId == 'modalIdClaimProviderSelector'){
        if(!this.doCall){
          this.getProviders();
          this.doCall = true;
        } 
      }        
      });  */

      EventBus.$on("onGetProviders", function () {           
          this.getProviders(); 
      }.bind(this));
    },

  created(){ 
   
  },

    beforeDestroy () {     
      EventBus.$off('onGetProviders');  
      EventBus.$off('onCreateFile'); 
    },

	methods:{

    resetModal(){
      this.ProviderModel = '';
    },

    ChangeDropDownProvider(provider){
         this.ProviderModel = provider;
    },

    validate_required(){
       return this.isProviderValid = this.ProviderModel ? true : false;
    },

    handleOk(bvModalEvt) {      
        bvModalEvt.preventDefault();      
        this.handleSubmit()
      },

      getProviders() {
            ClaimFileCreations.getProviders()
                .then((response) => {                
                if (response.data && response.data.length > 0) {
                    this.ProviderList = response.data;                   
                }
            })
                .catch((error) => {
                if (error.response) {
                    this.showMessage(this.$t('modalProviderSelector.ProvFilter'), error, "error");
                }
            });
        },


    emitEventCancel(){
      
      }, 

      handleSubmit() {   
        if (!this.validate_required()) {
          this.showMessage(this.$t('modalProviderSelector.ProvSelector'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");
          return;
        }
        if(this.TemplateA && this.TypeOfBill){
          EventBus.$emit("onSelectProvider", this.ProviderModel.providerCode);
           EventBus.$emit("onGetTypeOfBill", true)
          this.$bvModal.show(this.modalIdTypeofBill);
        }else{
          EventBus.$emit("onCreateFile", this.ProviderModel.providerCode);
        }
        this.$nextTick(() => {
          this.$bvModal.hide('modalIdClaimProviderSelector');
        }) 
      },

     emitEventOK(){  
      if(this.validate_required()){
         if(this.TemplateA){
          this.$bvModal.show(this.modalIdTypeofBill);
        }
       
      }else{
        this.showMessage(this.$t('modalProviderSelector.ProvSelector'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");
      } 
       
      },
	},

}


</script>


