import ApiService from '@/services/api.service'

const ValueCodeService = {
    getAll(parameter){
        const requestData = {
            method: 'get',
            url: "/valuecodes/",       
            params: {
              SearchCriteria: parameter.SearchCriteria,
              PageSize: parameter.PageSize,
              PageNumber: parameter.PageNumber,  
            }
          }         
          return ApiService.customRequest(requestData);
    }, 
    getValueCodeByCode(parameter){
        const requestData = {
          method: 'get',
          url: "/valuecodes/",
          headers: {
          },
          params: {          
            code: parameter.code           
          }
        }     
        return ApiService.customRequest(requestData);       
      },
    Add(data){
        return ApiService.post(`/valuecodes`, data);
    },
    Delete(Id){      
        return ApiService.delete(`/valuecodes/${Id}`);
    },
    Update(Id,data){
        return ApiService.put(`/valuecodes/${Id}`,data);
    },   
    Get(Id){     
        return ApiService.get(`/valuecodes/${Id}`);
    },
  }


  
  export default ValueCodeService;