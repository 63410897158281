<template>
    <div>
     <b-modal size="xl" :id="ModalId" :visible="true" scrollable centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalAddGoals" @ok="this.emitEventCancel" @close="this.emitEventCancel" ok-only :ok-title="$t('Shared.BtnCancel')" @cancel="this.emitEventCancel"> 
      
        <div class="row">
            <div class="col-12">
              <div v-if="VisibleNoRecords" class="empty-row-banner m-3">
                <div class="text-center my-2">{{$t('treatmentplanList.nProbYet')}}</div>
              </div>
              <div class="IncidentContainerBox">
      
    
                <div class="row">
                  <div class="col-md-12">
                    <div class="grid-margin stretch-card">
                      <div class="card activeCard">
                        <div class="card-body">
                          <div v-b-toggle="'probList' + ' probListBanner'"
                            class="d-flex align-items-start justify-content-between">
                            <h6 class="card-title flex-grow lower-line text-truncate">
                              {{$t('modalEditProblem.Problem')}}: {{ this.ProblemDetail.problem.probDesc }}
                            </h6>
                          </div>
                  
                          <b-collapse :id="'probList'" visible>
                            <div class="flex-container">
                             
                              <MdTitle class="shrinkMargin"
                                v-bind:Text="$t('modalEditProblem.Problem')" v-bind:IconPrintDescription="$t('treatmentplanList.proReview')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleProblem'" />
      
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="float-left col text-muted p-item">
                                    <small>{{$t('modalEditProblem.Problem')}}</small><br>
                                    <b>{{ this.ProblemDetail.problem.probDesc }}</b>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="float-left col text-muted p-item">
                                    <small>{{$t('MdPatientDiagCodes.Diagnosis')}}</small><br>
                                    <b>{{ this.ProblemDetail.problem.dsm5description }} - {{ this.ProblemDetail.problem.dsm5 }}</b>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-4">
                                  <div class="float-left col text-muted p-item">
                                    <small>{{$t('modalEditGoal.dIdentified')}}</small><br>
                                    <b>{{ this.ProblemDetail.problem.probDate }}</b>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="float-left col text-muted p-item">
                                    <small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                    <b>{{ this.ProblemDetail.problem.userName }}</b>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <div class="float-left col text-muted p-item">
                                    <small>{{$t('treatmentplanList.cDate')}}</small><br>
                                    <b>{{ this.ProblemDetail.problem.dateTimeCreate }}</b>
                                  </div>
                                </div>
                              </div>
      
                              <MdTitle                                 
                                v-bind:BlankDescription="' '+ $t('treatmentplan.Behavioral')" v-bind:Text="$t('treatmentplan.Behavioral')"
                                v-bind:isSubtitle="true" v-bind:Name="'T_TitleBehavioral'" />
      
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="col text-muted">
                                    <ul>
                                      <li v-for="(behavioral, behaindex) in this.ProblemDetail.problemDefinitions" :key="behaindex">
                                        {{ behavioral.definitionDescription }}
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
      
      
                              <MdTitle 
                                v-bind:BlankDescription="$t('modalAddGoal.goals')" v-bind:Text="$t('modalAddGoal.goals')" v-bind:isSubtitle="true"
                                v-bind:Name="'T_TitleGoal'" />
      
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="text-muted p-item">
                                    <div v-for="(g, goalindex) in this.ProblemDetail.goals" :key="goalindex">
                                      <!-- GOALS -->
                                      <!--<MdTitle v-bind:Text="'Goal ' +(goalindex+1)" v-bind:isSubtitle="true" v-bind:Name="'T_TitleGoals'"/>-->
                                      <div class="mb-2 ml-2">
                                        <b-row v-b-toggle="'goal' + (goalindex + 1)">
                                          <b-col>
                                            <!-- Condicionar esto para mostrar estados específicos -->
                                            <!-- Si el estado es "MET", eliminar la clase completeDraft -->
                                            <span class="chRstatusfinished completeDraft align-middle">{{ g.goal.status
                                              }}</span>
                                            <p class="font-weight-bold ml-1"><b-icon class="when-closed" font-scale="1"
                                                variant="primary" icon="caret-down-fill"></b-icon><b-icon class="when-open"
                                                font-scale="1" variant="primary" icon="caret-up-fill"></b-icon>{{$t('modalEditGoal.goal')}}
                                              {{ goalindex + 1 }} {{ g.goal.goalDesc }}</p>
                                          </b-col>
                                        </b-row>
                                      </div>
                                      <b-collapse :id="'goal' + (goalindex + 1)" class="mb-3 ml-2">      
                                        <b-card bg-variant="light">
                                          <b-row cols="3">
                                            <b-col><small>{{$t('modalEditGoal.dIdentified')}}n</small><br>
                                              <p v-if="g.goal.dateSet">{{ g.goal.dateSet }}</p>
                                              <p v-else>{{$t('Shared.Notdefined')}}</p>
                                            </b-col>
      
                                            <b-col v-if="g.goal.dateExp"><small>{{$t('modalEditGoal.eDate')}}</small><br>
                                              <p>{{ g.goal.dateExp }}</p>
                                            </b-col>
                                            <b-col v-else><small>{{$t('modalEditGoal.eDate')}}</small><br>
                                              <p>{{$t('Shared.Notdefined')}}</p>
                                            </b-col>
      
                                            <b-col v-if="g.goal.dateAchieve"><small>{{$t('modalEditGoal.aDate')}}</small><br>
                                              <p>{{ g.goal.dateAchieve }}</p>
                                            </b-col>
                                            <b-col v-else><small>{{$t('modalEditGoal.aDate')}}</small><br>
                                              <p>{{$t('Shared.Notdefined')}}</p>
                                            </b-col>
      
                                            <b-col v-if="g.goal.status"><small>{{$t('Shared.Status')}}</small><br>
                                              <p>{{ g.goal.status }}</p>
                                            </b-col>
                                            <b-col v-else><small>{{$t('Shared.Status')}}</small><br>
                                              <p>{{$t('Shared.Notdefined')}}</p>
                                            </b-col>
      
                                            <b-col v-if="g.goal.sysUser"><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                              <p>{{ g.goal.userName }}</p>
                                            </b-col>
                                            <b-col v-else><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                              <p>{{$t('Shared.Notdefined')}}</p>
                                            </b-col>
      
                                            <b-col v-if="g.goal.dateTimeCreate"><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                              <p>{{ g.goal.dateTimeCreate }}</p>
                                            </b-col>
                                            <b-col v-else><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                              <p>{{$t('Shared.Notdefined')}}</p>
                                            </b-col>
                                          </b-row>
                                          <div class="mb-2 ml-3" v-for="(o, oindex) in g.objectives" :key="oindex">
                                            <b-row v-b-toggle="'obj' + (goalindex + 1) + (oindex + 1)">
                                              <b-col>                                               
                                                <span class="chRstatusfinished completeDraft">{{ o.objective.status }}</span>
                                                <p class="mt-1"><span class="font-weight-bold"><b-icon class="when-closed"
                                                      font-scale="1" variant="primary" icon="caret-down-fill"></b-icon><b-icon
                                                      class="when-open" font-scale="1" variant="primary"
                                                      icon="caret-up-fill"></b-icon> {{$t('modalEditObjective.Objective')}} {{ oindex + 1 }}</span>:
                                                  {{ o.objective.objDesc }}</p>
                                              </b-col>
                                            </b-row>
      
                                            <b-collapse :id="'obj' + (goalindex + 1) + (oindex + 1)"
                                              class="mb-3 mt-2 ml-2">
      
                                              <b-card bg-variant="white">
                                                <b-row cols="3">
                                                  <!-- IMHO, la condición debería estar en el párrafo, que es lo que cambia -->
                                                  <b-col v-if="o.objective.dateSet"><small>{{$t('modalEditGoal.dIdentified')}}</small><br>
                                                    <p>{{ o.objective.dateSet }}</p>
                                                  </b-col>
                                                  <b-col v-else><small>{{$t('modalEditGoal.dIdentified')}}</small><br>
                                                    <p>{{$t('Shared.Notdefined')}}</p>
                                                  </b-col>
      
                                                  <b-col v-if="o.objective.dateExp"><small>{{$t('modalEditGoal.eDate')}}</small><br>
                                                    <p>{{ o.objective.dateExp }}</p>
                                                  </b-col>
                                                  <b-col v-else><small>{{$t('modalEditGoal.eDate')}}</small><br>
                                                    <p>{{$t('Shared.Notdefined')}}</p>
                                                  </b-col>
      
                                                  <b-col v-if="o.objective.dateAchieve"><small>{{$t('modalEditGoal.aDate')}}</small><br>
                                                    <p>{{ o.objective.dateAchieve }}</p>
                                                  </b-col>
                                                  <b-col v-else><small>{{$t('modalEditGoal.aDate')}}</small><br>
                                                    <p>{{$t('Shared.Notdefined')}}</p>
                                                  </b-col>
      
                                                  <b-col v-if="o.objective.status"><small>{{$t('Shared.Status')}}</small><br>
                                                    <p>{{ o.objective.status }}</p>
                                                  </b-col>
                                                  <b-col v-else><small>{{$t('Shared.Status')}}</small><br>
                                                    <p>{{$t('Shared.Notdefined')}}</p>
                                                  </b-col>
      
                                                  <b-col v-if="o.objective.sysUser"><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                                    <p>{{ o.objective.userName }}</p>
                                                  </b-col>
                                                  <b-col v-else><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                                    <p>{{$t('Shared.Notdefined')}}</p>
                                                  </b-col>
      
                                                  <b-col v-if="o.objective.dateTimeCreate"><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                                    <p>{{ o.objective.dateTimeCreate }}</p>
                                                  </b-col>
                                                  <b-col v-else><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                                    <p>{{$t('Shared.Notdefined')}}</p>
                                                  </b-col>
                                                </b-row>
                                              </b-card>
                                            </b-collapse>
                                          
                                            <div class="mb-2 ml-2" v-for="(i, iindex) in o.interventions" :key="iindex">
                                              <b-row
                                                v-b-toggle="'int' + (goalindex + 1) + (oindex + 1) + (iindex + 1)">
                                                <b-col>
                                                  <p class="mt-1"><span class="font-weight-bold"><b-icon class="when-closed"
                                                        font-scale="1" variant="primary"
                                                        icon="caret-down-fill"></b-icon><b-icon class="when-open"
                                                        font-scale="1" variant="primary" icon="caret-up-fill"></b-icon>
                                                      {{$t('modalEditIntervention.interV')}} {{ iindex + 1 }}</span>:
                                                    {{ i.intDesc }}</p>
                                                </b-col>
                                              </b-row>
      
                                              <b-collapse :id="'int' + (goalindex + 1) + (oindex + 1) + (iindex + 1)"
                                                class="mb-3 mt-2 ml-2">
      
                                                <b-card bg-variant="white">
                                                  <b-row cols="3">  
      
                                                    <b-col v-if="i.startDate"><small>{{$t('GroupNotes.StartDate')}}</small><br>
                                                      <p>{{ i.startDate }}</p>
                                                    </b-col>
                                                    <b-col v-else><small>{{$t('GroupNotes.StartDate')}}</small><br>
                                                      <p>{{$t('Shared.Notdefined')}}</p>
                                                    </b-col>
      
                                                    <b-col v-if="i.amount"><small>{{$t('modalEditIntervention.amount')}}</small><br>
                                                      <p>{{ i.noAmt }} {{ i.amount }}</p>
                                                    </b-col>
                                                    <b-col v-else><small>{{$t('modalEditIntervention.amount')}}</small><br>
                                                      <p>{{$t('Shared.Notdefined')}}</p>
                                                    </b-col>
      
                                                    <b-col v-if="i.frequency"><small>{{$t('Shared.Frequency')}}</small><br>
                                                      <p>{{ i.noFrequency }} {{ i.frequency }}</p>
                                                    </b-col>
                                                    <b-col v-else><small>{{$t('Shared.Frequency')}}</small><br>
                                                      <p>{{$t('Shared.Notdefined')}}</p>
                                                    </b-col>
      
                                                    <b-col v-if="i.duration"><small>{{$t('MdPainAssessmentNotesOutCome.Duration')}}</small><br>
                                                      <p>{{ i.noDuration }} {{ i.duration }}</p>
                                                    </b-col>
                                                    <b-col v-else><small>{{$t('MdPainAssessmentNotesOutCome.Duration')}}</small><br>
                                                      <p>{{$t('Shared.Notdefined')}}</p>
                                                    </b-col>
      
                                                    <b-col v-if="i.therapistCode"><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                                      <p>{{ i.therapistName }}</p>
                                                    </b-col>
                                                    <b-col v-else><small>{{$t('treatmentplanList.createdBy')}}</small><br>
                                                      <p>{{$t('Shared.Notdefined')}}</p>
                                                    </b-col>
      
                                                    <b-col v-if="i.dateTimeCreate"><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                                      <p>{{ i.dateTimeCreate }}</p>
                                                    </b-col>
                                                    <b-col v-else><small>{{$t('treatmentplanList.cDate')}}</small><br>
                                                      <p>{{$t('Shared.Notdefined')}}</p>
                                                    </b-col>
      
                                                  </b-row>
                                                </b-card>
                                                <b-card bg-variant="white" class="mt-2" v-if="i.therapyNames.length > 0">
                                                  <b-row>
                                                    <b-col><small>{{$t('modalEditIntervention.Modalities')}}</small><br>
                                                      <ul>
                                                        <li v-for="(m, mindex) in i.therapyNames" :key="mindex">{{ m }}</li>
                                                      </ul>
                                                    </b-col>
                                                  </b-row>
                                                </b-card>
                                              </b-collapse>
                                            </div>
      
                                          </div>
                                        </b-card>
                                      </b-collapse>
      
                                      <!-- OBJECTIVES -->
      
      
                                    </div>
      
      
                                  </div>
                                </div>
                              </div>
      
      
                            </div>
                          </b-collapse>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
      
       
     </b-modal>  
    </div>
</template>
 
 <script>
 import Utils from '@/common/utils'
 import ShowMessage from '@/components/messages/ShowMessage.js'

 export default {
   name: 'ModalProblemDetail',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     ProblemDetail:Object   
   },
   
   data(){
     return {
        VisibleNoRecords:false
     }
   },
 
   methods:{
     emitEventOK(/*bvModalEvent*/){
     
     },
    FormatTreatmentPlanDates(obj) {       
          obj.goals.forEach(function (g) {
          if (g.goal.dateAchieve)
            g.goal.dateAchieve = Utils.formatterDateToString(g.goal.dateAchieve);
          if (g.goal.dateExp)
            g.goal.dateExp = Utils.formatterDateToString(g.goal.dateExp);
          if (g.goal.dateSet)
            g.goal.dateSet = Utils.formatterDateToString(g.goal.dateSet);
          if (g.goal.dateTimeCreate)
            g.goal.dateTimeCreate = Utils.formatterDateToString(g.goal.dateTimeCreate);

          g.goal.statusobj = { code: g.goal.status, description: g.goal.status };

          g.objectives.forEach(function (o) {
            if (o.objective.dateAchieve)
              o.objective.dateAchieve = Utils.formatterDateToString(o.objective.dateAchieve);
            if (o.objective.dateExp)
              o.objective.dateExp = Utils.formatterDateToString(o.objective.dateExp);
            if (o.objective.dateSet)
              o.objective.dateSet = Utils.formatterDateToString(o.objective.dateSet);
            if (o.objective.dateTimeCreate)
              o.objective.dateTimeCreate = Utils.formatterDateToString(o.objective.dateTimeCreate);


            o.interventions.forEach(function (i) {
              if (i.dateTimeCreate)
                i.dateTimeCreate = Utils.formatterDateToString(i.dateTimeCreate);
              if (i.signedDate)
                i.signedDate = Utils.formatterDateToString(i.signedDate);
              if (i.startDate)
                i.startDate = Utils.formatterDateToString(i.startDate);

            })
          })
        })

      this.ProblemDetail = obj;     

    },

 

     emitEventCancel(){
      this.$emit('ModalTPProblemDetail');
    },    
   },
 
  mounted(){
    this.FormatTreatmentPlanDates(this.ProblemDetail);
   },
 };
 </script>

