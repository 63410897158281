<template>
    <div>
      
        <div>
      <a 
        @click="GotoGroupNoteList()">        
            <span>{{ $t('GroupNotesDashboard.GNoteList') }}</span>
      </a>
      <a class="rm-optionsgnt" v-if="ItemsList.groupNode.groupStatus != 'Finalized'" :class="{ 'isDisabled': itemdisabled, rmActiveItem : active_el == ItemsList.groupNode.headerID }" 
        @click="triggerClick(ItemsList.groupNode)">        
        <span :class="{ 'ikon' : true,  'med-status-pending text-danger': this.ItemsList.groupNode.noteStatus && this.ItemsList.groupNode.noteStatus.includes('Pending'), 'med-status-complete text-success' : this.ItemsList.groupNode.noteStatus == 'Finalized' }"></span> {{ $t('GroupNotesItemRightBar.GroupNoteParent') }}
      </a>
      <a :class="{ 'text-truncate': true, 'isDisabled': itemdisabled, rmActiveItem : active_el == it.patientCode.trim(), 'rm-optionsuserM': true}" 
        v-for="(it,index) in this.searchPatients(this.ItemsList.patientNodes,patient)" :key="index"  
            @click="triggerClick(it)">        
            <span :class="{'ikon': true, 'med-status-complete text-success': it.isNew == false}"></span> {{ it.patientName }}
      </a>
      <small>{{ $t('GroupNotesItemRightBar.OptionMenu') }}</small> 
      </div>
    </div>   
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import { EventBus } from '../../../event-bus.js';  
import DBFunctions from '@/common/DBFunctions.js'

export default {
  name: 'GroupNotesItemRightBar',
  mixins: [ShowMessage, DBFunctions],
 data() {
    return{
        ItemsList: {
         'patientNodes': [],
         'groupNode': {}
        },      
        Class:'',
        itemdisabled: false,
        PatientCode: '',
        aNoteName: "",
        ItemsListAuthorized: [],
        parameter: {
          UserId: "",
        },
        active_el: '',
        message: this.$t('Layout.displayedScreen'),
        patient: '',
        pendingChanges: false,
    }
  },
 
  methods: {

      GotoGroupNoteList(){
          this.$router.push({ name: "groupnotes"});
      },

      setPendingChanges() {
        let NoteInfo = {
          "Status": false
        }
        EventBus.$emit('onNoteIsEditing', NoteInfo);
        this.pendingChanges = false
      },

      goToNote(item) {
        let isGroupNote = item.headerID ? true :false
        this.active_el = isGroupNote ? item.headerID : item.patientCode.trim(); 
        EventBus.$emit('onPatientClickGroupNotes', isGroupNote ? null : item.patientCode);
        EventBus.$emit('onShowNote', isGroupNote, isGroupNote ? item : this.ItemsList.groupNode, item);
      },

      savingChanges(item) {
        //this.setPendingChanges()
        EventBus.$emit('saveFromGroupNotes',item,'')
      },

      triggerClick: function (item){
        if (this.pendingChanges) {
          this.savingChanges(item)
        } else {
          this.goToNote(item)
        }
      },

      checkOne(code){
        this.ItemsList.forEach(element => {
            if(element.value === code){
                element.checked = !element.checked;
            }
        })
      },

      checkAll(){
        var state = this.ItemsList[0].checked;
        this.ItemsList.forEach(element => {            
            element.checked = !state;            
        })
      },

      
      searchPatients(arr, value = '') {
        if (arr && arr.length) {
          if (value == '') {
            return arr;
          }
          value = value.toLowerCase()
          return arr.filter(e => (e.patientName ? e.patientName.toLowerCase().includes(value) : null));
        }
          return null;
      },

      getIsNew() {
        for (let index = 0; index < this.ItemsList.patientNodes.length; index++) {
          const it = this.ItemsList.patientNodes[index];
          if (it.isNew === false) {
            //this.isNewNote = false
            break
          }
        }
      },

      sortPatientNodes() {
        this.ItemsList.patientNodes.sort((a, b) => {
        const nameA = a.patientName.toUpperCase();
        const nameB = b.patientName.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
        });
      },

      isAllPatientNoteNew() {
      let allNew = true
      this.ItemsList.patientNodes.forEach(el => {
        if (el.isNew == false)
          allNew = false
      });
      return allNew
    },
  },


  updated(){
    this.itemdisabled = this.$attrs.itemdisabled;
    this.PatientCode = this.$attrs.PatientCode;
  },

  beforeCreate(){
    this.itemdisabled = this.$attrs.itemdisabled;
    this.PatientCode = this.$attrs.PatientCode;
  },

  async mounted() {
    this.itemdisabled = this.$attrs.itemdisabled;
    this.PatientCode = this.$attrs.PatientCode;
  },

  async created(){

    EventBus.$on("updateNoteStatusOnSave", async function (noteStatus) {
      this.ItemsList.groupNode.noteStatus = noteStatus
    }.bind(this));

    EventBus.$on("updateGroupStatusOnSave", async function () {
      if (this.ItemsList.groupNode.groupStatus == 'New') {
        this.ItemsList.groupNode.groupStatus = 'Pending'
      }
    }.bind(this));

    EventBus.$on("updateNoteStatusOnMoveSave", async function (headerid, signed) {
      if (this.ItemsList.groupNode.headerID == headerid) {
        this.ItemsList.groupNode.noteStatus = signed ? 'Finalized' : 'Pending'
      }
    }.bind(this));

    EventBus.$on("updateGroupStatusOnReset", async function (groupNode) {
        this.ItemsList.groupNode = groupNode
        this.ItemsList.patientNodes = []
    }.bind(this));

    EventBus.$on("resetFromMdNote", async function (patientCode) {
        if (patientCode != 'GroupNote') {
          let selected = this.ItemsList.patientNodes.find(el => el.patientCode === patientCode)
          selected.isNew = true
          if (this.isAllPatientNoteNew()) {
            this.ItemsList.groupNode.groupStatus = 'New'
          }
        } else {
          this.ItemsList.groupNode.noteStatus = 'New'
        }    
    }.bind(this));

    EventBus.$on("updateGroupStatusOnFinalized", async function () {
        this.ItemsList.groupNode.groupStatus = 'Finalized'
    }.bind(this));

    EventBus.$on("updateGroupStatusOnUnFinalized", async function () {
        this.ItemsList.groupNode.groupStatus = 'Pending'
    }.bind(this));

    EventBus.$on("updatePatients", function (headerId) {
      this.ItemsList.patientNodes.forEach(el => {
          if (el.headerId === headerId) {
            el.isNew = false
            EventBus.$emit('updatePatientList',this.ItemsList.patientNodes);
            this.getIsNew()
          }
      });
    }.bind(this));
    
    EventBus.$on("onNoteIsEditing", function (e) {
			this.pendingChanges = e.Status;
		}.bind(this));

    EventBus.$on("onGotoGroupNote", function (e) {
      if (e)
        this.goToNote(e)
		}.bind(this));

    EventBus.$on("onLoadGroupNode", function (e,o) {
      this.ItemsList.groupNode = e
      if (this.ItemsList.groupNode.groupStatus == 'Pending') {
        EventBus.$emit('onPatientClickGroupNotes', null);
        EventBus.$emit('onShowNote', true, this.ItemsList.groupNode, {});
        this.active_el = this.ItemsList.groupNode.headerID
      }
      if ( this.ItemsList.groupNode.groupStatus == 'Finalized') {
        this.active_el = o.patientCode
        EventBus.$emit('onPatientClickGroupNotes', o.patientCode);
        EventBus.$emit('onShowNote', false, this.ItemsList.groupNode, o);
      }

		}.bind(this));

    EventBus.$on("onInsertPatient", function (e) {
      let selected = this.ItemsList.patientNodes.find(el => el.patientCode === e.patientCode)
      if (!selected) {
        this.ItemsList.patientNodes.push(e)
        this.sortPatientNodes();
        EventBus.$emit('updatePatientList',this.ItemsList.patientNodes);
      }
		}.bind(this));

    EventBus.$on("setVoidGroupNote", function (patientCode) {
      let selected = this.ItemsList.patientNodes.find(el => el.patientCode === patientCode)
      if (selected) {
        selected.isVoid = true
      }
		}.bind(this));

    EventBus.$on("unSelectItem", function () {
      this.active_el = ''
		}.bind(this));

    EventBus.$on("onUpdateAfterFinalized", function (patientList) {
        this.ItemsList.patientNodes = []
        patientList.forEach(el => {
          this.ItemsList.patientNodes.push(el)
        });
        this.sortPatientNodes();
        EventBus.$emit('updatePatientList',this.ItemsList.patientNodes);
		}.bind(this));

    EventBus.$on("onDeletePatient", function (e) {
      let selected = this.ItemsList.patientNodes.find(el => el.patientCode === e.patientCode)
      this.ItemsList.patientNodes.splice(this.ItemsList.patientNodes.indexOf(selected),1)
		}.bind(this));

    EventBus.$on("onGNSearchPatient", function (value) {
      this.patient = value
		}.bind(this));
  },

  destroyed(){
		EventBus.$off('onDeletePatient');
		EventBus.$off('onUpdateAfterFinalized');
		EventBus.$off('onInsertPatient');
		EventBus.$off('onLoadGroupNode');
		EventBus.$off('onGotoGroupNote');
		EventBus.$off('onNoteIsEditing');
		EventBus.$off('updatePatients');
		EventBus.$off('updateGroupStatusOnUnFinalized');
		EventBus.$off('updateGroupStatusOnFinalized');
    EventBus.$off('resetFromMdNote');
    EventBus.$off('updateGroupStatusOnReset');
    EventBus.$off('updateNoteStatusOnMoveSave');
    EventBus.$off('updateGroupStatusOnSave');
    EventBus.$off('updateNoteStatusOnSave');
    EventBus.$off('setVoidGroupNote');
    EventBus.$off('onGNSearchPatient');
    EventBus.$off('unSelectItem');
	}
};
</script>

<style>
.isDisabled {
  cursor: not-allowed!important;
  opacity: 0.5!important;
}
.isDisabled > a {
  color: currentColor!important;
  display: inline-block!important;  /* For IE11/ MS Edge bug */
  pointer-events: none!important;
  text-decoration: none!important;
}
.rmActiveItem {
  background-position: -230px !important;
  color: #FFF !important;
}

.itemGroupNote{
    width: 230px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

</style>


