<template>
<div class="fileDropArea" v-cloak @drop.prevent="addFileDragAndDrop($event)" @dragover.prevent>
<div style="display:flex; justify-content:start; flex-wrap:wrap; padding:10px;">
  <div v-if="!ReadOnly && !ChartReview" class="dropfiles" @click="AreaGreenClick"><input type="file" ref="input" hidden multiple="multiple" @change="addFileDragAndDrop($event)"/><i class="icon-cloud-upload"></i> Drag and drop new files here or touch to upload</div>
  <Loading v-bind:IsLoading="this.Load" />   
<div class="fileGroup" v-for="(file,index) in files" :key="index">
    <div class="marquee fileInfo"><p>► {{file.Image}} ({{file.extension}}): {{/*file.description == '' ?*/ Text/* : file.description*/}}</p></div>
  <b-row class="uploaded_files text-center" align-v="center" :data-extension="file.extension">
    <b-icon :id="file.code+'-tip'" icon="file-earmark-arrow-down" v-on:click="DownloadDocument(file)" font-scale="3" :class="file.Image + ' rounded-circle p-2 pointer'" variant="light"></b-icon>
  
    <b-dropdown split :text="file.name" class="ml-1" variant="light" :readonly="IsChartReview" @input="FileChanged()" menu-class="filesdropmenu" v-on:click="OpenDocument(file)">
      <b-dropdown-form v-on:submit.prevent>
        <b-form-group :label="$t('HouseFile.FileName')" :label-for="file.code" @submit.stop.prevent>
          <b-form-input  min="1" max="85" maxlength="85" v-model="file.name" @input="FileChanged()" :readonly="ReadOnly || ChartReview" :ref="file.code" :id="file.code" v-bind:class="[file.name.length === 0 ? 'ng-invalid' : '']" placeholder="File name missing"></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('Shared.Description')" :label-for="file.code+'-desc'" class="mt-2">
          <b-form-textarea
            :id="file.code+'-desc'"
            v-model="file.description"
            @input="FileChanged()"
            placeholder="Enter description here..."
            rows="6"
            :readonly="IsChartReview || ReadOnly"
            max-rows="6"
            class="text-secondary cutescroll py-1"
            ></b-form-textarea>
        </b-form-group>
        <b-button href="#" variant="secondary" class="mt-2" @click="removeFile(file)" :disabled="IsChartReview || ReadOnly">{{$t('Shared.Delete')}}</b-button>
        <!--<b-button href="#" variant="primary" class="float-right mt-2">Update</b-button>-->
      </b-dropdown-form>
    </b-dropdown>
  </b-row>  
</div>
</div>
<!--<ModalDocumentAttachment v-show="FullUrl !== ''"
v-bind:TitleWindow="'Attachment Notes Documents'" 
v-bind:ModalId="'modalIdDocumentAttachment'"
v-bind:Url="FullUrl"      
/>-->

<DxPoPup v-if="isVisible" 
:Title="$t('MdNotesInFiles.Attachment')"
:closeWindow="closeRepWindow"
:isVisible="isVisible"
:Url="this.FullUrl"
/>

</div>
</template>

<script>
import Utils from '../../common/utils'
import AttachmentNotes from '@/services/attachmentnotes.service'
import documents from "../../assets/images/filetypes/documents.jpg"
import graphics from "../../assets/images/filetypes/graphics.jpg"
import compressed from "../../assets/images/filetypes/compressed.jpg"
import audio from "../../assets/images/filetypes/audio.jpg"
import video from "../../assets/images/filetypes/video.jpg"
import models from "../../assets/images/filetypes/models.jpg"
import spreadsheets from "../../assets/images/filetypes/spreadsheets.jpg"
import diskimages from "../../assets/images/filetypes/diskimages.jpg"
import unknown from "../../assets/images/filetypes/unknown.jpg"
import ShowMessage from '@/components/messages/ShowMessage.js'
import Loading from '@/components/template/loading/Loading'
  export default {
    props:{
      Records:Array,
      IsReadOnly:[Boolean],   
      IsChartReview:[Boolean]   
      },
        mixins: [ShowMessage],
    components: {
    Loading
  },
    data: function () { 
      return {
        Text:'',
        Load:false,
        modalIdDocuments:"modalIdPatientDocument",   
        FullUrl:"",
        isVisible:false,
        fileobject:{},
        files:this.Records,
        ReadOnly:this.IsReadOnly,
        ChartReview:this.IsChartReview,
        documents: documents
      };
    },
    methods: {
      closeRepWindow() {
        this.isVisible = false;
      },
      EditName(file){
        document.getElementById(file.code).readOnly = false;
        document.getElementById(file.code).focus();
      },
      ReturnImage(img){
        if(img === 'documents')
          return this.documents;
        else if(img === 'graphics')
          return graphics;
        else if(img === 'compressed')
          return compressed;
        else if(img === 'audio')
          return audio;
        else if(img === 'video')
          return video;
        else if(img === 'models')
          return models;
        else if(img === 'spreadsheets')
          return spreadsheets;
        else if(img === 'diskimages')
          return diskimages;
        else
          return unknown;
      },

      RemoveFocus(file){
        document.getElementById(file.code).readOnly = true;
      },

      AreaGreenClick(){
        this.$refs.input.click();
        this.$refs.input.value = "";
      },

 SyncFileReader(file) {
    let self = this;
    let ready = false;
    let result = '';

    const sleep = function (ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    self.readAsDataURL = async function() {
        while (ready === false) {
          await sleep(100);
        }
        return result;
    }    

    const reader = new FileReader();
    reader.onloadend = function(evt) {
        result = evt.target.result;
        ready = true;
    };
    reader.readAsDataURL(file);
  },

  ExistFile(name){
    var l = this.files.length;
   for(var i = 0; i < l; i++){
        var extension = this.files[i].extension;
        var fullname = this.files[i].name+extension.toLowerCase();
        if(name === fullname){
          return true;
        }
        
    }
    return false;
  },

 async addFileDragAndDrop(e) {

     let droppedFiles = e.type === 'drop'?e.dataTransfer.files:e.target.files;     
     var extension = "";
      if(!droppedFiles) return;
      if(this.IsChartReview || this.ReadOnly){
        this.showMessage(this.$t('MdNotesInFiles.AddFile'),this.$t('MdNotesInFiles.NoteRead-only'),"error");
      }else{
        for (var i = 0; i < droppedFiles.length; i++) {
        var filename = droppedFiles[i].name;
        var tmppath = URL.createObjectURL(droppedFiles[i]);       
        var size = droppedFiles[i].size;
        if(size !== 0){
          if(!this.ExistFile(filename)){
        
        var fileNameNoExtension;
        var pos = filename.lastIndexOf('.');
        if(pos !== -1){
          fileNameNoExtension = filename.substring(0, pos);
          extension = filename.substring(pos).toUpperCase();
        }
        var Image = Utils.getType(extension);
        var type = droppedFiles[i].type;
        var code = this.getUniqueID();
        const fileReader = new this.SyncFileReader(droppedFiles[i]);
        const arrayBuffer = await fileReader.readAsDataURL();
        if(arrayBuffer.indexOf(',') !== -1){
           var b = arrayBuffer.split(',');         
           this.files.push({Url:tmppath,"name":fileNameNoExtension,"Image":Image,"extension":extension,"base64":b[1],"description":"","code":code,"type":type,"new":true,"isreadonly":true});
           this.FileChanged();
        }
        }else{
           this.showMessage(this.$t('MdNotesInFiles.AddFile'),this.$t('MdNotesInFiles.DuplicateFieldMsg'),"error");
        }
        }else{
           this.showMessage(this.$t('MdNotesInFiles.AddFile'),this.$t('MdNotesInFiles.FileNoBytesMsg'),"error");
        }  
      }
      }
      
    },

    getUniqueID(){
      return Date.now() + ( (Math.random()*100000).toFixed());
    },

    removeFile(file){
        this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
          title: this.$t('MdNotesInFiles.DeletFile'),
          okTitle: this.$t('Shared.TitleDelete'),
          cancelTitle: this.$t('Shared.BtnCancel'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
       })
          .then(value => {
            if(value){
                 this.files = this.files.filter(item => item !== file);
                 this.$emit('UpdateFiles', this.files);
                 this.FileChanged();
            }
          })     
    },
 
    async Download(url, filename) {          
           const data = await fetch(url)
           const blob = await data.blob()
           const objectUrl = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.setAttribute('href', objectUrl)
          link.setAttribute('download', filename)
          link.style.display = 'none'
          document.body.appendChild(link)                          
          link.click()                          
          document.body.removeChild(link)
      },

  openBase64InNewTab (data, mimeType) {
    var byteCharacters = atob(data);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var file = new Blob([byteArray], { type: mimeType + ';base64' });
    var fileURL = URL.createObjectURL(file);    
    window.open(fileURL);
},

  DownloadDocument(file){
    console.log('Download');  
    var baseurl = this.$store.state.apiurl.replace('/api/', '');
    this.Load = true;
      if(!file.new){
          AttachmentNotes.getDocumentbyUrl(file.code)
          .then((response) => { 
            if(response.data){ 
              var data = response.data; 
              var name = data.docfilename;
              this.Download(baseurl+data.url,name); 
              this.Load = false;          
            }
          })
          .catch(error => {
            this.Load = false;
            this.showMessage(this.$t('Msg.GetDocument'),error,"error");
          }); 
      }else{
          var data = file.base64;             
          let a = document.createElement("a");
          a.href = "data:"+file.type+";base64," + data;
          a.download = file.name+file.extension;
          a.click();
          this.Load = false;
      }
      
  },

  VerifyExtension(ext){
      if(ext){
        if(ext.toLowerCase() === ".pdf" || ext.toLowerCase() === ".jpeg" ||
       ext.toLowerCase() === ".jpg" || ext.toLowerCase() === ".png" || 
       ext.toLowerCase() === ".bmp" || ext.toLowerCase() === ".svg" ||
       ext.toLowerCase() === ".gif" || ext.toLowerCase() === ".eps"){
        return true;
       }
       return false;
      }
      return false;
    },

  OpenDocument(file){     
    var baseurl = this.$store.state.apiurl.replace('/api/', '');   
   if(!file.new){
      this.Load = true;
          AttachmentNotes.getDocumentbyUrl(file.code)
          .then((response) => { 
            if(response.data){   
              var data = response.data;  
              this.FullUrl = baseurl+data.url; 
              this.fileobject = file; 
              this.Load = false; 
              if(this.VerifyExtension(file.extension)){
                this.isVisible = true;   
              }else{
                let a = document.createElement("a");
                a.href = this.FullUrl;              
                a.click();
                this.Load = false;
              } 
            }
          })
          .catch(error => {
            this.Load = false;
            this.showMessage(this.$t('Msg.GetDocument'),error,"error");
          }); 
      }else{   
          if(this.VerifyExtension(file.extension)){
            this.FullUrl = file.Url; 
            this.isVisible = true;  
          }else{
                var data64 = file.base64;             
                let a = document.createElement("a");
                a.href = "data:"+file.type+";base64," + data64;
                a.download = file.name+file.extension;
                a.click();
                this.Load = false;
          }
           

      }
  },

  SendFiles(files){
    this.$emit('UploadFiles', files);
  },

  FileChanged(){
    this.$emit('FileChanged', true);
  }

    },
    mounted(){     
      this.Text = this.ReadOnly || this.ChartReview ?  this.$t('MdNotesInFiles.Text1'):this.$t('MdNotesInFiles.Text2');
    }
  };
</script>

<style>
.ng-invalid {
  border: 1px solid red !important;
}

.AutoHeightContainerMoreFiles{
height: calc(100%  + 250px) !important;
}

.AutoHeightContainerMinusFiles{
height: 550px!important;
}

.fileDropArea{
  height: calc(100vh - 224px) !important;
}
</style>
