import ApiService from '@/services/api.service'


const NotesControlValues = { 
  
    getImage(id) {
      return ApiService.get(`/NotesControlValues/GetImage/${id}`);
    },
    
  }
  
  export default NotesControlValues;