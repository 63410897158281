<template>
<div>
  <body class="sidebar-fixed">    
      <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth px-0">
          <div class="row w-100 mx-0">
            <div class="col-lg-4 mx-auto">
              <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                <div class="brand-logo">
                  <img src="../../../assets/images/logo.svg" alt="logo">
                </div>
                <div id="ContainerLogin" >
                    <p class="text-muted">{{$t('AccessDenied.aDenied')}}</p>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>     
      </div> 
  </body>
</div>
</template>

<script>
export default {
  name: 'AccessDenied',
  methods:{

  }
   
};
</script>
<style>
.URLName{
  font-weight: bold;
  color:  #19BEE8;
  -webkit-font-smoothing: antialiased;
}
</style>
