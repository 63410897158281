<template>
   <sidebar-menu 
   :relative=false 
   :theme="menuTheme" 
   :hideToggle=true 
   :collapsed="collapsedProp" 
   width="250px" 
   :menu="menu"  
   @toggle-collapse="onToggleCollapse"
   :showOneChild=true
   @item-click="onItemClick"
   />       
</template>

<script>
import MenuService from '@/services/menu.service'
import { EventBus } from '../../../event-bus.js';
import DBFunctions from '@/common/DBFunctions.js'
import $ from "jquery";

//var s;
//EventBus.$on('onFilterMenu', s);



    export default {
      props: {
        Collapsed: Boolean,      
      },     
      name:'MdMenu',
        mixins: [EventBus,DBFunctions],
      computed: {
      collapsedProp: {
        get() { return this.Collapsed}
        }
      
      },
      data() {
          return {  
              OptionsRightMenu:{
                btnvisible:true,
              },         
              menuTheme:'white-theme',
              collap: this.Collapsed,
              menu: [],
              filter:'',
              CopyMenu:[],
              message: '',
              currentRouteName: this.$route.name,
              arr:[
    {
        "type": "header",
        "code": "442373854011698",
        "header": false,
        "hiddenOnCollapse": false,
        "title": "Dashboards",
        "href": null,
        "icon": "med-isologo",
        "child": [
            {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Patient Dashboard",
                "href": "/app/patients",
                "icon": null,
                "child": []
            },
            {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Clinical Dashboard",
                "href": "/app/clinical",
                "icon": null,
                "child": []
            }            
        ]
    } , 
    {
        "type": "header",
        "code": "442373854011698",
        "header": false,
        "hiddenOnCollapse": false,
        "title": "Reports",
        "href": null,
        "icon": "mdi mdi-arrow-right-drop-circle",
        "child": [
            {
                "type": "category",
                "code": "442373856283458",
                "header": true,
                "hiddenOnCollapse": false,
                "title": "Financial",
                "href": null,
                "icon": null,
                "child": null
            },
            {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Patient",
                "href": null,
                "icon": null,
                "child": [
                              {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Patient Census",
                                "href": null,
                                "icon": null,
                                "child": null
                              },
                             {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Patient Admissions",
                                "href": null,
                                "icon": null,
                                "child": null
                              },                              
                ]
            },
            {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Employee",
                "href": null,
                "icon": null,
                "child": [
                              {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Employee Census",
                                "href": null,
                                "icon": null,
                                "child": null
                              },
                             {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Employee Admissions",
                                "href": null,
                                "icon": null,
                                "child": null
                              },                              
                ]
            },
           {
                "type": "category",
                "code": "442373856283458",
                "header": true,
                "hiddenOnCollapse": false,
                "title": "Statisticals",
                "href": null,
                "icon": null,
                "child": null
            },
            {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Patient Stats",
                "href": null,
                "icon": null,
                "child": [
                              {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Patient Census Stats",
                                "href": null,
                                "icon": null,
                                "child": null
                              },
                             {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Patient Admissions Stats",
                                "href": null,
                                "icon": null,
                                "child": null
                              },                              
                ]
            },
            {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Employee Stats",
                "href": null,
                "icon": null,
                "child": [
                              {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Employee Census Stats",
                                "href": null,
                                "icon": null,
                                "child": null
                              },
                             {
                                "type": "menu",
                                "code": "442366253323153",
                                "header": false,
                                "hiddenOnCollapse": false,
                                "title": "Employee Admissions Stats",
                                "href": null,
                                "icon": null,
                                "child": null
                              },                              
                ]
            }, 
            {
                "type": "category",
                "code": "442373856283458",
                "header": true,
                "hiddenOnCollapse": false,
                "title": "OTHERS",
                "href": null,
                "icon": null,
                "child": null
            },                                   
             {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Discharge Report",
                "href": null,
                "icon": null,
                "child": []
            },
             {
                "type": "menu",
                "code": "442366253323153",
                "header": false,
                "hiddenOnCollapse": false,
                "title": "Scheduler Report",
                "href": null,
                "icon": null,
                "child": []
            },            
        ]
    }    
]
          }
      },
      methods:{
        onToggleCollapse (collapsed) { 
          this.Collapsed = collapsed
        },

       FilterMenu(e){
         let result = [];
          if(e !== ''){
            this.doFilter(this.CopyMenu, e, result);
            this.menu = result;
          } else {
            this.menu = this.CopyMenu;
            this.CleanIcon(this.CopyMenu, e, result);
          }          
        },       

      RemoveChilds(array){
        for(var i = 0; i<array.length; i++){
          if(array[i].child.length === 0){
            delete array[i].child;
          }
        }
      },

   doFilter(array, filter, result, force=false) {
          if(!array) return;
          for (let i = 0; i < array.length; i++) {
            let matches = array[i].title.toUpperCase().includes(filter.toUpperCase());
            if (array[i].type == "category") {
              force = matches;
            }
            if(array[i].icon === null){
              array[i].icon = 'ikon med-zoom';
            }
            if (force || matches) {
              let clone = {...array[i]};
              if (!force) {
                delete clone.child;
              }
              result.push(clone);
            }
            if (!force) {
              this.doFilter(array[i].child, filter, result, force);
            }
          }
       },

        CleanIcon(array, filter, result, force=false) {
          if(!array) return;
          for (let i = 0; i < array.length; i++) {
            let matches = array[i].title.toUpperCase().includes(filter.toUpperCase());
            if (array[i].type == "category") {
              force = matches;
            }            
            if(array[i].icon === 'ikon med-zoom'){
              array[i].icon = null;
            }
            if (force || matches) {
              let clone = {...array[i]};
              if (!force) {
                delete clone.child;
              }
              result.push(clone);
            }
            if (!force) {
              this.CleanIcon(array[i].child, filter, result, force);
            }
          }
       },
       AddLangMenuElemParentIcon(json, parentIcon) {
          json.forEach((item) => {
              /** necesary for favorites lang */
              item.originalTitle = item.title;
              item.title = this.$t('menu.' + item.title);
              item.parentIcon = parentIcon || item.icon;
              if (item.child && item.child.length > 0) {
                  this.AddLangMenuElemParentIcon(item.child, item.parentIcon);
              }
          });
      },
        getMenu() {            
          MenuService.getMenu()
            .then((response) => { 
                if(response.data && response.data.length > 0){
                  // Create a copy of response.data
                  /*this.menu = this.arr; /* enable for testing menu */                   
                  this.menu = JSON.parse(JSON.stringify(response.data)); /* disable for testing menu */
                  this.AddLangMenuElemParentIcon(this.menu)
                  
                  this.$store.commit('setLeftMenu',this.menu);
                    /*response.data.forEach(element => {
                    this.$store.commit('setLeftMenu',element);
                    });*/  
                  this.CopyMenu = this.menu;
                  //this.PupulateSelects();
                } 
                document.getElementById('leftLoader').style.display = 'none';
            })
            .catch((error) => {
                document.getElementById('leftLoader').style.display = 'none';
                  if (error.response) {
                    this.showMessage("Error loading menu",error,"error");
                }
            });
        },
        showSuccessToast(titleKey) {
          this.$bvToast.toast(this.message, {
            title: this.$t(titleKey),
            autoHideDelay: 4000,
            variant: "success"
          });
        },

        isOnRoute(item) {
          if(this.currentRouteName === this.$router.currentRoute.name){
              this.showSuccessToast('menu.' + item.title)
          } else {
              this.currentRouteName = this.$router.currentRoute.name
          }
        },
        onItemClick( event, item ) {
          this.message = this.$t('Layout.displayedScreen');
          const isMovile = this.$store.state.isMovile;
          if ( isMovile ) {
            this.$store.commit('setlayoutStatus', 'L0R0');
            this.$emit('getActionFromItems', item); 
          }
          /* Send Data from clicked menu item to the main page*/
          this.$nextTick(() => {
            EventBus.$emit('getClickMenuItemData', item);
          });

          if(item?.child?.length === 0 || item.child === undefined || item.child === null){
            EventBus.$emit('chekMenuClicked', item);
          }
        }
      },
      created: function () {
          EventBus.$on("onFilterMenu", function (e) {
              $('.vsm--item').css('color','red');
            this.FilterMenu(e);
          }.bind(this));

          /** Get current RouteName */
          EventBus.$on("onUpdatedMenu", function (e) {
             this.currentRouteName = e
          }.bind(this));

          /** Add buttons from taskbar */
          EventBus.$on("chekMenuClicked", function (item) {
            this.isOnRoute(item)
          }.bind(this));
      },
      beforeDestroy () {     
        EventBus.$off('onUpdatedMenu');
        EventBus.$off('chekMenuClicked');  
      },
      mounted() {
        this.getMenu();
      }
    }
</script>

<style>
  @import '../../../assets/css/all.css';
 

</style>
