<template>
    <div >
      <DxPopup
        :visible="isVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"        
        :show-close-button="true"
        @hidden="onHiddenReport"
        :show-title="true"
        :hide-on-parent-scroll="true"
        :title="Title"       
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"       
      >
        <div>
          <!-- <b-table striped hover sticky-header="400px" table-class="h-100" :items="items"></b-table> -->
          <MdTable 
            :items="items"
            :showMessageIsEmpty="ShowMessageIsEmpty"
            :sticky-header="true"
            :onRowSelected="onRowSelected"
          />             
        </div>
    </DxPopup>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { DxPopup } from 'devextreme-vue/popup';
    import ShowMessage from '@/components/messages/ShowMessage.js'

    export default Vue.extend({
        name: "DxPoPupTable",
        mixins: [ ShowMessage ],
        components: { DxPopup },
        props: {
          Title:String,
          isVisible: Boolean,   
          items: Array,   
          closeWindow: Function,
          ShowMessageIsEmpty: Boolean,
          onRowSelected: Function
        },
        data() {
            return {
             
            }
        },
        methods: {
            onHiddenReport() {
              this.closeWindow()
            },
        },
        
        mounted () {          
         
        },
    })
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  }
</style>
