
<template>
  <div>
    <h4 v-if="Hide" :class="Class" :id="Name">            
          <b-icon v-if="VisibleAdd"
              @click="ClickAdd"
              icon="plus" 
              v-b-tooltip.hover.top="$t('Shared.Add')+' '+this.IconAddDescription" 
              class="rounded-circle editTitleButton mr-1" 
              scale=".5" 
              font-scale="1.3">
          </b-icon> 
          <b-icon v-if="VisibleAddBlank"
            @click="ClickAddBlank"
            icon="plus-circle-fill" 
            v-b-tooltip.hover.top="$t('Shared.Add')+' '+this.BlankDescription" 
            class="rounded-circle editTitleButton mr-1" 
            scale=".5" 
            font-scale="1.3">
          </b-icon> 
          <b-icon v-if="VisibleAddExisting"
            @click="ClickAdd"
            icon="shield-fill-plus" 
            v-b-tooltip.hover.top="$t('Shared.Add')+' '+this.ExistingDescription" 
            class="rounded-circle editTitleButton mr-1" 
            scale=".5" 
            font-scale="1.3">
          </b-icon> 
          <b-icon v-if="VisibleEdit"
            @click="ClickIcon"
            icon="pencil-fill" 
            v-b-tooltip.hover.top="$t('Shared.Edit')" 
            class="rounded-circle  editTitleButton mr-1" 
            scale=".5" 
            font-scale="1.3">
          </b-icon> 
          <b-icon v-if="VisiblePrint"
            @click="ClickPrint"
            icon="printer-fill" 
            v-b-tooltip.hover.top="this.IconPrintDescription" 
            class="rounded-circle editTitleButton mr-1" 
            scale=".5" 
            font-scale="1.3">
          </b-icon> 
        {{Text}}
    </h4>
  </div>
</template>

<script>
  export default {
    name: 'MdTitle',
    props: {
      IconAddDescription:String,
      Text:          String,
      isSubtitle:    Boolean,
      VisibleEdit :Boolean,
      VisibleAdd:Boolean,
      isHide:        Boolean,
      Name:          String,   
      
      //TP
      VisibleAddBlank:Boolean,
      BlankDescription:String,
      VisibleAddExisting:Boolean,   
      ExistingDescription:String,
      IconPrintDescription:String,
      VisiblePrint:Boolean,    
    },
    data() {
      return {
          Class: this.isSubtitle ? "page-subtitle" : "page-title text-truncate",
          Hide: this.isHide  ? false : true
      }
    },
    methods:{
      ClickIcon(){
        this.$emit('ClickEdit');
      },

      ClickAdd(){
        this.$emit('ClickAdd');
      },

      ClickAddBlank(){
        this.$emit('ClickAddTPBlank');
      },

      ClickPrint(){
        this.$emit('ClickPrint');
      },

    }
  }
</script>