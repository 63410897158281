
import moment from 'moment'
import i18n from '@/plugins/i18n';
i18n.locale = localStorage.lang != null 
            ? localStorage.lang 
            : i18n.locale;

// initialisation of voiceReco
const isSpeechRecognitionSupported = 'SpeechRecognition' in window || 'webkitSpeechRecognition' in window;
let recognition = null;
let recognizing = false;

if(isSpeechRecognitionSupported) {
    window.SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    recognition = new window.SpeechRecognition();
    recognizing = false;
}

const fDefaultDate = "1899-12-30T00:00:00"; //1899-12-30 00:00:00.000
const Utils = {

    HTML2PlainText(HTML) {
        let div = document.createElement("div");
        div.innerHTML = HTML;
        return div.textContent || div.innerText || "";
    },

    strToTitleCase(str) {
        if (str) {
            str = str.toLowerCase();
            str = str.split(' ');
            for (var i = 0; i < str.length; i++) {
                str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
              }
            return str.join(' ');
        }
        return '';
    },

    arrToTitleCase(response, fieldName) {
        if (response) {
         let str = '';
         response.map(function(newArray) {
            str = newArray[fieldName].toLowerCase();
            str = str.split(' ');
            for (var i = 0; i < str.length; i++) {
                str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
              }
            newArray[fieldName] = str.join(' ');
            return newArray;    
        })
        return response;
        }
        return []
    },

     dateIsValid(date) {
        return !Number.isNaN(new Date(date).getTime());
      },

    IsValidFieldDate(date){
        var valid = false;
        if(date !== ''){
            var arr = date.split('-');
            valid = parseInt(arr[0]) > 1900 && parseInt(arr[1]) > 0 && parseInt(arr[1]) <= 12 && parseInt(arr[2]) > 0 && parseInt(arr[2]) <= 31?true:false;
        }
        return valid;
    },

    formatterSSN(inSSN, outSSN){
        if(inSSN == null){
            inSSN = "";
        }    
        if(inSSN.length == 2 || inSSN.length == 5){
            outSSN = outSSN+"-";
        }if(inSSN.length == 11){
            outSSN = outSSN.slice(0, -1);
        }
        return outSSN;
    },

    formatterDate(value){
        if(value){
            return moment(value).format('YYYY-MM-DD')
        }else{
            return null;
        }
    },

    formatDateTime(value){
        if(value){
            return moment(value).format('YYYY-MM-DD HH:mm:ss')
        }else{
            return null;
        }
    },

    DateIsEmpty(value) {		
		return fDefaultDate === value;
	},

    formatterDateToString(value){
        if(value){
            return moment(value).format('MM/DD/YYYY')
        }
    },

    isDateFromAndDateTo(DateFrom,DateTo){
        let result = false
        if(Date.parse(DateFrom) < Date.parse(DateTo) || 
           Date.parse(DateTo) > Date.parse(DateFrom) ||
           Date.parse(DateFrom) == Date.parse(DateTo))
           result = true
        return result
    },

    formatterDateTimeToString(value){
        if(value){
            return moment(value).format('MM/DD/YYYY hh:mm A')
        }
    },

    formatterDateTimeAndSecondsToString(value){
        if(value){
            return moment(value).format('MM/DD/YYYY HH:mm:ss')
        }
    },

    formatDateCustom(value){
        if(value){
            return moment(value).format('YYYYMMDD')
        }
    },

    formatTimeCustom(value){
        if(value){
            return moment(value).format('HHmmss')
        }
    },

    formatTime(value){
        if(value){
            return moment(value).format('hh:mm A')
        }
    },

    formatTime2(value){ //Input format (09:30)
        if(value){
            return moment(value, "HH:mm").format("hh:mm A")
        }
    },

    formatTimeWSec(value){
        if(value){
            return moment(value).format('hh:mm:ss A')
        }
    },

    formatTimeHm(value){
        if(value){
            return moment(value).format('HH:mm')
        }
    },

    setTimeToDateTime(date,time) { //return 2018-06-25T20:10:55-04:00
        let timeArr = time.split(':')
        let newDate = new Date(date);
        newDate.setHours(timeArr[0],timeArr[1])
        return moment(newDate).format();
    },

    getCurrentDateTime() {
        let currentDateTime = new Date();
        return moment(currentDateTime).format();
    },

    ValidateDate(date){ 
            if(this.dateIsValid(date)){
                var ReturnDate = new Date(Date.parse(date)).toISOString();
                return ReturnDate;       
            }else{
                return false;
            }
       
    },

    isValidDate(date) {
        const newDate = moment(date);
        return newDate.isValid();
    },

    ValidateDateSchedulerEditor(startTime, endTime) {
        let result = true
        
        let stTime = moment(startTime).format('MM/DD/YYYY HH:mm')
        let enTime = moment(endTime).format('MM/DD/YYYY HH:mm')
        
        if (stTime === enTime || stTime > enTime) 
            result = false

        return result
    },

    IsValidDateAndRange(date){     
        if(this.ValidateDate(date)){
        var arr = [];
        if(date){         
            arr = date.includes('/') ? date.split('/') : date.split('-');
        }
        if(arr.length >= 3){
            var m = arr[0];
            var d = arr[1];
            var y = arr[2].split(' ');
            var datefull = y[0]+"-"+m+"-"+d;
            return moment(datefull).isBetween('1899-12-30', '2300-12-30');
        }else{
            return false;
        }
    }else{
        return false;
    }
    },

    convertTo24Hour(time) { // 2024-02-12 12:20 am --> 00:20 || 2024-02-12 02:20 pm --> 14:20
        return moment(time, 'YYYY-MM-DD hh:m A').format('HH:mm')
    },

    getType(ext){
        let result = "";
      switch (ext) {
         case ".JPG" :case ".PNG" :case ".TIF" :case ".TIFF" :case ".BMP" :case ".GIF" :case ".HEIC" :case ".SVG" :case ".JPEG" :case ".WEBP":case ".DIB":case ".RLE":case ".TGA":case ".PCX":case ".PSD":case ".CPT":case ".AI":case ".PPM":case ".CEL":case ".PIC":case ".RAW":case ".DDS":case ".PSPIMAGE":case ".THM":case ".YUV":case ".RGB":case ".RGBA":case ".EPS":
          result = "graphics";
          break;
         case ".DOCX" :case ".DOC" :case ".PAGES" :case ".TEX" :case ".WPD" :case ".WPS" :case ".MSG" :case ".LOG" :case ".TXT" :case ".RTF" :case ".ODT" :case ".DOCM" :case ".DOTX" :case ".DOTM" :case ".DOT" :case ".PDF":case ".INDD":case ".PCT": case ".PPT": case ".PPTX": case ".PPS": case ".PPSX":
          result = "documents";
          break;
         case ".ZIP" :case ".RAR" :case ".SITX" :case ".7Z" :case ".GZ" :case ".TPSR" :case ".TAR" :case ".LZMA" :case ".MPKG" :case ".SIFZ": case ".FZPZ" :case ".PUB" :case ".GZIP" :case ".LZ4" :case ".ZLIB" :case ".LZFSE":case ".CBR":case ".DEB":case ".PKG":case ".RPM":case ".TAR.GZ":case ".ZIPX":case ".RTE":case ".ICE":case ".XAPK":case ".SIT":
          result =  "compressed";
          break;
         case ".AIF" :case ".IFF" :case ".M3U" :case ".M4A" :case ".MID" :case ".MP3" :case ".MPA" :case ".WAV" :case ".WMA": case ".OGG":
          result = "audio";
          break;
         case ".3G2" :case ".3GP" :case ".ASF" :case ".AVI" :case ".FLV" :case ".M4V" :case ".MOV" :case ".MP4" :case ".MPG": case ".MKV": case ".RM": case ".STR": case ".SWF": case ".VOB": case ".WMV":
          result = "video";
          break;
         case ".3DM" :case ".3DS" :case ".MAX" :case ".OBJ" :case ".X" :case ".3DMF" :case ".OFF" :case ".C4D" :case ".LWO": case ".LW": case ".MA": case ".DWG": case ".DXF":
          result = "models";
          break;
         case ".XLR" :case ".XLS" :case ".XLSX" :case ".123" :case ".GNUMERIC" :case ".DEX" :case ".DEF" :case ".LX" :case ".NUMBERS": case ".NB": case ".NUMBERS-TEF": case ".PMVX": case ".XLTM": case ".XLSB": case ".SXC": case ".ODS": case ".IMP": case ".MAR": case ".BKS": case ".EFU": case ".OTS": case ".PRESTO": case ".EDXZ": case ".CELL": case ".RDF": case ".XAR": case "._XLS": case ".PMD": case ".SDC": case ".FP": case ".WQ2": case "._XLSX": case ".QPW": case ".GSHEET": case ".FODS": case ".OGWU": case ".WKU":
          result = "spreadsheets";
          break;
         case ".BIN" :case ".CUE" :case ".DMG" :case ".ISO" :case ".MDF" :case ".TOAST" :case ".VCD" :case ".CSO" :case ".DAX": case ".DMGPART": case ".ROM": case ".VDI": case ".QCOW": case ".VMDK": case ".IMG": case ".DVD": case ".MDS": case ".DSK": case ".D00": case ".BWS": case ".MBI": case ".SDI": case ".WLZ": case ".I02": case ".L01": case ".NRG": case ".MD0": case ".DAA": case ".DCF": case ".IMA": case ".TIB": case ".OMG": case ".UIF": case ".CISO": case ".UDF": case ".IMAGE": case ".MD2": case ".IBDAT":
          result = "diskimages";
          break;
        default:
          result = "unknown";
      }
      return result;
    },

    generateUUID() { // Public Domain/MIT
        let d = new Date().getTime();//Timestamp
        let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16;//random number between 0 and 16
            if(d > 0){//Use timestamp until depleted
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    },

    generateRecID() {
        let d = new Date().getTime();
        let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;
        return 'xxxxxxxxxxxxxxxy'.replace(/[xy]/g, function(c) {
            let r = Math.random() * 16;
            if(d > 0){
                r = (d + r)%16 | 0;
                d = Math.floor(d/16);
            } else {
                r = (d2 + r)%16 | 0;
                d2 = Math.floor(d2/16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    },

    validateBirthDate( date ) {
        const birthDate = moment(date, 'YYYY-MM-DD');
        if (!birthDate.isValid()) {
          return false;
        }
        const birthDateYear = birthDate.year();
        const birthDateMonth = birthDate.month() + 1;
        const birthDateDay = birthDate.date();
        const currentDate = moment();
        const currentYear = currentDate.year();
        const currentMonth = currentDate.month() + 1;
        const currentDay = currentDate.date();
        if (
            birthDateYear > currentYear ||
          (birthDateYear === currentYear && birthDateMonth > currentMonth) ||
          (birthDateYear === currentYear && birthDateMonth === currentMonth && birthDateDay > currentDay)
        ) {
          return false;
        }
        return true;
    },
    toggleSpeechBar() {
        if(isSpeechRecognitionSupported) {
            var speechBar = document.getElementById('speechBar');
            if(speechBar.classList.contains("active")){
                speechBar.classList.remove('active');
            } else {
                speechBar.classList.add('active');
            }
        }
    },
    stopSpeechToText() {
        var self = this;
        if(isSpeechRecognitionSupported) {
            self.toggleSpeechBar()
            recognizing = false;
            recognition.stop();
        }
    },
    
    startSpeechToText(elemId) {
        if(elemId) {
            var self = this;
            // Stop voice reconigtion if is on
            // self.stopSpeech();

            if(isSpeechRecognitionSupported) {
                recognition.lang = i18n.locale;
                recognition.continuous = true;
                recognition.interimResults = true;
                recognition.maxAlternatives = 1;
                console.log(elemId)
                var elemById = document.getElementById(elemId);
                
                recognition.onend = () => {
                    if(recognizing) {
                        self.toggleSpeechBar()
                    }
                    recognizing = false;
                };
                
                recognition.onresult = (event) => {
                    if (typeof event.results === "undefined") {
                        recognizing = false;
                        recognition.stop();
                        return;
                    }
                    for (var i = event.resultIndex; i < event.results.length; ++i) {
                        if (event.results[i].isFinal) {
                            // let finalSentence = event.results[i][0].transcript;
                            // elemById.value += (this.capitalizeFirstLetter(finalSentence));
                            elemById.value += Array.from (event.results[i][0].transcript)
                                .join ("");
                            //simulation of trigger keypress
                            elemById.dispatchEvent(
                                new Event("input", { bubbles: true, cancelable: true })
                            ); elemById.dispatchEvent(new Event("change"));
                        }
                    }
                };
                if (!recognizing) {
                    recognition.start();
                    recognizing = true;
                } else {
                    recognition.stop();
                    recognizing = false;
                }
            }
        } else {
            return;
        }
    },
    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    startSpeech(elemId) {
        if(elemId) {
            var self = this;
            // Stop voice reconigtion if is on
            if( recognizing ) {
                self.stopSpeechToText()
            }
            var elemById = document.getElementById(elemId);
            if(isSpeechRecognitionSupported && elemById.value.length > 0 ) {
                if (speechSynthesis.speaking) {
                    // SpeechSyn is currently speaking, cancel the current utterance(s)
                    speechSynthesis.cancel();
                }
                else {
                    // Good to go
                    var binding = new SpeechSynthesisUtterance(elemById.value);
                    binding.lang = i18n.locale;
                    speechSynthesis.speak(binding);

                    binding.onend = () => {
                        self.stopSpeech();
                    }
                }
            }
        } else {
            return;
        }
    },
    stopSpeech() {
        var self = this;
        // Stop voice reconigtion if is on
        if(isSpeechRecognitionSupported ) {
            self.toggleSpeechBar()
            // SpeechSyn is currently speaking, cancel the current utterance(s)
            speechSynthesis.cancel();
           
        }
    },
}

export default Utils