<template>
    <div v-bind:style="style" class="cutescroll">
      <!--<b-input-group >
        <b-form-checkbox-group
            v-model="selected"
            :options="Options"
            :style="this.columnCountStyle"
            name="flavour-2a"
            stacked 
            @change="OnChange"
            :text-field="textField"
            :value-field="valueField"
          ></b-form-checkbox-group>
        </b-input-group>-->
        <b-form-group :label="LabelProp" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              v-model="selected"
              :options="Options"
              :aria-describedby="ariaDescribedby"
              :style="columnCountStyle"
              name="plain-stacked"
              :text-field="textField"
              :value-field="valueField"
              @change="OnChange"
              plain
              stacked
            ></b-form-radio-group>
          </b-form-group>
      </div>
</template>
    
<script>
    import { EventBus } from '@/event-bus.js';
    export default {
      name: 'RadioGroup',
       props: {
         isReadOnly:    Boolean,
         value:         [Number, String],
         TransDate:     String,
         Options:       Array,
         Tag:           String,
         isRequired:    Boolean,
         isValid:       Boolean,
         textField:     String,
         valueField:    String,
         Height:        String,
         columnCount:   Number,
         LabelProp:String,
         OnChangeProp:Function
       },
    
      data(){
          return{
            ControlMax:1,
            selected: this.value,    
            style:{
                overflowY:'auto',
                overflowX: 'hidden',
                height: this.Height,
                padding: '3px 20px',
               
            },
            columnCountStyle:this.columnCount != null ? "column-count: "+this.columnCount+"; column-gap: 100px;" : "column-count: 1;column-gap: 100px;"
          }
      },
    
      watch: {
        value: function (newValue) {
          this.selected = newValue ? newValue : 0;
        },
        
        isValid: function (newValue) {
            this.setValid(newValue)
        }
      },
    
      methods:{
        OnChange(value){  
          this.$emit('change', value);    
          this.$emit('input', value);
          this.style.borderColor = value.length == 0 && this.isRequired ? '#dc3545 !important' : '#e0e0e0 !important';
          this.OnChangeProp(value); 
        },
    
        setValid(state){
          this.style.borderColor = state && this.isRequired ? '#e0e0e0 !important' : '#dc3545 !important' 
        },
    
        onInput(){
          var changed = {
            "Changed":true,
            "ComponentName":this.Label,
            "Tag": this.Tag
          }
          EventBus.$emit('onChanged', changed);
        }
      },
    
      mounted(){}
    };
    </script>

<style>
.form-check .form-check-label {
    min-height: 18px; 
    display: block; 
    margin-left: 0 !important; 
    font-size: 0.875rem;
    line-height: 1.1;
    cursor: pointer;
    transition: all 0.3s ease-out;
}
</style>
    
    
    