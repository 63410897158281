<template>
    <div id="PowerBIReport">
        <MdTitle v-bind:Text="$t(this.$route.params.name)" />
        <div id="report-container">

        </div>
        <div class="page-actions">
        <MdButton
          v-bind:Label="$t('Shared.Back')"
          v-bind:ClassProp="'primary btn-login mx-2'"
          v-on:click="reportList"
          v-bind:Name="'btn_ReportList'"/>       
      </div>
    </div>
</template>

<script>
import PowerBIService from '@/services/powerbi.service'
import * as pbi from "powerbi-client"

export default {
    name: 'PowerBIReport',
    data() {
        return {
            
        }
    },
    methods: {
        embedReport(id){
            if(this.$route.params.type == "report"){
                PowerBIService.getPowerBIReportEmbed(id).then(embedData => {
                    console.log(embedData)
                    this.displayEmbed(embedData)
                }).catch(error => {
                    console.log(error)
                })
            }
            else if(this.$route.params.type == "dashboard"){
                PowerBIService.getPowerBIDashboardEmbed(id).then(embedData => {
                    console.log(embedData)
                    this.displayEmbed(embedData)
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        reportList() {
            this.$router.push({ name: "reportListPowerBI" }).catch(()=>{});
        },
        displayEmbed(embedData){
            var permissions = pbi.models.Permissions.All
            var config = {
                type: embedData.data.type,
                tokenType: pbi.models.TokenType.Embed,
                hostname: "https://app.powerbi.com",
                accessToken: embedData.data.embedToken.token,
                embedUrl: embedData.data.embedReport[0].embedUrl,
                id: embedData.data.embedReport[0].reportId,
                permissions: permissions,
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false
                        }
                    },
                    background: pbi.models.BackgroundType.Transparent
                }
            }
            let powerbi = new pbi.service.Service(
                pbi.factories.hpmFactory,
                pbi.factories.wpmpFactory,
                pbi.factories.routerFactory
            )
            var reportContainer = document.getElementById("report-container")
            var report = powerbi.bootstrap(reportContainer, config)
    
            report.off("loaded");
            report.off("rendered");
            report.on("error", function() {
                this.report.off("error")
            })
        }
    },
    created() {
        this.embedReport(this.$route.params.id)
    }
}
</script>

<style>
    #report-container{
        padding: 2%;
        height: 90vh;
    }
</style>