<template>
    <div id="mdPopup">
        <DxPopup
            :visible="ShowPopup"
            :close-on-outside-click="false"
            :show-close-button="CloseButton"
            :show-title="Title ? true : false"
            :title="Title"
            :width="Width"
            :height="Height"
            @hidden="emitOnHidden"
            @showing="emitOnShowing"
            :shading="true"
            shading-color="rgba(0, 0, 0, 0.5)"
            :animation="null"
        >
        <DxPosition
            at="center"
            my="center"
            of="mdPopup"
        />
        <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            :options="CloseButtonOptions"
        />
        <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            :options="OKButtonOptions"
        />
        <template>
            <DxScrollView
                height="100%"
                width="100%">
                <slot />
            </DxScrollView>
        </template>
        </DxPopup>
    </div>
</template>

<script>
    import { DxPopup, DxToolbarItem, DxPosition } from 'devextreme-vue/popup';
    import { DxScrollView } from "devextreme-vue/scroll-view";
    export default {
        name: 'MdPopup',
        components: {
            DxPopup, DxToolbarItem, DxScrollView, DxPosition
        },
        props: {
            ShowPopup: Boolean,
            CloseButton: Boolean,
            Title: String,
            Width: [String,Number],
            Height: [String,Number],
            BtnOKCaption: String,
            BtnOKIcon: String,
            BtnOKType: String,
            BtnOKClick: Function,
            BtnCloseCaption: String,
            BtnCloseIcon: String,
            BtnCloseType: String,
            BtnCloseClick: Function,
        },
        data() {
            return {
                OKButtonOptions: {
                    icon: this.BtnOKIcon,
                    text: this.BtnOKCaption ? this.BtnOKCaption : this.$t('Shared.TitleOK').toUpperCase(),
                    type: this.BtnOKType,
                    onClick: this.BtnOKClick
                },
                CloseButtonOptions: {
                    icon: this.BtnCloseIcon,
                    text: this.BtnCloseCaption ? this.BtnCloseCaption : this.$t('Shared.Close'),
                    type: this.BtnCloseType,
                    onClick: this.BtnCloseClick
                },
            }
        },

        methods: {
            emitOnHidden(){ 
                this.$emit('OnHidden');
            },

            emitOnShowing(){
                this.$emit('OnShowing');
            }
        },
    }
</script>

<style>
.dx-toolbar-label{
    font-size: 1.3em !important;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
}
.dx-scrollable-wrapper{
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    font-weight: initial;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
}
.dx-popup-bottom{
  vertical-align: middle;
  background: linear-gradient(0deg, #CCC 0%, #dfdfe0 35%, #FFF) !important;
  border-top: 1px #DDD solid;
  padding: 10px !important;
}
.dx-button-mode-contained{
    color: #ffffff !important;
    background-color: #969696 !important;
    border-color: #a3a4a5;
    text-transform: uppercase;
    transition: all 0.3s ease-out;
}
.dx-button-mode-contained:hover{
    color: #fff;
    background-color: #222 !important;
    border-color: #898b8c;
}

.dx-button-mode-contained.dx-button-default{
    color: #fff !important;
    background-color: #0F9CD2 !important;
    border-color: #EEE;
    border-radius: 6px;
    text-transform: uppercase;
    transition: all 0.3s ease-out;
}
.dx-button-mode-contained.dx-button-default:hover{
    color: #fff;
    background-color: #026EB5 !important;
    border-color: #EEE;
}
</style>