<template>
    <!--<div class="notes several" style="background-repeat:repeat;background-image:url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAREAAAEbCAIAAABybSe/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAKdSURBVHhe7cexCcAwAAQx7z+Gp/MY6a94SC9Qo3PuA37oga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hth7YemDrga0Hhvs+U0KVn3ReFYcAAAAASUVORK5CYII=');">--><!-- | 1 = single | +1 = several | --> <!--@click="destroyComp"-->
<div class="notes several">

  <h4 class="note-title text-truncate" v-b-tooltip.hover >
    <span v-if="this.PatientSelected.patientCode === '' || this.PatientSelected.patientCode === 'GroupNote'" >{{ $t('GroupNotesItemRightBar.GroupNoteParent') }} </span>
    <span v-if="this.PatientSelected.patientCode !== '' && this.PatientSelected.patientCode !== 'GroupNote'" >{{ $t('Shared.PatientCode') }} {{this.PatientSelected.patientCode}} - {{$t('Shared.PatientName')}} {{this.PatientSelected.name}}</span>       
  </h4> <!-- if title is too long & there are several notes, use the v-b-tooltip & title properties -->


 <b-dropdown right no-caret size="sm" :text="$tc('Shared.ActionsTxt',2)" :class="this.changesPending && !NoteIsReadOnly ? 'notesActions notSaved' : 'notesActions mr-2'">
   <b-dropdown-item-button v-on:click="SaveNote(false)" >{{ $t('Shared.Save') }}</b-dropdown-item-button>
   <b-dropdown-item-button  
     v-on:click="EnableTherapist"
     v-scroll-to="this.vscrollto"
   >{{ $tc('Shared.Therapist',2) }}                            
   </b-dropdown-item-button>

   <b-dropdown-item-button 
     v-on:click="EnableCosigner"
     v-scroll-to="this.vscrollto"
   >{{ $t('MdNote.Cosigner') }}
   </b-dropdown-item-button>

   <b-dropdown-item-button 
     v-on:click="EnableTherapies"
     v-scroll-to="this.vscrollto"
   >{{ $t('MdNote.BillingInfo') }}
   </b-dropdown-item-button>

   <b-dropdown-item-button v-if="showPrint"
     v-on:click="OnclickPrint"       
   >{{ $t('MdNote.Print') }}
   </b-dropdown-item-button>

   <!--<b-dropdown-item-button>Unlock</b-dropdown-item-button>-->
   
 </b-dropdown>

 <!--clase notesContent Tiene un valor left que hay que tener en cuenta cuando hay varias notas abiertas-->
 <div class="notesContent cutescroll" :id="'ContainerNotesHeader_'">
   <!-- COMPONENTS FOR THE NOTE -->
   <!-- TIP: try inserting & TESTING components one by one -->
   <!--Aqui en el encabezado-->
   <div v-if="VisibleHeader" class="float-right">
     <span id="hidden-wrapper" class="d-inline-block" tabindex="0">
     <b-icon
       icon="x-circle-fill"
       @click="HideHeader"
       class="rounded-circle notes-close"
       scale=".36"
       font-scale="2.6"
       style="position: sticky;"
       > <!-- create the CloseNote action. Open notes should be closed with the HIDDEN class -->
     </b-icon>
     </span>
      <b-tooltip target="hidden-wrapper">{{ $t('MdNote.HideHeader') }}</b-tooltip>
   </div>									
   <div v-if="VisibleHeader" style="margin-left: 0.1em;" class="row" :id="'NotesHeaderParent_'" >
     <div class="col-md-12 mx-auto" :id="'NoteHeader_'">
       <ul class="nav nav-pills nav-pills-custom" id="pills-tab-custom" role="tablist">                      
         <li class="nav-item">
           <a :class="ClassTabTherapist" 
              :href="'#'"
              @click="EnableTherapist"
              id="pills-home-tab-custom" 
              data-toggle="pill"  
              role="tab" 
              aria-controls="pills-home" 
              aria-selected="true">
             {{ $tc('Shared.Therapist',2) }}
           </a>
         </li>
         <li class="nav-item">
           <a :class="ClassTabCosigner" 
              :href="'#'"
              @click="EnableCosigner"
              id="pills-profile-tab-custom" 
              data-toggle="pill" 
              role="tab" 
              aria-controls="pills-profile" 
              aria-selected="false">
             {{ $t('MdNote.Cosigner') }}
           </a>
         </li>
         <li class="nav-item">
           <a :class="ClassTabTherapies" 
              :href="'#'"
              @click="EnableTherapies"
              id="pills-contact-tab-custom" 
              data-toggle="pill"
              role="tab" 
              aria-controls="pills-contact" 
              aria-selected="false">
             {{ $t('MdNote.BillingInfo') }}
           </a>
         </li>         
       </ul>
       <div class="tab-content tab-content-custom-pill flex-row" id="pills-tabContent-custom">
         <div :class="ClassTabContentTherapist" 
              :id="'Therapist-'"
              role="tabpanel" 
              aria-labelledby="pills-home-tab-custom"
           >
           <div class="row flex-row">
             <b-col>
               <MdSelect
                 v-bind:Label="$t('Shared.WrittenBy')"
                 data-field="therapistcode"
                 v-bind:Options="TherapistsList"
                 v-bind:FieldDescription="'fullName'"
                 v-bind:FieldCode="'code'"
                 v-bind:Model="therapistcode"                       
                 v-bind:Multiple="false"
                 v-bind:IsDisabled="NoteIsReadOnly"
                 :Input="ChangeDropDownTherapist"
                 v-bind:Tag="Id"
               />
             </b-col>
             <b-col>
               <MdCheckBox
                 v-bind:Label="$t('MdNote.Sign')"
                 v-bind:Name="'checkbox-Sign'"
                 v-bind:ClassProp="'AlignCheck'"
                 v-bind:CheckedValue="true"
                 v-bind:UncheckedValue="false"
                 v-bind:isReadOnly="NoteIsReadOnly || therapistcode.code === ''"
                 v-model="isSigned"
                 v-on:change="onSignPassword"
                 v-bind:Tag="Id"          
               />
             </b-col> 
             <b-col>
               <div id="date-input-sign">
                 <MdEdit 
                   v-bind:Label="$t('MdNote.SignedOn')"
                   v-bind:TypeProp="'text'" 
                   v-bind:Name="'signdatetime'"
                   v-bind:isRequired="true"
                   v-model="this.Header.signedDate"
                   v-bind:isReadOnly="true"
                   v-bind:Tag="Id"
                   v-bind:Id="'input_mdgSignedOn_id'"
                  >                                     
                 </MdEdit>
               </div>
               <b-tooltip target="date-input-sign" :title="this.Header.signedDate" variant="info"></b-tooltip>
             </b-col>
             <b-col>
               <MdEdit 
                 v-bind:Label="$t('MdNote.Service')"
                 v-bind:TypeProp="'date'" 
                 v-bind:Name="'Service'"
                 v-bind:Min="'1900-01-01'"
                 v-bind:Max="'2200-12-31'"
                 v-bind:isReadOnly="NoteIsReadOnly"
                 v-model="ServiceDate" 
                 @input="ChangeServiceDate"
                 v-bind:Tag="Id"                  
                 >                                     
               </MdEdit>
             </b-col>
             <b-col>
               <MdCheckBox
                 v-bind:Label="$t('MdNote.Billable')"
                 v-bind:Name="'checkbox-Billable'"
                 v-bind:ClassProp="'AlignCheck'"
                 v-bind:CheckedValue="true"
                 v-bind:UncheckedValue="false"
                 v-bind:isReadOnly="NoteIsReadOnly || this.Header.disableBillable"
                 v-model="Header.isBillable"
                 v-on:change="onChangeBillable"
                 v-bind:Tag="Id"
               />
             </b-col>    
             <b-col>
               <MdCheckBox
                 v-bind:Label="$t('MdNote.Void')"
                 v-bind:Name="'checkbox-Void'"
                 v-bind:ClassProp="'AlignCheck'"
                 v-bind:CheckedValue="true"
                 v-bind:UncheckedValue="false"
                 v-bind:isReadOnly="NoteIsReadOnly"
                 v-model="Header.isVoid"
                 v-on:change="onReason"
                 v-bind:Tag="Id"
               />
             </b-col>
             <b-col v-if="HoursList.length > 0">
                   <!-- TODO ADD INFO FOR START AND END TIME-->
                   <MdSelect 
                     v-bind:Label="$t('MdNote.STARTTIME')"                      
                     v-bind:Options="this.HoursList"
                     v-bind:FieldDescription="'text'"
                     v-bind:FieldCode="'value'"
                     v-bind:IsDisabled="NoteIsReadOnly"
                     v-bind:Model="StartTime"                       
                     v-bind:Multiple="false"
                     :Input="ChangeDropDownStartTime"
                     v-bind:Tag="Id"
                   />
                 </b-col>
                 <b-col v-if="HoursList.length > 0">
                   <!-- TODO ADD INFO FOR START AND END TIME-->
                   <MdSelect
                     v-bind:Label="$t('MdNote.ENDTIME')"                      
                     v-bind:Options="this.HoursList"
                     v-bind:FieldDescription="'text'"
                     v-bind:FieldCode="'value'"
                     v-bind:IsDisabled="NoteIsReadOnly"
                     v-bind:Model="EndTime"                       
                     v-bind:Multiple="false"
                     :Input="ChangeDropDownEndTime"
                     v-bind:Tag="Id"
                   />
                 </b-col> 
           </div>

           <div class="tab-end">
           </div>
         </div>

         <div :class="ClassTabContentCosigner" 
             :id="'Cosigner-'" 
             role="tabpanel" 
             aria-labelledby="pills-profile-tab-custom"
           >

               <div class="row flex-row">
                 <b-col>
                   <MdSelect
                     v-bind:Label="$t('MdNote.Cosignedby')"
                     data-field="cotherapistcode"
                     v-bind:Options="CoTherapistsList"
                     v-bind:FieldDescription="'fullName'"
                     v-bind:FieldCode="'code'"
                     v-bind:IsDisabled="NoteIsReadOnly"
                     v-bind:Model="CoTherapists"                       
                     v-bind:Multiple="false"
                     :Input="ChangeDropDownCoTherapist"
                     v-bind:Tag="Id"
                   />
                 </b-col>
                 <b-col>
                   <b-row>
                     <b-col>
                       <MdCheckBox
                         v-bind:Label="$t('MdNote.Sign')"
                         v-bind:Name="'_checkbox-Signed'"
                         v-bind:ClassProp="'AlignCheck'"
                         v-bind:CheckedValue="true"
                         v-bind:isReadOnly="NoteIsReadOnly || CoTherapists.code === ''"
                         v-bind:UncheckedValue="false"
                         v-model="Header.isCoSigned"
                          v-on:change="onChangeCoSigned"
                          v-bind:Tag="Id"  
                       />
                     </b-col>
                   </b-row>
                 </b-col>              
                 <b-col>
                   <div id="date-input-cosign">
                     <MdEdit 
                       v-bind:Label="$t('MdNote.SignedOn')"
                       v-bind:TypeProp="'text'" 
                       v-bind:Name="'cosigndatetime'"
                       v-bind:isRequired="true"
                       v-model="this.Header.coSignedDate"
                       v-bind:isReadOnly="true"
                       v-bind:Tag="Id"
                       v-bind:Id="'input_mdgcoSignedOn_id'"
                       >                                     
                     </MdEdit>
                   </div>
                   <b-tooltip target="date-input-cosign" :title="this.Header.coSignedDate"  variant="info"></b-tooltip>
                 </b-col>
               </div>

           <div class="tab-end">
           </div>
         </div>

         <div :class="ClassTabContentTherapies" 
             :id="'Therapies-'" 
             role="tabpanel"
             aria-labelledby="pills-contact-tab-custom"
           >
           <b-row>
             <b-col lg="3" md="3" sm="12">
               <MdLookup
                 v-bind:Label="$t('MdNote.Facility')"
                 v-bind:ModelCode="FacilityCode"
                 v-bind:ModelDescription="FacilityDescription"
                 v-bind:RefCode="'_Facility'"
                 v-bind:IsDisabled="NoteIsReadOnly"
                 v-bind:Api="'Facilities'"
                 v-bind:FieldCode="'code'"
                 v-bind:FieldDescription="'company'"
                 :SearchTitle="$t('MdNote.Facility')"
                 :GetValues="onCodeFacilityOk"
                 :SearchFieldCode="'Code'"
                 v-bind:Tag="Id"                     
               />
               </b-col>
           </b-row>
           <MdEditTable 
             v-bind:items="ObjectItems.ArrayVModel"
             v-bind:ObjectItems="ObjectItems"
             v-bind:fields="fields"     
             v-bind:isReadOnly="NoteIsReadOnly"
             v-bind:noteStatus="this.Header.noteStatus"
             v-bind:defaultUnit="this.Header.defaultUnit"
             v-bind:transCode="this.Header.transCode"
             v-bind:Tag="Id"
           />

           <div class="tab-end">
           </div>
         </div>
       </div>
     </div>
   </div>
   <div class="alert alert-fill-warning" role="alert" v-if="false">
     <i class="mdi mdi-alert-circle"></i>
     {{ $t('Msg.NoteVoied') }} 
   </div>
   <div class="alert alert-fill-warning" role="alert" v-if="false">
     <i class="mdi mdi-alert-circle"></i>
     {{ $t('MdNote.ReadOnlyPermission') }}
   </div>
   <div class="cutescroll">
     <div style="position:relative;border: solid 1px transparent;">
         <component 
           v-for="(control, index) in DesignerControls" 
           :NoteIsVoid="false" 
           :HeaderId="'6454564'" 
           :is="control.controlType" 
           :control="control" 
           :IsChartReview="false"
           :TransDate="TransDate"
           :NoteId="filter.NotesId"
           :DateFrom="filter.TransDate"             
           :ref="'Ref_'+control.controlID"
           v-model="ArrayModel[index]['VM_'+control.controlID]" 
           :key="control.controlID"
           v-bind:id="control.controlID"
           :HideToolBar="true">
         </component>

         <NotesInFiles 
           v-if="this.VisibleDocumentNotes" 
           v-bind:IsChartReview="IsChartReview"
           v-bind:IsReadOnly="NoteIsReadOnly"
           @UpdateFiles="OnUpdateDocumentFile($event)" 
           @FileChanged="OnFileChanged($event)" 
           v-bind:Records="this.FileRecords"/>
     </div>
   </div>
 </div>

 <MdPasswordDlg v-if="showSignPassword" 
     v-bind:Type="TypePassWord"
     v-bind:UserId="UserIdSelected"
     @getModalPassword="onModalPasswordResult($event)"
 />
 <MdReasonDlg v-if="showReason"
     @getModalReasonCancel="onModalReasonResult($event)"
     @getModalReasonOK="onModalReasonOK"
 />

 <NotesRep v-if="isReportVisible" 
     :closeWindow="closeRepWindow"
     :HeaderId="this.Header.Id"
     :FormId="this.Header.notesId"
     :PatientCode="this.$route.params.id"
     :isReportVisible="isReportVisible"
     :ReportTitle="this.getReportTitle()"
     :Transdate="this.TransDate" 
     :Notetime="this.Header.noteTime ? this.Header.noteTime : null" 
     :Void="false"
     :NoteName="this.NOTE"
 />
 
 <Loading v-bind:IsLoading="this.Load" /> 
</div>	
</template>

<script>
import Vue from 'vue';
import NotesInFiles from '@/components/NotesComponents/MdNotesInFiles'
import { EventBus } from '../../../event-bus.js';
import DBFunctions from '@/common/DBFunctions.js'
import ClinicalService from '@/services/clinical.service'
//import Utils from '../../../common/utils' 
 import Utils from '@/common/utils'
// import LocalStorage from '@/services/storage.service'
/*

import moment from 'moment'
import GlobalService from '@/services/global.service'
import PatientService from '@/services/patient.service' 
import TherapistService from '@/services/therapist.service'
import AttachmentNotes from '@/services/attachmentnotes.service'*/

import TIssSignatureCapture from '@/components/NotesComponents/MdSignatureCaptureCN'
import TIssImage from '@/components/NotesComponents/MdImage'
import TIssImageEditor from '@/components/NotesComponents/MdImageEditor'
import TIssLabel from '@/components/NotesComponents/MdLabelCN'
import TIssEdit from '@/components/NotesComponents/MdEditCN'
import TIssMemo from '@/components/NotesComponents/MdMemoCN'
import TIssRichEdit from '@/components/NotesComponents/MdMemoCN'
import TIssHtmlEditor from '@/components/NotesComponents/MdRichEditCN'
import TIssCheckBox from '@/components/NotesComponents/MdCheckBoxCN'
import TIssFormTable from '@/components/NotesComponents/MdEditTableCN'
import TIssComboBox from '@/components/NotesComponents/MdSelectCN'
import TIssCheckListBox from '@/components/NotesComponents/MdCheckListBoxCN'
import TIssDateTimePicker from '@/components/NotesComponents/MdDateTimePickerCN'
import TIssRadioGroup from '@/components/NotesComponents/MdRadioGroupCN'
import TIssListBox from '@/components/NotesComponents/MdListBox'
import TIssImageBG from '@/components/NotesComponents/MdImage'
import ShowMessage from '@/components/messages/ShowMessage.js'
import TIssMaskEdit from '@/components/NotesComponents/MdMaskEditCN'
import Loading from '@/components/template/loading/Loading'


const VueScrollTo = require('vue-scrollto')
Vue.use(VueScrollTo)



export default {
props: {
 IsChartReview:[Boolean],
 NOTE: [String,Number,Object], 
 TransDate:String,
 Time:String,
 //Header:Object,
 ProgramCode:[String,Number],
 CategoryCode:[String],
 DateFrom:String,
 NotePatientInfo: Object,
},

components: {    
 TIssLabel,TIssSignatureCapture,TIssEdit,TIssMemo,TIssCheckBox,
 TIssFormTable,TIssComboBox,TIssCheckListBox,TIssDateTimePicker,
 TIssRichEdit,TIssImage,TIssImageEditor,
 TIssListBox,TIssImageBG,TIssRadioGroup,TIssMaskEdit,Loading,
 NotesInFiles,TIssHtmlEditor
},
mixins: [EventBus, ShowMessage, DBFunctions],
data: function() {
 return { 
   IsNew:true, 
   PatientSelected:{
    patientCode:"",
    name:""
   },
   Header:{
    signedDate:"",
   },
   lastPatientCode:"",
   FirstCall:false,
   ServiceDate:"",
   isSigned:false,
   IsShowCosignerTab:false,
   VisibleHeader:false,
   VisibleActions:false,
   Id:"HeaderId",
   ClassTabTherapist:'',
   ClassTabCosigner:'',
   ClassTabTherapies:'',
   ClassTabBillingInfo:'',
   DesignerControls:[],
   EmployeeCode:'',
   TypePassWord:'',
   UserIdSelected:'',
   therapistcode:{
     "code": "",
     "firstName": "",
     "fullName": "",
     "lastName": "",
   },
   CoTherapists:{
     "code": "",
     "firstName": "",
     "fullName": "",
     "lastName": "",
   },
   TherapistsList:[],
   CoTherapistsList:[],
   NotesTherapiesByNoteList:[],
   TherapiesBillingInfoList:[],      
   HoursList:[],
   fields: [
         { key: 'Therapies', sortable: true, label: this.$t('Shared.Therapies'), class: 'text-rigth' },
         { key: 'Units', sortable: true, label: this.$t('MdNote.Units'), class: 'text-rigth' },
       ],
  
   Controls:[],
   DataSaved:[],
   ReasonSelected:{},
   //TransDateTitle:moment(this.Header.transDate).format("ddd, ll"),
   TimeTitle:"",
   filter:{},
   ClassTabContentTherapist:'',
   ClassTabContentCosigner:'',
   ClassTabContentTherapies:'',
   ClassTabContentBillingInfo:'',
   ArrayModel: [],
   FileRecords:[],
   //TransDate: Utils.formatterDateToString(this.filter.TransDate),
   DocumentListChanged:false,
   showPrint:false,
   isReportVisible: false,
   VisibleDocumentNotes:false,
   TmpArrayModel:[], 
   ClassTabContent:'tab-pane fade', // tab-pane fade active show
   ClassTabContentActive:'tab-pane fade active show',
   ClassTab:'nav-link', //nav-link  active 
   ClassTabActive:'nav-link active', //nav-link  active 
   itemsNotesHeader: [],
   //ServiceDate:this.Header.serviceDate,
   FacilityCode:"",
   FacilityDescription:"",
   StartTime:{'text':'','value':''},
   EndTime:{'text':'','value':''},        
   NoteOptions:{},
   //Id:this.Header.Id,
   //CopyIsVoid:this.Header.isVoid,
   DataNote:{
     Header:{
     //  Chartno:this.Header.chartno, // ready to send         
       Cotherapistcode:"", // ready to send
     //  Headerid:this.Header.Id.toString(), // ready to send
      // Issigned:this.Header.isSigned, // ready to send
     //  Isvoid:this.Header.isVoid, // ready to send
       IsScheduled:true, // ready to send
      // Notesid:this.Header.notesId, // ready to send
       Patientcode:this.$route.params.id, // ready to send
       Servicedate:this.ServiceDate,       // ready to send
       Therapistcode:"", // ready to send
       //Transcode:this.Header.transCode, //ready to send
       Transdate:this.TransDate, //ready to send
      // Isbillable:this.Header.isBillable, // ready to send
       notescontents: "", // ready to send
       StartTime:this.StartTime,  // ready to send
       EndTime:this.EndTime,  // ready to send
       FacilityCode:"", // ready to send
      // poscode: "", // falta
       ProgCode:this.ProgramCode, // ready to send         
      // IsPendingCoSignature:this.Header.isPendingCoSignature, // ready to send
      // IsCoSigned:this.Header.isCoSigned, // // ready to send
       Amendment:"", // ready to send
       groupCreationId:"", // falta
      // AdmissionRecordId:this.$route.params.recordid, // ready to send
       ApptType:"", // ready to send
       NoteTime:null, // ready to send
       Activityid:"" // ready to send
     },
     details: [       
     ],
     therapies: [],
     attachments:[]
   },
   ObjectItems:{
     ArrayValues:this.TherapiesBillingInfoList,
     ArrayVModel:[],
   },
   therapistList: [
       { value: null, text: '' },  
       { value: 'MEDEZ', text: 'Medez' }
   ],
   therapiesList: [
       { value: null, text: '' },  
       { value: 'ADM', text: this.$t('Shared.Admissions') }
   ],
   voidList: [
       { value: null, text: '' },  
       { value: '1', text: '1' },
       { value: '2', text: '2' },
       { value: '3', text: '3' }
   ],     
   Facility:{ "description": '' },
   Pos:{ "description": '' },
   showSignPassword: false,
   showReason:false, 
   vscrollto :{
       el: '#NoteHeader_',
       container: '#ContainerNotesHeader_',
       duration: 500,
       lazy: false,
       easing: 'linear',
       offset: 0,
       force: true,
       cancelable: true,
       x: false,
       y: true
     },
   NoteIsReadOnly: false,
   Load: false,
   headersChanged: false,
   changesPending: false
   }
},

methods: {

  onSignPassword(/*value*/) {
       /* this.TypePassWord = 'Sign';
        this.UserIdSelected = value ? this.therapistcode.code : '';  
        this.showSignPassword = value;        
        this.Header.isSigned = value;
        this.$set(this.DataNote.Header, 'Issigned', value);*/
      },

  HideHeader(){
      this.VisibleHeader = false;
    },

    onChangeBillable(/*value*/){
       /* this.Header.isBillable = value;
        this.$set(this.DataNote.Header, 'Isbillable', value);*/
      },

      onReason(/*value*/){
        /*this.showReason = value;
        this.Header.isVoid = value;*/       
      },

      EnableTherapist(){
      this.VisibleHeader = true;
      this.ClassTabCosigner = 'nav-link';
      this.ClassTabContentCosigner = 'tab-pane fade';
      this.ClassTabTherapist = 'nav-link active';
      this.ClassTabTherapies = 'nav-link';
      this.ClassTabBillingInfo = 'nav-link';
      this.ClassTabContentTherapist = 'tab-pane fade active show';
      this.ClassTabContentTherapies = 'tab-pane fade';
      this.ClassTabContentBillingInfo = 'tab-pane fade';
    },

    EnableCosigner(){
      this.VisibleHeader = true;
      this.ClassTabCosigner = 'nav-link active';
      this.ClassTabContentCosigner = 'tab-pane fade active show';
      this.ClassTabTherapist = 'nav-link';
      this.ClassTabTherapies = 'nav-link';
      this.ClassTabBillingInfo = 'nav-link';
      this.ClassTabContentTherapist = 'tab-pane fade';
      this.ClassTabContentTherapies = 'tab-pane fade';
      this.ClassTabContentBillingInfo = 'tab-pane fade';
    },
    EnableTherapies(){
      this.VisibleHeader = true;
      this.ClassTabCosigner = 'nav-link';
      this.ClassTabContentCosigner = 'tab-pane fade';
      this.ClassTabTherapist = 'nav-link';
      this.ClassTabTherapies = 'nav-link active';
      this.ClassTabBillingInfo = 'nav-link';
      this.ClassTabContentTherapist = 'tab-pane fade';
      this.ClassTabContentTherapies = 'tab-pane fade active show';
      this.ClassTabContentBillingInfo = 'tab-pane fade';
    },
    EnableBillingInfo(){
      this.VisibleHeader = true;
      this.ClassTabCosigner = 'nav-link';
      this.ClassTabContentCosigner = 'tab-pane fade';
      this.ClassTabTherapist = 'nav-link';
      this.ClassTabTherapies = 'nav-link';
      this.ClassTabBillingInfo = 'nav-link active';
      this.ClassTabContentTherapist = 'tab-pane fade';
      this.ClassTabContentTherapies = 'tab-pane fade';
      this.ClassTabContentBillingInfo = 'tab-pane fade active show';
    },

    OnclickPrint(){

    },

    ChangeServiceDate(/*e*/){      
       // this.$set(this.DataNote.Header, 'Servicedate', e);
      },


      ChangeDropDownCoTherapist(/*value*/) {
       /*  if(value){
           this.CoTherapists = value;
           this.$set(this.DataNote.Header, 'Cotherapistcode', value.code);
         }else{
           this.CoTherapists = {
              "code": "",
              "firstName": "",
              "fullName": "",
              "lastName": "",
           };
           this.$set(this.DataNote.Header, 'Cotherapistcode', "");
         }
        this.Header.isCoSigned = false;
        this.Header.isPendingCoSignature = true;*/
      },

    ChangeDropDownTherapist(/*value*/){ 
       /* if(value){
          this.therapistcode = value;  
          this.$set(this.DataNote.Header, 'Therapistcode', value.code);    
          this.Header.isSigned = false;    
        }else{
          this.therapistcode = {
            "code": "",
            "firstName": "",
            "fullName": "",
            "lastName": "",
          }
          this.$set(this.DataNote.Header, 'Therapistcode', "");
          this.Header.isSigned = false;
        } */
         //this.Header.isSigned = false;
      },

      onChangeCoSigned(/*value*/){
       /* this.Header.isCoSigned = value;
        this.TypePassWord = 'Cosign';
        this.UserIdSelected = value ? this.CoTherapists.code : '';        
        this.showSignPassword = value;      
        this.$set(this.DataNote.Header, 'IsCoSigned', value);*/
      },

      onCodeFacilityOk(/*result*/){
       /* this.itemsNotesHeader.facilityCode = result.code;
        this.FacilityCode = result.code;
        this.FacilityDescription = result.description;
        this.$set(this.DataNote.Header, 'FacilityCode', result.code);*/
      },

    async getFilterbyGroupCreationId(){
        var filters =  this.$store.state.GroupNotesFilters;      
        var id = this.$route.params.id;        
        for(var i = 0; i < filters.length; i++){          
          if(filters[i].GroupCreationId === id)
            return await filters[i];
        }       
        return await null;
       },


SetReadOnlyDivideNote(PatientCode,arr){
    var result = [];
    if(PatientCode === 'GroupNote'){
        arr.forEach(element => {
            if(element.isCommonControlNote){
                result.push(element);
            } 
       });
    }else{
         arr.forEach(element => {
            if(element.isCommonControlNote){              
            element.isReadOnly = element.controlType === 'TIssDateTimePicker' ? 'Y' : true;
            } 
       });
        result = arr;
    }
    return result;
},

setNoteIsEditing(NoteId,isChanged){
        var NoteInfo = {
         "NoteId":NoteId,
         "Status":isChanged
        }
        EventBus.$emit('onNoteIsEditing', NoteInfo);
        if(!isChanged){
          this.ArrayModel.map(function(component){
          if(component.Changed == true && component.NoteId == NoteId){
            component.Changed = false;
          }
            return component;
          });
          this.headersChanged = false;
          this.DocumentListChanged = false;
        }
        if(NoteId === this.Id){
          this.changesPending = isChanged
          this.showPrint = false
        }
},

AutoSave(){
 setTimeout(() => 
    this.$bvToast.toast(this.$t('GroupNotes.autoSaveMsg'), {
      title: this.$t('GroupNotes.GroupNotes'),
      autoHideDelay: 800,
      variant: "success"
    })
    ,50); 
},
 
getControlsAndValues(PatientCode, HeaderId){
    


 
  if(PatientCode !== this.lastPatientCode){
    this.Load = true;
  var params = {
    "NoteId":this.filter.NoteId,
    "PatientCode":PatientCode,
    "IsGroupNote":true,
  //"RecordId":this.$route.params.recordid,
    "TransDate":Utils.formatterDateToString(this.filter.TransDate),
    "HeaderId": HeaderId ? HeaderId : null,
  //"TransCode":this.Header.transCode,
    "IsNew":this.filter.Status === 'NEW' ? true : false,
};

if(this.FirstCall){
  this.AutoSave();
}

if(!this.FirstCall){
  this.FirstCall = true; 
}

setTimeout(() => 
ClinicalService.getControlsAndvalues(params)
    .then(async (response) =>  {              
      if(response.data && response.data.length > 0){      
        this.DesignerControls = [];
        this.lastPatientCode = PatientCode;
        var arr = response.data;            
        var l = arr.length; 
        for(var i = 0; i < l; i++){
            let valueItems = {};
            var ControlType = arr[i].controlType;
            var ControlId = arr[i].controlID;
            valueItems["Type"] = arr[i].controlType;    
            valueItems["ControlId"] = arr[i].controlID;
            valueItems["Extension"] = arr[i].extension;
            valueItems["Required"]  = arr[i].isRequired;
            valueItems["Changed"]  = false;
            valueItems["NoteId"] = this.Id

            if(ControlType === 'TIssEdit' || ControlType === 'TIssMemo' || 
               ControlType === 'TIssRichEdit'|| ControlType === 'TIssMaskEdit' || ControlType === 'TIssHtmlEditor' ){
                valueItems["VM_"+ControlId] = arr[i].contents;
            }else if(ControlType === 'TIssDateTimePicker'){
                valueItems["VM_"+ControlId] = arr[i].contents ? Utils.formatterDate(arr[i].contents) : "";
            }
            else if(ControlType === 'TIssImageBG' || ControlType === 'TIssSignatureCapture' || ControlType === 'TIssImage' || ControlType === 'TIssImageEditor'){ 
                valueItems["VM_"+ControlId] = arr[i].imageContents;
            }else if(ControlType === 'TIssCheckListBox'){
                valueItems["VM_"+ControlId] = arr[i].selectedValues;
            }else if(ControlType === 'TIssRadioGroup'){
                valueItems["VM_"+ControlId] = arr[i].selectedValues[0];
            }                  
            else if(ControlType === 'TIssComboBox'){
                valueItems["VM_"+ControlId] = arr[i].selectedValues[0];
            }
            else if(ControlType === 'TIssCheckBox'){
                valueItems["VM_"+ControlId] = arr[i].checked;
            }else if(ControlType === 'TIssFormTable'){
                var itemstable = arr[i].table.rows !== null?arr[i].table.rows:[];
                valueItems["VM_"+ControlId] = itemstable;

                for(var j = 0 ; j < arr[i].table.columns.length; j++ ){
                  if(arr[i].table.columns[j].isRequired){
                      arr[i].table.columns[j].thClass = '';
                  }
                }
                valueItems["Columns"]  =  arr[i].table.columns;
            }
            else{
                valueItems["VM_"+ControlId] = "";
            }
            this.ArrayModel.push(valueItems);
        } 
       
        this.DesignerControls = await this.SetReadOnlyDivideNote(PatientCode, response.data);       
        this.VisibleActions = true;        
    }   
    this.Load = false;
    })
    .catch(error => {
      if (error.response) {             
        this.showMessage(this.$t('MdNote.ControlsValues'),error,"error");
      }      
     this.Load = false;
    })
    ,550); 



  }



/*} else if(this.Header.customFrame === 'TFRMDOCUMENTNOTES'){
    this.getDocuments();
}*/
},
  
},

mounted(){  
   
},

updated () {
 
},

async created(){ 
    this.filter = this.$store.state.GroupNotesFilters //await this.getFilterbyGroupCreationId();
    this.getControlsAndValues("GroupNote");

    EventBus.$on("onClickRightMenuItem", function (item) {
      this.PatientSelected = item;   
        this.getControlsAndValues(item.patientCode, item.headerId);      
     }.bind(this));

     EventBus.$on("onSignatureDone", function (e) {  
        const code = e.ControlId;
      this.ArrayModel.map(function(control){
        if(control.ControlId == e.ControlId){
            control["VM_"+code] = e.base64;
        }
          return control;
      });  
    }.bind(this));

     EventBus.$on("onControlWasChanged", function (e) {   
        this.ArrayModel.map(function(control){
        if(control.ControlId == e.ControlId && control.NoteId == e.NoteId){
          control.Changed = e.Changed;
        }
          return control;
      });
        this.setNoteIsEditing(e.NoteId,true)
     }.bind(this));

     EventBus.$on("onChanged", function (e) { 
        if(e.Tag == this.Id) {
          this.headersChanged = e.Changed
        }
        this.setNoteIsEditing(e.Tag, true)
     }.bind(this));

     EventBus.$on("onSignatureClear", function (Id) {   
      this.ArrayModel.map(function(control){
        if(control.ControlId == Id){         
           control["VM_"+Id] = "";
        }
          return control;
      });
     }.bind(this));


},

destroyed(){   
    EventBus.$off("onClickRightMenuItem");  
}
}

</script>

<style>
.SignedDate > span{
 color:black;
 font-size:12px;   
 font-weight:bold;       
}
.multiselect__single, input {
 white-space: nowrap;
 overflow: hidden; 
}

.RequiredClass{
border: solid 2px #dc3545 !important;
border-bottom: 2px solid red!important;
/* border-style: solid !important;
border-color: rgb(201, 76, 76)!important;
border-color: red !important;*/
}
</style>