<template>
    <div id="diagnosis">
        <div class="row">
            <div class="col-12">
                <h4 class="page-title text-truncate"> {{ $t('Diagnosis.PatientDiagnosis') }} - 
                    <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
                    <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
                    <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
                    <span v-if="this.PatientTitle.ageFormatted">  {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
                    <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
                    <span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
                    <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
                    <span v-else v-show="IsClinical">- {{ $t('Shared.Active') }}  </span>
                </h4>                     
            </div>
        </div>
        <p class="mx-3 text-muted" v-if="this.items.length > 0">
            {{ $t('Diagnosis.HasDiagnostic',{fullName: this.PatientTitle.fullName}) }}
        </p>
        <p class="mx-3 text-muted" v-if="this.items.length == 0">
            {{ $t('Diagnosis.NoDiagnostic',{fullName: this.PatientTitle.fullName}) }}
        </p>
        <div class="row">
            <div class="col-md-12">
                <div v-for="(i,index) in this.items" :key="index">                  
                 <MdTitle @ClickEdit="OpenModal(i)" v-bind:VisibleEdit="i.VisibleEdit && !isReadOnly" v-bind:Text="i.title" v-bind:isSubtitle="true" v-bind:Name="i.programName"/>
                 <table class="diagTable mx-2">
                    <tr v-for="(v,index) in i.diagnoses" :key="index">
                        <td><span class="diagCode">{{v.code}}</span></td>
                        <td>{{v.description}}</td>
                    </tr>
                 </table>
                </div>
            </div>
        </div>

        <ModalDiagnosis
            v-bind:TitleWindow="this.$t('MdPatientDiagCodes.Diagnoses')"
            v-bind:ModalId="this.modalId"           
        />

    </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import EnumService from '@/services/enum.service'
import AdmissionService from '@/services/admission.service'
import PatientService from '@/services/patient.service'  
import Utils from '../../../common/utils'
import { EventBus } from '@/event-bus.js';
import moment from 'moment'

export default {
 mixins: [ShowMessage, DBFunctions],
  name: 'Diagnosis',

  data(){
      return{
        IsClinical:false,
        infoList:[],
       modalId:"modalDiagnosis",
       RegistrationInfo:{},
       AdmissionSelected:{},
       BaseFields:[{ key: 'name', sortable: true, label: this.$t('Shared.Description') },{ key: 'code', sortable: true, label: this.$t('Shared.Code')}],
       isReadOnly:false,
       items:[],
       PatientTitle:{      
            programName:'',
            fullName:'',
            admitDate:'',
            dischargeDate:'',
            dob:''
        },
        OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
        },  
      }
  },
  methods: {

    OpenModal(i){
        EventBus.$emit('onOpenModalDiagnosis', i);
        this.$bvModal.show(this.modalId);         
    },

    UpdateDiagnose(data){       
        AdmissionService.DeleteAndInsert(data)
                .then((response) => {
                    if(response.status == '200'){
                        this.getDiagnoses();
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('MdPatientDiagnosis.Diagnosis'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                           
                })
                .catch((error) => {
                    if (error.response) {
                        this.showMessage('Error',error,"error");
                    }
                });  
    },

    async GetPatientByCode(){
      this.infoList = await this.getInfoTitle(this.$route.params.id);
      if(this.infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
         
           this.PatientTitle.age = this.infoList[0].age;
           this.PatientTitle.ageFormatted = this.infoList[0].ageFormatted;
          if(this.infoList[0].dob){
              this.PatientTitle.dob = moment(this.infoList[0].dob).format('MM/DD/YYYY');
          }   
          this.PatientTitle.fullName = this.infoList[0].firstname + ' '+ this.infoList[0].lastname;
      }
    },

         formatterDateToString(date){
            return Utils.formatterDateToString(date);
        },

    getDiagnoses(){         
             PatientService.getDiagnoses(this.$route.params.id)
                .then((response) => {
                     if(response.status == '200' && response.data){
                        this.$emit('load', false);
                        this.items= response.data;                        
                            this.items.forEach(element => {                     
                                element.dateFrom = element.dateFrom ? this.formatterDateToString(element.dateFrom) : "";
                                element.dateTo = element.dateTo ? this.formatterDateToString(element.dateTo) :  this.$t('Shared.Active');
                                                         
                              if(element.programName !== 'CURRENT DIAGNOSIS'){
                                element.title = this.$t('MdPatientDiagnosis.Diagnosis')+" - "+element.programName;                                  
                                element.VisibleEdit = true;
                                if(element.dateFrom){
                                    element.title += ' - ' + element.dateFrom;
                                } 
                                if(element.dateTo){
                                    element.title += ' - ' + element.dateTo;
                                } 
                              }else{
                                element.title = element.programName; 
                                element.VisibleEdit = false;
                              }
                             
                            });
                     }             
                })
                .catch((error) => {            
                     if(error.response.status === 400 ){
                        this.showMessage(this.$t('MdPatientDiagnosis.Diagnosis'),error.response.data.errorMessage,"error");
                    }else{
                        this.showMessage(this.$t('MdPatientDiagnosis.Diagnosis'),error,"error");
                    }  
                    this.$emit('load', false);
                });  
             },
             
    PopulatePatientTitle(){
        if(this.RegistrationInfo.dateFrom)
            this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
        if(this.RegistrationInfo.dateTo)
            this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');	
      if(this.$route.name.includes('Clinical-diagnosis')){ 
          this.PatientTitle.programName = this.RegistrationInfo.programName;
          this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
          this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
          this.PatientTitle.fullName = this.RegistrationInfo.patientName;
          this.PatientTitle.dob = moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');
          this.PatientTitle.age = this.RegistrationInfo.age;
          this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }
      if(this.$route.name.includes('patient-diagnosis')){  
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';
          if(this.infoList.length > 0){
            this.PatientTitle.dob = moment(this.infoList[0].dob).format('MM/DD/YYYY');            
            this.PatientTitle.fullName = this.infoList[0].firstname + ' '+ this.infoList[0].lastname;
          }
          
      }
    },
  },
  async mounted() {
    this.$emit('load', true);  
    this.OptionsRightMenu.visible = true;      
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('Clinical-diagnosis') ? EnumService.ClinicalItems : EnumService.PatientItems;
    this.$emit('getVisibleMenu', this.OptionsRightMenu);   
    this.getDiagnoses();     
    this.isReadOnly = this.$store.state.isPatientDiagnosisEHRReadOnly;    
    if(this.$route.name.includes('patient-diagnosis')){ 
        this.GetPatientByCode();        
     } 

    if(this.$route.name.includes('Clinical-diagnosis')){  
        
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);        
         this.IsClinical = this.RegistrationInfo.dateTo?false:true;
         if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;
    }

    this.PopulatePatientTitle();
   
    


  },
  created(){
    EventBus.$on("onSaveDiagnoses", function (obj) {        
        this.UpdateDiagnose(obj);        
    }.bind(this));
  },

  beforeDestroy () {     
        EventBus.$off('onSaveDiagnoses');       
    },
   
};
</script>


