<template>
    <div>
     <b-modal size="xl" :id="ModalId" :visible="true" centered no-close-on-backdrop no-close-on-esc :title="TitleWindow" ref="modalEditSignature" @close="this.emitEventCancel" @ok="handleOk" :ok-title="$t('Shared.Save')"  @cancel="this.emitEventCancel"> 
      
      <div class="card bg-light">
        <div class="card-body">  
            <form ref="form_SignatureEdit" @submit.stop.prevent="handleSubmit">                
            <b-row>        
                <b-col lg="3" md="3" sm="12">         
                    <MdSelect
                        v-bind:Label="$t('modalProviderSelector.Provider')"
                        v-bind:Options="ProviderList"
                        v-bind:FieldDescription="'therapistName'"
                        v-bind:FieldCode="'therapistCode'"
                        v-bind:Model="SignatureEdit.ProviderModel"                                 
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownProvider"
                    />                           
                </b-col>
                <b-col lg="1" sm="1" md="12" >
                    <MdCheckBox
                    v-bind:Label="$t('MdNote.Sign')"
                    v-bind:Name="'checkboxAdd-Sign'" 
                    v-bind:ClassProp="'AlignCheck'"
                    v-bind:CheckedValue="true"
                    v-bind:UncheckedValue="false"              
                    v-model="SignatureEdit.isSigned"
                    v-on:change="onSignatureTP"
                    v-bind:Tag="'SignAddTreatmentPlanSignature'"          
                    />
                </b-col>                         
                <b-col lg="3" md="3" sm="12">         
                    <MdSelect
                        v-bind:Label="$t('MdNote.Cosignedby')"
                        v-bind:Options="CoProviderList"
                        v-bind:FieldDescription="'coTherapistName'"
                        v-bind:FieldCode="'coTherapistCode'"
                        v-bind:Model="SignatureEdit.CoProviderModel"                                 
                        v-bind:Multiple="false"
                        :Input="ChangeDropDownCoProvider"
                    />
                </b-col>
                <b-col lg="1" sm="1" md="12" >
                    <MdCheckBox
                    v-bind:Label="$t('MdNote.Sign')"
                    v-bind:Name="'Co-checkboxAdd-Sign'" 
                    v-bind:ClassProp="'AlignCheck'"
                    v-bind:CheckedValue="true"
                    v-bind:UncheckedValue="false"              
                    v-model="SignatureEdit.isCosigned"
                    v-on:change="onCoSignatureTP"
                    v-bind:Tag="'CoSignAddTreatmentPlanSignature'"          
                    />
                </b-col> 
                <b-col lg="3" md="3" sm="12">         
                  <MdSelect
                      v-bind:Label="$tc('Incident.IncPartClient')"
                      v-bind:Options="ClientList"
                      v-bind:FieldDescription="'name'"
                      v-bind:FieldCode="'code'"
                      v-bind:Model="SignatureEdit.ModelClient"                                 
                      v-bind:Multiple="false"
                      :Input="ChangeDropDownClient"
                  />
              </b-col>
              <b-col>
                  <MdCheckBox
                  v-bind:Label="$t('MdNote.Sign')"
                  v-bind:Name="'checkbox-Client'" 
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"                       
                  v-bind:UncheckedValue="false"              
                  v-bind:isReadOnly="disabledCheckClient"                      
                  v-model="SignatureEdit.ModelClientCheck"                 
                  v-on:change="onSignatureClient($event)"
                  v-bind:Tag="'TreatmentPlanSignatureClient'"            
                  />  
              </b-col>
            </b-row>
            </form> 
        </div>
    </div>
       
     </b-modal>  
     <MdPasswordDlg v-if="showSignPassword" 
     v-bind:Type="TypePassWord"
     v-bind:UserId="UserIdSelected"
     @getModalPassword="onModalPasswordResult($event)"
  />
  <modalSignPatientGuardian
      @ModalTPSignPatientGuardian="OnReturnSign($event)" 
      @ModalTPSignPatientGuardianWizardOK="OnReturnOKWizard($event)"
      v-if="showModalSignPatientGuardian" 
      v-bind:Option="option"
      v-bind:ModalId="'modalIdSignPatientEditGuardian'" 
      v-bind:ItemChecked="itemChecked" 
      v-bind:TitleWindow="labelSign"
      v-bind:ShowGuardianDrop="ShowGuardianDrop"
  />
    </div>
</template>
  
  <script>
  //import Utils from '@/common/utils'
  import ShowMessage from '@/components/messages/ShowMessage.js'
  import TreatmentPlan from '@/services/treatmentplan.service'
  import EmployeeService from '@/services/employee.service'
  import LocalStorage from '@/services/storage.service'
  
  
  export default {
   name: 'ModalEditSignature',
   mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     ProblemRecId:String,
     groupId:Number,
     headerId:String,
     probCode:String, 
     SignatureEdit:Object  
   },
   
   data(){
     return {
      option:"",
      labelSign:"",
      ShowGuardianDrop:false,
      ProviderList:[],
      ClientList:[
            {code:"PatientSigned",name:this.$t('modalAddSignature.pSigned')},
            {code:"PatientRefused",name:this.$t('modalAddSignature.pRefused')},
            {code:"GuardianSigned",name:this.$t('modalAddSignature.gSigned')},
          ],
          ClientModel:{code:"",name:""},
      isSigned:false,
      TypePassWord:"",
      itemChecked:{},  
      DataModalGuardianSig:{}, 
      UserIdSelected:"",   
      showSignPassword: false,  
      isCosigned:false,
      ClientCheckModel:false, 
      showModalSignPatientGuardian:false,   
      refuseSigned:false,
      isPatientSigned:false,
      disabledCheckClient:false,
      CoProviderList:[],
      CoProviderModel:{coTherapistCode:"",coTherapistName:""},
      GuardianList:[],
      ProviderModel:{therapistCode:"",therapistName:""},
      GuardianModel:"",
      guardianSigned:false,
      signedDate:null,
      coSignedDate:null,
      patientSignedDate:null,
      ContactDate:null,
      
      objectivedescriptionBlank:"",
      ObjectiveList:[],
      ObjectiveModel:[],
      Objectivedata:[],
     }
   },
  
   methods:{
     emitEventOK(/*bvModalEvent*/){  
      this.UpdateSignatureModal();       
     },

     handleOk(bvModalEvt) {      
        bvModalEvt.preventDefault();      
        this.handleSubmit()
      },

    checkFormValidity() {
         return this.SignatureEdit.isSigned || this.SignatureEdit.isCosigned || this.SignatureEdit.ModelClientCheck;
      },

    handleSubmit() {       
        !this.checkFormValidity() ? this.showMessage(this.$t('modalAddSignature.sign', {'label': this.$t('Shared.Edit')}),this.$t('modalAddSignature.atLeastMsg'),"error"):this.emitEventOK();
      },
  
     ChangeDropDownClient(value){         
              this.SignatureEdit.ModelClient = value ? value : {code:"",name:""};      
              this.showModalSignPatientGuardian = false;       
              if(value){
                  if(value.code === "PatientRefused"){                  
                      this.disabledCheckClient = true;
                      this.option = this.$tc('Shared.Patient');
                      this.labelSign = this.$t('modalAddSignature.sign', {'label': this.$tc('Shared.Patient')});
                      this.SignatureEdit.ModelClientCheck = false;
                  }else{
                      this.disabledCheckClient = false;
                  } 
  
                  if(value.code === "GuardianSigned"){
                      this.option = this.$t('modalAddSignature.guardian');
                      this.labelSign = this.$t('modalAddSignature.sign', {'label': this.$t('modalAddSignature.guardian')});
                      this.ShowGuardianDrop = true;
                  }
  
                  if(value.code === "PatientSigned"){
                      this.option = this.$tc('Shared.Patient');
                      this.labelSign = this.$t('modalAddSignature.sign', {'label': this.$tc('Shared.Patient')});
                      this.ShowGuardianDrop = false;
                  }
                  
                  if(value.code === "GuardianSigned" && this.SignatureEdit.ModelClientCheck){
                      this.showModalSignPatientGuardian = true;
                  }
  
                  else if(value.code === "PatientSigned" && this.SignatureEdit.ModelClientCheck){
                      this.showModalSignPatientGuardian = true;
                  }
                  
                  else{
                      this.showModalSignPatientGuardian = false;
                  }
  
  
  
              }
          },
  
     ChangeDropDownProvider(value){
      this.SignatureEdit.isSigned = false;
      this.SignatureEdit.ProviderModel = value ? value : {therapistCode:"",therapistName:""};
     },
  
     ChangeDropDownCoProvider(value){
        this.SignatureEdit.isCosigned = false;
        this.SignatureEdit.CoProviderModel = value ? value : {coTherapistCode:"",coTherapistName:""};
     },
  
     onSignatureClient(value){          
        this.showModalSignPatientGuardian = value && (this.SignatureEdit.ModelClient.code === "GuardianSigned" || this.SignatureEdit.ModelClient.code === "PatientSigned") ? true : false;
    },
  
  
     OnReturnSign(data){       
          if(data.option === this.$tc('Shared.Patient')){
              data.item.isPatientSigned = false; 
              data.item.CheckGuardianReadOnly = false;              
              data.item.CheckRefuseReadOnly = false;
              data.item.SelectGuardianReadOnly = false;
          }
          if(data.option === this.$t('modalAddSignature.guardian')){
              data.item.guardianSigned = false;            
              data.item.CheckPatientReadOnly = false;
              data.item.CheckRefuseReadOnly = false;
              data.item.SelectGuardianReadOnly = false;
          }
         
          this.showModalSignPatientGuardian = false;
      },
  
      UpdateSignatureModal(){         
          let data = {
              customFrame: 'TreatmentPlanNotes',       
              therapistCode: this.SignatureEdit.ProviderModel.therapistCode,
              coTherapistCode: this.SignatureEdit.CoProviderModel.coTherapistCode,
              patientCode: this.$route.params.id,
              isSigned: this.SignatureEdit.isSigned,
              isCosigned: this.SignatureEdit.isCosigned,
              //isPatientSigned: this.SignatureEdit.ModelClientCheck === true && this.SignatureEdit.ModelClient.code === "PatientSigned" ? true : false,
              //guardianSigned: this.ModelClientCheck === true && this.SignatureEdit.ModelClient.code === "GuardianSigned" ? true : false,
             //refuseSigned: this.SignatureEdit.ModelClient.code === "PatientRefused" ? true : false,          
              
             
            // contactRecNo : this.DataModalGuardianSig.guardianCode !== "" && this.DataModalGuardianSig.guardianCode !== null  ? parseInt(this.DataModalGuardianSig.guardianCode) :null,
             contactRecNo : this.SignatureEdit.contactRecNo ? parseInt(this.SignatureEdit.contactRecNo) : null,
             image:this.DataModalGuardianSig.base64,
          };
          if(this.SignatureEdit.ModelClientCheck === true && this.SignatureEdit.ModelClient.code === "PatientSigned" ){           
            data.isPatientSigned = true;
          }else{
            data.isPatientSigned = false;
          }
          
          if(this.SignatureEdit.ModelClientCheck=== true && this.SignatureEdit.ModelClient.code === "GuardianSigned"){
            data.guardianSigned = true;
          }else{
            data.guardianSigned = false;
          }
          
          if(this.SignatureEdit.ModelClient.code === "PatientRefused"){
            data.refuseSigned = true;
          }else{
            data.refuseSigned = false;
          }
        

           TreatmentPlan.UpdateSignature(this.SignatureEdit.signatureRecId,data)
              .then((response) => { 
                 if(response.data){
                  this.$emit('RefreshGetTP');
                  setTimeout(() => 
                          this.$bvToast.toast(this.$t('treatmentplan.SignatureSaved'), {
                           title: this.$t('Msg.Saving'),
                            autoHideDelay: 5000,
                            variant: "success"
                          })
                          , 5); 
                 }             
              })
              .catch((error) => {
                  if (!error.response) {
                          this.showMessage(this.$t('modalAddSignature.sign', {'label': this.$t('Shared.Edit')}),this.$t('Msg.NotAbleToUpdate'),"error");
                  }     
                     else if(error.response.status === 400 ){
                       this.showMessage(this.$t('modalAddSignature.sign', {'label': this.$t('Shared.Edit')}),error.response.data.errorMessage,"error");
                       }   
                       else if(error.response.status === 500){
                          this.showMessage(this.$t('modalAddSignature.sign', {'label': this.$t('Shared.Edit')}),this.$t('Msg.ThereWereSomeError'),"error");
                       } else{
                           this.showMessage(this.$t('modalAddSignature.sign', {'label': this.$t('Shared.Edit')}),error.response.data.errorMessage,"error");
                       }    
              });       
      },
  
  
      OnReturnOKWizard(data){        
          this.DataModalGuardianSig = data;
          this.SignatureEdit.contactRecNo = this.DataModalGuardianSig.guardianCode;
      },
  
     onCoSignatureTP(value){
          this.TypePassWord = 'Cosign'; 
          this.UserIdSelected = this.SignatureEdit.CoProviderModel.coTherapistCode;
          this.showSignPassword = value;
      },
  
      onSignatureTP(value){  
          this.TypePassWord = 'Sign'; 
         this.UserIdSelected = this.SignatureEdit.ProviderModel.therapistCode;
          this.showSignPassword = value;
      },
  
   
  
     getEmployeeProvider(){
        const UserId = LocalStorage.getUserId();
        EmployeeService.getEmployees(UserId)
              .then((response) => { 
                if(response.data){                 
                  let result = response.data;                    
                  for(var i = 0; i < result.length; i++){
                      this.ProviderList.push({therapistCode:result[i].code,therapistName:result[i].name});
                  }
                   
                   
                }             
              })
              .catch((error) => {
                  this.$emit('load', false);
                      if (!error.response) {
                          this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.retrieveInfo'), "error");
                      }
                      else if (error.response.status === 400) {
                          this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                      } else if (error.response.status === 500) {
                          this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.ThereWereSomeError'), "error");
                      } else {
                          this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                      }
              });
      },
  
      getEmployeeCoProvider(){      
        EmployeeService.getEmployeeCosigner()
              .then((response) => { 
                if(response.data){                 
                  let result = response.data;                    
                  for(var i = 0; i < result.length; i++){
                      this.CoProviderList.push({coTherapistCode:result[i].code,coTherapistName:result[i].name});
                  }
                   
                }             
              })
              .catch((error) => {
                  this.$emit('load', false);
                      if (!error.response) {
                          this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.retrieveInfo'), "error");
                      }
                      else if (error.response.status === 400) {
                          this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                      } else if (error.response.status === 500) {
                          this.showMessage(this.$t('modalAddSignature.eProvider'), this.$t('Msg.ThereWereSomeError'), "error");
                      } else {
                          this.showMessage(this.$t('modalAddSignature.eProvider'), error.response.data.errorMessage, "error");
                      }
              });
      },
     
      onModalPasswordResult(result){ 
            if(result.type === 'Sign'){
              if(result.Event === "Ok"){
                  if(result.status !== true) {
                      this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");   
                      this.SignatureEdit.isSigned = false;                  
                  }                   
              }else{
                this.SignatureEdit.isSigned = false;
              }
                    
          }
          if(result.type === 'Cosign'){
              if(result.Event === "Ok"){
                  if(result.status !== true) {
                      this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");  
                      this.SignatureEdit.isCosigned = false;          
                  }               
              }
              else{
                this.SignatureEdit.isCosigned = false;       
              }
          } 
  
          this.showSignPassword = false;
      },
  
     onSignaturePatient(){
  
     },
  
     onSignatureRefused(){
  
     },
   
     emitEventCancel(){
      this.$emit('ModalTreatmentPlanSignatureCANCEL');
    },    
   },
  
   created(){
      this.getEmployeeProvider();
      this.getEmployeeCoProvider();
     },
  
   mounted(){         
        if(this.SignatureEdit.refuseSigned){                  
            this.disabledCheckClient = true;                 
            this.SignatureEdit.ModelClientCheck = false;
        }
        else{
            this.disabledCheckClient = false;
        }     
   },
  };
  </script>
  
  