<template>
    <div>
     <b-modal :id="modalId" size="xl" scrollable  :title="TitleModal" @ok="this.emitEventOK" @cancel="this.emitEventCancel" @hide="resetModal"> 
     
        <MdTabList v-bind:TabList="tablist" v-bind:ShowTabList="false">
            <div class="tab-content tab-content-custom-pill" id="pills-tabContent-custom">
              <div class="tab-pane fade show active" id="diag1_4" role="tabpanel">
                <b-row>
                 <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode1"
                       v-bind:ModelDescription="adm.diagnosis.diagCode1Description"
                       v-bind:RefCode="'DiagnosisAdmitting'"
                       v-bind:Label="$t('Admission.Diagnosis1Admitting')"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:FieldCode="'code'"
                       v-bind:Fields="BaseFields"
                       v-bind:FieldDescription="'name'"                   
                       :SearchTitle="$t('Admission.Diagnosis1')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidAdmittingCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                  <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode2"
                       v-bind:ModelDescription="adm.diagnosis.diagCode2Description"
                       v-bind:Label="$t('Admission.Diagnosis2Primary')"
                       v-bind:Fields="BaseFields"
                       v-bind:RefCode="'DiagnosisPrimary'"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:FieldCode="'code'"
                       v-bind:FieldDescription="'name'"                     
                       :SearchTitle="$t('Admission.Diagnosis2')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidPrimaryCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                 <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode3"
                       v-bind:ModelDescription="adm.diagnosis.diagCode3Description"
                       v-bind:Label="$t('Admission.Diagnosis3')"
                       v-bind:RefCode="'Diagnosis3'"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:Fields="BaseFields"
                       v-bind:FieldCode="'code'"
                       v-bind:FieldDescription="'name'"                      
                       :SearchTitle="$t('Admission.Diagnosis3')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                 <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode4"
                       v-bind:ModelDescription="adm.diagnosis.diagCode4Description"
                       v-bind:Label="$t('Admission.Diagnosis4')"
                       v-bind:RefCode="'Diagnosis4'"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:Fields="BaseFields"
                       v-bind:FieldCode="'code'"
                       v-bind:FieldDescription="'name'"                     
                       :SearchTitle="$t('Admission.Diagnosis4')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                </b-row>
              </div>
              <div class="tab-pane fade" id="diag5_8" role="tabpanel">
                 <b-row>
                 <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode5"
                       v-bind:ModelDescription="adm.diagnosis.diagCode5Description"
                       v-bind:RefCode="'Diagnosis5'"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:FieldCode="'code'"
                       v-bind:Fields="BaseFields"
                       v-bind:Label="$t('Admission.Diagnosis5')"
                       v-bind:FieldDescription="'name'"                       
                       :SearchTitle="$t('Admission.Diagnosis5')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidAdmittingCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                  <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode6"
                       v-bind:ModelDescription="adm.diagnosis.diagCode6Description"
                       v-bind:Label="$t('Admission.Diagnosis6')"
                       v-bind:RefCode="'Diagnosis6'"
                       v-bind:Fields="BaseFields"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:FieldCode="'code'"
                       v-bind:FieldDescription="'name'"                      
                       :SearchTitle="$t('Admission.Diagnosis6')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidPrimaryCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                 <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode7"
                       v-bind:ModelDescription="adm.diagnosis.diagCode7Description"
                       v-bind:RefCode="'Diagnosis7'"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:Label="$t('Admission.Diagnosis7')"
                       v-bind:Fields="BaseFields"
                       v-bind:FieldCode="'code'"
                       v-bind:FieldDescription="'name'"                      
                       :SearchTitle="$t('Admission.Diagnosis7')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                 <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode8"
                       v-bind:ModelDescription="adm.diagnosis.diagCode8Description"
                       v-bind:RefCode="'Diagnosis8'"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:Label="$t('Admission.Diagnosis8')"
                       v-bind:Fields="BaseFields"
                       v-bind:FieldCode="'code'"
                       v-bind:FieldDescription="'name'"                    
                       :SearchTitle="$t('Admission.Diagnosis8')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                </b-row>
              </div>
              <div class="tab-pane fade" id="diag9_12" role="tabpanel">
              <b-row>
                 <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode9"
                       v-bind:ModelDescription="adm.diagnosis.diagCode9Description"
                       v-bind:RefCode="'Diagnosis9'"
                       v-bind:Fields="BaseFields"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:FieldCode="'code'"
                       v-bind:Label="$t('Admission.Diagnosis9')"
                       v-bind:FieldDescription="'name'"                       
                       :SearchTitle="$t('Admission.Diagnosis9')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidAdmittingCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                  <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode10"
                       v-bind:ModelDescription="adm.diagnosis.diagCode10Description"
                       v-bind:Label="$t('Admission.Diagnosis10')"
                       v-bind:Fields="BaseFields"
                       v-bind:RefCode="'Diagnosis10'"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:FieldCode="'code'"
                       v-bind:FieldDescription="'name'"                       
                       :SearchTitle="$t('Admission.Diagnosis10')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidPrimaryCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                 <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode11"
                       v-bind:ModelDescription="adm.diagnosis.diagCode11Description"
                       v-bind:RefCode="'Diagnosis11'"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:Fields="BaseFields"
                       v-bind:Label="$t('Admission.Diagnosis11')"
                       v-bind:FieldCode="'code'"
                       v-bind:FieldDescription="'description'"                     
                       :SearchTitle="$t('Admission.Diagnosis11')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                 <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode12"
                       v-bind:ModelDescription="adm.diagnosis.diagCode12Description"
                       v-bind:RefCode="'Diagnosis12'"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:Label="$t('Admission.Diagnosis12')"
                       v-bind:Fields="BaseFields"
                       v-bind:FieldCode="'code'"
                       v-bind:FieldDescription="'name'"                     
                       :SearchTitle="$t('Admission.Diagnosis12')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
              </b-row>
              </div>
              <div class="tab-pane fade" id="diag13_16" role="tabpanel">
                 <b-row>
                 <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode13"
                       v-bind:ModelDescription="adm.diagnosis.diagCode13Description"
                       v-bind:RefCode="'Diagnosis13'"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:Fields="BaseFields"
                       v-bind:FieldCode="'code'"
                       v-bind:Label="$t('Admission.Diagnosis13')"
                       v-bind:FieldDescription="'name'"                       
                       :SearchTitle="$t('Admission.Diagnosis13')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidAdmittingCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                  <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode14"
                       v-bind:ModelDescription="adm.diagnosis.diagCode14Description"
                       v-bind:Label="$t('Admission.Diagnosis14')"
                       v-bind:RefCode="'Diagnosis14'"
                       v-bind:Fields="BaseFields"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:FieldCode="'code'"
                       v-bind:FieldDescription="'name'"                      
                       :SearchTitle="$t('Admission.Diagnosis14')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidPrimaryCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                 <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode15"
                       v-bind:ModelDescription="adm.diagnosis.diagCode15Description"
                       v-bind:RefCode="'Diagnosis15'"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:Fields="BaseFields"
                       v-bind:Label="$t('Admission.Diagnosis15')"
                       v-bind:FieldCode="'code'"
                       v-bind:FieldDescription="'name'"                     
                       :SearchTitle="$t('Admission.Diagnosis15')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                 <b-col lg="3" md="3" sm="12">
                    <MdLookup
                       v-bind:ModelCode="adm.diagnosis.diagCode16"
                       v-bind:ModelDescription="adm.diagnosis.diagCode16Description"
                       v-bind:RefCode="'Diagnosis16'"
                       v-bind:Api="'DiagnosisICD10Providers'"
                       v-bind:Fields="BaseFields"
                       v-bind:Label="$t('Admission.Diagnosis16')"
                       v-bind:FieldCode="'code'"
                       v-bind:FieldDescription="'name'"                                      
                       :SearchTitle="$t('Admission.Diagnosis16')"
                       :GetValues="onCodeConditionOk"
                       :InvalidFeedback="$t('Admission.InvalidDiagnosisCode')"
                       :SearchFieldCode="'Code'"                     
                    />
                 </b-col>
                </b-row>
              </div>
            </div>  
        </MdTabList>
     
     </b-modal>
    </div>
</template>
 
 <script>
 //import GlobalService from '@/services/global.service' 
 import { EventBus } from '@/event-bus.js';
 import moment from "moment";
 //import Utils from '@/common/utils'
 export default {
   name: 'ModalDiagnosis',
    props: {
      ModalId: String,
      TitleWindow:String,    
    },
    data(){
        return {
            modalId:this.ModalId,
            TitleModal:"",
            Admission:{},
            adm:{
                diagnosis:{
                    diagCode1: "",
                    diagCode1Description:"",
                    diagCode2: "",
                    diagCode2Description:"",
                    diagCode3: "",
                    diagCode3Description:"",
                    diagCode4: "",
                    diagCode4Description:"",
                    diagCode5: "",
                    diagCode5Description:"",
                    diagCode6: "",
                    diagCode6Description:"",
                    diagCode7: "",
                    diagCode7Description:"",
                    diagCode8: "",
                    diagCode8Description:"",
                    diagCode9: "",
                    diagCode9Description:"",
                    diagCode10: "",
                    diagCode10Description:"",
                    diagCode11: "",
                    diagCode11Description:"",
                    diagCode12: "",
                    diagCode12Description:"",
                    diagCode13: "",
                    diagCode13Description:"",
                    diagCode14: "",
                    diagCode14Description:"",
                    diagCode15: "",
                    diagCode15Description:"",
                    diagCode16: "",
                    diagCode16Description:"",
                }
            },
            tablist: [
                { title: 'Diag 1-4', link: '#diag1_4', active: true},
                { title: 'Diag 5-8', link: '#diag5_8'},
                { title: 'Diag 9-12', link: '#diag9_12'},
                { title: 'Diag 13-16', link: '#diag13_16'}
            ],
            BaseFields:[{ key: 'name', sortable: true, label: this.$t('Shared.Description') },{ key: 'code', sortable: true, label: this.$t('Shared.Code')}],
        }
    },
 
    methods:{
      emitEventOK(){  
        this.PrepareDiagnoseList();
       },

       clearAdm(){
        this.adm.diagnosis.diagCode1 = "";
        this.adm.diagnosis.diagCode1Description = "";
        this.adm.diagnosis.diagCode2 = "";
        this.adm.diagnosis.diagCode2Description = "";
        this.adm.diagnosis.diagCode3 = "";
        this.adm.diagnosis.diagCode3Description = "";
        this.adm.diagnosis.diagCode4 = "";
        this.adm.diagnosis.diagCode4Description = "";
        this.adm.diagnosis.diagCode5 = "";
        this.adm.diagnosis.diagCode5Description = "";
        this.adm.diagnosis.diagCode6 = "";
        this.adm.diagnosis.diagCode6Description = "";
        this.adm.diagnosis.diagCode7 = "";
        this.adm.diagnosis.diagCode7Description = "";
        this.adm.diagnosis.diagCode8 = "";
        this.adm.diagnosis.diagCode8Description = "";
        this.adm.diagnosis.diagCode9 = "";
        this.adm.diagnosis.diagCode9Description = "";
        this.adm.diagnosis.diagCode10 = "";
        this.adm.diagnosis.diagCode10Description = "";
        this.adm.diagnosis.diagCode11 = "";
        this.adm.diagnosis.diagCode11Description = "";
        this.adm.diagnosis.diagCode12 = "";
        this.adm.diagnosis.diagCode12Description = "";
        this.adm.diagnosis.diagCode13 = "";
        this.adm.diagnosis.diagCode13Description = "";
        this.adm.diagnosis.diagCode14 = "";
        this.adm.diagnosis.diagCode14Description = "";
        this.adm.diagnosis.diagCode15 = "";
        this.adm.diagnosis.diagCode15Description = "";
        this.adm.diagnosis.diagCode16 = "";
        this.adm.diagnosis.diagCode16Description = "";        
       },

       onCodeConditionOk(result){
         var type = result.FieldName;       
         if(type.indexOf('Diagnosis') >= 0) {             
             switch (type) {
                case "DiagnosisAdmitting": 
                  this.adm.diagnosis.diagCode1 = result.code;  
                  this.adm.diagnosis.diagCode1Description = result.description;                    
                  break;
                case "DiagnosisPrimary": 
                  this.adm.diagnosis.diagCode2 = result.code;
                  this.adm.diagnosis.diagCode2Description = result.description;    
                  break;
                case "Diagnosis3": 
                  this.adm.diagnosis.diagCode3 = result.code;   
                  this.adm.diagnosis.diagCode3Description = result.description;   
                  break;
                case "Diagnosis4": 
                  this.adm.diagnosis.diagCode4 = result.code;    
                  this.adm.diagnosis.diagCode4Description = result.description;   
                  break;
                case "Diagnosis5": 
                  this.adm.diagnosis.diagCode5 = result.code;    
                  this.adm.diagnosis.diagCode5Description = result.description;   
                  break;
                case "Diagnosis6": 
                  this.adm.diagnosis.diagCode6 = result.code;   
                  this.adm.diagnosis.diagCode6Description = result.description; 
                  break;
                case "Diagnosis7": 
                  this.adm.diagnosis.diagCode7 = result.code;   
                  this.adm.diagnosis.diagCode7Description = result.description; 
                  break;
                case "Diagnosis8": 
                  this.adm.diagnosis.diagCode8 = result.code; 
                  this.adm.diagnosis.diagCode8Description = result.description; 
                  break;
                case "Diagnosis9": 
                  this.adm.diagnosis.diagCode9 = result.code;
                 this.adm.diagnosis.diagCode9Description = result.description;    
                  break;
                case "Diagnosis10": 
                  this.adm.diagnosis.diagCode10 = result.code; 
                  this.adm.diagnosis.diagCode10Description = result.description;   
                  break;
                case "Diagnosis11": 
                  this.adm.diagnosis.diagCode11 = result.code;   
                   this.adm.diagnosis.diagCode110Description = result.description;     
                  break;
                case "Diagnosis12": 
                  this.adm.diagnosis.diagCode12 = result.code;
                    this.adm.diagnosis.diagCode120Description = result.description;   
                  break;
                case "Diagnosis13": 
                  this.adm.diagnosis.diagCode13 = result.code;                    
                    this.adm.diagnosis.diagCode13Description = result.description;   
                  break;
                case "Diagnosis14": 
                  this.adm.diagnosis.diagCode14 = result.code;   
                    this.adm.diagnosis.diagCode14Description = result.description;   
                  break;
                case "Diagnosis15": 
                  this.adm.diagnosis.diagCode15 = result.code;   
                    this.adm.diagnosis.diagCode15Description = result.description;   
                  break;
                default:
                  this.adm.diagnosis.diagCode16 = result.code;  
                  this.adm.diagnosis.diagCode16Description = result.description;  
             }
         }
     },
 
       emitEventCancel(){
       
       }, 
 
       resetModal(){
 
       },

       PrepareDiagnoseList(){     
        var new_startDate= new Date(this.Admission.dateFrom);
        var date_result = moment(new_startDate).format();     
        var obj = {
            RecordId:this.Admission.recordId,
            PatientCode:this.$route.params.id,
            DateFrom:date_result,
            DiagCode1:this.adm.diagnosis.diagCode1,
            DiagCode2:this.adm.diagnosis.diagCode2,
            DiagCode3:this.adm.diagnosis.diagCode3,
            DiagCode4:this.adm.diagnosis.diagCode4,
            DiagCode5:this.adm.diagnosis.diagCode5,
            DiagCode6:this.adm.diagnosis.diagCode6,
            DiagCode7:this.adm.diagnosis.diagCode7,
            DiagCode8:this.adm.diagnosis.diagCode8,
            DiagCode9:this.adm.diagnosis.diagCode9,
            DiagCode10:this.adm.diagnosis.diagCode10,
            DiagCode11:this.adm.diagnosis.diagCode11,
            DiagCode12:this.adm.diagnosis.diagCode12,
            DiagCode13:this.adm.diagnosis.diagCode13,
            DiagCode14:this.adm.diagnosis.diagCode14,
            DiagCode15:this.adm.diagnosis.diagCode15,
            DiagCode16:this.adm.diagnosis.diagCode16,
        }
        
        EventBus.$emit('onSaveDiagnoses', obj);

       }
 
       
    
    },
 
    mounted(){
        EventBus.$on("onOpenModalDiagnosis", function (admission) { 
            this.Admission = admission;
            this.TitleModal = admission.title;
            var arr = admission.diagnoses;
            var l = arr.length;
            this.clearAdm();            
            if(l > 0){
                for(var i = 0; i < l; i++){
                    var pos = i+1;
                    this.adm.diagnosis["diagCode" + pos] = arr[i].code;
                    this.adm.diagnosis["diagCode" + pos+"Description"] = arr[i].description;                  
                }
            }
           
        }.bind(this));
    },

    beforeDestroy () {     
        EventBus.$off('onOpenModalDiagnosis');       
    },
    
 };
 </script>