<template>
    <div >
      <b-modal v-if="!this.recNo"
        id="dailyNotes" 
        size="md" 
        scrollable 
        centered 
        :title="this.$t('DailyNoteList.DailyNotesTitle')" 
        :visible="true"
        :no-close-on-backdrop="true" 
        @ok="onClickShowReport" 
        @cancel="onClickClose"
        @close="onClickClose">
        <b-row>
          <b-col lg="6" md="6" sm="6">
            <MdEdit 
              v-bind:Label="$t('Shared.datefrom')"
              v-bind:TypeProp="'date'" 
              v-bind:Name="'datefrom'"
              v-bind:Min="'1900-01-01'"
              v-bind:Max="'2200-12-31'"
              v-model="dateFrom">                                     
            </MdEdit>
          </b-col>
          <b-col lg="6" md="6" sm="6">
            <MdEdit 
              v-bind:Label="$t('Shared.dateTo')"
              v-bind:TypeProp="'date'" 
              v-bind:Name="'dateto'"
              v-bind:Min="'1900-01-01'"
              v-bind:Max="'2200-12-31'"
              v-model="dateTo">                                     
            </MdEdit>
          </b-col>
        </b-row>
      </b-modal>

      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        title="Daily Notes Report"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
        :wrapper-attr="popupAttributes"
      >
        <ReportViewer
          ref="dailyNotes"
          :ReportName="'DailyNotes'"
          :ReportTitle="this.$t('DailyNoteList.DailyNotesTitle')"
          :IsAnAPIReport="true"
        />
    </DxPopup>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { DxPopup } from 'devextreme-vue/popup';
    import ShowMessage from '@/components/messages/ShowMessage.js'
    import Utils from '../../../common/utils'

    export default Vue.extend({
        name: "DailyNotesRep",
        mixins: [ ShowMessage ],
        components: { DxPopup },
        props: {
          patientCode: String,
          closeWindow: Function,
          noteType: String,
          patientName: String,
          recNo: [String, Number],
          noteTypeName: String,
        },
        data() {
            return {
                isParamVisible: true,
                isReportVisible: false,
                dateFrom: Utils.formatterDate(Date()),
                dateTo: Utils.formatterDate(Date()),
                reportParams: [],
                popupAttributes: {
                  id: 'modaldailyNotes'
              }

            }
        },
        methods: {

            onShowing(e) {
              let contentElement = e.component.content();
              let titleElement = e.component._$title[0]
              contentElement.style.padding = '0px';
              titleElement.style.padding = '0px 20px'
            },

            onHiddenReport() {
              this.isReportVisible = false
              this.closeWindow()
            },

            onClickClose() {
              this.closeWindow()
              this.isParamVisible = false;
            },

            onClickShowReport() {
              this.isParamVisible = false

              this.reportParams = []
              this.reportParams.push({ paramName: 'startDate', paramValue: this.dateFrom})
              this.reportParams.push({ paramName: 'endDate', paramValue: this.dateTo})
              this.reportParams.push({ paramName: 'patientCode', paramValue: this.patientCode})
              this.reportParams.push({ paramName: 'patientName', paramValue: this.patientName})
              this.reportParams.push({ paramName: 'noteType', paramValue: this.noteType && this.noteType ==='ALL' ? '' : this.noteType})
              this.reportParams.push({ paramName: 'recNo', paramValue: this.recNo})
              this.reportParams.push({ paramName: 'noteTypeName', paramValue: this.noteTypeName})
              
              this.isReportVisible = true
              this.$refs.dailyNotes.buildDocument(this.reportParams)
            }
        },
        
        mounted () {
          if (this.recNo)
           this.onClickShowReport()
        },
    })
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  } 
</style>
