import ApiService from '@/services/api.service'

const AttachmentNotes = {

    getAttachmentNotes(HeaderId){
        return ApiService.get(`/AttachmentNotes?HeaderId=${HeaderId}`);
    },

    getDocument(Code){
        return ApiService.get(`/AttachmentNotes/${Code}`);
    },  

    getDocumentbyUrl(Id){     
        return ApiService.get(`/AttachmentNotes/AttachmentNotesGetUrl/${Id}`);
    },

}

export default AttachmentNotes;