<template>
<div class="fileDropAreaSignature">
  <div>
<div class="row">
  <div class="col-md-12">

    <div class="s-header" v-bind:style="styletitle">
            <span class="s-title">{{this.Title}}</span>
            <div class="btn-group btn-group-sm float-sm-right" role="group" aria-label="Signature options">             
              <b-button size="sm" @click="Clear" class="table-title-btn">{{$t('MdEditTableCN.Clear')}}</b-button>
              <b-button size="sm" v-on:click="Undo" class="table-title-btn">{{$t('MdSignatureCaptureCN.Undo')}}</b-button>
            </div>
        </div>
     <canvas 
        :id="idLocal" 
        width="450"      
        class="signature" 
        v-on:mousedown="handleMouseDown" 
        v-on:mouseup="handleMouseUp" 
        v-on:mousemove="handleMouseMove" 
        v-on:mouseout="handleMouseOut" 
        v-on:touchstart="touchstart"      
        v-on:touchend="touchend"      
        v-on:touchmove="touchmove" 
        >        
      </canvas>  
  </div>
</div>
    <Loading v-bind:IsLoading="this.Load" /> 
      
  </div>
    

</div>
</template>

<script>
import ShowMessage from "@/components/messages/ShowMessage.js";
import Loading from '@/components/template/loading/Loading';
import { EventBus } from '@/event-bus.js';
  export default {
    props:{
      Title:String,
      Id:String,
      Data64:String, 
      VisibleLoadImage:Boolean
      },
        mixins: [ShowMessage,Loading],
    components: {
    Loading
  },
    data: function () {
      return {        
        Load:false,
        files:[],
        dataUrl:"",
        CanNotDraw:false,
        CanvasToBMP_dly : 9,
        drawing : false,
        mouse : {x: 0, y: 0},
        previous : {x: 0, y: 0},
        points: [],
        pathsry :[],
        base64Value : this.Data64,
        ReadOnly:false,
        titleLocal:this.Title,
        idLocal:this.Id,
        stylecanvas:{backgroundColor:"#FFF"},
        ChartReview:this.IsChartReview,     
        styletitle:{
        position:'absolute',
         width: 451+'px',
       // top: this.$attrs.control.controlTop+'px',
       // left: this.$attrs.control.controlLeft+'px'
       },  
        /*style:{
        position:'absolute',       
        width:'auto',
        height:'90px;',
       
        pointerEvents: ''
      },*/
      };
    },
    methods: {      
      AreaGreenClick(){
        this.$refs.input.click();
        this.$refs.input.value = "";
      },

      Undo(){  
        this.pathsry.splice(-1,1);  
        this.drawPaths();
    },


    drawPaths: function () {        
         var canvas = document.getElementById(this.idLocal);
         var ctx = canvas.getContext("2d"); 
         ctx.clearRect(0,0,canvas.width,canvas.height);  
        this.pathsry.forEach(path=>{
          ctx.beginPath();
          ctx.moveTo(path[0].x,path[0].y);  
          for(let i = 1; i < path.length; i++){
            ctx.lineTo(path[i].x,path[i].y); 
          }
          ctx.stroke();
        })
    },

 SyncFileReader(file) {
    let self = this;
    let ready = false;
    let result = '';

    const sleep = function (ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    self.readAsDataURL = async function() {
        while (ready === false) {
          await sleep(100);
        }
        return result;
    }    

    const reader = new FileReader();
    reader.onloadend = function(evt) {
        result = evt.target.result;
        ready = true;
    };
    reader.readAsDataURL(file);
  },

  CleanComponent(){
    this.files = [];
    this.Clear();
     this.$refs.input.value = "";
  },

  handleMouseDown: function (event) {      
      var canvas = document.getElementById(this.idLocal);   
      event.preventDefault() 
      this.drawing = true; 
      this.previous = {x:this.mouse.x,y:this.mouse.y};
      this.mouse = this.oMousePos(canvas, event);
      this.points = [];
      this.points.push({x:this.mouse.x,y:this.mouse.y});

    },

    oMousePos(canvas, evt) {
      var ClientRect = canvas.getBoundingClientRect();
        return {
          x: Math.round(evt.clientX - ClientRect.left),
          y: Math.round(evt.clientY - ClientRect.top)
        }
  },

    handleMouseOut: function(event){
      this.drawing = false;      
      event.preventDefault()
    },

    touchmove: function(event){
      var canvas = document.getElementById(this.idLocal);     
      event.preventDefault()
       var touch = event.touches[0];
      var mouseEvent = new MouseEvent("mousemove", {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
    canvas.dispatchEvent(mouseEvent);
   
    },

    touchend:function(event){
      var canvas = document.getElementById(this.idLocal);
      //canvas.style = "";
      event.preventDefault()
       var mouseEvent = new MouseEvent("mouseup", {});
       canvas.dispatchEvent(mouseEvent);
    },

    touchstart: function(event){
      var canvas = document.getElementById(this.idLocal);
      //canvas.style = "";
      //mousePos = getTouchPos(canvas, event);
      event.preventDefault()
      var touch = event.touches[0];
      var mouseEvent = new MouseEvent("mousedown", {
          clientX: touch.clientX,
          clientY: touch.clientY
      });

       canvas.dispatchEvent(mouseEvent);
    },

    handleMouseUp: function (event) {   
     this.drawing=false;     
     this.pathsry.push(this.points);   
     this.Save();  
     event.preventDefault()
    },

    Save(){	
        var canvas = document.getElementById(this.idLocal);
        this.dataUrl = canvas.toDataURL();
        EventBus.$emit('onMouseStop', this.dataUrl);
    },

    handleMouseMove: function (event) {   
      var canvas = document.getElementById(this.idLocal);    
      var ctx = canvas.getContext("2d");
      event.preventDefault()
      if(!this.drawing)
          return;
     
        ctx.lineWidth = 3;
        this.previous = {x:this.mouse.x,y:this.mouse.y};
        this.mouse = this.oMousePos(canvas, event);
        this.points.push({x:this.mouse.x,y:this.mouse.y});
        ctx.beginPath();
        ctx.moveTo(this.previous.x,this.previous.y);
        ctx.lineTo(this.mouse.x,this.mouse.y);
        ctx.stroke();
      
    },

  ExistFile(name){
    var l = this.files.length;
   for(var i = 0; i < l; i++){
        var extension = this.files[i].extension;
        var fullname = this.files[i].name+extension.toLowerCase();
        if(name === fullname){
          return true;
        }
        
    }
    return false;
  },

   canvasImage: function(base64){
      var x = document.getElementById(this.idLocal);
      var canvas = x.getContext('2d'); 
      var image = new Image();
      image.src = base64;
      image.addEventListener("load",function(){
        canvas.drawImage(image,0,0);              
      },false);
    },

    Clear(){			
      var canvas = document.getElementById(this.idLocal);
      var ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      var w = canvas.width;
      canvas.width = 1;
      canvas.width = w;      
		},

 async addFileDragAndDrop(e) {
     let droppedFiles = e.type === 'drop'?e.dataTransfer.files:e.target.files;     
     var extension = "";
      if(!droppedFiles) return;     
        for (var i = 0; i < droppedFiles.length; i++) {
        var filename = droppedFiles[i].name;
        var size = droppedFiles[i].size;
        if(size !== 0){
          if(!this.ExistFile(filename)){
        
        var fileNameNoExtension;
        var pos = filename.lastIndexOf('.');
        if(pos !== -1){
          fileNameNoExtension = filename.substring(0, pos);
          extension = filename.substring(pos).toUpperCase();
        }
        const fileReader = new this.SyncFileReader(droppedFiles[i]);
        const arrayBuffer = await fileReader.readAsDataURL();
        if(extension.toLowerCase() === '.bmp'){
          this.Clear();
          this.files = [];
            if(arrayBuffer.indexOf(',') !== -1){
              var b = arrayBuffer.split(',');         
              this.files.push({"name":fileNameNoExtension,"extension":extension,"base64":b[1]});           
            }
        }else{
          this.showMessage(this.$t('MdSignature.AddImdTitule'),this.$t('MdSignature.AllowBMPMsg'),"error");
        }
   
        }else{
           this.showMessage(this.$t('MdSignature.AddImdTitule'),this.$t('MdSignature.DuplicateImgMsg'),"error");
        }
        }else{
           this.showMessage(this.$t('MdSignature.AddImdTitule'),this.$t('MdSignature.Img0BytesMsg'),"error");
        }  
      }
   
      if(this.files.length > 0){
        var data64 = "data:image/bmp;base64,"+this.files[0].base64;
        this.base64Value = this.files[0].base64;
        EventBus.$emit('OnSubmitBase64', this.base64Value); 
        this.canvasImage(data64);
      }
      
    },

    getUniqueID(){
      return Date.now() + ( (Math.random()*100000).toFixed());
    },

    },

    created(){
     
    },

    mounted(){
     /* if(this.base64Value){
        var data64 = "data:image/bmp;base64,"+this.base64Value;
        this.canvasImage(data64);
      }*/

      if(this.Data64){      
       this.dataUrl = "data:image/bmp;base64,"+this.Data64;      
       this.canvasImage(this.dataUrl);
    }
    }
  };
</script>

<style>
.ng-invalid {
  border: 1px solid red !important;
}

.AutoHeightContainerMoreFiles{
height: calc(100%  + 250px) !important;
}

.AutoHeightContainerMinusFiles{
height: 550px!important;
}

.fileDropArea{
  height: calc(100vh - 224px) !important;
}
</style>
