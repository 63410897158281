<template>
      <div class="col-md-4 grid-margin stretch-card">
					<div class="card activeCard">
						<div class="card-body">
						<div v-if="isUsePatientInfo" class="d-flex align-items-start justify-content-between pointer">
							<h6 v-on:click="GoToPatientInfo" class="card-title flex-grow lower-line text-truncate">{{ $t('MdPatientDiagnosis.Diagnosis') }}</h6><i class="mdi mdi-arrow-right-drop-circle text-corporate" v-on:click="this.GoToPatientInfo"></i>
						</div>
						<div v-if="!isUsePatientInfo" class="d-flex align-items-start justify-content-between">
							<h6 class="card-title flex-grow lower-line text-truncate">{{ $t('MdPatientDiagnosis.Diagnosis') }}</h6>
						</div>
							<div class="text-muted p-fixed cutescroll">
						<p v-if="Diagnosis.length === 0">{{ $t('MdPatientDiagnosis.NoKnownDiagnosis') }}</p>
						<ul>							
							<li v-for="(it, index) in Diagnosis" :key="`it.diagCode-${index}`">{{it.diagName}}</li>						
						</ul>
						</div>
						</div>
						</div>
					</div>
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
  export default {
    props: {
		PatientCode:String
   },
   mixins: [DBFunctions],
    data() {
      return {
		Diagnosis:[],
		isUsePatientInfo: this.$store.state.isUsePatientInfo
      }
    },
    
  methods:{
	async getDiagnosis(){
		this.Diagnosis = await this.getPatientDiagnosis(this.PatientCode);		
	},
	GoToPatientInfo(){
        this.$router.push({ name: "patient-diagnosis", params: { id: this.$route.params.id } }).catch(()=>{});
    },
  },
   mounted() {
	this.getDiagnosis();
   }
}

</script>