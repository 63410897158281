<template>
  <div id="IncidentWorkflow">
    <MdTitle v-bind:Text="$t('menu.Incident Workflow Dashboard')" />
      <div class="flex-container">
      <b-row>
        <b-col md="3" lg="3" sm="12">
          <MdEdit 
            v-bind:Label="$t('Shared.datefrom')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'DateFrom'"
            v-model="DateFrom"
            v-bind:Min="'1900-01-01'"
            v-bind:OnPressEnter="loadListing"
            v-bind:Max="'2200-12-31'"
            v-bind:isValid="this.isDateFromValid"
            v-bind:isRequired="true"
            v-on:change="DateFromChange($event)"           
           >                                     
          </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
          <MdEdit 
            v-bind:Label="$t('Shared.dateTo')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'DateTo'"
            v-bind:OnPressEnter="loadListing"
            v-model="DateTo"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-on:change="DateToChange($event)"          
            v-bind:isValid="this.isDateToValid">                                     
           </MdEdit>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$tc('Shared.Patient',2)"
            v-bind:Options="PatientsOptions"
            v-bind:FieldDescription="'patientname'"
            v-bind:FieldCode="'patientcode'"
            v-bind:Model="PatientsModel"  
            v-bind:Multiple="true" 
            :Input="ChangePatients"
            v-bind:AllowEmpty="true"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">         
          <MdSelect
						v-bind:Label="$t('Incident.IncReporter')"
						v-bind:FieldDescription="'name'"
						v-bind:FieldCode="'code'"
						v-bind:Options="ReporterOptions"
						v-bind:Model="ReporterModel"											
						v-bind:Multiple="true"
            v-bind:AllowEmpty="true"
						:Input="ChangeReporter"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">         
          <MdSelect
            v-bind:Label="$t('IncidentWorkflow.Workflow')"
            v-bind:Options="WorkflowOptions"
            v-bind:Model="WorkflowModel"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'workflowID'"
            v-bind:AllowEmpty="true"
            v-bind:Multiple="true"             
            :Input="ChangeWorkflow"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$tc('Shared.Program',2)"
            v-bind:Options="ProgramOptions"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:AllowEmpty="true"
            v-bind:Multiple="true"
            v-bind:Model="ProgramModel"  
            :Input="ChangeProgram"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('Shared.Category')"
            v-bind:Options="IncidentOptions"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'description'"
            v-bind:AllowEmpty="true"
            v-bind:Multiple="true"
            v-bind:Model="IncidentModel"  
            :Input="ChangeIncident"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('Incident.IncSeverity')"
            v-bind:Options="SeverityOptions"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'code'"
            v-bind:AllowEmpty="true"
            v-bind:Multiple="true"
            v-bind:Model="SeverityModel"  
            :Input="ChangeSeverity"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="'Workflow Status'"
            v-bind:Options="StatusOptions"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'value'"
            v-bind:AllowEmpty="true"
            v-bind:Multiple="false"
            v-bind:Model="StatusModel"  
            :Input="ChangeStatus"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('menu.Notes')"
            v-bind:Options="NotesOptions"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'description'"
            v-bind:AllowEmpty="true"
            v-bind:Multiple="false"
            v-bind:Model="NotesModel"  
            :Input="ChangeNotes"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="'Notes Status'"
            v-bind:Options="NotesStatusOptions"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'value'"
            v-bind:AllowEmpty="true"
            v-bind:Multiple="false"
            v-bind:Model="NotesStatusModel"  
            :Input="ChangeNotesStatus"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdButton
          v-bind:Label="$t('Shared.Search')"
          v-bind:Name="'btnsearchincident'"
          v-bind:ClassProp="'secondary mt-3 mx-1 btn-login'"         
          v-on:click="loadListing()"/>
        </b-col>
      </b-row>
      </div>

      <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
       <div class="row">
          <div class="col-md-12">
            <MdTable 
              v-bind:fields="fields"
              v-bind:IsIncidentWorkflow="true"
              v-bind:items="items"
              v-bind:totalRows="totalRows"
              v-bind:isReadOnly="isReadOnly"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:FieldsDate="FieldsDateArr"
              v-bind:perPage="perPage"
              v-bind:pageOptions="pageOptions"
              v-bind:EnabledRowClicked="true"
              :viewIconEvent="ViewIncident"
              :onRowSelected="onRowSelected"
              :onRowClicked="onRowClicked"
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"
              :perPageFunction="PerPageFunction"
              v-bind:refTable="'IWFTable'"
              ref="IWFTable">
            </MdTable>
          </div>
        </div>
        
        <div class="space-actions"></div>
        <div class="page-actions">
          <MdButton
            v-bind:Label="btnVoidIncidentLabel"
            v-bind:Name="'btnvoidincidentwork'"
            v-bind:ClassProp="'secondary mx-2 btn-login'"
            v-bind:isReadOnly="btnVoidIncidentRO"
            v-on:click="voidIncident()"/>
          <MdButton 
            v-bind:Label="$t('IncidentWorkflow.InWorkFloForCom')"
            v-bind:Name="'btnforcecompletion'"
            v-bind:ClassProp="'secondary mx-2 btn-login'"
            v-bind:isReadOnly="btnForceCompletionRO"
            v-on:click="forceCompletion()"/>
        </div>

        <MdPasswordDlg v-if="showSignPassword" 
          v-bind:Type="TypePassWord"
          v-bind:UserId="UserIdSelected"
          v-bind:Operation="Operation"
          v-bind:VerificationType="'SupervisorPassword'"
          @getModalPassword="onModalPasswordResult($event)"
        />

        <IncidentWorkflowSummaryModal v-if="showSummary"
          v-bind:IncidentId="IncidentID">
        </IncidentWorkflowSummaryModal>
  </div>
</template>

<script>
import Utils from '@/common/utils'
import { EventBus } from '@/event-bus.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import Patients from '@/services/patient.service'
import EnumService from "@/services/enum.service"
import ProgramService from '@/services/programs.services'
import IncidentSetup from '@/services/incidentsetup.service'
import IncidentWorkflow from '@/services/incidentworkflow.service'
import IncidentReportSeveritySetupService from '@/services/incidentreportseveritysetup.service'
import EmployeeService from '@/services/employee.service'

export default {
  name: 'IncidentWorkflow',
  mixins: [ShowMessage, Utils],
  components: { },
  props: {
    employee: Boolean
  },
  data(){
    return{   
        Operation:"",    
        showSignPassword:false,
        showSignPasswordSBTD:false,
        TypePassWord:'Sign',
        UserIdSelected:'',
        
        DateFrom: Utils.formatterDate(Date()),
        DateTo: Utils.formatterDate(Date()),
        PatientsModel: null,
        ReporterModel: null,
        WorkflowModel: null,
        ProgramModel :  null,
        IncidentModel: null,
        SeverityModel: null,
        NotesModel   : { description: this.$t('Shared.All')},
        StatusModel  : { value: 'pending', name: this.$t('ENUM.NoteStatus.Pending') },
        NotesStatusModel : { value: 'all', name: this.$t('Shared.All') },
        PatientsOptions: [],
        ReporterOptions: [],
        WorkflowOptions: [],
        ProgramOptions : [],
        IncidentOptions: [],
        SeverityOptions: [],
        NotesOptions   : [],
        StatusOptions  : EnumService.IncidentWorkflowStatusOptions,
        NotesStatusOptions : EnumService.IncidentWorkflowNotesStatusOptions,

        parameter:{
          SearchCriteria:'',
          PageNumber : null,
          PageSize:null,
          Status: false,
        },
        paramPatient:{
          SearchCriteria:'',
          PageNumber : null,
          PageSize:null,
          IsInpatient: true,
        },
        isDateFromValid: true,
        isDateToValid: true,
        OptionsRightMenu:{
            visible:false,
            btnvisible:false,
            ItemsList:[],
            favButtonAtion: false
        },
        fields: [
          { key: 'recID', sortable: true, label: 'RecID', class: 'hidden' },
          { key: 'incidentDate', sortable: true, label: this.$t('Incident.IncDate'), class: 'text-rigth' },      
          { key: 'incidentTime', sortable: true, label: this.$t('Incident.IncTime'), class: 'hidden' },
          { key: 'severityName', sortable: true, label: this.$t('Incident.IncSeverity'), class: 'text-rigth'}, 
          { key: 'categoryDescription', sortable: true, label: this.$t('Shared.Category'), class: 'text-rigth' }, 
          { key: 'patientName', sortable: true, label: this.$t('Shared.PatientName'), class: 'text-rigth' },
          { key: 'patientCode', sortable: true, label: this.$t('Shared.PatientCode'), class: 'text-rigth' }, 
          { key: 'programName', sortable: true, label: this.$tc('Shared.Program'), class: 'text-rigth' },
          { key: 'reporterName', sortable: true, label: this.$tc('Incident.IncReporter'), class: 'text-rigth' },
          { key: 'workflowDescription', sortable: true, label: this.$tc('IncidentWorkflow.WorkflowDescription'), class: 'text-rigth' },
          { key: 'incidentId', sortable: true, label: this.$t('Incident.IncId'), class: 'text-rigth'},          
        ],
        FieldsDateArr:['incidentDate'],
        items: [],
        totalRows: 0,
        isReadOnly: false,
        ShowMessageIsEmpty:false,
        currentPage: 1,
        perPage: 50,
        pageOptions: [5, 10, 15,20,30,40,50],
        selected: [],
        ButtonLabel: null,
        incidentWorkflowParams: {
          SearchCriteria            : null,
          DateFrom                  : Utils.formatterDate(Date()),
          DateTo                    : Utils.formatterDate(Date()),
          PatientCodeList           : null,
          ReporterCodeList          : null,
          WorkflowIdList            : null,
          ProgramCodeList           : null,
          CategoryDescriptionList   : null,
          SeverityCodeList          : null,
          WorkflowStatus            : 'pending',
          Note                      : null,
          PageNumber                : null,
          PageSize                  : null,
          NoteStatus                : null
        },
        btnVoidIncidentRO: true,
        btnVoidIncidentLabel: 'VOID',
        btnForceCompletionRO: true,
        SeeIncidentWFAdmin: false,
        showSummary: false,
        IncidentID: '',
        RecID: '',
        indexSelected: 0
    }
  },
  watch: {
    employee(newValue) {
      this.params.Employee = newValue
      this.currentPage = 1;
      this.loadListing()
    }
  },

  methods:{

    async getButtonLabel(RecID) {            
      await IncidentWorkflow.getButtonLabel(RecID)
      .then((response) => { 
        if(response.data){
          this.ButtonLabel = response.data
        }
      })
      .catch((error) => {
        if (error.response) {
          this.showMessage(this.$t('IncidentWorkflow.Workflow'),error,"error");
        }
      });
    },

    async ViewIncident(item, index) {
      this.indexSelected = index
      this.IncidentID = item.incidentId.trim()
      this.RecID = item.recID
      this.showSummary = true
      this.$emit("load", true);
    },

    onModalPasswordResult(result){
      if(result.Event === 'Ok') {
        if(result.status === true) {
         this.$emit('load', true);
         if(result.Operation === "Void") {
          IncidentWorkflow.setVoid(this.selected[0].recID,this.selected[0].status === 'Void' ? 'N' : 'V')
              .then(() => {
                  this.currentPage = 1;
                  this.loadListing()
              })
              .catch((error) => {
                if (!error.response) {
                  this.showMessage(this.$t('IncidentWorkflow.Workflow'),error,"error");
                } else {
                  this.showMessage(this.$t('IncidentWorkflow.Workflow'),error,"error");
                }
              });        
         } 
         else if(result.Operation === "Completion") {
          IncidentWorkflow.setForceWorkflowCompletion(this.selected[0].recID)
                .then(() => {
                  this.currentPage = 1;
                  this.loadListing()
                })
                .catch((error) => {
                  if (!error.response) {
                    this.showMessage(this.$t('IncidentWorkflow.Workflow'),error,"error");
                  } else {
                    this.showMessage(this.$t('IncidentWorkflow.Workflow'),error,"error");
                  }
                });
         }      
        }
      else{
        this.showMessage(this.$t('IncidentWorkflow.Workflow'),this.$t('Msg.InvalidSupervisorPass'),"error");
      }
      }    
      this.showSignPassword = false;
    },

    voidIncident() {
      this.showSignPassword = true;
      this.Operation = "Void";  
    },
    
    forceCompletion() {
      this.showSignPassword = true;
      this.Operation = "Completion";  
    },

    SearchIncident() {
      this.currentPage = 1;
      this.loadListing()
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    onRowClicked(item,index) {    
      this.indexSelected = index
    },

    async onRowSelected(item) {
      this.selected = item;
      
      this.btnVoidIncidentRO = item.length > 0 ? false : true;
      this.btnForceCompletionRO = item.length > 0 ? false : true;

      if (item.length > 0 && item[0].status === 'Void') {
        this.btnVoidIncidentLabel = 'UNVOID'
      } else
        this.btnVoidIncidentLabel = 'VOID'

      await this.loadMenu(item)
    },

    pageChanged (page) {            
      this.currentPage = page;    
      this.loadListing()    
    },

    PerPageFunction(perpage){
      this.perPage = perpage;  
      this.currentPage = 1;
      this.loadListing() 
    },

    async getIncidentWorkflow() {
      this.incidentWorkflowParams.PageNumber = this.currentPage;
      this.incidentWorkflowParams.PageSize = this.perPage;
      await IncidentWorkflow.getAll(this.incidentWorkflowParams)
        .then((response) => { 
        if(response.data){
          this.items = response.data;
          this.totalRows = response.data.length > 0 ? response.data[0].maxRows : 0;
          this.ShowMessageIsEmpty = response.data.length > 0 ? false : true
          this.items.forEach(function(element) {
            if (element.incidentDate)
              element.incidentDate =  `${Utils.formatterDateToString(element.incidentDate)} ${Utils.formatTime2(element.incidentTime)}`;
          });
          
          this.$emit("load", false);
        }             
        })
        .catch((error) => {
          this.$emit("load", false);
          if (!error.response) {
            // network error
            this.showMessage(this.$t('IncidentWorkflow.Workflow'),this.$t('Msg.retrieveInfo'),"error");
        } else {
            this.showMessage(this.$t('IncidentWorkflow.Workflow'),error,"error");
        }
        });
    },
    
    DateFromChange(value) {
      this.incidentWorkflowParams.DateFrom = value
    },

    DateToChange(value) {
      this.incidentWorkflowParams.DateTo = value
    },

    ChangePatients(item) {
      if(item.length > 0)  {
        this.PatientsModel = item;
        let selectedPatients = [];
        item.forEach(function(item) {   
          selectedPatients.push(item.patientcode.trim());               
        });
        this.incidentWorkflowParams.PatientCodeList = selectedPatients
      } else {
        this.PatientsModel = null;
        this.incidentWorkflowParams.PatientCodeList = null
      }  
      this.loadListing();
    },

    ChangeReporter(item) {   
      if(item.length > 0)  {
        this.ReporterModel = item;
        let selectedReporter = [];
        item.forEach(function(item) {   
          selectedReporter.push(item.code.trim());               
        });
        this.incidentWorkflowParams.ReporterCodeList = selectedReporter
      } else {
        this.ReporterModel = null;
        this.incidentWorkflowParams.ReporterCodeList = null
      }
      this.loadListing();
    },

    ChangeWorkflow(item) {   
      if(item.length > 0)  {
        this.WorkflowModel = item;
        let selectedWorkflow = [];
        item.forEach(function(item) {   
          selectedWorkflow.push(item.workflowID.trim());               
        });
        this.incidentWorkflowParams.WorkflowIdList = selectedWorkflow
      } else {
        this.WorkflowModel = null;
        this.incidentWorkflowParams.WorkflowIdList = null
      }
      this.loadListing();
    },

    ChangeProgram(item) {   
      if(item.length > 0)  {
        this.ProgramModel = item;
        let selectedProgram = [];
        item.forEach(function(item) {   
          selectedProgram.push(item.code.trim());               
        });
        this.incidentWorkflowParams.ProgramCodeList = selectedProgram
      } else {
        this.ProgramModel = null;
        this.incidentWorkflowParams.ProgramCodeList = null
      }
      this.loadListing();
    },

    ChangeIncident(item) {   
      if(item.length > 0)  {
        this.IncidentModel = item;
        let selectedIncident = []
        item.forEach(function(item) {   
          selectedIncident.push(item.description.trim());               
        });
        this.incidentWorkflowParams.CategoryDescriptionList = selectedIncident
      } else {
        this.IncidentModel = null;
        this.incidentWorkflowParams.CategoryDescriptionList = null
      }
      this.loadListing();
    },

    ChangeSeverity(item) {
      if (item.length > 0)  {
        this.SeverityModel = item;
        let selectedSeverity = [];
        item.forEach(function(item) {   
          selectedSeverity.push(item.code.trim());
        });
        this.incidentWorkflowParams.SeverityCodeList = selectedSeverity
      } else {
        this.SeverityModel = null;
        this.incidentWorkflowParams.SeverityCodeList = null
      }
      this.loadListing();
     },

    ChangeNotes(item) {   
      if (item != null) {
        this.incidentWorkflowParams.Note = item.description == 'All' ? "" : item.description
        this.NotesModel = item;
      } else {
        this.incidentWorkflowParams.Note = null;
      }
      this.loadListing();
    },

    ChangeStatus(item) {
      if (item != null) {
        this.incidentWorkflowParams.WorkflowStatus = item.name == 'All' ? "" : item.value
        this.StatusModel = item;
      } else {
        this.incidentWorkflowParams.WorkflowStatus = null;
      }
      this.loadListing();
    },

    ChangeNotesStatus(item) {
      if (item != null) {
        this.incidentWorkflowParams.NoteStatus = item.value == 'all' ? null : item.value
        this.NotesStatusModel = item;
      } else {
        this.incidentWorkflowParams.NoteStatus = null;
      }
      this.loadListing();
    },

    getPatients() {
      Patients.getAll(this.paramPatient)
        .then((response) => { 
          if(response.data && response.data.length > 0){   
            this.PatientsOptions = response.data;
            this.PatientsOptions.forEach(element => {
              element.patientname = element.firstname + " " + element.lastname + " - " + element.patientcode;
            });
          }
        })
        .catch(error => {
          if (error.response) {
            this.showMessage(this.$t('IncidentWorkflow.Workflow'),error,"error");
          } 
        });  
    },

    getSeverity() {
      IncidentReportSeveritySetupService.getSeverityAll()
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              this.SeverityOptions = response.data;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('IncidentWorkflow.Workflow'),error,"error");
            } 
          });  
    },
    
    getPrograms(){
      let params = {'ProgramType': 'Inpatient'} 
      ProgramService.getAll(params)
        .then((response) => {
          if(response.status == '200' && response.data && response.data.length > 0){
            this.ProgramOptions = response.data;
          }               
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$t('IncidentWorkflow.Workflow'),error,"error");
          }
        });  
    },

    getReporter(){
      EmployeeService.getEmployeesAll()
        .then((response) => {
          if(response.status == '200' && response.data && response.data.length > 0){
            this.ReporterOptions = response.data;
          }               
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$t('IncidentWorkflow.Workflow'),error,"error");
          }
        });  
    },


    getWorkflow(){
      IncidentWorkflow.getIncidentWorkflow()
        .then((response) => {
          if(response.status == '200' && response.data && response.data.length > 0){
            this.WorkflowOptions = response.data;
          }               
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$t('IncidentWorkflow.Workflow'),error,"error");
          }
        });  
    },

    getIncident(){
      IncidentSetup.getAll(this.parameter)
        .then((response) => {
          if(response.status == '200' && response.data && response.data.length > 0){
            this.IncidentOptions = response.data;
          }               
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$t('IncidentWorkflow.Workflow'),error,"error");
          }
        });  
    },

    getNotesFilter(){
      IncidentWorkflow.getNotesFilter()
        .then((response) => {
          if(response.status == '200' && response.data && response.data.length > 0){
            this.NotesOptions = response.data;
            this.NotesOptions.unshift({description:this.$t('Shared.All')})
          }               
        })
        .catch((error) => {
          if (error.response) {
            this.showMessage(this.$t('IncidentWorkflow.Workflow'),error,"error");
          }
        });  
    },

    async ExecuteAllCalls(){
      this.$emit("load", true);
      this.getPatients()
      this.getSeverity()
      this.getPrograms()
      this.getReporter()
      this.getWorkflow()
      this.getIncident()  
      this.getNotesFilter()
    },

    async loadListing(noFirstItemSelected = false) {      
      if(this.DateFrom === null || this.DateFrom === '' || Utils.IsValidDateAndRange(this.DateFrom)){
        this.showMessage(this.$t('IncidentWorkflow.Workflow'), this.$t('Msg.CheckRequiredFields'),"error");  
        this.isDateFromValid = false;
          return;
      }
      else if(this.DateTo === null || this.DateTo === '' || Utils.IsValidDateAndRange(this.DateTo)){
        this.showMessage(this.$t('IncidentWorkflow.Workflow'), this.$t('Msg.CheckRequiredFields') ,"error"); 
        this.isDateToValid = false;     
          return;
      }else{
        this.$emit('load', true);
        this.isDateFromValid = true;
        this.isDateToValid = true; 
        await this.getIncidentWorkflow();
        
        if (this.items.length > 0 && !noFirstItemSelected) {
          this.$refs.IWFTable.selectRow(0)
        }

        if (this.items.length == 0) {
          EventBus.$emit('cleanPatientInfo');
        }
      }
    },

    async loadMenu(item) {
      if (item.length > 0) {
        this.$emit('load', true);
        await this.getButtonLabel(item[0].recID)

      /*this.OptionsRightMenu.visible = false;
        this.OptionsRightMenu.btnIncidentWorkflowvisible = true;
        this.OptionsRightMenu.PatientCode = item[0].patientCode.trim()
        this.OptionsRightMenu.ItemsList = JSON.parse(JSON.stringify(this.ButtonLabel))

        this.$emit('onMenuRightBarIncidentWorkflow', this.OptionsRightMenu);
        this.$emit('onVisibleRightMenu', this.OptionsRightMenu);
        this.$emit('getVisibleMenu', this.OptionsRightMenu);*/  

        EventBus.$emit('loadMenu',JSON.parse(JSON.stringify(this.ButtonLabel)), 
                        item[0].patientCode.trim(), item[0].incidentId, item[0].incidentDate);
      } else {
        EventBus.$emit('loadMenu',[],'','','');
      }
    }
  },
   async mounted() {
    
    this.OptionsRightMenu.visible = false;
    this.OptionsRightMenu.btnIncidentWorkflowvisible = true;
    this.OptionsRightMenu.PatientCode = this.$route.params.id
    this.OptionsRightMenu.ItemsList = []

    this.$emit('onMenuRightBarIncidentWorkflow', this.OptionsRightMenu);

    let layoutStatus = this.$store.state.layoutStatus;
    switch(layoutStatus) {
      case 'L0R1':
        this.OptionsRightMenu.favButtonAtion = true;
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
      break;
      case 'L1R0':
      case 'L1R1':
          this.OptionsRightMenu.btnvisible = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
      break;
      default:
          this.OptionsRightMenu.favButtonAtion = true;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }

    const allPromise = Promise.all([await this.ExecuteAllCalls()]);
    await allPromise.then(() => {
    }).catch(error => {       
      this.showMessage(this.$t('IncidentWorkflow.Workflow'),error,"error");
    });
    
    await this.loadListing();

    this.$emit('onVisibleRightMenu', this.OptionsRightMenu);
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
  },

  created() {
    EventBus.$on("CloseIWFSummary", function () {
      this.showSummary = false
      this.$refs.IWFTable.selectRow(this.indexSelected)
    }.bind(this));
    
    EventBus.$on("stopWheel", function () {
      this.$emit('load',false);
    }.bind(this));

    EventBus.$on("loadMenuOnCloseNote", async function () {
      await this.loadListing(true)
      this.$refs.IWFTable.selectRow(this.indexSelected)
      let itemSelected = this.selected
      this.loadMenu(itemSelected)
    }.bind(this)); 
  },
  
  destroyed() {
    EventBus.$off('CloseIWFSummary');
    EventBus.$off('stopWheel');
    EventBus.$off('loadMenuOnCloseNote');

    this.OptionsRightMenu.visible = false;
    this.OptionsRightMenu.btnIncidentWorkflowvisible = false; 
    this.$emit('onMenuRightBarIncidentWorkflow', this.OptionsRightMenu);     
  }
};
</script>
<style>
  #fileList{
    padding: 1em;
  }
</style>