import ApiService from '@/services/api.service'

const UserPhotoService = {

    AddUserPhoto(data){
      return ApiService.post(`/UserPhotos`, data);
    },
    UpdateUserPhoto(Id,data){
        return ApiService.put(`/UserPhotos/${Id}`,data);
    }, 
    GetUserPhoto(Id){     
      return ApiService.get(`/UserPhotos/getPhoto/${Id}`);
    }
}

export default UserPhotoService;