<template>
    <div class="col-md-6 grid-margin stretch-card" v-if="Notes.length > 0">
      <div class="card activeCard">
        <div class="card-body">    
          <div class="d-flex align-items-start justify-content-between">
            <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('Shared.Notes') }}</h6>
          </div>
          <div class="text-muted p-fixed cutescroll">
            <p v-if="Notes.length === 0">{{ $t('MdNotesByAppt.NoNotes') }}</p>
            <ul>							
              <li v-for="(n, index) in Notes" :key="`${index}`">
              <a href="#" v-on:click="OnClickNote(n)"> {{n.description}} </a>
              </li>						
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>
  
  <script>
  import DBFunctions from '@/common/DBFunctions.js'
  import { EventBus } from '@/event-bus.js';
  import AppointmentService from '@/services/appointment.service'
  export default {
    props: {
        ApptType:String
   },
   mixins: [DBFunctions],
    data() {
      return {     
        Notes:[],
        Visible:true,
        Isclicked:false,
      }
    },
    
  methods:{
   /*async getNotesByApptype(){
        this.Notes = await this.getNotesByAppt(this.ApptType);		
    },*/

    SelectNote(n){
      if(!this.Isclicked){
      var lnotes = [];
       for(var i = 0; i<this.Notes.length; i++){
        var s = {code:this.Notes[i].notesID.trim(),description:this.Notes[i].description.trim()}
        lnotes.push(s);
       }
       var objNote = {
        Clicked:{
          code:n.notesID.trim(),
          description:n.description.trim(),
        },
        List:lnotes
       };
       EventBus.$emit('onNoteSchedulerClicked', objNote);
       this.Isclicked = true;
      }
    },

    getNotes() { 
      var params = {
          PageSize: 9999999,          
          PageNumber : 1,       
          PatientCode: this.$route.params.id,   
          ActivityId: this.$route.params.actId,
          ActivityTypeCodeList:"",
          NoteStatus:0, 
          NoteIdList:"",
          TherapistCode:"",       
          DateFrom:null,
          DateTo:null,
      };
      AppointmentService.getNotesByActId(params)
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.Notes = response.data;
                }
              })
              .catch(error => {
                if (!error.response) {                
                  this.showMessage(this.$t('MdNotesByAppt.AppoinNotesTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('MdNotesByAppt.AppoinNotesTitle'),error,"error");
                }
              });
            },

    OnClickNote(n){ 
      if(this.Visible){
        this.$router.push({ name: "scheduler-appointment-notes", params: { id: this.$route.params.id,actId:this.$route.params.actId } }).catch(()=>{});
        setInterval(()=> this.SelectNote(n), 500)
      }else{
        this.$bvToast.toast(this.$t('MdNotesByAppt.PatientPresMsg'), {
                         title: this.$t('MdNotesByAppt.Appointment'),
                          autoHideDelay: 5000,
                          variant: "warning"
                        })
      }       
      
    }
  
  },

  created(){   
    EventBus.$on("onVisibleApptType", function (state) {  
      this.Visible = state;
    }.bind(this));
  },

   mounted() {
    this.getNotes();
   }
  }
  
  </script>