<template>
  <div>
            <div class="table-notes-title">
              <div class="text-truncate t-header">{{this.Title}}</div>
              <div class="btn-group btn-group-sm float-sm-right" role="group" aria-label="Table options">               
                <b-button :hidden="TableIsReadOnly || isReadOnly" size="sm" @click="addNewEmptyRecord(false)" class="table-title-btn">{{ $t('Shared.Add')}}</b-button>
                <b-button :hidden="TableIsReadOnly || isReadOnly" size="sm" @click="DeleteRecord" class="table-title-btn">{{ $t('Shared.Delete')}}</b-button>               
              </div>
            </div>
            <b-table
                sticky-header
                selectable
                class="table table-sm  medezTables noteTable cutescroll"
                :select-mode="fMultiSelect"
                :items="items"
                :fields="fFields"
                :current-page="cPage"                
                @row-selected="rowSelected"
                responsive
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="filtered"               
            >  


            <!--
          <template :slot="slotName" v-for="slotName in ['Code', 'Name', 'Age']">  
             <select :key="slotName">
                <option>1</option>             
            </select>   
            <span :class="'flag-icon-'   props.option.toLowerCase()" />{{ countriesByCode[props.option] }}         
          </template> -->



            <template v-slot:cell(Code)="row">      
               <select v-if="row.field.type === 'dropdown'">
                <option>123456</option>             
              </select> 
               <b-form-input  v-if="row.field.type === 'edit'"
               :min="1"
               v-model.number="row.item.value"            
               type="number"  
               />
            </template>
           
         <!--
            <template >                    
             <select class="cutescroll" v-on:change="ChangeTerapies($event,row)" v-on:focus="OnFocusRow(row)" style="width:100%;" :disabled="isReadOnly || row.item.billed" v-model="row.item.code" v-on:input="onInput">
                <option v-for="val in ObjectItems.ArrayValues" :key="val.code" :value="val.code">{{val.name}}</option>             
            </select>                       
            </template>
            <template v-slot:cell(Units)="row">
               <b-form-input
               :min="1"
               v-model.number="row.item.units"
               :readonly="isReadOnly || row.item.billed"
               type="number"  
               v-on:focus="OnFocusRow(row)"
               v-on:input="onInput"/>
            </template>
            -->
            </b-table>
  </div>
</template>

<script>

import ShowMessage from '@/components/messages/ShowMessage.js'
//import Utils from '@/common/utils'
import { EventBus } from '@/event-bus.js';
  export default {
    name:"MdEditTableGeneric",
    mixins: [ShowMessage],
    props: {      
     fields: Array,
     items:Array,    
     totalRows: Number,    
     isReadOnly: Boolean,
     deleteIconEvent: Function,
     onRowSelected: Function,
     currentPage: Number,
     onFiltered: Function,
     perPage: Number,
     pageChanged: Function,
     perPageFunction: Function,
     noteStatus: String,
     defaultUnit: Number,
     transCode: String,
     Tag: String
   },
    data() {
      return {
          filter: null,
          filterOn: [],
          Title:'',
          pPage: this.perPage,
          cPage: this.currentPage,
          fFields:this.fields,        
          ModelSelect:{
            
          },
          TableIsReadOnly:false,
          TableIsrequired:false,       
          fMultiSelect: 'single',
          pageOptions: [5, 10, 15 ],
          MaxRecId:-1,
          PositionsByRows:[],
          ColumnsCalulated:[],         
          isNewRecord: false,
          index: -1
      }
    },
    
  methods:{
    OnFocusRow(row){       
        this.index = row.index;      
    },

    editButton(row) {
      this.editIconEvent(row);
    },

    deleteButton(row) {
      this.deleteIconEvent(row);
    },

    rowSelected() {

    },

    filtered(filteredItems) {
      this.onFiltered(filteredItems);
    },

    fpageChanged(page) {
      this.pageChanged(page);
    },

    pageFunction(perpage) {
      this.perPageFunction(perpage);
    },

    otherButton(row) {
      this.otherIconEvent(row);
    },

    DeleteRecord(){

    },

 

    addNewEmptyRecord(firstTime) {
     /* if(!firstTime){
        this.setChangedStatus()
      }*/
      console.log(firstTime);
      let newFields = {};
      var maxRec = this.MaxRecId;
      this.fFields.forEach(element => {      
        newFields[element.key] = element.defaultValue?element.defaultValue:'';
        if(element.key.toLowerCase() === 'RecID'.toLowerCase()){
           newFields[element.key] = maxRec;
        }
      });
      this.items.unshift(newFields);
    },

    viewButton(row) {
      this.viewIconEvent(row);
    },

     InitializeVariables(){
      if(this.items.length==0)
          this.addNewEmptyRecord(true);
    },


    setChangedStatus(){
      var changed = {
        "Changed":true,
        "ComponentName":'MdEditTableGeneric',
        "Tag": this.Tag
        }
        EventBus.$emit('onChanged', changed);
    },

    onInput(){     
      this.setChangedStatus();      
    }
 },
  created() {
    if(this.deleteIconEvent ) {
      this.fFields.unshift({ key: 'actions', label: this.$tc('Shared.ActionsTxt',2)});
    }
  },
  mounted(){
    this.InitializeVariables();
    //this.resizeColumn();
  }
}

</script>
<style>
.hidden_header {
  visibility: collapse;
}
</style>