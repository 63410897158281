import ApiService from '@/services/api.service'

class ModalService {

  getAll(Resource,parameter){  
     
    const requestData = {
      method: 'get',
      url: Resource,
      headers: {
        
      },
      params: { 
        Code: parameter.Code,
        PatientCode: parameter.PatientCode,
        InsuranceCode: parameter.InsuranceCode,
        SearchCriteria: parameter.SearchCriteria,
        PageSize: parameter.PageSize,
        PageNumber : parameter.PageNumber
      }
    }
        return ApiService.customRequest(requestData);
  }

  get(Resource,Code) {
    return ApiService.get(`${Resource}?Code=${Code}`);
  }
  
}

export default new ModalService();