<template>
    <div >
      <b-modal 
        id="dailyscheduler" 
        size="md" 
        scrollable 
        centered 
        :title="$t('schedulerDailyRep.ReportTitle')" 
        :visible="true"
        :hide-header-close="true"
        :no-close-on-backdrop="true" 
        @ok="onClickShowReport" 
        @cancel="onClickClose">
      <b-row>
          <b-col lg="6" md="6" sm="12">
            <MdSelect
              v-bind:Label="$tc('Shared.Employee',2)"
              v-bind:Options="employeesOptions"
              v-bind:FieldDescription="'employeeName'"
              v-bind:FieldCode="'employeeCode'"
              v-bind:Model="employees"                       
              v-bind:Multiple="true"
              :Input="ChangeDropDownEmployees"
            />
          </b-col>
          <b-col lg="6" md="6" sm="12">
            <MdSelect
              v-bind:Label="$tc('Shared.Patient',2)"
              v-bind:Options="patientOptions"
              v-bind:FieldDescription="'PatientName'"
              v-bind:FieldCode="'patientcode'"
              v-bind:Model="patient"                       
              v-bind:Multiple="true"
              :Input="ChangeDropDownPatients"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="6" sm="12">
            <MdSelect
              v-bind:Label="$t('Shared.AppointmentType')"
              v-bind:Options="AppointmentTypesOptions"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'code'"
              v-bind:Model="appointmentTypes"                       
              v-bind:Multiple="true"
              :Input="ChangeDropDownAppointmentType"
            />
          </b-col>
          <b-col lg="6" md="6" sm="12">
            <MdSelect
              v-bind:Label="$t('schedulerDailyRep.Reason')"
              v-bind:Options="reasonOptions"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Model="reason"                       
              v-bind:Multiple="true"
              :Input="ChangeDropDownReason"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" md="12" sm="12">
            <MdSelect
              v-bind:Label="$tc('Shared.Program',2)"
              v-bind:Options="programOptions"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:ModelArray="program"                       
              v-bind:Multiple="true"
              :Input="ChangeDropDownProgram"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="6" sm="6">
            <MdEdit 
              v-bind:Label="$t('Shared.datefrom')"
              v-bind:TypeProp="'date'" 
              v-bind:Name="'datefrom'"
              v-bind:Min="'1900-01-01'"
              v-bind:Max="'2200-12-31'"
              v-model="dateFrom"
              v-bind:isRequired="true">                                     
            </MdEdit>
          </b-col>
          <b-col lg="6" md="6" sm="6">
            <MdEdit 
              v-bind:Label="$t('Shared.dateTo')"
              v-bind:TypeProp="'date'" 
              v-bind:Name="'dateto'"
              v-bind:Min="'1900-01-01'"
              v-bind:Max="'2200-12-31'"
              v-model="dateTo"
              v-bind:isRequired="true">                                     
            </MdEdit>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" md="12" sm="12">
            <MdRadioGroup
              v-bind:Label="$t('schedulerDailyRep.ShowedUp')"
              v-model="showedup"
              v-bind:Options="showedupOptions"
              v-bind:Name="'showedup'"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" md="12" sm="12">
            <MdRadioGroup
              v-bind:Label="$t('schedulerDailyRep.Confirmed')"
              v-model="confirmed"
              v-bind:Options="confirmedOptions"
              v-bind:Name="'confirmed'"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="12" md="12" sm="12">
            <MdRadioGroup
              v-bind:Label="$t('ChartReview.OrderBy')"
              v-model="order"
              v-bind:Options="orderOptions"
              v-bind:Name="'order'"
            />
          </b-col>
        </b-row>
      </b-modal>

      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        :title="$t('schedulerDailyRep.RepTitle')"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
      >
        <ReportViewer
          ref="reportSchedulerDaily"
          :ReportName="'DailyScheduler'"
          :ReportTitle="$t('schedulerDailyRep.ReportTitle')"
          :IsAnAPIReport="true"
        />
    </DxPopup>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { DxPopup } from 'devextreme-vue/popup';
    import Utils from '../../../common/utils'
    import ClinicalService from '@/services/clinical.service'
    import CalendarResourcesService from '@/services/calendarresources.service'
    import AppointmentTypes from '@/services/appointmenttypes.service'
    import ReasonService from '@/services/reason.service'
    import PatientService from '@/services/patient.service'
    import ShowMessage from '@/components/messages/ShowMessage.js'

    export default Vue.extend({
        name: "SchedulerDailyRep",
        mixins: [ ShowMessage ],
        components: { DxPopup },
        props: {
          startDate: Date,
          closeWindow: Function
        },
        data() {
            return {
                isParamVisible: true,
                isReportVisible: false,
                dateFrom: Utils.formatterDate(this.startDate),
                dateTo: Utils.formatterDate(this.startDate),
                reportParams: [],
                patientOptions: [{}],
                programOptions: [{}],
                patient: null,
                program: null,
                AppointmentTypesOptions: [{}],
                reasonOptions: [{}],
                appointmentTypes: null,
                reason: null,
                employeesOptions: [{}],
                employees: null,
                showedupOptions: [
                  { value: 'all', text: this.$t('Shared.All') },
                  { value: 'T', text: this.$t('Shared.Show') },
                  { value: 'F', text: this.$t('schedulerDailyRep.NoShow') },
                ],
                confirmedOptions: [
                  { value: 'all', text: this.$t('Shared.All') },
                  { value: 'T', text: this.$t('schedulerDailyRep.Confirmed') },
                  { value: 'F', text: this.$t('schedulerDailyRep.NoConfirmed') }
                ],
                showedup: 'all',
                confirmed: 'all',
                orderOptions: [
                  { value: 'time', text: this.$t('Incident.IncTime') },
                  { value: 'appttype', text: this.$t('Shared.AppointmentType') },
                  { value: 'patientname', text: this.$t('Shared.PatientName') }
                ],
                order: 'time',
                appt: false,
                page: false,
                showAddress: false,
                checkboxesOptions: [ 
                    this.$t('schedulerDailyRep.ShowApptTpe'),
                    this.$t('schedulerDailyRep.RemovePeB'),
                    this.$t('schedulerDailyRep.ShowPatiAddre')
                ],
                checkboxes: null,
                programParam: '',
                employeeParam: '',
                appointmentTypeParam: '',
                reasonParam: '',
                patientParam: '',
                parameter:{
                  SearchCriteria:'',
                  PageNumber : 0,
                  PageSize:0,
                  Status: true,       
                },
            }
        },
        methods: {

            onShowing(e) {
              let contentElement = e.component.content();
              let titleElement = e.component._$title[0]
              contentElement.style.padding = '0px';
              titleElement.style.padding = '0px 20px'
            },

            onHiddenReport() {
              this.isReportVisible = false
              this.closeWindow()
            },

            ChangeDropDownPatients(value) {
              this.patientParam = [];
              let selectedPatient = [];
              value.forEach(function(item) {   
                selectedPatient.push(item.patientcode);               
              });

              this.patientParam = selectedPatient;
            },

            ChangeDropDownProgram(value) {
              this.programParam = [];
              let selectedProgram = [];
              value.forEach(function(item) {   
                selectedProgram.push(item.code);               
              });

              this.programParam = selectedProgram;
            },

            ChangeDropDownReason(value) {
              this.reasonParam = [];
              let selectedReason = [];
              value.forEach(function(item) {   
                selectedReason.push(item.code);               
              });

              this.reasonParam = selectedReason;
            },

            ChangeDropDownEmployees(value) {
              this.employeeParam = [];
              let selectedEmployees= [];
              value.forEach(function(item) {   
                selectedEmployees.push(item.employeeCode);               
              });

              this.employeeParam = selectedEmployees;
            },

            ChangeDropDownAppointmentType(value) {
              this.appointmentTypeParam = [];
              let selectedApptTypes= [];
              value.forEach(function(item) {   
                selectedApptTypes.push(item.code);               
              });

              this.appointmentTypeParam = selectedApptTypes;
            },

            getPrograms(){
              ClinicalService.getPrograms()
                .then((response) => {
                     if(response.status == '200' && response.data && response.data.length > 0){
                       this.programOptions = response.data;                                
                     }               
                })
                .catch(error => {
                    if (!error.response) {
                    // network error
                      this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                    } else {
                      this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error");
                    }
                });  
            },

            getEmployees() { 
              CalendarResourcesService.getAll()
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.employeesOptions = response.data;
                }
              })
              .catch(error => {
                if (!error.response) {
                // network error
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error");
                }
              });
            },

            getAppointmentTypes() { 
              AppointmentTypes.getAll()
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.AppointmentTypesOptions = response.data;
                }
              })
              .catch(error => {
                if (!error.response) {
                // network error
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error");
                }
              });
            },

            getReason() { 
              ReasonService.getAll()
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.reasonOptions = response.data;
                }
              })
              .catch(error => {
                if (!error.response) {
                // network error
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error");
                }
              });
            },

            getPatients() { 
              PatientService.getAll(this.parameter)
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.patientOptions = response.data;
                  this.patientOptions.forEach(function(element) {
                    element.PatientName = element.firstname + " " + element.lastname;   
                  });
                }
              })
              .catch(error => {
                if (!error.response) {
                // network error
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error");
                }
              });
            },

            onClickClose() {
              this.closeWindow()
              this.isParamVisible = false;
            },

            onClickShowReport() {
              this.isParamVisible = false

              this.reportParams = []
              this.reportParams.push({ paramName: 'startDate', paramValue: this.dateFrom})
              this.reportParams.push({ paramName: 'endDate', paramValue: this.dateTo})
              this.reportParams.push({ paramName: 'program', paramValue: this.programParam})
              this.reportParams.push({ paramName: 'employee', paramValue: this.employeeParam})
              this.reportParams.push({ paramName: 'apptType', paramValue: this.appointmentTypeParam})
              this.reportParams.push({ paramName: 'reason', paramValue: this.reasonParam})
              this.reportParams.push({ paramName: 'patient', paramValue: this.patientParam})
              this.reportParams.push({ paramName: 'showedup', paramValue: this.showedup})
              this.reportParams.push({ paramName: 'confirmed', paramValue: this.confirmed})
              this.reportParams.push({ paramName: 'order', paramValue: this.order})

              this.isReportVisible = true
              this.$refs.reportSchedulerDaily.buildDocument(this.reportParams)
            }
        },
        
        mounted () {
          this.getPrograms()
          this.getEmployees()
          this.getAppointmentTypes()
          this.getReason()
          this.getPatients();

         
        },
    })
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  } 
</style>
