<template>
    <div >
      <DxPopup
        :visible="isVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"        
        :show-close-button="true"
        @hidden="onHiddenReport"
        :show-title="true"
        :hide-on-parent-scroll="true"
        :title="Title"       
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"       
      >
      <iframe id="iframe" :src="this.Url" width="100%" height="100%"  frameborder="0"></iframe>
    </DxPopup>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { DxPopup } from 'devextreme-vue/popup';
    import ShowMessage from '@/components/messages/ShowMessage.js'

    export default Vue.extend({
        name: "DxPoPup",
        mixins: [ ShowMessage ],
        components: { DxPopup },
        props: {
          Title:String,
          isVisible: Boolean,   
          Url:String,   
          closeWindow: Function,  
        },
        data() {
            return {
             
            }
        },
        methods: {
            onHiddenReport() {
              this.closeWindow()
            },
        },
        
        mounted () {          
         
        },
    })
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  } 
</style>
