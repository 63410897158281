<template>
   <div id="schedulerdb">
        <div class="row">
            <div class="col-12">
                    <MdTitle v-bind:Text="$t('schedulerDB.ScheDashboard')" />  
                    <div class="row">                    
                        <div class="col-sm-12 col-md-12">
                            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                                <div class="input-group">
                                    <input type="text" class="form-control" v-model="parameter.SearchCriteria" @keyup.enter="SearchAppoinments()">
                                        <div class="input-group-append">
                                            <MdButton 
                                                v-bind:Name="'btnsearchinput'"
                                                v-bind:VariantProp="'btn btn-sm search-attached'"
                                                v-bind:IconClass="'mdi mdi-magnify'"
                                                v-on:click="SearchAppoinments">
                                            </MdButton>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> 
        </div>

        <b-row>
            <b-col md="3" lg="3" sm="12">
                <MdEdit 
                    v-bind:Label="$t('Shared.datefrom')"
                    v-bind:TypeProp="'date'" 
                    v-bind:Name="'DateFrom'"
                    v-model="DateFrom"
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"
                    v-on:change="DateFromChange($event)"
                    v-bind:Id="'input_schedulerdb_date_from'">                                     
                </MdEdit>
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdEdit 
                    v-bind:Label="$t('Shared.dateTo')"
                    v-bind:TypeProp="'date'" 
                    v-bind:Name="'DateTo'"
                    v-model="DateTo"
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"
                    v-on:change="DateToChange($event)"
                    v-bind:Id="'input_schedulerdb_date_to'">                                     
                </MdEdit>
            </b-col>  
            <b-col md="3" lg="3" sm="12">
                <MdSelect
                  v-bind:Label="$tc('Shared.Patient',2)"
                  v-bind:Options="PatientsList"
                  v-bind:Model="PatientVmodel"
                  v-bind:FieldDescription="'patientname'"
                  v-bind:FieldCode="'patientcode'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="true"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownPatients"
                  :CustomLabel="customLabelPatients"/>
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdSelect
                  v-bind:Label="$tc('Shared.Therapist')"
                  v-bind:Options="TherapistList"
                  v-bind:Model="TherapistVmodel"
                  v-bind:FieldDescription="'employeeName'"
                  v-bind:FieldCode="'employeeCode'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="true"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownTherapist"/>
            </b-col>

           
            <!--<b-col md="2" lg="2" sm="12">
                <MdSelect
                  v-bind:Label="'Facilities'"
                  v-bind:Options="FacilitiesList"
                  v-bind:Model="FacilitiesVmodel"
                  v-bind:FieldDescription="'company'"
                  v-bind:FieldCode="'code'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="true"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownFacilities"/>
            </b-col>-->
        </b-row>

        <b-row>
          <b-col md="3" lg="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Shared.AppointmentType')"
              v-bind:Options="AppoinmentTypeList"
              v-bind:Model="AppoinmentTypeVmodel"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'code'"
              v-bind:Placeholder="selectPlaceholder"
              v-bind:Multiple="true"
              v-bind:RequireEvent="true"
              v-bind:IfUsevalidation="false"
              :Input="ChangeDropDownAppoinmentType"/>
        </b-col>

            <b-col md="3" lg="3" sm="12">
                <MdSelect
                  v-bind:Label="$tc('Shared.Program',2)"
                  v-bind:Options="ProgramsList"
                  v-bind:Model="ProgramsVmodel"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'code'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="true"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownPrograms"/>
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdSelect
                  v-bind:Label="$t('schedulerDB.AppointmentStatus')"
                  v-bind:Options="ApptStatusList"
                  v-bind:Model="ShowVmodel"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'value'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="true"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownShow"/>
            </b-col>
            <b-col md="3" lg="3" sm="12">
                <MdSelect
                  v-bind:Label="$t('ClinicalNotes.NoteStatus')"
                  v-bind:Options="NoteStatusList"
                  v-bind:Model="NoteStatusVmodel"
                  v-bind:FieldDescription="'name'"
                  v-bind:FieldCode="'value'"
                  v-bind:Placeholder="selectPlaceholder"
                  v-bind:Multiple="false"
                  v-bind:RequireEvent="true"
                  v-bind:IfUsevalidation="false"
                  :Input="ChangeDropDownNoteStatus"/>
            </b-col>

           
        </b-row>
   
        <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'" class="mt-2"/>

        <div class="row"> 
            <div class="col-md-3">
               <MdCheckBox
                v-bind:Label="$t('Shared.SelectAll')"
                v-bind:Name="'SelectTableAll'"
                v-bind:ClassProp="'AlignCheck mx-3'"
                v-bind:CheckedValue="true"
                v-bind:isReadOnly="this.items.length === 0"
                v-bind:UncheckedValue="false"
                v-model="UseSelectTableAll" 
                v-on:change="onChangeSelectAll"                
             /> 
            </div>
            <div class="col-md-3 mt-3">
              <span class="tableSelectedItems"><span class="selItemsVal">{{ SelectedRecords }}</span> {{ $t('claimbatchList.item') }}<span v-if="SelectedRecords != 1">s</span> {{ $t('claimbatchList.selected') }}</span>
            </div> 
        </div> 
       
        <MdTablePaginationClient
              v-bind:fields="fields"           
              v-bind:items="items"
              v-bind:totalRows="totalRows"  
              v-bind:refTable="refTable"           
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPage"
              v-bind:perPage="perPage"             
              v-bind:ShowDetails="true"
              v-bind:SelectAll="UseSelectTableAll"
              v-bind:FieldsDate="FieldsDateArr"
              v-bind:pageOptions="pageOptions" 
              v-bind:viewIconEvent="ViewIcon"             
              :onRowSelected="onRowSelected" 
              :onFiltered="onFiltered"
              :pageChanged="pageChanged"
              :RowClick="RowClicked"
              :perPageFunction="PerPageFunction">
            </MdTablePaginationClient>
   
        <div class="page-actions">   
           <!-- <b-dropdown id="dropdown-dropup" dropup text="Notifications" variant="primary" class="m-2">
                <b-dropdown-item href="#">Email to Patient</b-dropdown-item>
                <b-dropdown-item href="#">Text to Patient</b-dropdown-item>
                <b-dropdown-item href="#">Email to Employee</b-dropdown-item>
                <b-dropdown-item href="#">Text to Employee</b-dropdown-item>
            </b-dropdown>
            -->
           <b-dropdown id="dropdown-dropup" dropup :text="$tc('Shared.ActionsTxt',2)" variant="primary" class="m-2">
                <b-dropdown-item href="#">{{$t('schedulerDB.dropdown.ShowUp')}}</b-dropdown-item>
                <b-dropdown-item href="#">{{$t('schedulerDB.dropdown.EmailPatient')}}</b-dropdown-item>
                <b-dropdown-item href="#">{{$t('schedulerDB.dropdown.TextPatient')}}</b-dropdown-item>
                <b-dropdown-item href="#">{{$t('schedulerDB.dropdown.EmailEmpl')}}</b-dropdown-item>
                <b-dropdown-item href="#">{{$t('schedulerDB.dropdown.TextEmpl')}}</b-dropdown-item>
                <b-dropdown-item href="#">{{$t('ActList.Activities')}}</b-dropdown-item>               
            </b-dropdown>
          
        </div>
    </div>
</template>

<script>

import moment from 'moment'
import PatientService from '@/services/patient.service' 
import CalendarResourcesService from '@/services/calendarresources.service'
import AppoinmentTypeService from '@/services/appointmenttypes.service'
import FacilitiesService from '@/services/facilities.service'
import ProgramService from '@/services/programs.services'
import AppointmentService from '@/services/appointment.service'
import Utils from '@/common/utils'
import EnumService from '@/services/enum.service' 
import { EventBus } from '@/event-bus.js';


export default {
  name: 'SchedulerDB',
  data(){
    return{
        parameter:{
        SearchCriteria:'',
        PageNumber : 0,
        PageSize:0,
        Status: '',
        Programs:'',    
      },
      OptionsRightMenu:{
            visible:false,
            btnvisible:false,
            ItemsList:[],
            favButtonAtion: false
        },
      fullParam: {
            PageSize:0,
            PageNumber:0
        },
    PatientsList:[],
    TherapistList:[],
    AppoinmentTypeList:[],
    FacilitiesList:[],
    ProgramsList:[],
    NoteStatusList:EnumService.SchedulerDashboardNoteStatus,
    ApptStatusList:EnumService.SchedulerDashboardApptStatus,    
    items:[],
    FieldsDateArr:[],
    PatientVmodel:"",
    NoteStatusVmodel:"",
    ShowVmodel:"",
    TherapistVmodel:"",
    AppoinmentTypeVmodel:"",
    FacilitiesVmodel:"",
    ProgramsVmodel:"",
    PatientSelected:"",
    FacilitieSelected:"",
    ProgramSelected:"",
    EmployeeSelected:"",
    ApptTypeSelected:"",
    ShowSelected:"",
    NoteStatusSelected:"",
    refTable: 'selectableTableSchedulerDB',
    ShowMessageIsEmpty:false,
    UseSelectTableAll:false,
    SelectedRecords:0, 
    perPage: 5,
    SelectedPage : 0,
    totalRows: 0,      
    currentPage: 1,
    pageOptions: [5, 10, 15 ],
    selectPlaceholder: this.$t('ClinicalNotes.SelectOptions'),
    DateFrom: moment(this.NewDate).format("YYYY-MM-DD"),
    DateTo: moment(this.NewDate).format("YYYY-MM-DD"),
    fields:[        
        { key: 'activityId', sortable: true, label: this.$t('Activities.Activity'), class: 'text-rigth hidden' }, 
        { key: 'startDate', sortable: true, label: this.$t('Medication.StartDate'), class: 'text-rigth' }, 
        { key: 'startTime', sortable: true, label: this.$t('MdCurrentAppointment.StarTime'), class: 'text-rigth' }, 
        { key: 'endDate', sortable: true, label: this.$t('Medication.EndDate'), class: 'text-rigth' },        
        { key: 'endTime', sortable: true, label: this.$t('MdCurrentAppointment.EndTime'), class: 'text-rigth' }, 
        { key: 'appointmentTypeName', sortable: true, label: this.$t('Shared.AppointmentType'), class: 'text-rigth' }, 
        { key: 'therapistName', sortable: true, label:  this.$tc('Shared.Therapist'), class: 'text-rigth' }, 
        { key: 'status', sortable: true, label: this.$t('Shared.Status'), class: 'text-rigth'}, 
        { key: 'patientName', sortable: true, label: this.$t('Shared.PatientName'), class: 'text-rigth' }, 
        { key: 'nickName', sortable: true, label: this.$t('Patient.NickName'), class: 'text-rigth' }, 
        { key: 'patientCode', sortable: true, label: this.$t('Shared.PatientCode'), class: 'text-rigth' }, 
        { key: 'dob', sortable: true, label: this.$t('Shared.Dob'), class: 'text-rigth' }, 
        { key: 'cellPhone', sortable: true, label: this.$t('Shared.CellPhone'), class: 'text-rigth' }, 
        { key: 'dayPhone', sortable: true, label: this.$t('Shared.DayPhone'), class: 'text-rigth' }, 
        { key: 'nighTPhone', sortable: true, label: this.$t('Shared.NightPhone'), class: 'text-rigth' }, 
        { key: 'email', sortable: true, label: this.$t('Shared.Email'), class: 'text-rigth' }, 
        { key: 'program', sortable: true, label: this.$tc('Shared.Program'), class: 'text-rigth' },  
        { key: 'facilityName', sortable: true, label: this.$t('MdNote.Facility'), class: 'text-rigth' },         
    ]
    }
  },

  methods:{
    onChangeSelectAll(v){
      if(v){
        this.SelectedRecords = this.items.length; 
        for(var i = 0 ; i < this.items.length; i++){
           this.$set(this.items[i], 'selected', true); 
        }
      }else{
        this.SelectedRecords = 0;
        for(var j= 0 ; j < this.items.length; j++){
           this.$set(this.items[j], 'selected', false); 
        }
      }
    },

    ChangeDropDownPatients(patients){
      this.PatientVmodel = patients;
      this.PatientSelected = "";
      var temp = [];
      patients.forEach(function(e) {   
        temp.push(e.patientcode.trim());
      })
      this.PatientSelected = temp.join(',');     
      this.SearchAppoinments();   
    },

    ChangeDropDownTherapist(therapists){
      this.TherapistVmodel = therapists;
      this.EmployeeSelected = "";
      var temp = [];
      therapists.forEach(function(e) {   
        temp.push(e.employeeCode.trim());
      })
      this.EmployeeSelected = temp.join(',');    
      this.SearchAppoinments();   
    },

    ChangeDropDownAppoinmentType(appttypes){
      this.AppoinmentTypeVmodel = appttypes;
      this.ApptTypeSelected = "";
      var temp = [];
      appttypes.forEach(function(e) {   
        temp.push(e.code.trim());
      })
      this.ApptTypeSelected = temp.join(',');    
      this.SearchAppoinments();   
    },

    ChangeDropDownFacilities(facilities){
      this.FacilitiesVmodel = facilities;
      this.FacilitieSelected = "";
      var temp = [];
      facilities.forEach(function(e) {   
        temp.push(e.code.trim());
      })
      this.FacilitieSelected = temp.join(',');    
    },

    ChangeDropDownPrograms(programs){
      this.ProgramsVmodel = programs;
      this.ProgramSelected = "";
      var temp = [];
      programs.forEach(function(e) {   
        temp.push(e.code.trim());
      })
      this.ProgramSelected = temp.join(',');  
      this.SearchAppoinments();     
    },

    ChangeDropDownNoteStatus(notestatus){
      this.NoteStatusVmodel = notestatus != null ? notestatus : "";
      this.NoteStatusSelected = notestatus != null ? notestatus.value : "";
      this.SearchAppoinments();   
    },

    ChangeDropDownShow(options){
      this.ShowVmodel = options;
      this.ShowSelected = "";
      var temp = [];
      options.forEach(function(e) {   
        temp.push(e.value.trim());
      })
      this.ShowSelected = temp.join(',');
      this.SearchAppoinments();   
    },

    DateFromChange(){
      this.SearchAppoinments();
    },

    DateToChange(){
      this.SearchAppoinments();
    },


    ViewIcon(row){
      //appointment-Dashboard
      this.OptionsRightMenu.visible = true;
        this.OptionsRightMenu.btnvisible = true;
        this.OptionsRightMenu.ItemsList = EnumService.AppointmentItems
        
        let layoutStatus = this.$store.state.layoutStatus;
      
        switch(layoutStatus) {
          case 'L0R0':
              this.$store.commit('setlayoutStatus', 'L0R1');
          break;
          default:
              this.$store.commit('setlayoutStatus', 'L1R1');
        }
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        
      this.$router.push({ name: "appointment-Dashboard", params: { id: row.patientCode,actId:row.activityId } });   
    },

    onRowSelected(){
      this.SelectedRecords = 0;
     if(this.items.length > 0){
    for(var i = 0; i < this.items.length; i++){
      if(this.items[i].selected){
        this.SelectedRecords++;
      }
    }
     }
    },

    onFiltered(){

    },

    pageChanged(){

    },

    RowClicked(item){
      if(item.selected){      
          this.$set(item, 'selected', false);         
        }else{
          this.$set(item, 'selected', true);       
        }
        EventBus.$emit('rowClicked', item);
    },

    PerPageFunction(page){
      this.perPage = page;
    },

    getEmployees() { 
        CalendarResourcesService.getAll(this.fullParam)
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.TherapistList = response.data;
                }
              })
              .catch(error => {
                if (!error.response) {
                // network error
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error");
                }
              });
            },

    getPatients(){   
      PatientService.getAll(this.fullParam)
      .then((response) => {
       if(response.status == '200' && response.data && response.data.length > 0){
        this.PatientsList = response.data; 
        this.PatientsList.forEach(function(element) {	
            element.patientname = element.firstname + " " + element.lastname;   
        });       
        }       
        this.$emit('load', false);    
      })
      .catch(error => {      
        this.$emit('load', false);
        if (!error.response) {
            // network error
            this.showMessage(this.$tc('Shared.Patient',2),this.$t('Msg.retrieveInfo'),"error");
        } else {
            this.showMessage(this.$tc('Shared.Patient',2),error,"error");
        }
      });    
    },

    getAppointmentTypes() { 
        AppoinmentTypeService.getListing()
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.AppoinmentTypeList = response.data;
                }
              })
              .catch(error => {
                if (!error.response) {
                // network error
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error");
                }
              });
    },

    getFacilities() {        
        FacilitiesService.getAll(this.fullParam)
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.FacilitiesList = response.data;
                }
              })
              .catch(error => {
                if (!error.response) {
                // network error
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage(this.$t('schedulerDailyRep.ReportTitle'),error,"error");
                }
              });
    },

    getPrograms(){
      ProgramService.getAll(this.fullParam)
        .then((response) => { 
          if(response.data && response.data.length > 0){   
            this.ProgramsList = response.data;
          }
        })
        .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage(this.$tc('Shared.Program',2),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$tc('Shared.Program',2),error,"error");
            }
        });  
    },

    SearchAppoinments(){ 
      var param = {
        PatientCode:this.PatientSelected,
        EmployeeCode:this.EmployeeSelected,
        AppointmentType:this.ApptTypeSelected,
        FacilityCode:this.FacilitieSelected,
        ProgramCode:this.ProgramSelected,
        NoteStatus:this.NoteStatusSelected,
        AppointmentStatus:this.ShowSelected,
        DateFrom:this.DateFrom,
        DateTo:this.DateTo,
        SearchCriteria: this.parameter.SearchCriteria
      }
      this.$emit('load', true);
      AppointmentService.getAll(param)
        .then((response) => { 
          if(response.data){   
            this.items = response.data;
            this.totalRows = this.items.length;
            this.items.forEach(function(element) {
              //element.selected = false;
                  if (element.dob)	
                      element.dob = Utils.formatterDateToString(element.dob);
                  if (element.startDate)	
                      element.startDate = Utils.formatterDateToString(element.startDate);        
                  if (element.endDate)
                      element.endDate =  Utils.formatterDateToString(element.endDate);        
            });
            if (this.items.length === 0) {
							this.ShowMessageIsEmpty = true;
						}
          }
          this.$emit('load', false);
        })
        .catch(error => {
          this.$emit('load', false);
            if (!error.response) {
            // network error
              this.showMessage(this.$t('schedulerDB.Appointments'),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$t('schedulerDB.Appointments'),error,"error");
            }
        });  
    },

    customLabelPatients ({ patientname, patientcode }) {
        return `${patientname} – ${patientcode}`
      }

  },

  created(){
    this.getPatients();
    this.getEmployees();
    this.getAppointmentTypes();
    this.getFacilities();
    this.getPrograms();

    EventBus.$on("rowClicked", function () {        
    this.SelectedRecords = 0;
    for(var i = 0; i < this.items.length; i++){
      if(this.items[i].selected){
        this.SelectedRecords++;
      }
    }
      
     }.bind(this));
  },

  mounted(){
    this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = true;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = true;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }  

      this.SearchAppoinments();   
  }
   
};
</script>


