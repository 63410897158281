<template>
    <div >
      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        :title="reportTitle"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
      >
        <ReportViewer
          ref="groupNoteCompositeReport"
          :ReportName="'GroupNotesCompositeReport'"
          :ReportTitle="reportTitle"
          :IsAnAPIReport="true"
        />
    </DxPopup>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { DxPopup } from 'devextreme-vue/popup';
    import ShowMessage from '@/components/messages/ShowMessage.js'

    export default Vue.extend({
        name: "GroupNoteCompositeReport",
        mixins: [ ShowMessage ],
        components: { DxPopup },
        props: {
          closeWindow: Function,
          reportTitle: String,
          groupCreationId: String,
          isGroupNote: Boolean
        },
        data() {
            return {
                isReportVisible: false,
                reportParams: [],
            }
        },
        methods: {

            onShowing(e) {
              let contentElement = e.component.content();
              let titleElement = e.component._$title[0]
              contentElement.style.padding = '0px';
              titleElement.style.padding = '0px 20px'
            },

            onHiddenReport() {
              this.isReportVisible = false
              this.closeWindow()
            },

            onClickClose() {
              this.closeWindow()
            },

            onClickShowReport() {
              this.reportParams = []

              this.reportParams.push({ paramName: 'title', paramValue: this.reportTitle})
              this.reportParams.push({ paramName: 'groupCreationId', paramValue: this.groupCreationId})
              this.reportParams.push({ paramName: 'isGroupNote', paramValue: this.isGroupNote})

              this.isReportVisible = true
              this.$refs.groupNoteCompositeReport.buildDocument(this.reportParams)
            }
        },
        
        mounted () {
          this.onClickShowReport()
        },
    })
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  } 
</style>
