<template>
   <div id="ContainerChartReview">
      <div class="row">
		<div class="col-12">
			<div class="accordion" role="tablist">
				<b-card no-body class="noteSearchPanel mb-0">
					<b-card-header
						header-tag="header"
						class="p-0"
						role="tab">
						<b-button
							block
							v-on:click="Accordion1Click"
							class="collapseBTN text-truncate"
							><b-icon
								icon="arrow-down-right-circle"
								aria-hidden="true"
							></b-icon>
							{{ $t('ChartReview.CHARTREVIEW') }} -  
							<!--<span v-if="this.RegistrationInfo.patientName">{{this.RegistrationInfo.patientName+' - '}}</span>
							<span v-if="this.$route.params.id">{{this.$route.params.id + ' - '}}</span>
							<span v-if="this.RegistrationInfo.dob"> Dob {{this.RegistrationInfo.dob}}</span>
							<span v-if="this.RegistrationInfo.age"> Age {{this.RegistrationInfo.age}}</span>						
							<span v-if="this.Origin === 'Clinical' && this.RegistrationInfo.programName">- {{this.RegistrationInfo.programName}}  </span>
							<span v-if="this.Origin === 'Clinical' && this.RegistrationInfo.dateFrom">- {{this.RegistrationInfo.dateFrom}}  </span>
							<span v-if="this.Origin === 'Clinical' && this.RegistrationInfo.dateTo">- {{this.RegistrationInfo.dateTo}}  </span>
							<span v-show="this.Origin === 'Clinical'" v-else>- Active  </span>-->
							<span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName + ' - '}}</span>
							<span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
							<span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
							<span v-if="this.PatientTitle.ageFormatted"> {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
							<span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
							<span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
							<span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
							<span v-else v-show="IsClinical">- {{ $t('Shared.Active') }}  </span>
							
							</b-button>
					</b-card-header>
					<b-collapse
						id="accordion-1"
						visible
						accordion="ClinicalNotes"
						role="tabpanel"
						v-show="ShowFilter"
						v-on:click="ShowFilter = true">
						<b-card-body class="p-1">
							<b-card-text class="notesPanelsChartReview cutescroll">
								<b-row>
									<b-col md="4" lg="4" sm="12">
										<MdEdit 
											v-bind:Label="$t('Shared.datefrom')"
											v-bind:TypeProp="'date'" 
											v-bind:Name="'DateFrom'"
											v-model="DateFrom"
											v-bind:Min="'1900-01-01'"
											v-bind:Max="'2200-12-31'"
											v-on:change="DateFromChange($event)">                                     
										</MdEdit>
									</b-col>
									<b-col md="4" lg="4" sm="12">
										<MdEdit 
											v-bind:Label="$t('Shared.dateTo')"
											v-bind:TypeProp="'date'" 
											v-bind:Name="'DateTo'"
											v-model="DateTo"
											v-bind:Min="'1900-01-01'"
											v-bind:Max="'2200-12-31'"
											v-on:change="DateToChange($event)">                                     
										</MdEdit>
									</b-col>
                  <b-col md="4" lg="4" sm="12">
										<div class="form-group">
												<MdSelect
												v-bind:Label="$t('ChartReview.NoteTypes')"
												v-bind:FieldDescription="'description'"
												v-bind:FieldCode="'notesid'"
												v-bind:Options="OptionNoteTypes"
												v-bind:Model="this.NoteTypesVModel"
												v-bind:Placeholder="selectPlaceholder"
												v-bind:Multiple="true"
												:Input="ChangeDropDownNoteTypes"/>
											</div>		
									</b-col>				
								</b-row>
								<b-row>
									<b-col  md="4" lg="4" sm="12">
										<div class="form-group">
												<MdSelect
												v-bind:Label="$tc('Shared.Therapist')"
												v-bind:FieldDescription="'name'"
												v-bind:FieldCode="'code'"
												v-bind:Options="TherapistList"
												v-bind:Model="this.Therapist"
												v-bind:Placeholder="selectPlaceholder"
												v-bind:Multiple="true"
												:Input="ChangeDropDownTherapist"/>
											</div>		
									</b-col>
									<b-col  md="4" lg="4" sm="12">
										<div class="form-group">
												<MdSelect
												v-bind:Label="$t('ChartReview.CoTherapist')"
												v-bind:FieldDescription="'name'"
												v-bind:FieldCode="'code'"
												v-bind:Options="CotherapistList"
												v-bind:Model="this.Cothetapist"
												v-bind:Placeholder="selectPlaceholder"
												v-bind:Multiple="true"
												:Input="ChangeDropDownCotherapist"/>
											</div>		
									</b-col>
									<!--<b-col  md="4" lg="4" sm="12">
										<div class="form-group">
										<MdSelect
												v-bind:Label="$t('ChartReview.OrderBy')"
												v-bind:FieldDescription="'description'"
												v-bind:FieldCode="'code'"
												v-bind:Options="ViewAsList"
												v-bind:Model="this.ViewAs"
												v-bind:Placeholder="selectPlaceholder"
												v-bind:Multiple="false"
												:Input="ChangeDropDownViewAs"/>
											</div>			
									</b-col>-->
								<b-col md="4" lg="4" sm="12">													
											<div class="form-group">
												<MdSelect
												v-bind:Label="$t('ChartReview.NoteStatus')"
												v-bind:Options="OptionNoteStatus"
												v-bind:ModelArray="this.NoteStatusVModel"
												v-bind:Placeholder="selectPlaceholder"																
												v-bind:FieldDescription="'name'"
												v-bind:Multiple="false"
												v-bind:FieldCode="'value'"
												v-bind:IfUsevalidation="false"
												:Input="ChangeDropDownNoteStatus"/>
											</div>										
									</b-col>
								</b-row>
								<b-row >
								<b-col  md="4" lg="4" sm="12" v-if="this.Origin === 'Patient'">
									<div class="form-group">
												<MdSelect
												v-bind:Label="$t('Shared.Admissions')"
												v-bind:FieldDescription="'label'"
												v-bind:FieldCode="'recordId'"
												v-bind:Options="ProgramList"
												v-bind:Model="Program"
												v-bind:Placeholder="selectPlaceholder"
												v-bind:Multiple="true"
												:Input="ChangeDropDownProgram"/>
									</div>	
								</b-col>
								<b-col lg="3" md="3" sm="12">             
									<MdRadioGroup
										v-bind:Label="$t('ChartReview.OrderBy')"
										v-model="RadioOrderBy"
										v-bind:Options="options"
										v-bind:Name="'radio-inline'"
										v-on:change="ChangeRadioGroup($event)"
									/>
								</b-col>  									
								</b-row>							
								<b-row>
									<b-col md="12">
										<div class="text-center text-md-right mt-3">
											<button v-on:click="SearchNotes"
												type="button"
												class="btn btn-primary btn-login mx-2 pull-right">
												{{ $t('Shared.Search') }}
											</button>
										</div>
									</b-col>
								</b-row>
								<b-row>
									<b-col sm="12" lg="12" md="12">
									<div v-if="RadioOrderBy === 'Note Type'">
										<MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
										<MdTable
											v-bind:fields="fields"
											v-bind:IsChartReview="true"
											v-bind:items="items"
											v-bind:useAddTime="false"
											v-bind:totalRows="totalRows"
											v-bind:totalRowsSubItems="totalRowsSubItems"
											v-bind:showMessageIsEmpty="
												ShowMessageIsEmpty
											"
											v-bind:isReadOnly="false"
											v-bind:selectMode="
												selectMode
											"
											v-bind:currentPage="
												currentPage
											"
											v-bind:currentPageSubItems="currentPageSubItems"
											v-bind:perPage="perPage"
											v-bind:perPageSubItems="perPageSubItems"
											v-bind:pageOptions="
												pageOptions
											"
											v-bind:pageOptionsSubItems="
												pageOptionsSubItems
											"
											v-bind:refTable="refTable"
											
											:onRowSelected="
												onRowSelected
											"
											:onFiltered="onFiltered"
											:pageChanged="pageChanged"
											:pageChangedSubItems="pageChangedSubItems"
											v-bind:ShowDetails="true"
											:perPageFunction="
												PerPageFunction
											"
											:perPageFunctionSubItems="
												PerPageFunctionSubItems
											"
											:avisar="onResultados"
											:CheckClick="onCheck"
											:NotePatientInfo="PatientInfo"
										>
										</MdTable>
									</div>
									<div v-else v-show="Date">
								<MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
									<MdTable 
										v-bind:fields="fieldsDate"
										v-bind:items="items"
										v-bind:IsChartReview="true"
										v-bind:IsViewDate="true"
										v-bind:totalRows="totalRows"
										v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
										v-bind:currentPage="currentPage"
										v-bind:perPage="perPage"
										:viewIconEvent="OpenNote"
										v-bind:ShowDetails="true"
										v-bind:pageOptions="pageOptions"
										:onRowSelected="onRowSelected"										
										:onFiltered="onFiltered"
										:pageChanged="pageChanged"
										:perPageFunction="PerPageFunction"
										:NotePatientInfo="PatientInfo">
									</MdTable>
									</div>
									</b-col>
								</b-row>
							
							</b-card-text>
						</b-card-body>
					</b-collapse>
				</b-card>

				<b-card no-body class="openNotesPanel mb-0">
					<b-card-header
						header-tag="header"
						class="p-0"
						role="tab">
						<b-button
							block
							v-on:click="ClickBtnAccordion2"
							class="collapseBTN"
							><b-icon
								icon="arrow-down-right-circle"
								aria-hidden="true"
							></b-icon>
							{{ $t('ChartReview.OPENNOTES') }}</b-button>
					</b-card-header>
					<b-collapse
						id="accordion-2"
						accordion="ClinicalNotes"
						role="tabpanel"
						:class="this.ClassAccordion"
						v-show="ShowNote"
						v-on:click="ShowNote = true"
						ref="a2">
						<b-card-body class="p-1" v-show="ShowNote">
							<b-card-text class="notesPanels">
								<!-- OPEN NOTES CODE -->
								<div :class="this.ClassContainerNotes" ref="ContainerNotes">
								<MdNote
									v-for="(model, index) in this.ClinicalNotesListOpened"
									v-bind:NOTE="model.description"										
									v-bind:TransDate="model.date"
									v-bind:Time="model.Time"
									v-bind:Header="model"
									v-bind:ProgramCode="ProgramCode"
									v-bind:IsChartReview="true"
									:key="model.Id"
									:id="model.Id"
									v-on:remove="CloseNote(index)"
									:model="model"
									v-bind:NotePatientInfo="PatientInfo">
								</MdNote>
								</div>
								<!-- END OPEN NOTES CODE -->
							</b-card-text>
						</b-card-body>
					</b-collapse>
				</b-card>
			</div>
		</div>
	</div>
   </div>
</template>

<script>
import EnumService from '@/services/enum.service';
import DBFunctions from '@/common/DBFunctions.js'
import LocalStorage from '@/services/storage.service';
import moment from "moment";
import GlobalService from '@/services/global.service';
//import ClinicalService from '@/services/clinical.service';
import ChartReview from '@/services/chartreview.service.js';
import ShowMessage from '@/components/messages/ShowMessage.js';
import EmployeeService from '@/services/employee.service';
import Utils from "@/common/utils";
import { EventBus } from '@/event-bus.js';

export default {
 name: 'ChartReviewBase',
 props:{Origin:String},
 mixins: [DBFunctions,ShowMessage],
  data(){
    return {
	OptionsRightMenu:{
        visible:true,
        ItemsList:[],
        btnvisible:true,
		itemdisabled: false,          
	},
	IsClinical:false,
	PatientTitle:{      
       programName:'',
       fullName:'',
       admitDate:'',
       dischargeDate:'',
       dob:''
     },
	OptionNoteStatus: EnumService.NoteStatus,
	NoteStatusVModel: {value:0,name: this.$t('Shared.All')},
	options: [this.$t('Shared.NoteType'),this.$t('Shared.Date')],
	RadioOrderBy: 'Note Type',
	parameterListing:{
        SearchCriteria:'',
        PageNumber : 1,
        PageSize:1,
        AdmissionRecordIdList:[],
		TherapistCodesList:[],
		CoTherapistCodesList:[],
		NotesIdList:[],
      },
	PatientInfo:{},
	RegistrationInfo:{},
	DateFrom:'',
	DateTo:'',
	ProgramCode:'',
	TherapistList:[],
	CotherapistList:[],
	OptionNoteTypes:[],
	ProgramList:[],
	Program:"",
	//ViewAsList:[{'code':1,'description':'Note Type'},{'code':2,'description':'Date'}],
	Therapist:"",
	//ViewAs:{'code':1,'description':'Note Type'},
	ShowFilter:true,
	ShowNote: false,
	items: [],
	ShowMessageIsEmpty: false,
	refTable: "selectableTable",
	selectMode: "single",
	selectPlaceholder: this.$t('ChartReview.SelectOptions'),
	NoteTypesVModel: "",
	Cothetapist:"",
	ClassContainerNotes:'',
	ClassAccordion:'',
	ClinicalNotesListOpened:[],
	parameter:{
		NoteTypes:[],
		NoteStatus:0,
		SearchCriteria:'',
        PageNumber : 1,
        PageSize:1,
        AdmissionRecordIdList:[],
		TherapistCodesList:[],
		CoTherapistCodesList:[],
		NotesIdList:[],
	},
	pageOptions: [5,10,20,30,40,50],
	pageOptionsSubItems: [5,10,20,30,40,50],
	totalRows: 0,
	totalRowsSubItems: 0,
	currentPage: 1,
	currentPageSubItems: 1,
			perPage: 50,
			perPageSubItems: 50,
			filter: null,
			filterOn: [],
		fields: [
				{ key: 'show_details', sortable: true, label: this.$t('ChartReview.ShowDetails')},
				{ key: 'description', sortable: true,label:this.$t('Shared.NoteType')},				
				{ key:'notesId',sortable: true, class:'hidden'},
				{ key: 'frequency', sortable: true, label: this.$t('Shared.Frequency')},
				{ key: 'isBillable', class:'hidden',sortable: true, formatter: value => {return value ? this.$t('ChartReview.Billable') : this.$t('ChartReview.NotBillable')}},
				{ key: 'isGroup', sortable: true, label: this.$t('ChartReview.IsGroup'), formatter: value => {return value ? 'Y' : 'N'}},
				
				//{key:'HeaderId',sortable: true, class:'hidden'},
				
			
			],
			fieldsDate: [
				{ key: 'transdate', sortable: true,label: this.$t('ChartReview.NoteDate')},
				{ key: 'description', sortable: true,label: this.$t('Shared.NoteType')},
				{ key: 'therapistName', sortable: true,label: this.$tc('Shared.Therapist')},
				//{ key: 'signedDate', sortable: true,label: 'Signed Date'},
				{ key: 'coTherapistName', sortable: true,label: this.$t('ChartReview.CoTherapist')},			
				//{ key: 'coSignedDate', sortable: true,label: 'CoSigned Date'},
				{ key:'notesId',sortable: true, class:'hidden'},				
				{ key: 'isBillable',label: this.$t('ChartReview.Billable'), sortable: true, formatter: value => {return value ? this.$t('ChartReview.Billable') : this.$t('ChartReview.NotBillable')}},
				{ key:'report',sortable: true, label:this.$t('Shared.Report')},
				//{key:'HeaderId',sortable: true, class:'hidden'},
				
			
			],
    }
  },

  methods:{
   
	DateFromChange(e){
		this.DateFrom = e;
		
		//this.getNotesTherapies();
	},
	DateToChange(){		
		//this.getNotesTherapies();
	},

	ChangeRadioGroup(e){		
		this.currentPage = 1;
		this.parameter.PageNumber = 1;
		this.RadioOrderBy = e;
		this.getNoteList();
	},
    ChangeDropDownNoteTypes(notetypes){
	var l = notetypes.length;
		var TempNotesIdList = [];
		if(l>0){
			var i = 0;
			notetypes.forEach(function(e) {   
        if(i<l-1){
          TempNotesIdList += e.notesid+",";
        }else{
          TempNotesIdList += e.notesid;
        }
        i++;                    
        });
        this.parameterListing.NotesIdList = TempNotesIdList;
		}else{
			this.parameterListing.NotesIdList = [];
		}   
//	this.getListing(); 
    },

	ChangeDropDownCotherapist(cotherapist){
		var l = cotherapist.length;
		var TempCoTherapist = [];
		if(l>0){
			var i = 0;
			cotherapist.forEach(function(e) {   
        if(i<l-1){
          TempCoTherapist += e.code+",";
        }else{
          TempCoTherapist += e.code;
        }
        i++;                    
        });
        this.parameterListing.CoTherapistCodesList = TempCoTherapist;
		}else{
			this.parameterListing.CoTherapistCodesList = [];
		}   
	//this.getListing(); 
	},

	ChangeDropDownTherapist(therapist){
		var l = therapist.length;
		var TempTherapist = [];
		if(l>0){
			var i = 0;
			therapist.forEach(function(e) {   
        if(i<l-1){
          TempTherapist += e.code+",";
        }else{
          TempTherapist += e.code;
        }
        i++;                    
        });
        this.parameterListing.TherapistCodesList = TempTherapist;
		}else{
			this.parameterListing.TherapistCodesList = [];
		}   
	//this.getListing(); 
	},

	ChangeDropDownViewAs(){

	},

	async getPrograms(){
		//this.ProgramList
		var arr = await this.getProgramsByPatient(this.$route.params.id);

		arr.forEach(element => {	
			element.label = element.dischargeDate ? element.programName + " ( "+element.admitDate + " - "+element.dischargeDate+" )": element.programName + " ( "+element.admitDate + " - Present )";
		
		});

		this.ProgramList = arr;
	
	},

	ChangeDropDownNoteStatus(NoteStatus){
	if(NoteStatus !== null){
			this.NoteStatusVModel =  NoteStatus;
		}else{
			this.NoteStatusVModel.value =  0;
		}
	},

	ChangeDropDownProgram(programs){
		var l = programs.length;
		var TempPrograms = [];
		if(l>0){
			var i = 0;
			programs.forEach(function(e) {   
        if(i<l-1){
          TempPrograms += e.recordId+",";
        }else{
          TempPrograms += e.recordId;
        }
        i++;                    
        });
        this.parameterListing.AdmissionRecordIdList = TempPrograms;
		}else{
			this.parameterListing.AdmissionRecordIdList = [];
		}   
	//this.getListing(); 
	},

	NoteIsDailyMultiple(NoteId){
		var result = false;
		this.items.forEach(element => {
			if(element.notesId === NoteId){	
				result = element.frequency.toLowerCase() === 'DAILY MULTIPLES'.toLowerCase();		
			}
        });
		return result;
	},

	AddSubItemsbyRow(NotesId,SubItems,Pagination){		
		this.items.forEach(element => {
			if(element.notesId === NotesId){				
				this.$set(element, 'currentPageSubItems', Pagination.currentPageSubItems);
				this.$set(element, 'perPageSubItems', Pagination.perPageSubItems);
				this.$set(element, 'totalRowsSubItems', Pagination.totalRowsSubItems);
				this.$set(element, 'SubItems', SubItems);
			}
        });		
	},

	OpenNote(item) {		
		item.notesId = item.notesID;
		item.Id = item.headerID;
		if(item.frequency === 'DAILY MULTIPLES'){
			item.Time = item.transdate;
		}
		item.serviceDate = item.serviceDate !== null ? Utils.formatterDate(item.serviceDate) : null;
		this.OnResultViewDate(item);
    },

	ExistDate(arr,TransDate){
		for(var i = 0; i < arr.length; i++){
			if(arr[i].date === TransDate){
				return true;
			}
		}
		return false;
	},

	getTimesByDate(arr,date){
		var temparr = [];
		for(var i = 0; i < arr.length; i++){
			if(moment(arr[i].transDate).format("ddd, ll") === date){
					var obj = {};
					if(arr[i].noteStatus == 'New'){								
								obj.icon = 'icon-doc mr-2';
								obj.description = this.$t('Shared.IconDescN');				
							}
							else if(arr[i].noteStatus == 'Voided'){
								obj.icon = 'icon-ban mr-2';
								obj.description = this.$t('Shared.IconDescVN');
							}
							else if(arr[i].noteStatus == 'Pending Signature'){
								obj.icon = 'icon-note  mr-2';
								obj.description = this.$t('Shared.IconDescPS');
							}
							else if(arr[i].noteStatus == 'Pending CosignerSignature'){
								obj.icon = 'icon-user mr-2';
								obj.description = this.$t('Shared.IconDescPCS');
							}else{
								obj.icon = 'icon-check mr-2';
								obj.description = this.$t('Shared.IconDescF');
							}
							obj.Id = arr[i].headerID;
							obj.Time = moment(arr[i].noteTime).format("HH:mm:ss");	
							obj.isBillable = this.NoteDates[i].isBillable;
							obj.therapistName = this.NoteDates[i].therapistName;
							obj.coTherapistName = this.NoteDates[i].coTherapistName;
							obj.isVoid = this.NoteDates[i].isVoid;
							obj.chartno = this.NoteDates[i].chartno;
							obj.defaultUnit = this.NoteDates[i].defaultUnit;
							obj.coTherapistCode = this.NoteDates[i].coTherapistCode;
							obj.disableBillable = this.NoteDates[i].disableBillable;
							obj.showBilling = this.NoteDates[i].showBilling;
							obj.frequency = this.NoteDates[i].frequency;
							obj.enableEdit = this.NoteDates[i].enableEdit;
							obj.userHasReadOnlyAccess = this.NoteDates[i].userHasReadOnlyAccess;
							obj.customFrame = this.NoteDates[i].customFrame;
							obj.showReport = this.NoteDates[i].showReport;
							if(this.NoteDates[i].coSignedDate){
								//obj.coSignedDate = Utils.formatterDateTimeToString(this.NoteDates[i].coSignedDate);
								obj.coSignedDate = moment(this.NoteDates[i].coSignedDate).format("ddd, ll");
							}else{
								obj.coSignedDate = null;
							}
							
							obj.endTime = this.NoteDates[i].endTime;
							obj.facilityCode = this.NoteDates[i].facilityCode;
							obj.isCoSigned = this.NoteDates[i].isCoSigned;
							obj.isPendingCoSignature = this.NoteDates[i].isPendingCoSignature;
							obj.isPendingSignature = this.NoteDates[i].isPendingSignature;
							obj.isSigned = this.NoteDates[i].isSigned;
							obj.noteStatus = this.NoteDates[i].noteStatus;
							obj.noteTime = this.NoteDates[i].noteTime;
							
							if(this.NoteDates[i].serviceDate){
								obj.serviceDate = Utils.formatterDate(this.NoteDates[i].serviceDate);
							}else{
								obj.serviceDate = null;
							}
							if(this.NoteDates[i].signedDate){
								//obj.signedDate = Utils.formatterDateTimeToString(this.NoteDates[i].signedDate);
								obj.signedDate = moment(this.NoteDates[i].signedDate).format("ddd, ll");
							}else{
								obj.signedDate = null;
							}

							if(this.NoteDates[i].noteTime === null)
								obj.isBase = true;
						
							obj.startTime = this.NoteDates[i].startTime;
							obj.therapistCode = this.NoteDates[i].therapistCode;
							obj.transCode = this.NoteDates[i].transCode;
							obj.transDate = this.NoteDates[i].transDate;
							obj.notesContents = this.NoteDates[i].notesContents;
					temparr.push(obj);
			}
		}
		return temparr;
	},

		getNoteList(){
		//this.$emit('load', true);
		EventBus.$emit('ShowWeel', true); 
		this.items = [];
		this.parameterListing.PageNumber = this.currentPage;
		this.parameterListing.PageSize = this.perPage;
		this.parameterListing.PatientCode = this.$route.params.id;
		this.parameterListing.DateFrom = this.DateFrom;
		this.parameterListing.DateTo = this.DateTo;	
		this.parameterListing.NoteStatus = this.NoteStatusVModel.value;
		this.parameterListing.UserId = LocalStorage.getUserId();
		var SubItems = [];
		var Exist = false;			
		if(this.RadioOrderBy === 'Note Type'){
			ChartReview.getNoteList(this.parameterListing)
                .then((response) => {
                     if(response.status == '200' && response.data){
                       this.items = response.data;
						
                       if(response.data.length > 0){                
                        let headerString = response.headers["x-pagination"];					
                        this.Header = JSON.parse(headerString);
                        this.totalRows = this.Header.TotalCount;
                       }
                     }                     
						//this.$emit('load', false);
						EventBus.$emit('ShowWeel', false); 
						if (this.items.length === 0) {
							this.ShowMessageIsEmpty = true;
						}
                })
                .catch((error) => {                    
                    //this.$emit('load', false);
					EventBus.$emit('ShowWeel', false); 
                    if (!error.response) {
                      this.showMessage(this.$t('ChartReview.NotesList'),this.$t('Msg.retrieveInfo'),"error");
                    } else {
                      this.showMessage(this.$t('ChartReview.NotesList'),error,"error");
                    }
                }); 
		}else{
			ChartReview.getAllDate(this.parameterListing)
                .then((response) => {
                     if(response.status == '200' && response.data){
                       this.items = response.data;					 										
                       if(response.data.length > 0){                
                        let headerString = response.headers["x-pagination"];					
                        this.Header = JSON.parse(headerString);
						var l = this.items.length;
                        this.totalRows = this.Header.TotalCount;
						var Pagination = {};				
						var obj = {};					
						Pagination.currentPageSubItems = this.currentPageSubItems;
						Pagination.perPageSubItems = this.perPageSubItems;
						Pagination.totalRowsSubItems = this.totalRows;

						var fulldate, tmpdate,tmptime,tmpsigneddate,tmpcosigneddate;
						for(var i = 0; i < this.items.length; i++){
							var notesID = this.items[i].notesID;
							if(this.items[i].transDate){
								tmpdate = moment(this.items[i].transDate).format("ddd, ll");
							}	
							
							if(this.items[i].signedDate){
								tmpsigneddate = moment(this.items[i].signedDate).format("ddd, ll");
								this.items[i].signedDate = tmpsigneddate;
							}

							if(this.items[i].coSignedDate){
								tmpcosigneddate = moment(this.items[i].coSignedDate).format("ddd, ll");
								this.items[i].coSignedDate = tmpcosigneddate;
							}
							

							tmptime = "";							
							if(this.items[i].frequency === 'DAILY MULTIPLES'){ // noteTime  transDate
								if(this.items[i].noteTime){
									tmptime = moment(this.items[i].noteTime).format('HH:mm:ss');
								}								
							}
							fulldate = tmpdate+" "+tmptime;
							this.items[i].transdate = fulldate;

							if(l > 0){
							if(this.NoteIsDailyMultiple(notesID)){
							
									var TransD = moment(this.items[i].transDate).format("ddd, ll");
									Exist = this.ExistDate(SubItems,TransD);
									obj.ArrTimes = this.getTimesByDate(this.items, TransD);							
									obj.date = TransD;
									if(!Exist)
										SubItems.push(obj);
									obj = {};							
								
							}else{								
							obj = {
								date:'',
								icon:'',
								description:'',
								Id:'',
								isBillable:false,
								defaultUnit:0,
								therapistName:'',
								coTherapistName:'',
								isVoid:false,
								coTherapistCode:null,
								coSignedDate:null,
								frequency:"",
								endTime:null,
								facilityCode:"",
								isCoSigned:null,
								isPendingCoSignature:null,
								isPendingSignature:null,
								isSigned:null,
								noteStatus:"",
								noteTime:null,							
								chartno:"",
								serviceDate:null,
								signedDate:null,
								startTime:null,
								therapistCode:"",
								transCode:"",
								transDate:null,
								notesContents:"",
								disableBillable:false,
								showBilling:false,
								showReport:false,
								userHasReadOnlyAccess:false,
								enableEdit:true,
								customFrame:""
							};
							obj.therapistName = this.items[i].therapistName;
							obj.coTherapistName = this.items[i].coTherapistName;
							obj.date = moment(this.items[i].transDate).format("ddd, ll");
							obj.Id = this.items[i].headerID;
							obj.isBillable = this.items[i].isBillable;
							obj.isVoid = this.items[i].isVoid;
							obj.coTherapistCode = this.items[i].coTherapistCode;
							obj.disableBillable = this.items[i].disableBillable;
							obj.defaultUnit = this.items[i].defaultUnit;
							obj.showBilling = this.items[i].showBilling;
							obj.chartno = this.items[i].chartno;
							obj.frequency = this.items[i].frequency;
							obj.enableEdit = this.items[i].enableEdit;
							obj.showReport = this.items[i].showReport;
							obj.customFrame = this.items[i].customFrame;
							obj.userHasReadOnlyAccess = this.items[i].userHasReadOnlyAccess;						
							if(this.items[i].coSignedDate){
								//obj.coSignedDate = Utils.formatterDateTimeToString(this.items[i].coSignedDate);
								obj.coSignedDate = moment(this.items[i].coSignedDate).format("ddd, ll");
							}
							obj.endTime = this.items[i].endTime;
							obj.facilityCode = this.items[i].facilityCode;
							obj.isCoSigned = this.items[i].isCoSigned;
							obj.isPendingCoSignature = this.items[i].isPendingCoSignature;
							obj.isPendingSignature = this.items[i].isPendingSignature;
							obj.isSigned = this.items[i].isSigned;
							obj.noteStatus = this.items[i].noteStatus;
							obj.noteTime = this.items[i].noteTime;
							if(this.items[i].serviceDate){
								obj.serviceDate = Utils.formatterDate(this.items[i].serviceDate);
							}
							if(this.items[i].signedDate){
								//obj.signedDate = Utils.formatterDateTimeToString(this.items[i].signedDate);
								obj.signedDate = moment(this.items[i].signedDate).format("ddd, ll");
							}
						
							obj.startTime = this.items[i].startTime;
							obj.therapistCode = this.items[i].therapistCode;
							obj.transCode = this.items[i].transCode;
							obj.transDate = this.items[i].transDate;
							obj.notesContents = this.items[i].notesContents;
							if(this.items[i].noteStatus == 'New'){								
								obj.icon = 'icon-doc mr-2';
								obj.description = this.$t('Shared.IconDescN');				
							}
							else if(this.items[i].noteStatus == 'Voided'){
								obj.icon = 'icon-ban mr-2';
								obj.description = this.$t('Shared.IconDescVN');
							}
							else if(this.items[i].noteStatus == 'Pending Signature'){
								obj.icon = 'icon-note  mr-2';
								obj.description = this.$t('Shared.IconDescPS');
							}
							else if(this.items[i].noteStatus == 'Pending CosignerSignature'){
								obj.icon = 'icon-user mr-2';
								obj.description = this.$t('Shared.IconDescPCS');
							}else{
								obj.icon = 'icon-check mr-2';
								obj.description = this.$t('Shared.IconDescF');
							}
							SubItems.push(obj);	
							this.AddSubItemsbyRow(notesID,SubItems,Pagination);						
						}
                       
						} 
						}	
												
                       }
                     }                     
						//this.$emit('load', false);
						EventBus.$emit('ShowWeel', false); 
						if (this.items.length === 0) {
							this.ShowMessageIsEmpty = true;
						}

						
                })
                .catch((error) => {                    
                    //this.$emit('load', false);
					EventBus.$emit('ShowWeel', false); 
                    if (!error.response) {
                      this.showMessage(this.$t('ChartReview.NotesList'),this.$t('Msg.retrieveInfo'),"error");
                    } else {
                      this.showMessage(this.$t('ChartReview.NotesList'),error,"error");
                    }
                }); 
		}

      
     },

	async getNoteAllDate(notesid,currentPageSubItems,perPageSubItems){
		//this.$emit('load', true);
		EventBus.$emit('ShowWeel', true);
		var SubItems = [];
		var Exist = false;	
		this.parameter.PageNumber = currentPageSubItems
		this.parameter.PageSize = perPageSubItems;
		this.parameter.PatientCode = this.$route.params.id;
		this.parameter.DateFrom = this.DateFrom;
		this.parameter.DateTo = this.DateTo;	
		this.parameter.UserId = LocalStorage.getUserId();
		this.parameter.NotesIdList = notesid;
		this.parameter.NoteStatus = this.NoteStatusVModel.value;

      await ChartReview.getAllDate(this.parameter)
                .then((response) => {
                     if(response.status == '200' && response.data){
                        this.NoteDates = response.data;						
						var l = this.NoteDates.length;
						let headerString = response.headers["x-pagination"];
						this.Header = JSON.parse(headerString);
						var totalRowsSubItems = this.Header.TotalCount;					
						var Pagination = {};				
						var obj = {};					
						Pagination.currentPageSubItems = currentPageSubItems;
						Pagination.perPageSubItems = perPageSubItems;
						Pagination.totalRowsSubItems = totalRowsSubItems;
						if(l > 0){
							if(this.NoteIsDailyMultiple(notesid)){
								for(var i = 0; i<l; i++){
									var TransD = moment(this.NoteDates[i].transDate).format("ddd, ll");
									Exist = this.ExistDate(SubItems,TransD);
									obj.ArrTimes = this.getTimesByDate(this.NoteDates, TransD);							
									obj.date = TransD;
									if(!Exist)
										SubItems.push(obj);
									obj = {};							
								}
							}else{
								for(var j = 0; j<l; j++){
							obj = {
								date:'',
								icon:'',
								description:'',
								Id:'',
								isBillable:false,
								defaultUnit:0,
								isVoid:false,
								therapistName:'',
								coTherapistName:'',
								coTherapistCode:null,
								coSignedDate:null,
								frequency:"",
								endTime:null,
								facilityCode:"",
								isCoSigned:null,
								isPendingCoSignature:null,
								isPendingSignature:null,
								isSigned:null,
								noteStatus:"",
								noteTime:null,		
								showReport:false,					
								chartno:"",
								serviceDate:null,
								signedDate:null,
								startTime:null,
								therapistCode:"",
								transCode:"",
								transDate:null,
								notesContents:"",
								disableBillable:false,
								showBilling:false,
								userHasReadOnlyAccess:false,
								enableEdit:true,
								customFrame:""
							};
							obj.therapistName = this.NoteDates[j].therapistName;
							obj.coTherapistName = this.NoteDates[j].coTherapistName;
							obj.date = moment(this.NoteDates[j].transDate).format("ddd, ll");
							obj.Id = this.NoteDates[j].headerID;
							obj.isBillable = this.NoteDates[j].isBillable;
							obj.isVoid = this.NoteDates[j].isVoid;
							obj.coTherapistCode = this.NoteDates[j].coTherapistCode;
							obj.disableBillable = this.NoteDates[j].disableBillable;
							obj.defaultUnit = this.NoteDates[j].defaultUnit;
							obj.showBilling = this.NoteDates[j].showBilling;
							obj.chartno = this.NoteDates[j].chartno;
							obj.frequency = this.NoteDates[j].frequency;
							obj.enableEdit = this.NoteDates[j].enableEdit;
							obj.customFrame = this.NoteDates[j].customFrame;
							obj.showReport = this.NoteDates[j].showReport;
							obj.userHasReadOnlyAccess = this.NoteDates[j].userHasReadOnlyAccess;						
							if(this.NoteDates[j].coSignedDate){
								//obj.coSignedDate = Utils.formatterDateTimeToString(this.NoteDates[j].coSignedDate);
								obj.coSignedDate = moment(this.NoteDates[j].coSignedDate).format("ddd, ll");
							}
							obj.endTime = this.NoteDates[j].endTime;
							obj.facilityCode = this.NoteDates[j].facilityCode;
							obj.isCoSigned = this.NoteDates[j].isCoSigned;
							obj.isPendingCoSignature = this.NoteDates[j].isPendingCoSignature;
							obj.isPendingSignature = this.NoteDates[j].isPendingSignature;
							obj.isSigned = this.NoteDates[j].isSigned;
							obj.noteStatus = this.NoteDates[j].noteStatus;
							obj.noteTime = this.NoteDates[j].noteTime;
							if(this.NoteDates[j].serviceDate){
								obj.serviceDate = Utils.formatterDate(this.NoteDates[j].serviceDate);
							}
							if(this.NoteDates[j].signedDate){
								//obj.signedDate = Utils.formatterDateTimeToString(this.NoteDates[j].signedDate);
								obj.signedDate = moment(this.NoteDates[j].signedDate).format("ddd, ll");
							}
						
							obj.startTime = this.NoteDates[j].startTime;
							obj.therapistCode = this.NoteDates[j].therapistCode;
							obj.transCode = this.NoteDates[j].transCode;
							obj.transDate = this.NoteDates[j].transDate;
							obj.notesContents = this.NoteDates[j].notesContents;
							if(this.NoteDates[j].noteStatus == 'New'){								
								obj.icon = 'icon-doc mr-2';
								obj.description = this.$t('Shared.IconDescN');				
							}
							else if(this.NoteDates[j].noteStatus == 'Voided'){
								obj.icon = 'icon-ban mr-2';
								obj.description = this.$t('Shared.IconDescVN');
							}
							else if(this.NoteDates[j].noteStatus == 'Pending Signature'){
								obj.icon = 'icon-note  mr-2';
								obj.description = this.$t('Shared.IconDescPS');
							}
							else if(this.NoteDates[j].noteStatus == 'Pending CosignerSignature'){
								obj.icon = 'icon-user mr-2';
								obj.description = this.$t('Shared.IconDescPCS');
							}else{
								obj.icon = 'icon-check mr-2';
								obj.description = this.$t('Shared.IconDescF');
							}
							SubItems.push(obj);
							}
						}
                       
						}                      
						
						this.AddSubItemsbyRow(notesid,SubItems,Pagination);

                     }                     
                     // this.$emit('load', false);
					EventBus.$emit('ShowWeel', false);
                })
                .catch((error) => {                    
                    //this.$emit('load', false);
					EventBus.$emit('ShowWeel', false);
                    if (!error.response) {
                      this.showMessage(this.$t('ChartReview.ClinicalGetAllDates'),this.$t('Msg.retrieveInfo'),"error");
                    } else {
                      this.showMessage(this.$t('ChartReview.ClinicalGetAllDates'),error,"error");
                    }
                }); 
     },

    SearchNotes(){
		this.getNoteList();
    },

    pageChanged(page) {            
		this.currentPage = page;
		this.parameter.PageNumber = this.currentPage;        
		this.getNoteList();
    },
	pageChangedSubItems(e) {            
      this.currentPageSubItems = e.page;	
      this.getNoteAllDate(e.NoteId,this.currentPageSubItems,this.perPageSubItems);
    },


    PerPageFunction(perpage){
		this.perPage = perpage;
		this.parameter.PageNumber = 1;
		this.getNoteList();
    },
	PerPageFunctionSubItems(e){
      this.perPageSubItems = e.page;
      //this.getNoteAllDate(this.NotesId);
     this.getNoteAllDate(e.NoteId,1,this.perPageSubItems);
		//this.getNoteAllDate(e.NoteId,this.currentPageSubItems,this.perPageSubItems);
    },
	onRowSelected(items) {
      this.selected = items;		
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },

    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },

    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
	unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

	ChangeDropDownTherapies(value){
            this.TherapiesVModel = value ? value.code : '';
        },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    async getPatientInfo(){
      this.PatientInfo = await this.getData(this.$route.params.id);
    },

		AdjustNoteContainer(Operation){
		var CountNotes = 0;	
		CountNotes = Operation === 'ADD'?this.$refs.ContainerNotes.children.length+1:this.$refs.ContainerNotes.children.length-1;		
			if(CountNotes === 1){
				this.ClassContainerNotes = 'cutescroll';
			}

			if(CountNotes > 1){
				this.ClassContainerNotes = 'notesHolder cutescroll';
			}
	},

	OnResultViewDate(obj){
		this.$emit('load', true);
		this.ExistsNote = this.ClinicalNotesListOpened.some(nItem => ( ((nItem.notesId === obj.notesId) && (nItem.Id === obj.Id)) ||
							(((nItem.frequency != 'DAILY MULTIPLES') && (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate)) ||
							((nItem.frequency === 'DAILY MULTIPLES') && (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate) && 
							(nItem.noteTime === obj.noteTime)))));																	  

																		
		if (!this.ExistsNote) {
			this.AdjustNoteContainer("ADD");
			this.ClinicalNotesListOpened.push(obj);
		}
		this.ClassAccordion = 'show';
		this.ShowNote = true;
		this.ShowFilter = false;
		setTimeout(() => this.$emit('load', false), 500);
	},

	onResultados(obj) {

		this.$emit('load', true);
		this.ExistsNote = this.ClinicalNotesListOpened.some(nItem => ( ((nItem.notesId === obj.notesId) && (nItem.Id === obj.Id)) ||
																		(((nItem.frequency != 'DAILY MULTIPLES') && (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate)) ||
																		((nItem.frequency === 'DAILY MULTIPLES') && (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate) && (nItem.noteTime === obj.noteTime)))));																	  

																		
		if (!this.ExistsNote) {
			this.AdjustNoteContainer("ADD");
			this.ClinicalNotesListOpened.push(obj);
		}
		
		this.ClassAccordion = 'show';
		this.ShowNote = true;
		this.ShowFilter = false;	
		setTimeout(() => this.$emit('load', false), 500);
		
	},

	CloseNote(index){
		this.ClinicalNotesListOpened.splice(index, 1)
		this.AdjustNoteContainer("REMOVE");

		if(this.ClinicalNotesListOpened.length == 0){
			this.ShowFilter = true;
			this.ShowNote = false;
		}
	},

	Accordion1Click(){	
		/*this.ShowFilter = true;
		this.ShowNote = false;*/
		this.ShowFilter = !this.ShowFilter;
		this.ShowNote = !this.ShowNote;	
		
		//this.ClassAccordion2 = '';
		//this.ClassAccordion1 = this.ClassAccordion1 === 'show' ? '' :'show';
	},

	ClickBtnAccordion2(){			
		/*this.ShowFilter = false;
		this.ShowNote = true;*/
		this.ShowFilter = !this.ShowFilter;
		this.ShowNote = !this.ShowNote;	
			
		//this.ClassAccordion1 = '';
		//this.ClassAccordion2 = this.ClassAccordion2 === 'show' ? '' : 'show';
	},

	onCheck(param){	
		this.NotesId = param.notesId;
		this.currentPageSubItems = param.currentPage;
		this.perPageSubItems = 50;
		this.getNoteAllDate(this.NotesId,this.currentPageSubItems,this.perPageSubItems);
	},

  getNoteTypes(){
		GlobalService.getNoteTypes(false)
            .then((response) => {
              if(response.data && response.data.length > 0){
                this.OptionNoteTypes = response.data;				
              }             
            })
            .catch((error) => {
              if (error.response) {
               this.showMessage(this.$t('ChartReview.GetNoteTypes'),error,"error");
              }
            });
	},
	getDefaultDateFromAndDateTo(){		
		if(this.Origin === 'Clinical'){
			ChartReview.getPatientRegistrationsDefaultDates(this.$route.params.recordid)
            .then((response) => {
              if(response.data){
                let defaultDate = response.data;			
				this.DateFrom = moment(defaultDate.dateFrom).format('YYYY-MM-DD');
				this.DateTo = moment(defaultDate.dateTo).format('YYYY-MM-DD');				
              }             
            })
            .catch((error) => {
              if (error.response) {
               this.showMessage(this.$t('ChartReview.GetDefaultDate'),error,"error");
              }
            });
		}
		if(this.Origin === 'Patient'){
			GlobalService.getDateTime()
				.then((response) => {
					if(response.status == '200' && response.data){
						this.DateTo = moment(response.data).format('YYYY-MM-DD');	
						this.DateFrom = moment(response.data).subtract(30, "days").format('YYYY-MM-DD');
					}
                })
                .catch((error) => {
					if(error.response) {
                        this.showMessage(this.$t('ChartReview.GetDate'),error,"error");
                    }
                });
		}
		
	},

	getAllEmployee(){
         EmployeeService.getAllEmployees()
            .then((response) => { 
               if(response.data && response.data.length > 0){
					this.TherapistList = response.data;
					this.CotherapistList = response.data;
               }             
            })
            .catch((error) => {
               if (error.response) {
                  this.showMessage(this.$tc('Shared.Employee'),error,"error");
               }
            });
      },

	async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);     
      if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          this.PatientTitle.ageFormatted = infoList[0].ageFormatted;
          if(infoList[0].dob){
              this.PatientTitle.dob = moment(infoList[0].dob).format('MM/DD/YYYY');
          }         
          this.PatientTitle.fullName = infoList[0].firstname + ' '+ infoList[0].lastname;
		this.PatientTitle.age = infoList[0].age;
      }
    },

	PopulatePatientTitle(){
        if(this.RegistrationInfo.dateFrom)
            this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
        if(this.RegistrationInfo.dateTo)
            this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');	
      if(this.$route.name.includes('Clinical')){ 
          this.PatientTitle.programName = this.RegistrationInfo.programName;
          this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
          this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
          this.PatientTitle.fullName = this.RegistrationInfo.patientName;
          this.PatientTitle.dob = moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');
          this.PatientTitle.age = this.RegistrationInfo.age;
          this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }
      if(this.$route.name.includes('patient')){         
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';
	
             
          //this.PatientTitle.fullName = this.patient.firstname + ' '+ this.patient.lastname;
      }
    },

	async getProgramCodeByRecordId(){
		this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);	
		if(this.RegistrationInfo.dateFrom)
			this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
		if(this.RegistrationInfo.dateTo)
			this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');	
		if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;
		this.ProgramCode = this.RegistrationInfo.programCode;	
	},
  },

  updated(){
	//this.getDefaultDateFromAndDateTo();
  },

 async mounted(){  
    this.parameter.PageNumber = this.currentPage;        
    this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems;
    this.OptionsRightMenu.Mode = true;
    this.OptionsRightMenu.visible = true; 
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    this.$emit('getBtnRightMenu', this.OptionsRightMenu); 
	

	if(this.$route.name.includes('patient')){  
        this.GetPatientByCode();
     } 

    if(this.$route.name.includes('Clinical')){   
		this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
		this.IsClinical = this.RegistrationInfo.dateTo?false:true; 
       /* this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
         this.IsClinical = this.RegistrationInfo.dateTo?false:true;
         if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;*/
    }

	this.PopulatePatientTitle();


	this.getDefaultDateFromAndDateTo();
	this.getAllEmployee();
    this.getPatientInfo();
    this.getNoteTypes();

	if(this.Origin === 'Patient'){
		this.getPrograms();
	}
	

  }
   
};
</script>


