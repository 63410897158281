<template>
<div>
  <body class="sidebar-fixed">    
      <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div class="content-wrapper d-flex align-items-center auth px-0">
          <div class="row w-100 mx-0">
            <div class="col-lg-4 mx-auto">
              <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                <div class="brand-logo">
                  <img src="../../../assets/images/logo.svg" alt="logo">
                </div>
                <div id="ContainerLogin" >
                  <div class="form-group">
                    <p class="text-muted">{{$t('Unauthorized.Dear')}} <span class="URLName">{{this.$route.query.u}}</span></p>
                    <hr>
                    <p class="text-muted">{{$t('Unauthorized.pInfo')}}</p>
                    <hr>
                  </div>
                  <div class="mt-3">
                    <MdButton 
                       v-bind:Label="$t('Unauthorized.sAgain')"
                       v-bind:Name="'btnSignIn'"
                       v-bind:ClassProp="'btn btn-block btn-primary btn-login btn-lg font-weight-medium auth-form-btn text-truncate'"
                       v-on:click="gotologin">
                    </MdButton>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>     
      </div> 
  </body>
</div>
</template>

<script>
export default {
  name: 'Unauthorized',

  methods:{

    gotologin(){
       this.$router.push('/login').catch(()=>{});
    }

  }
   
};
</script>
<style>
.URLName{
  font-weight: bold;
  color:  #19BEE8;
  -webkit-font-smoothing: antialiased;
}
</style>
