import ApiService from '@/services/api.service'


const OutComeStudyTitlesService = {
    getAll( parameter) { 
      const requestData = {
        method: 'get',
        url: "/MdOutComeStudyTitles/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          TestCode : parameter.TestCode
        }
      }     
      return ApiService.customRequest(requestData);         
    },
    
  }
  
  export default OutComeStudyTitlesService;