<template>
    <div class="row page-height">
		<div class="col-md-12">
			<h4 class="page-title text-truncate"> {{ $t('AppointmentDashboard.ApDashTitle') }} -
				<span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName+' '}}</span> -
				<span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
				<span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
				<span v-if="this.PatientTitle.ageFormatted"> {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
			</h4>
			<div class="row">
				<MdPatientInfoBox
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientInfoBox>

				<MdCurrentAppointment v-if="this.currentAppointmentDB.guid !== ''"
					v-bind:ActivityId="this.$route.params.actId"
					v-bind:currentAppointmentObj="this.currentAppointmentDB"
					v-bind:ExecuteCall="false">
				</MdCurrentAppointment>

				<MdHistoricalAppointment
					v-bind:PatientCode="this.$route.params.id">
				</MdHistoricalAppointment>

				<!--<MdNotesByAppt v-if="this.currentAppointmentDB.activitytypecode !== ''"
					v-bind:ApptType="this.currentAppointmentDB.activitytypecode">
				</MdNotesByAppt>-->
			</div>
		</div>

		<ModalAppointmentReason 
			v-bind:TitleWindow="$t('AppointmentDashboard.ReasonForNoShow')"  
			v-bind:ModalId="this.modalReasonId"
			v-bind:ClickedText="this.ClickedText" 
		/>     
		
	</div>
   
</template>

<script>
import EnumService from '@/services/enum.service'
//import SchedulerService from '@/services/schedulers.service'
import Utils from '@/common/utils'
import DBFunctions from '@/common/DBFunctions.js'
import moment from 'moment'
import { EventBus } from '@/event-bus.js';
export default {
	
  name: 'AppointmentDashboard',
   mixins: [DBFunctions],
  data() {
      return {		
		Loading:false,
		modalReasonId:"modalIdAppointmentReason",
		ClickedText:"",
		OptionsRightMenu:{
			visible:true,
			ItemsList:[],
			btnvisible:true,
			itemdisabled: false,		
		},		
		currentAppointmentDB:{
			guid:'',
			activitytypecode:'',
        employee:{
            firstname:"",
            lastname:""
        }
     },
		Appointments:[],
		PatientTitle:{      
			programName:'',
			fullName:'',
			admitDate:'',
			dischargeDate:'',
			dob:''
		}, 
		}
	},
	methods:{
		async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);
      if(infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
          this.PatientTitle.age = infoList[0].age;
          this.PatientTitle.ageFormatted = infoList[0].ageFormatted;
          if(infoList[0].dob){
              this.PatientTitle.dob = moment(infoList[0].dob).format('MM/DD/YYYY');
          }   
          this.PatientTitle.fullName = infoList[0].firstname + ' '+ infoList[0].lastname;
      }
    },

	/*getAppoinments() { 
		var param = {
			PatientCode:this.$route.params.id,
			ActivityId:this.$route.params.actId,
			PageSize:0,
            PageNumber:0
		};
        SchedulerService.getAppointmentsByPatient(param)
              .then((response) => {
                if(response.status == '200' && response.data && response.data.length > 0){
                  this.Appointments = response.data;
                }
              })
              .catch(error => {
                if (!error.response) {
                // network error
                  this.showMessage("Daily Scheduler",this.$t('Msg.RetrieveInformation'),"error");
                } else {
                  this.showMessage("Daily Scheduler",error,"error");
                }
              });
            },*/


	},


	async created(){
		this.currentAppointmentDB = await this.geCurrentAppointment(this.$route.params.actId);	
      if(this.currentAppointmentDB.transdate)	
        this.currentAppointmentDB.transdate = Utils.formatterDateToString(this.currentAppointmentDB.transdate);		

		EventBus.$on("onAppointmentReasonModal", function (reason) { 
			this.ClickedText  = reason;     
			this.$bvModal.show(this.modalReasonId);    
		}.bind(this));
	},

	async mounted(){
		this.GetPatientByCode();
		this.OptionsRightMenu.visible = true;    
		this.OptionsRightMenu.btnvisible = true;
		this.OptionsRightMenu.itemdisabled = false;
		this.OptionsRightMenu.ItemsList = EnumService.AppointmentItems
		this.$emit('getVisibleMenu', this.OptionsRightMenu); 

		//this.getAppoinments();
	
	},
    destroyed(){
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
};
</script>


