<template>
<div ref="designer" style="position: absolute; left: 0; right: 0; top: 0; bottom: 0" data-bind="dxReportDesigner: $data"></div>
</template>

<script>
import ko from "knockout";
import 'devexpress-reporting/dx-reportdesigner';
import ApiService from '@/services/api.service'
import StorageService from '@/services/storage.service'
import DBFunctions from '@/common/DBFunctions.js'
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils"
import ShowMessage from '@/components/messages/ShowMessage.js'

export default {
      beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
name: "ReportBuilder",
mixins: [ ShowMessage,DBFunctions ],
data(){
    return {
        Menu : this.$store.state.leftMenu,
        permission: [],
        OptionsRightMenu: {
            visible: false,
            ItemsList: [],
            btnvisible: false,
            itemdisabled: false,
            PatientCode: "",
            Mode: this.edit_mode,
            favButtonAtion: false
        },
    }
},

methods:{
    CanView(){
      return this.permission[0].roleCanView;
    },
},

created(){
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission); 
},

mounted() {    
      this.OptionsRightMenu.ItemsList = [];
        let layoutStatus = this.$store.state.layoutStatus;
        switch (layoutStatus) {
            case "L0R1":
                this.OptionsRightMenu.favButtonAtion = false;
                this.$emit("getVisibleMenu", this.OptionsRightMenu);
                break;
            case "L1R0":
            case "L1R1":
                this.OptionsRightMenu.btnvisible = false;
                this.$emit("getVisibleMenu", this.OptionsRightMenu);
                break;
            default:
                this.OptionsRightMenu.favButtonAtion = false;
                this.$emit("getVisibleMenu", this.OptionsRightMenu);
        }
     if(this.permission.length == 0){
             this.$router.push({name: "Main"});    
              setTimeout(() => 
                this.showPermissionMessage()
              , 50); 
              this.$emit('load', false); 
    }else{
        ajaxSetup.ajaxSettings = { headers: { 'Authorization': `Bearer ${StorageService.getOnlyToken()}` } };
        var designerOptions = {
        //reportUrl: ko.observable("Blank"),
        requestOptions: {
            host: ApiService.APIURL,
            getDesignerModelAction: "ReportBuilder/GetReportDesignerModel",
            //invokeAction: "DXXRD"
        }
    };
    ko.applyBindings(designerOptions, this.$refs.designer);
    }
},
beforeDestroy() {
    ko.cleanNode(this.$refs.designer);
}
};
</script>
