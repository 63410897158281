import ApiService from '@/services/api.service'


const IncidentReportOptionsService = {
    getAll() { 
         
      const requestData = {
        method: 'get',
        url: "/IncidentReportOptions/",
        headers: {
        },
      }     
      return ApiService.customRequest(requestData);         
    },
  }
  
  export default IncidentReportOptionsService;