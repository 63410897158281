import ApiService from '@/services/api.service'

const ClinicalService = {
  getAll(parameter) { 
    var param = {};
    var _Url = '/PatientRegistrations/Table';
    param.SearchCriteria = parameter.SearchCriteria,
    param.PageSize = parameter.PageSize,
    param.PageNumber = parameter.PageNumber
    param.Status = parameter.Status
    param.IsInpatient = parameter.IsInPatient
    if(parameter.Programs !== ''){
      _Url +="?"+parameter.Programs;
    }
    if(parameter.employees !== ''){
      if(_Url.includes("?")){
        _Url +="&"+parameter.employees;
      }else{
        _Url +="?"+parameter.employees;
      }      
    }
  const requestData = {
    method: 'get',
    url: _Url,
    params: param
  }
  return ApiService.customRequest(requestData);
},

getAllByPatientCode(parameter) { 
  var param = {};
  var _Url = '/PatientRegistrations/Table';
  param.SearchCriteria = parameter.SearchCriteria,
  param.PageSize = parameter.PageSize,
  param.PageNumber = parameter.PageNumber,
  param.PatientCode = parameter.PatientCode,
  param.IsInpatient = parameter.IsInpatient
const requestData = {
  method: 'get',
  url: _Url,
  params: param
}
return ApiService.customRequest(requestData);
},

getAllAdmissionByDay(parameter) { 
  var _Url = '/PatientRegistrations/Table';  
const requestData = {
  method: 'get',
  url: _Url,
  params: parameter
}
return ApiService.customRequest(requestData);
},

getNoteList(parameter) {
  var param = {};
  var _Url = '/NotesLists/';
  param.PageSize = parameter.PageSize;
  param.PageNumber = parameter.PageNumber;
  param.PatientCode = parameter.PatientCode;
  param.DateFrom = parameter.DateFrom;
  param.DateTo = parameter.DateTo;
  param.TransCode = parameter.TransCode;
  param.ProgramCode = parameter.ProgramCode;
  param.UserId = parameter.UserId;
  param.NotesIdList = parameter.NoteTypes;
 
  if(parameter.NoteStatus !== ""){
    param.NoteStatus = parameter.NoteStatus
  }
const requestData = {
  method: 'get',
  url: _Url,
  params: param
}
return ApiService.customRequest(requestData);
},

getAllDate(parameter) {
  var param = {};
  var _Url = '/NotesListDates/';
  param.PageSize = parameter.PageSize,
  param.PageNumber = parameter.PageNumber,
  param.PatientCode = parameter.PatientCode
  param.DateFrom = parameter.DateFrom
  param.DateTo = parameter.DateTo
  param.TherapyCode = parameter.TransCode
  param.AdmissionRecordID = parameter.RecordId
  param.NotesID = parameter.NotesId
  param.ProviderCode = parameter.ProviderCode
  param.NoteStatus = parameter.NoteStatus
const requestData = {
  method: 'get',
  url: _Url,
  params: param
}
return ApiService.customRequest(requestData);
},

getSummarieRegistrationInfo(parameter) { 
  var param = {};
  var _Url = '/PatientRegistrations/';
  param.RecordId = parameter
const requestData = {
  method: 'get',
  url: _Url,
  params: param
}
return ApiService.customRequest(requestData);
},


getSummarieRegistrationInfoTitle(parameter) { 
  var param = {};
  var _Url = '/PatientRegistrations/Table';
  param.RecordId = parameter
const requestData = {
  method: 'get',
  url: _Url,
  params: param
}
return ApiService.customRequest(requestData);
},
    getPrograms() {
      return ApiService.get(`/Programs/`);
    },

    getTeamsMembers() {
        return ApiService.get(`/AdmissionTeamEmployees/`);
      },


  

    getControls(parameter) {
      var param = {};
      var _Url = '/NotesFormControls/';
      param.FormId = parameter.NoteId;
      param.PatientCode = parameter.PatientCode;   
      param.RecordId = parameter.RecordId
      param.TransDate = parameter.TransDate   
    const requestData = {
      method: 'get',
      url: _Url,
      params: param
    }
    return ApiService.customRequest(requestData);
    },

    getControlsAndvalues(parameter) {
      var param = {};
      var _Url = '/NotesFormControls/';
      param.FormID = parameter.NoteId;
      param.PatientCode = parameter.PatientCode;   
      param.RecordId = parameter.RecordId;
      param.ProgramCode = parameter.ProgramCode;
      param.TransDate = parameter.TransDate; 
      param.HeaderId = parameter.HeaderId;
      param.TransCode = parameter.TransCode;
      param.IsNew = parameter.IsNew;
      param.IsGroupNote = parameter.IsGroupNote;
      param.IncidentId = parameter.IncidentId;
      param.IsCommonPart = parameter.IsCommonPart;
      param.GroupNodeHeaderId = parameter.GroupNodeHeaderId;
      param.ActivityId = parameter.ActivityId;
      param.Ver = parameter.Ver;
    const requestData = {
      method: 'get',
      url: _Url,
      params: param
    }
    return ApiService.customRequest(requestData);
    },

    getDataSaved(parameter) {
      var param = {};
      var _Url = '/NotesFormControls/Values/';
      param.BioCode = parameter.BioCode;
      param.PatientCode = parameter.PatientCode;
      param.HeaderID = parameter.HeaderID;
      param.TransDate = parameter.TransDate;
      param.PageNumber = 1;
      param.PageSize = 9999;
    const requestData = {
      method: 'get',
      url: _Url,
      params: param
    }
    return ApiService.customRequest(requestData);
    },
  
    getNotesHeader(parameter) {  
      return ApiService.get(`/NotesHeaders/${parameter.Headerid}:${parameter.Notesid}`); 
    },

    getNotesHeaderListing(parameter) {  
      
      return ApiService.get(`/NotesHeaders?Headerid=${parameter.Headerid}&Notesid=${parameter.Notesid}&IgnoreVoidStatus=${parameter.IgnoreVoidStatus}&IsVoid=${parameter.IsVoid}&IsGroupNote=${parameter.IsGroupNote}&GroupCreationId=${parameter.GroupCreationId??""}&Ver=${parameter.Ver}`); 
    },

    SaveNote(data){     
        return ApiService.post(`/ClinicalNotes`,data);
    },

    UpdateNote(HeaderId,NoteId,data){
      return ApiService.put(`/ClinicalNotes/${HeaderId}:${NoteId}`,data);
    },

    NotesTherapiesByNote(HeaderId, GroupCreationId, IsGroupNote) {     
      return ApiService.get(`/NotesTherapies/ByNote?HeaderId=${HeaderId}&GroupCreationId=${GroupCreationId}&IsGroupNote=${IsGroupNote}`);  
    },

    getTherapiesHeaderBillingInfoFromGroupNotes(ProgramCode){
      return ApiService.get(`/TherapyByPrograms/GroupNotes?ProgramCode=${ProgramCode}`);  
    },

    getTherapiesHeaderBillingInfo(ProgramCode,CategoryCode){
      return ApiService.get(`/TherapyByPrograms?ProgramCode=${ProgramCode}&CategoryCode=${CategoryCode??""}`);
    },

    getTherapiesHeaderBillingInfoFromScheduler(TransCode,ProgramCode){
      return ApiService.get(`/TherapyByPrograms/SchedulerNotes?TransCode=${TransCode}&ProgramCode=${ProgramCode}`);  
    },

    getPatientRegistrationsDefaultDates(RecordId){
      return ApiService.get(`/PatientRegistrations/DefaultDates?RecordId=${RecordId}`);  
    },

    getNotesHeaderVers(HeaderId,NotesId){
      return ApiService.get(`/NotesHeaderVers/Versions?HeaderId=${HeaderId}&NoteId=${NotesId}`);  
    },

    getEnableEdit(HeaderId,NotesId,IsGroupNote){
      return ApiService.get(`/NotesHeaders/EnableEdit/${HeaderId}:${NotesId}:${IsGroupNote}`);  
    },

    getShowNote(HeaderId,NotesId,IsGroupNote){
      return ApiService.get(`/NotesHeaders/ShowNote/${HeaderId}:${NotesId}:${IsGroupNote}`);  
    },

    getLastTenValues(parameter) {
      var param = {};
      var _Url = '/NotesFormControls/LastTenValues/';
      param.ControlId = parameter.ControlId;
      param.PatientCode = parameter.PatientCode;
      param.TransDate = parameter.TransDate;
      param.PageNumber = 1;
      param.PageSize = 9999;
    const requestData = {
      method: 'get',
      url: _Url,
      params: param
    }
    return ApiService.customRequest(requestData);
    },

    getTherapies(){
      return ApiService.get(`/Therapies`);  
    },
    
    getClinicalNoteInfo(parameter) {
      var param = {};
      var _Url = '/NotesListDates/GetClinicalNoteInfo';
      param.NotesID = parameter.NotesID;
      param.HeaderId = parameter.HeaderId;   
      param.PatientCode = parameter.PatientCode.trim();
      param.TherapyCode = parameter.TherapyCode; 
      param.TransDate = parameter.TransDate;
      param.AdmissionRecordID = parameter.AdmissionRecordID;
      param.IsVoid = parameter.IsVoid;
      const requestData = {
        method: 'get',
        url: _Url,
        params: param
      }
      return ApiService.customRequest(requestData);
    },
    
  }
  
  export default ClinicalService;