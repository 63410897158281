import ApiService from '@/services/api.service'

const RoomsService = {
    getAll(parameter){
        const requestData = {
            method: 'get',
            url: "/rooms/",       
            params: {
              SearchCriteria: parameter.SearchCriteria,
              PageSize: parameter.PageSize,
              PageNumber: parameter.PageNumber,  
            }
          }       
          return ApiService.customRequest(requestData);
    }, 

    getAllRooms(){     
      return ApiService.get(`/Rooms/Summary`);
    },

    Add(data){
        return ApiService.post(`/rooms`, data);
    },
    Delete(Id){      
        return ApiService.delete(`/rooms/${Id}`);
    },
    Update(Id,data){
        return ApiService.put(`/rooms/${Id}`,data);
    },   
    Get(Id){     
        return ApiService.get(`/rooms/${Id}`);
    },
    getRoomsByCode(parameter){
      const requestData = {
        method: 'get',
        url: "/rooms/",
        headers: {
        },
        params: {          
          code: parameter.code           
        }
      }     
      return ApiService.customRequest(requestData);       
    },
  }


  
  export default RoomsService;