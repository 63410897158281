<template>
<div id="ContainerBuilding"> 
      <h4 v-if="this.$attrs.editMode" class="page-title text-truncate">
       {{ $t('building.Edit')}}
      </h4>
      <h4 v-if="!this.$attrs.editMode" class="page-title text-truncate">
        {{$t('building.Add') }}      
      </h4> 
        <b-row>
            <b-col lg="3" md="3" sm="12">
                 <MdEdit
                     v-model="Building.code"                    
                     v-bind:Label="$t('Shared.Code')"          
                     v-bind:isReadOnly="this.$attrs.editMode"
                     v-bind:isRequired="true"
                     v-bind:MaxLengthProp="2"
                     v-bind:isValid="isCodeValid"
                     v-on:blur="OnCodeBlur(Building.code)"
                     :InvalidFeedback="$t('building.BuildCodeRequired')"
                     :SearchFieldCode="'code'"
                     v-bind:Id="'input_b_code'"
                  />
            </b-col>
            <b-col lg="3" md="3" sm="12">
                <MdEdit 
                  v-bind:Label="$t('Shared.Name')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'Name'"  
                  v-bind:MaxLengthProp="32"  
                  v-model="Building.name"
                  v-bind:isReadOnly="isReadOnlyBuilding"
                  v-bind:Id="'input_b_name'">                                     
               </MdEdit>
            </b-col>
             <b-col lg="3" md="3" sm="12">
                 <MdEdit 
                  v-bind:Label="$t('Shared.Address1')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'description1'"  
                  v-bind:MaxLengthProp="32"  
                  v-model="Building.address1"
                  v-bind:isReadOnly="isReadOnlyBuilding"
                  v-bind:Id="'input_b_addrs1'"
                  >                                     
               </MdEdit>
            </b-col>

            <b-col lg="3" md="3" sm="12">
              <!--<MdCheckBox
                  v-bind:Label="'VIP'"
                  v-bind:Name="'isPrivate'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="Building.isprivate"                 
               />-->
                <MdEdit 
                  v-bind:Label="$t('Shared.Address2')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'description2'"  
                  v-bind:MaxLengthProp="32"  
                  v-model="Building.address2"
                  v-bind:isReadOnly="isReadOnlyBuilding"
                  v-bind:Id="'input_b_addrs2'"
                  >  
                  </MdEdit>       
            </b-col>  
        </b-row>

        <b-row>
            <b-col lg="3" md="3" sm="12">
                 <MdEdit 
                  v-bind:Label="$t('Shared.Zip')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'Zip'"  
                  v-bind:MaxLengthProp="32"  
                  v-model="Building.zipcode"
                  v-bind:isReadOnly="isReadOnlyBuilding"
                  v-bind:Id="'input_b_zip'"
                  />
            </b-col>
            <b-col lg="3" md="3" sm="12">
                 <MdEdit 
                  v-bind:Label="$t('Shared.City')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'City'"  
                  v-bind:MaxLengthProp="32"  
                  v-model="Building.city"
                  v-bind:isReadOnly="isReadOnlyBuilding"
                  v-bind:Id="'input_b_city'"
                  />
            </b-col>
            <b-col lg="3" md="3" sm="12">
                 <MdEdit 
                  v-bind:Label="$t('Shared.State')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'State'"  
                  v-bind:MaxLengthProp="32"  
                  v-model="Building.state"
                  v-bind:isReadOnly="isReadOnlyBuilding"
                  v-bind:Id="'input_b_state'"
                  />
            </b-col>
                <b-col lg="3" md="3" sm="12">
                  <MdCheckBox
                    v-bind:Label="$t('Shared.Inactive')"
                    v-bind:Name="'Inactive'"
                    v-bind:ClassProp="'AlignCheck'"
                    v-bind:CheckedValue="true"
                    v-bind:UncheckedValue="false"
                   v-model="Building.inactive"                 
                  />                    
            </b-col> 
        </b-row>
      


    <div class="page-actions">      
     <MdButton
               v-bind:Label="$t('building.List')"
               v-bind:Name="'btnbuildinglist'"
               v-bind:ClassProp="'secondary mx-2 btn-login'"
               v-on:click="DiscardChanges"/>    
        <MdButton
            v-bind:Label="$t('Shared.Save')"
            v-bind:ClassProp="(this.isReadOnlyBuilding)?'primary mx-2':'primary btn-login mx-2'"
            v-on:click="SaveOrEdit"
            v-bind:isReadOnly="isReadOnlyBuilding"
            v-bind:Name="'btn_save_building'"/>
    </div>

</div>
</template>

<script>


import DBFunctions from '@/common/DBFunctions.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import BuildingService from '@/services/building.service'
import { EventBus } from '../../../../event-bus.js';
//import Utils from '@/common/utils'

let elemSaveBtn = '';
export default {
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  name: 'BUILDING',
 mixins: [ShowMessage, DBFunctions/*,EventBus*/],
  data(){
    return{
        parameter:{
            SearchCriteria:'',
            PageNumber : 1,
            PageSize:0,         
        },
        pendingChanges:false,
     Building:{
        code:'',
        name:'',
        address1:'',
        address2:'',
        city:'',
        state:'',
        zipcode:'',
        inactive:false
     },
        isReadOnlyBuilding:false,
        dBuilding:'',
        permission:[],
        Menu : this.$store.state.leftMenu,
        isbuildingValid:true,   
        isCodeValid:true,   
        CodeExist:false,      
        OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },

    }
  },

  methods:{
    DiscardChanges(){
       this.checkExistFavorite('/app/buildings');
       this.$router.push({name: "buildingsList"});
    },
    CanView(){
      return this.permission[0].roleCanView;
  },

  onCodeConditionOk(){

  },

    getBuilding(){
      if(this.$attrs.editMode){
      this.$emit('load', true);     
      BuildingService.Get(this.$route.params.id)
        .then((response) => {
            if(response.status == '200' && response.data){
              this.Building = response.data;  
            }   
              this.$emit('load', false);                     
        })
        .catch((error) => {                    
            if (!error.response) {
              this.showMessage(this.$t('Shared.Building'),this.$t('Msg.retrieveInfo'),"error");
            }                      
            else if(error.response.status === 400 ){
              this.showMessage(this.$t('Shared.Building'),error.response.data.errorMessage,"error");
            }
            else if(error.response.status === 405 ){             
                  this.$router.push({name: "Main"});    
                    setTimeout(() => 
                        this.showPermissionMessage()
                        , 50);            
            } 
            else if(error.response.status === 500){
              this.showMessage(this.$t('Shared.Building'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
              this.showMessage(this.$t('Shared.Building'),error.response.data.errorMessage,"error");
            } 
            this.$emit('load', false);            
        }); 
    
      }
    },

    AddBuilding(){
      if(!this.CodeExist){
      this.$emit('load', true);
      BuildingService.Add(this.Building)
                .then((response) => {
                    if(response.status == '200'){
                        this.Building = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);
                        /*if(!this.OffRoute){
                           this.GotoAdmissionList();
                        }*/
                        this.$router.push({name: "buildingsList"}).catch(()=>{});
                      
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                   this.showMessage(this.$t('Shared.Building'),this.$t('Msg.retrieveInfo'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('Shared.Building'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('Shared.Building'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Shared.Building'),error.response.data.errorMessage,"error");
                     }
                     elemSaveBtn.removeAttribute("disabled");  
                    this.$emit('load', false);
                }); 
      }else{
         this.showMessage(this.$t('Shared.Building'),this.$t('building.BuildCodeExist'),"error");
      }
    },


    EditBuilding(){
      this.$emit('load', true);
      BuildingService.Update(this.$route.params.id,this.Building)
                .then((response) => {
                    if(response.status == '200'){
                        this.Building = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);                        
                        this.$router.push({name: "buildingsList"}).catch(()=>{});                      
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                   this.showMessage(this.$t('Shared.Building'),this.$t('Msg.retrieveInfo'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('Shared.Building'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('Shared.Building'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Shared.Building'),error.response.data.errorMessage,"error");
                     }
                     elemSaveBtn.removeAttribute("disabled");  
                    this.$emit('load', false);
                }); 
    },

     DoAction(){       
        !this.$attrs.editMode ? this.AddBuilding() : this.EditBuilding(); 
      },

      SaveOrEdit(){ 
         if (this.validate_required()) {
            this.DoAction();
         } else {
            this.showMessage(this.$t('Shared.Building'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");          
         }
      },

    validate_required() {       
        this.isCodeValid = this.Building.code.length > 0 ? true : false;  
        return this.isCodeValid;
      },

      OnCodeBlur(value){           
          if(!this.$attrs.editMode){           
            if (value.trim()!== '') {          
            var parameter = {
               "code":value
            };
            BuildingService.getBuildingsByCode(parameter)
                .then((response) => {
                    if(response.status == '200' && response.data){
                      // this.$emit('load', false);  
                       if(response.data.length > 0){
                          this.showMessage(this.$t('Shared.Building'),this.$t('building.BuildCodeExist'),"error");
                          this.CodeExist = true;
                       }else{
                           this.CodeExist = false;
                       } 
                    }  
                     this.$emit('load', false);                          
                })
                .catch((error) => {
                    this.$emit('load', false);                  
                  if(error.response.status !== 404 && error.response.status !== 500){
                      if(error.response) {
                        this.showMessage(this.$t('Shared.Building'),error,"error");
                    }
                  }
                    
                });  
            }
               }
    
       },
       checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
      }
  },

  created(){
    EventBus.$on("onChanged", function () {       
          this.checkUserAction();
     }.bind(this));
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
  },

  mounted(){
      this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
      this.getBuilding();
      setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_building');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );        
  },

    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
   
};
</script>


