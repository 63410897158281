<template>
<div>
    <v-multiselect-listbox :options=this.Available
                       :reduce-display-property="(option) => option[this.description]"
                       :reduce-value-property="(option) => option[this.code]"
                       v-model=Selected
                       @change="changeSelected"
                       :search-options-placeholder=this.searchAvailablePlaceholder
                       :selected-options-placeholder=this.searchSelectedPlaceholder
                       :no-options-text=this.noAvailableSelectedText
                       :selected-no-options-text=this.noSelectedText
                       :no-options-found-text=this.noAvailableFoundText
                       :no-selected-options-found-text=this.noSelectedFoundText
                       >
	</v-multiselect-listbox>
  </div>
</template>

<script>
import vMultiselectListbox from 'vue-multiselect-listbox'
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css';
  export default {
	components: {
        vMultiselectListbox
  },
    props: {
      available: Array,
      selected: Array,
      description: String,
      code: String,
      searchAvailablePlaceholder: String,
      searchSelectedPlaceholder: String,
      noAvailableSelectedText: String,
      noSelectedText: String,
      noAvailableFoundText: String,
      noSelectedFoundText: String
   },
    data() {
      return {
        Available: this.available,
        Selected: this.selected
      }
    },

  methods:{
    changeSelected(value){
      this.$emit('selected', value);
    }
  },
}

</script>