<template>
<div id="ContainerHcpcsCPT">
      <h4 v-if="this.$attrs.editMode" class="page-title text-truncate">
        {{$t('modifier.Edit')}}
      </h4>
      <h4 v-if="!this.$attrs.editMode" class="page-title text-truncate">
        {{$t('modifier.Add')}}           
      </h4>
        <b-row>
            <b-col lg="3" md="3" sm="12">
                <MdEdit
                     v-model="Modifier.code"                    
                     v-bind:Label="$t('Shared.Code')"          
                     v-bind:isReadOnly="this.$attrs.editMode"
                     v-bind:isRequired="true"
                     v-bind:MaxLengthProp="2"
                     v-on:blur="OnCodeBlur(Modifier.code)"
                     v-bind:isValid="isModifierValid"
                     :InvalidFeedback="$t('modifier.ModiRequired')"
                     :SearchFieldCode="'Code'"
                     v-bind:Id="'input_code'"
                  />
            </b-col>  
            <b-col lg="3" md="3" sm="12">
                <MdEdit
                     v-model="Modifier.description"   
                      v-bind:MaxLengthProp="50"                 
                     v-bind:Label="$t('Shared.Description')"  
                  />
            </b-col> 
            <b-col lg="3" md="3" sm="12">
              <MdCheckBox
                  v-bind:Label="$t('modifier.IsInternal')"
                  v-bind:Name="'isInternalModifier'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-model="Modifier.isInternal"                 
               />
            </b-col>             
        </b-row>
       

    <div class="page-actions">      
     <MdButton
               v-bind:Label="$t('modifier.ModiList')"
               v-bind:Name="'btnmodifierlist'"
               v-bind:ClassProp="'secondary mx-2 btn-login'"
               v-on:click="DiscardChanges"/>    
        <MdButton
            v-bind:Label="$t('Shared.Save')"
            v-bind:ClassProp="(this.isReadOnlyModifier)?'primary mx-2':'primary btn-login mx-2'"
            v-on:click="SaveOrEdit"
            v-bind:isReadOnly="isReadOnlyModifier"
            v-bind:Name="'btn_save_modifier'"/>
    </div>

</div>
</template>

<script>


import DBFunctions from '@/common/DBFunctions.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import ModifierService from '@/services/modifier.service'
import { EventBus } from '../../../../event-bus.js';
//import Utils from '@/common/utils'

let elemSaveBtn = '';
export default {
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  name: 'Modifier',
 mixins: [ShowMessage, DBFunctions/*,EventBus*/],
  data(){
    return{
        parameter:{
            SearchCriteria:'',
            PageNumber : 1,
            PageSize:0,         
        },
       Modifier:{
        code:"",
        description:"",
        isInternal:false
       },
       Menu : this.$store.state.leftMenu,
       permission:[],
       CodeExist:false,
       pendingChanges : false,
        isReadOnlyModifier:false,
      isModifierValid:true,
        OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },

    }
  },

  methods:{

    CanView(){
      return this.permission[0].roleCanView;
  },

    DiscardChanges(){
       this.checkExistFavorite('/app/modifiers');
       this.$router.push({ name: "modifier-list"});
    },

     AddModifier(){
       if(!this.CodeExist){
      this.$emit('load', true);
      ModifierService.AddModifier(this.Modifier)
                .then((response) => {
                    if(response.status == '200'){
                        this.Modifier = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);
                        /*if(!this.OffRoute){
                           this.GotoAdmissionList();
                        }*/
                        this.$router.push({name: "modifier-list"}).catch(()=>{});
                      
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                             
                })
                .catch((error) => {
                    if (!error.response) {
                   this.showMessage(this.$t('modifier.ModifierTitle'),this.$t('Msg.NotAbleToInsert'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('modifier.ModifierTitle'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('modifier.ModifierTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('modifier.ModifierTitle'),error.response.data.errorMessage,"error");
                     }
                     elemSaveBtn.removeAttribute("disabled"); 
                    this.$emit('load', false);
                }); 
       }else{
        this.showMessage(this.$t('modifier.ModifierTitle'),this.$t('modifier.ModiExist'),"error");
       }
    },

    OnCodeBlur(value){
            //if (this.patientZipChanged && value.trim()!== '') {
               if(!this.$attrs.editMode){
                  if (value.trim()!== '') {
          
            var parameter = {
               "code":value
            };
            ModifierService.getModifierByCode(parameter)
                .then((response) => {
                    if(response.status == '200' && response.data){
                      // this.$emit('load', false);  
                       if(response.data.length > 0){
                          this.showMessage(this.$t('modifier.ModifierTitle'),this.$t('modifier.ModiExist'),"error");
                          this.CodeExist = true;
                       }else{
                           this.CodeExist = false;
                       } 
                    }  
                     this.$emit('load', false);                          
                })
                .catch((error) => {
                    this.$emit('load', false); 
                  //  this.patient.city = '';
                   // this.patient.state = '';
                  if(error.response.status !== 404 && error.response.status !== 500){
                      if(error.response) {
                        this.showMessage(this.$t('modifier.ModifierTitle'),error,"error");
                    }
                  }
                    
                });  
            }
               }
    
       },


    EditModifier(){
      this.$emit('load', true);
      ModifierService.UpdateModifier(this.$route.params.id,this.Modifier)
                .then((response) => {
                    if(response.status == '200'){
                        this.Modifier = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);
                        /*if(!this.OffRoute){
                           this.GotoAdmissionList();
                        }*/
                        this.$router.push({name: "modifier-list"}).catch(()=>{});
                      
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                             
                })
                .catch((error) => {
                    if (!error.response) {
                   this.showMessage(this.$t('modifier.ModifierTitle'),this.$t('Msg.NotAbleToInsert'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('modifier.ModifierTitle'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('modifier.ModifierTitle'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('modifier.ModifierTitle'),error.response.data.errorMessage,"error");
                     }
                     elemSaveBtn.removeAttribute("disabled"); 
                    this.$emit('load', false);
                }); 
    },

  getModifier(){
      if(this.$attrs.editMode){
      this.$emit('load', true);     
      ModifierService.GetModifier(this.$route.params.id)
        .then((response) => {
            if(response.status == '200' && response.data){
              this.Modifier = response.data;  
            }   
              this.$emit('load', false);                     
        })
        .catch((error) => {                    
            if (!error.response) {
              this.showMessage(this.$t('modifier.ModifierTitle'),this.$t('Msg.NotAbleToSave'),"error");
            }                      
            else if(error.response.status === 400 ){
              this.showMessage(this.$t('modifier.ModifierTitle'),error.response.data.errorMessage,"error");
            }
            else if(error.response.status === 405 ){             
                  this.$router.push({name: "Main"});    
                    setTimeout(() => 
                        this.showPermissionMessage()
                        , 50);            
            } 
            else if(error.response.status === 500){
              this.showMessage(this.$t('modifier.ModifierTitle'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
              this.showMessage(this.$t('modifier.ModifierTitle'),error.response.data.errorMessage,"error");
            } 
            this.$emit('load', false);            
        }); 
    
      }
    },

      DoAction(){     
        !this.$attrs.editMode ? this.AddModifier() : this.EditModifier(); 
      },

      SaveOrEdit(){ 
         if (this.validate_required()) {
            this.DoAction();
         } else {
            this.showMessage(this.$t('modifier.ModifierTitle'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");          
         }

      },

      validate_required() {
        this.isModifierValid = this.Modifier.code.length > 0 ? true : false;   
        return this.isModifierValid;
      },
      checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
      }
  },

  created(){
    EventBus.$on("onChanged", function () {       
          this.checkUserAction();
     }.bind(this));
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
  },

  mounted(){
      this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
      this.getModifier();
      setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_modifier');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );    
  },

    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
   
};
</script>


