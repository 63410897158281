import Vue from 'vue'

import '@babel/polyfill'
import 'mutationobserver-shim'

import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
//import '@/assets/js/vendor.bundle.base.js'
import "bootstrap";
import './icons.js'
import store from './globalvar'
import ModalTablePaginationServer from '@/components/template/modaltablepagserver/ModalTablePaginationServer'
import ModalTablePaginationServerMultiple from '@/components/template/modaltablepagserver/ModalTablePaginationServerMultiple'
import ModalAdmissionTeamMembers from '@/components/pages/admission/ModalAdmissionTeamMembers'
import ModalInsuranceTherapies from '@/components/pages/setup/insuranceCompanies/ModalInsuranceTherapies'
import ModalInsuranceContacts from '@/components/pages/setup/insuranceCompanies/ModalInsuranceContacts'
import ModalClaimBatchError from '@/components/pages/claimbatch/modalClaimBatchError'
import ModalClaimDetail from '@/components/pages/claimbatch/modalClaimDetail'
import ModalClaimError from '@/components/pages/claimbatch/modalClaimError'
import TableClaimWarningError from '@/components/pages/claimbatch/TableWarningError'
import ModalClaimWarning from '@/components/pages/claimbatch/modalClaimWarning'
import ModalAdmissionOccurrence  from '@/components/pages/admission/ModalAdmissionOccurrence'
import MdTable from '@/components/MedEZComponents/table/MdTable'
import MdTableClaimBatchDetail from '@/components/MedEZComponents/table/MdTableClaimBatchDetail'
import MdTablePaginationClient from '@/components/MedEZComponents/table/MdTablePaginationClient'
import MdTableClaimBatch from '@/components/MedEZComponents/table/MdTableClaimBatch'
import MdPatientInfoBox from '@/components/MedEZComponents/summaries/MdPatientInfoBox'
import MdAppointmentByDay from '@/components/MedEZComponents/summaries/MdAppointmentByDay'
import MdAdmissionByDay from '@/components/MedEZComponents/summaries/MdAdmissionByDay'
import MdPatientAllergies from '@/components/MedEZComponents/summaries/MdPatientAllergies'
import MdPatientDiagnosis from '@/components/MedEZComponents/summaries/MdPatientDiagnosis'
import MdCurrentAppointment from '@/components/MedEZComponents/summaries/MdCurrentAppointment'
import MdNotesByAppt from '@/components/MedEZComponents/summaries/MdNotesByAppt'
import MdHistoricalAppointment from '@/components/MedEZComponents/summaries/MdHistoricalAppointment'
import MdPatientRegistrationsHistory from '@/components/MedEZComponents/summaries/MdPatientRegistrationsHistory'
import MdPatientRegistrationInfo from '@/components/MedEZComponents/summaries/MdPatientRegistrationInfo'
import MdSelect from '@/components/MedEZComponents/select/MdSelect'
import MdEditTable from '@/components/MedEZComponents/table/MdEditTable'
import MdEditTableGeneric from '@/components/MedEZComponents/table/MdEditTableGeneric'
import MdTableProvider from '@/components/pages/setup/facilities/MdTableProvider'
import MdNote from '@/components/MedEZComponents/notes/MdNote'
import MdGroupNote from '@/components/pages/groupnotes/MdGroupNote'
import AppMenu from '@/components/menu/AppMenu'
import VueSidebarMenu from 'vue-sidebar-menu'
import MdMemo from '@/components/MedEZComponents/memo/MdMemo'
import MdEdit from '@/components/MedEZComponents/edit/MdEdit'
import MdSearch from '@/components/MedEZComponents/mdsearch/MdSearch'
import MdMenu from '@/components/template/Mdmenu/MdMenu'
import HeaderLeftMenu from '@/components/template/Mdmenu/HeaderLeftMenu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import VueSignaturePad from 'vue-signature-pad';
import MdRadioGroup from '@/components/MedEZComponents/radio/MdRadioGroup' 
import MdCheckBox from '@/components/MedEZComponents/checkbox/MdCheckBox'
import MdCheckListBox from '@/components/MedEZComponents/checkbox/MdCheckListBox'

import MdButton from '@/components/MedEZComponents/buttons/MdButton' 
import VueRepeater from 'vue-repeater'
import 'vue-repeater/dist/lib/vue-repeater.css'
import address from '@/components/repeater/templates/RepeaterTherapies'
import MdRepeater from '@/components/repeater/MdRepeater'
import MdDualListBox from '@/components/MedEZComponents/duallistbox/MdDualListBox'
import MdTitle from '@/components/MedEZComponents/titles/MdTitle'
import MdTabList from '@/components/MedEZComponents/tabs/MdTabList'
import MdLookup from '@/components/MedEZComponents/lookup/MdLookup' 
import LookupFromIconPlus from '@/components/MedEZComponents/lookup/LookupFromIconPlus' 
import MdLookupMultiple from '@/components/MedEZComponents/lookup/MdLookupMultiple'  
import Fragment from 'vue-fragment'
import VueMask from 'v-mask'
//import { ListBoxComponent, ListBoxPlugin } from '@syncfusion/ej2-vue-dropdowns';
import MdPatientDiagCodes from '@/components/NotesComponents/OutComeStudies/MdPatientDiagCodes'
import MdPatientInsurances from '@/components/NotesComponents/OutComeStudies/MdPatientInsurances'
import MdPatientContacts from '@/components/NotesComponents/OutComeStudies/MdPatientContacts'
import MdPaginationItems from '@/components/MedEZComponents/paginationitem/MdPaginationItem'
import { VueContext } from '@sn8/vue-context-bootstrap';
import MdPasswordDlg from '@/components/MedEZComponents/password/MdPasswordDlg'
import ModalSignandSave from '@/components/MedEZComponents/notes/modalSignandSave'
import ModalCoSignandSave from '@/components/MedEZComponents/notes/modalCoSignandSave'
import MdPassword from '@/components/MedEZComponents/password/MdPassword'
import MdReasonDlg from '@/components/MedEZComponents/reason/MdReasonDlg'
import MdGetLastAnswers from '@/components/NotesComponents/MdGetLastAnswers'
import MdPatientAllergiesOutCome from '@/components/NotesComponents/OutComeStudies/MdPatientAllergiesOutCome'
import MdGenericOutCome from '@/components/NotesComponents/OutComeStudies/MdGenericOutCome'
import MdPainAssessmentNotesOutCome from '@/components/NotesComponents/OutComeStudies/MdPainAssessmentNotesOutCome'
import MdPatientVitalSingOutCome from '@/components/NotesComponents/OutComeStudies/MdPatientVitalSingOutCome'
import MdRichEdit from '@/components/MedEZComponents/richedit/MdRichEdit'
import ChartReviewBase from '@/components/pages/chartreview/ChartReview'
import MdTableComponent from '@/components/MedEZComponents/table/MdTableComponent'
import MdTablePatientDiagnosis from '@/components/pages/patients/TablePatientDiagnosis'
import CKEditor from 'ckeditor4-vue';
//Vue.use(PrimeVue);
import Listbox from 'primevue/listbox';
import ReportViewer from '@/components/MedEZComponents/report/ReportViewer';
import SchedulerDailyRep from '@/components/pages/reports/schedulerDailyRep'
import MdPopup from '@/components/MedEZComponents/popup/MdPopup'
import ModalProviderSelector from '@/components/pages/claimfile/modalProviderSelector'
import ModalViewFile from '@/components/pages/claimfile/modalViewFile'
import ModalTypeOfBill from '@/components/pages/claimfile/modalTypeOfBill.vue'
import MdTableTypeOfBill from '@/components/pages/claimfile/MdTableTypeOfBill.vue'
import NotesRep from '@/components/pages/reports/notesRep'
import LoadDocument from '@/components/pages/patients/LoadDocument';
import VitalSignsRep from '@/components/pages/reports/vitalSignsRep'
import DailyNotesRep from '@/components/pages/reports/dailyNotesRep'
import LogNotesRep from '@/components/pages/reports/logNotesRep'
import NotesCompositeRep from '@/components/pages/reports/notesCompositeRep'
import EmployeeInbox from '@/components/pages/clinical/employeeinbox/EmployeeInbox'
import ModalUserResetPassword from '@/components/pages/setup/users/user/ModalUserResetPassword'
import MdSignature from '@/components/MedEZComponents/signature/MdSignature'
import MdTableGenericFields from '@/components/MedEZComponents/table/MdTableGenericFields'
import ModalEmployeeBuilding from '@/components/pages/setup/employees/modalEmployeeBuilding';
import ModalEmployeeProgram from '@/components/pages/setup/employees/modalEmployeeProgram';
import ModalEmployeeDocument from '@/components/pages/setup/employees/modalEmployeeDocument';
import ModalEmployeeProviderId from '@/components/pages/setup/employees/modalEmployeeProviderId';
import ModalCamera from '@/components/MedEZComponents/camera/modalCamera';
import ModalDocument from '@/components/pages/patients/modalDocument';
import ModalDiagnosis from '@/components/pages/patients/ModalDiagnosis';
import ModalDocumentAttachment from '@/components/NotesComponents/modalDocumentAttachment';
import modalEditWileyModalityObj from '@/components/NotesComponents/modalEditWileyModalityObj';
import ePharmacyBase from '@/components/pages/ePharmacy/ePharmacyBase'
import ModalAppointmentReason from '@/components/pages/appointment/ModalCancelAppointmentReason';
import ModalHistoricalAppointment from '@/components/pages/appointment/ModalHistoricalAppointment';

import MdColor from '@/components/MedEZComponents/color/MdColor';
import MdMultiSelectListBox from '@/components/MedEZComponents/multiselectlistbox/MdMultiselectListbox';
import MdProgress from '@/components/MedEZComponents/progress/MdProgress'
import DxPoPup from '@/components/MedEZComponents/DxPopup/DxPopup'
import DxPoPupTable from '@/components/MedEZComponents/DxPopup/DxPopupTable.vue'
import ReportList from '@/components/pages/reports/ReportList'
import DailyServiceCharge from '@/components/pages/reports/dailyServiceCharge'
import DailyServiceLog from '@/components/pages/reports/dailyServiceLog'

import IncidentReport from '@/components/pages/incidentReport/IncidentReport'
import ModalParticipantClient from '@/components/pages/incidentReport/ModalParticipantClient'
import ModalParticipantEmployee from '@/components/pages/incidentReport/ModalParticipantEmployee'
import ModalParticipantContact from '@/components/pages/incidentReport/ModalParticipantContact'
import ModalParticipantVisitor from '@/components/pages/incidentReport/ModalParticipantVisitor'
import ModalCategory from '@/components/pages/incidentReport/ModalCategory'
import DrugList from '@/components/pages/setup/drugs/drugList'
import Drug from '@/components/pages/setup/drugs/drug'

import MdCheckListBox2 from '@/components/MedEZComponents/checkbox/MdCheckListBox2' 
import MdCheckListBoxByCycle from '@/components/MedEZComponents/checkbox/MdCheckListBoxByCycle' 
import RadioGroup from '@/components/MedEZComponents/radio/RadioGroup' 
import PatientContactsRep from '@/components/pages/reports/patientContactsRep'
import PatientAllergyRep from '@/components/pages/reports/PatientAllergyRep'
import PatientInsurancesRep from '@/components/pages/reports/PatientInsurancesRep'
import ICRMultipleRep from '@/components/pages/reports/ICRMultipleRep'
import ICRMultipleViewRep from '@/components/pages/reports/ICRMultipleViewRep'

import IncidentWorkflow from '@/components/pages/incidentWorkflow/IncidentWorkflow'
import IncidentReportHeaderBox from '@/components/pages/incidentWorkflow/IncidentReportHeaderBox'
import IncidentReportPoliceBox from '@/components/pages/incidentWorkflow/IncidentReportPoliceBox'
import IncidentReportCPSBox from '@/components/pages/incidentWorkflow/IncidentReportCPSBox'
import IncidentReportEmergencyBox from '@/components/pages/incidentWorkflow/IncidentReportEmergencyBox'
import IncidentWorkflowRightBar from '@/components/pages/incidentWorkflow/IncidentWorkflowRightBar'
import ModalPriorAutho from '@/components/pages/patients/insurances/modalPriorAuthorization';
import IncidentWorkflowNotesModal from '@/components/pages/incidentWorkflow/IncidentWorkflowNotesModal'
import IncidentWorkflowSummaryModal from '@/components/pages/incidentWorkflow/IncidentWorkflowSummaryModal'
import IncidentWorkflowSummary from '@/components/pages/incidentWorkflow/IncidentWorkflowSummary'

// Wiley Treatment Plan
import wyModalEditProblem from '@/components/pages/wytreatmentplan/wyModalEditProblem'
import wyModalEditSignature from '@/components/pages/wytreatmentplan/wyModalEditSignature'
import wyModalSignPatientGuardian from '@/components/pages/wytreatmentplan/wyModalSignPatientGuardian'
import wyModalBlankTPBehavioral from '@/components/pages/wytreatmentplan/wyModalBlankBehavioral'
import wyModalEditTPProblem from '@/components/pages/wytreatmentplan/wyModalEditTPProblem'
import wyModalEditTPBehavioral from '@/components/pages/wytreatmentplan/wyModalEditTPBehavioral'
import wyModalAddGoal from '@/components/pages/wytreatmentplan/wyModalAddGoal'
import wyModalAddObjective from '@/components/pages/wytreatmentplan/wyModalAddObjective'
import wyModalEditGoal from '@/components/pages/wytreatmentplan/wyModalEditGoal'
import wyModalAddIntervention from '@/components/pages/wytreatmentplan/wyModalAddIntervention'
import wyModalEditObjective from '@/components/pages/wytreatmentplan/wyModalEditObjective'
import wyModalEditIntervention from '@/components/pages/wytreatmentplan/wyModalEditIntervention'
import wyModalAddSignature from '@/components/pages/wytreatmentplan/wyModalAddSignature'

// MedEZ Treatment Plan
import mdModalEditProblem from '@/components/pages/mdtreatmentplan/mdModalEditProblem'
import mdModalEditSignature from '@/components/pages/mdtreatmentplan/mdModalEditSignature'
import mdModalSignPatientGuardian from '@/components/pages/mdtreatmentplan/mdModalSignPatientGuardian'
import mdModalBlankTPBehavioral from '@/components/pages/mdtreatmentplan/mdModalBlankBehavioral'
import mdModalEditTPProblem from '@/components/pages/mdtreatmentplan/mdModalEditTPProblem'
import mdModalEditTPBehavioral from '@/components/pages/mdtreatmentplan/mdModalEditTPBehavioral'
import mdModalAddGoal from '@/components/pages/mdtreatmentplan/mdModalAddGoal'
import mdModalAddObjective from '@/components/pages/mdtreatmentplan/mdModalAddObjective'
import mdModalEditGoal from '@/components/pages/mdtreatmentplan/mdModalEditGoal'
import mdModalAddIntervention from '@/components/pages/mdtreatmentplan/mdModalAddIntervention'
import mdModalEditObjective from '@/components/pages/mdtreatmentplan/mdModalEditObjective'
import mdModalEditIntervention from '@/components/pages/mdtreatmentplan/mdModalEditIntervention'
import mdModalAddSignature from '@/components/pages/mdtreatmentplan/mdModalAddSignature'

//Speech to text Component
import MdTextSpeech from '@/components/MedEZComponents/textspeech/MdTextSpeech'
import TreatmentPlanRep from '@/components/pages/reports/treatmentPlanRep'
import GroupNoteCompositeRep from '@/components/pages/reports/groupNoteCompositeRep'
import ObjectiveReviewRep from '@/components/pages/reports/objectiveReviewRep'
import ProblemReviewRep from '@/components/pages/reports/problemReviewRep'
import TreatmentPlanProblemRep from '@/components/pages/reports/treatmentPlanProblemRep'
import modalProblemDetail from '@/components/NotesComponents/modalProblemDetail'
import ScheduleNotify from '@/components/pages/schedule/schedule-notify'
import ModalAddendums from '@/components/MedEZComponents/notes/modalAddendums'

//use by DevExpress Reporting tool
import "jquery-ui/themes/base/all.css";
import "devextreme/dist/css/dx.light.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.common.css";
import "@devexpress/analytics-core/dist/css/dx-analytics.light.css";
import "@devexpress/analytics-core/dist/css/dx-querybuilder.css";
import "devexpress-reporting/dist/css/dx-webdocumentviewer.css";
import "devexpress-reporting/dist/css/dx-reportdesigner.css";
import 'devextreme/dist/css/dx.light.css';

import VueGoodWizard from 'vue-good-wizard';

//For export csv file


Vue.use(VueGoodWizard);
//CKeditor
Vue.use( CKEditor );

//Vue.component(ListBoxPlugin.name, ListBoxComponent);

//Vue.use(ListBoxPlugin);
Vue.use(VueMask);
Vue.use(Fragment.Plugin)

Vue.config.productionTip = false
Vue.component('vue-repeater', VueRepeater)
Vue.component('test-address', address)

Vue.use(VueSignaturePad);
Vue.use(VueSidebarMenu)


import i18n from '@/plugins/i18n';


// Importar Modal table en la aplicacion para usarlo desde cualquier parte  

Vue.component('ModalAddendums', ModalAddendums)
Vue.component('modalProblemDetail', modalProblemDetail)
Vue.component('ModalPriorAutho', ModalPriorAutho)
Vue.component('ModalDiagnosis', ModalDiagnosis)
Vue.component('MdAdmissionByDay', MdAdmissionByDay)
Vue.component('MdAppointmentByDay', MdAppointmentByDay)
Vue.component('MdNotesByAppt', MdNotesByAppt)
Vue.component('DxPoPup', DxPoPup)
Vue.component('DxPoPupTable', DxPoPupTable)
Vue.component('ePharmacyBase', ePharmacyBase)
Vue.component('ModalDocument', ModalDocument)
Vue.component('ModalDocumentAttachment', ModalDocumentAttachment)
Vue.component('modalEditWileyModalityObj', modalEditWileyModalityObj)
Vue.component('ModalAppointmentReason', ModalAppointmentReason)
Vue.component('ModalHistoricalAppointment', ModalHistoricalAppointment)
Vue.component('LoadDocument', LoadDocument)
Vue.component('ModalCamera', ModalCamera)
Vue.component('MdMultiSelectListBox', MdMultiSelectListBox)
Vue.component('MdColor', MdColor)
Vue.component('ModalEmployeeProviderId', ModalEmployeeProviderId)
Vue.component('ModalEmployeeDocument', ModalEmployeeDocument)
Vue.component('ModalEmployeeBuilding', ModalEmployeeBuilding)
Vue.component('ModalEmployeeProgram', ModalEmployeeProgram)
Vue.component('MdTableGenericFields', MdTableGenericFields)
Vue.component('MdSignature', MdSignature)
Vue.component('ModalViewFile', ModalViewFile)
Vue.component('MdTableTypeOfBill', MdTableTypeOfBill)
Vue.component('ModalProviderSelector', ModalProviderSelector)
Vue.component('ModalTypeOfBill', ModalTypeOfBill)
Vue.component('ModalUserResetPassword', ModalUserResetPassword)
Vue.component('ModalClaimBatchError', ModalClaimBatchError)
Vue.component('TableClaimWarningError', TableClaimWarningError)
Vue.component('ModalClaimDetail', ModalClaimDetail)
Vue.component('ModalClaimError', ModalClaimError)
Vue.component('ModalClaimWarning', ModalClaimWarning)
Vue.component('ModalTablePaginationServer', ModalTablePaginationServer)
Vue.component('ModalTablePaginationServerMultiple', ModalTablePaginationServerMultiple)
Vue.component('ModalAdmissionTeamMembers', ModalAdmissionTeamMembers)
Vue.component('ModalInsuranceTherapies', ModalInsuranceTherapies)
Vue.component('ModalInsuranceContacts', ModalInsuranceContacts)
Vue.component('ModalAdmissionOccurrence', ModalAdmissionOccurrence)
Vue.component('MdTable', MdTable)
Vue.component('MdTableClaimBatchDetail', MdTableClaimBatchDetail)
Vue.component('MdTablePaginationClient', MdTablePaginationClient)
Vue.component('MdTableClaimBatch', MdTableClaimBatch)
Vue.component('MdPatientInfoBox', MdPatientInfoBox)
Vue.component('MdPatientAllergies', MdPatientAllergies)
Vue.component('MdPatientDiagnosis', MdPatientDiagnosis)
Vue.component('MdCurrentAppointment', MdCurrentAppointment)
Vue.component('MdHistoricalAppointment', MdHistoricalAppointment)
Vue.component('MdPatientRegistrationsHistory', MdPatientRegistrationsHistory)
Vue.component('MdPatientRegistrationInfo', MdPatientRegistrationInfo)
Vue.component('MdSelect', MdSelect)
Vue.component('MdSearch', MdSearch)
Vue.component('AppMenu', AppMenu)
Vue.component('MdEditTable', MdEditTable)
Vue.component('MdEditTableGeneric', MdEditTableGeneric)
Vue.component('MdTableProvider', MdTableProvider)
Vue.component('MdNote', MdNote)
Vue.component('MdGroupNote', MdGroupNote)
Vue.component('MdEdit', MdEdit)
Vue.component('MdMenu', MdMenu)
Vue.component('HeaderLeftMenu', HeaderLeftMenu)
Vue.component('MdRepeater', MdRepeater)
Vue.component('MdDualListBox', MdDualListBox)
Vue.component('MdMemo', MdMemo)
Vue.component('MdRadioGroup', MdRadioGroup)
Vue.component('MdCheckBox', MdCheckBox)
Vue.component('MdButton', MdButton)
Vue.component('MdTitle', MdTitle)
Vue.component('MdTabList', MdTabList)
Vue.component('MdLookup', MdLookup)
Vue.component('LookupFromIconPlus', LookupFromIconPlus)
Vue.component('MdLookupMultiple', MdLookupMultiple)
Vue.component('MdPatientDiagCodes', MdPatientDiagCodes)
Vue.component('MdPatientInsurances', MdPatientInsurances)
Vue.component('MdPatientContacts', MdPatientContacts)
Vue.component('VueContext', VueContext)
Vue.component('Listbox', Listbox)
Vue.component('MdPaginationItems', MdPaginationItems)
Vue.component('MdPasswordDlg', MdPasswordDlg)
Vue.component('ModalSignandSave', ModalSignandSave)
Vue.component('ModalCoSignandSave', ModalCoSignandSave)
Vue.component('MdPassword', MdPassword)
Vue.component('MdReasonDlg', MdReasonDlg)
Vue.component('MdGetLastAnswers', MdGetLastAnswers)
Vue.component('MdCheckListBox', MdCheckListBox)
Vue.component('MdPatientAllergiesOutCome', MdPatientAllergiesOutCome)
Vue.component('MdGenericOutCome', MdGenericOutCome)
Vue.component('MdPainAssessmentNotesOutCome', MdPainAssessmentNotesOutCome)
Vue.component('MdPatientVitalSingOutCome', MdPatientVitalSingOutCome)
Vue.component('MdRichEdit', MdRichEdit)
Vue.component('ChartReviewBase', ChartReviewBase)
Vue.component('MdTableComponent', MdTableComponent)
Vue.component('MdTablePatientDiagnosis', MdTablePatientDiagnosis)
Vue.component('ReportViewer', ReportViewer)
Vue.component('SchedulerDailyRep', SchedulerDailyRep)
Vue.component('MdPopup', MdPopup)
Vue.component('NotesRep', NotesRep)
Vue.component('VitalSignsRep', VitalSignsRep)
Vue.component('MdProgress', MdProgress)
Vue.component('NotesCompositeRep', NotesCompositeRep)
Vue.component('DailyNotesRep', DailyNotesRep)
Vue.component('ReportList', ReportList)
Vue.component('DailyServiceCharge', DailyServiceCharge)
Vue.component('DailyServiceLog', DailyServiceLog)
Vue.component('EmployeeInbox', EmployeeInbox)
Vue.component('LogNotesRep', LogNotesRep)
Vue.component('IncidentReport', IncidentReport)
Vue.component('ModalParticipantClient', ModalParticipantClient)
Vue.component('ModalParticipantEmployee', ModalParticipantEmployee)
Vue.component('ModalParticipantContact', ModalParticipantContact)
Vue.component('ModalParticipantVisitor', ModalParticipantVisitor)
Vue.component('ModalCategory', ModalCategory)
Vue.component('DrugList', DrugList)
Vue.component('Drug', Drug)
Vue.component('MdCheckListBox2', MdCheckListBox2)
Vue.component('MdCheckListBoxByCycle', MdCheckListBoxByCycle)
Vue.component('RadioGroup', RadioGroup)
Vue.component('PatientContactsRep', PatientContactsRep)
Vue.component('PatientAllergyRep', PatientAllergyRep)
Vue.component('PatientInsurancesRep', PatientInsurancesRep)
Vue.component('ICRMultipleRep', ICRMultipleRep)
Vue.component('ICRMultipleViewRep', ICRMultipleViewRep)
Vue.component('IncidentWorkflow', IncidentWorkflow)
Vue.component('IncidentReportHeaderBox', IncidentReportHeaderBox)
Vue.component('IncidentReportPoliceBox', IncidentReportPoliceBox)
Vue.component('IncidentReportCPSBox', IncidentReportCPSBox)
Vue.component('IncidentReportEmergencyBox', IncidentReportEmergencyBox)
Vue.component('IncidentWorkflowRightBar', IncidentWorkflowRightBar)
Vue.component('IncidentWorkflowNotesModal', IncidentWorkflowNotesModal)
Vue.component('IncidentWorkflowSummaryModal', IncidentWorkflowSummaryModal)
Vue.component('IncidentWorkflowSummary', IncidentWorkflowSummary)
Vue.component('MdTextSpeech', MdTextSpeech)
Vue.component('wyModalEditProblem', wyModalEditProblem)
Vue.component('wyModalEditSignature', wyModalEditSignature)
Vue.component('wyModalSignPatientGuardian', wyModalSignPatientGuardian)
Vue.component('wyModalEditTPProblem', wyModalEditTPProblem)
Vue.component('wyModalEditTPBehavioral', wyModalEditTPBehavioral)
Vue.component('wyModalBlankTPBehavioral', wyModalBlankTPBehavioral)
Vue.component('wyModalAddGoal', wyModalAddGoal)
Vue.component('wyModalAddObjective', wyModalAddObjective)
Vue.component('wyModalEditGoal', wyModalEditGoal)
Vue.component('wyModalAddIntervention', wyModalAddIntervention)
Vue.component('wyModalEditObjective', wyModalEditObjective)
Vue.component('wyModalEditIntervention', wyModalEditIntervention)
Vue.component('wyModalAddSignature', wyModalAddSignature)
Vue.component('mdModalEditProblem', mdModalEditProblem)
Vue.component('mdModalEditSignature', mdModalEditSignature)
Vue.component('mdModalSignPatientGuardian', mdModalSignPatientGuardian)
Vue.component('mdModalEditTPProblem', mdModalEditTPProblem)
Vue.component('mdModalEditTPBehavioral', mdModalEditTPBehavioral)
Vue.component('mdModalBlankTPBehavioral', mdModalBlankTPBehavioral)
Vue.component('mdModalAddGoal', mdModalAddGoal)
Vue.component('mdModalAddObjective', mdModalAddObjective)
Vue.component('mdModalEditGoal', mdModalEditGoal)
Vue.component('mdModalAddIntervention', mdModalAddIntervention)
Vue.component('mdModalEditObjective', mdModalEditObjective)
Vue.component('mdModalEditIntervention', mdModalEditIntervention)
Vue.component('mdModalAddSignature', mdModalAddSignature)
Vue.component('TreatmentPlanRep', TreatmentPlanRep)
Vue.component('GroupNoteCompositeRep', GroupNoteCompositeRep)
Vue.component('ObjectiveReviewRep', ObjectiveReviewRep)
Vue.component('ProblemReviewRep', ProblemReviewRep)
Vue.component('TreatmentPlanProblemRep', TreatmentPlanProblemRep)
Vue.component('ScheduleNotify', ScheduleNotify)

// Bootstrap
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Install BootstrapVue
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'

// Bootstrap

// Toasts

import VueBootstrapToasts from "vue-bootstrap-toasts";
Vue.use(VueBootstrapToasts);

// Toasts

//Configuracion de los servicios
import ApiService from '@/services/api.service'
// Visualizar la variable de entorno console.log('Main baseURL: ' + process.env.VUE_APP_BASE_URL)
//ApiService.init(process.env.VUE_APP_BASE_URL)
//Set the base URL of the API

// Tengo que cambiar esta linea cuando funcione con el valor del env
// ApiService.init('https://testapi.medezweb.com/api/')
ApiService.init('https://api.medezweb.com/api/')
// ApiService.init('https://apidamar.medezweb.com/api/')

// for Visual Studio
//ApiService.init('http://localhost:62930/api/')

// for Visual Studio Code
// ApiService.init('http://localhost:5000/api/')

// for BrowserStack
// ApiService.init('http://www.localhost.com:62930/api/')

// If token exists set header
// if (TokenService.getToken()) {
//   ApiService.setHeader()
// }

//IsVisibleRigthMenuBar : false
//export const IsVisibleRigthMenuBar = false;
Vue.prototype.$IsVisibleRigthMenuBar = false
Vue.config.productionTip = false

Vue.mixin({
  mounted() {
    if (localStorage.lang != null) {
      this.$i18n.locale = localStorage.lang;
    }
  },
});

new Vue({
  router, 
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')