<template>
<div id="ContainerEmployee">
       <h4 v-if="this.$attrs.editMode" class="page-title text-truncate">
        {{$t('employee.Edit')}}
      </h4>
      <h4 v-if="!this.$attrs.editMode" class="page-title text-truncate">
        {{$t('employee.Add') }}         
      </h4>
    <b-row>           
        <b-col md="12" sm="12" lg="12">
            <MdTabList v-bind:TabList="tablist" v-bind:ShowTabList="false" v-bind:ShowTabEnd="false">
                <div class="tab-content tab-content-custom-pill" id="pills-tabContent-custom">
                    <div class="tab-pane fade show active" id="general" role="tabpanel">
                        <b-row>
                        <b-col lg="3" md="3" sm="12">
                            <MdEdit
                                v-model="Employee.code"                    
                                v-bind:Label="$t('Shared.Code')"          
                                v-bind:isReadOnly="this.$attrs.editMode"
                                v-bind:isRequired="true"
                                v-bind:MaxLengthProp="8"
                                v-bind:isValid="isEmployeeValid"
                                v-on:blur="OnCodeBlur(Employee.code)"
                                :InvalidFeedback="'Employee Code is Required'"
                                :SearchFieldCode="'Code'"
                                 v-bind:Id="'input_e_code'"/>
                        </b-col>  
                        <b-col lg="3" md="3" sm="12">         
                            <MdSelect
                                v-bind:Label="$t('Shared.Type')"
                                v-bind:Options="TypeList"
                                v-bind:Model="TypeModel"
                                v-bind:FieldDescription="'description'"
                                v-bind:FieldCode="'code'"
                                v-bind:Multiple="false"             
                                :Input="ChangeDropDownType"/>
                        </b-col>
                        <b-col lg="3" md="3" sm="12">
                            <MdEdit
                                v-model="Employee.title"                    
                                v-bind:Label="$t('Patient.Title')"  
                                v-bind:isRequired="false"
                                v-bind:MaxLengthProp="100"
                                v-bind:Id="'input_e_t'"/>
                        </b-col>
                        <b-col lg="3" md="3" sm="12">
                            <MdEdit
                                v-model="Employee.empId"                    
                                v-bind:Label="'Emp ID'"  
                                v-bind:isRequired="false"
                                v-bind:MaxLengthProp="15"
                                v-bind:Id="'input_e_id'"/>
                        </b-col>    
                        </b-row>
                        <b-row>
                        <b-col lg="3" md="3" sm="12">
                            <MdEdit
                                v-model="Employee.firstname"                    
                                v-bind:Label="$t('Shared.FirstName')"  
                                v-bind:isRequired="false"
                                v-bind:MaxLengthProp="15"
                                v-bind:Id="'input_e_fn'"/>
                        </b-col>
                        <b-col lg="3" md="3" sm="12">
                            <MdEdit
                                v-model="Employee.lastname"                    
                                v-bind:Label="$t('Shared.LastName')"  
                                v-bind:isRequired="false"
                                v-bind:MaxLengthProp="15"
                                v-bind:Id="'input_e_ln'"/>
                        </b-col> 
                        <b-col lg="3" md="3" sm="12">
                            <MdEdit
                                v-model="Employee.initial"                    
                                v-bind:Label="$t('Patient.Initial')"  
                                v-bind:isRequired="false"
                                v-bind:MaxLengthProp="1"
                                v-bind:Id="'input_e_ini'"/>
                        </b-col>
                        <b-col lg="3" md="3" sm="12">
                            <MdEdit
                                v-model="Employee.email"                    
                                v-bind:Label="$t('Shared.Email')"  
                                v-bind:TypeProp="'email'" 
                                v-bind:isRequired="false"
                                v-bind:isValid="this.emailValid"
                                v-bind:MaxLengthProp="75"
                                v-bind:Id="'input_e_email'"/>
                        </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model="Employee.address1"                    
                                    v-bind:Label="$t('Shared.Address1')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="30"
                                    v-bind:Id="'input_e_addrs1'"/>
                            </b-col> 
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model="Employee.address2"                    
                                    v-bind:Label="$t('Shared.Address2')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="30"
                                    v-bind:Id="'input_e_addrs2'"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model="Employee.zip"                    
                                    v-bind:Label="$t('Shared.Zip')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="10"
                                    v-bind:Id="'input_e_zip'"/>
                            </b-col> 
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model="Employee.city"                    
                                    v-bind:Label="$t('Shared.City')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="25"
                                    v-bind:Id="'input_e_city'"/>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model="Employee.state"                    
                                    v-bind:Label="$t('Shared.State')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="2"
                                    v-bind:Id="'input_e_state'"/>
                            </b-col> 
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model="Employee.county"                    
                                    v-bind:Label="$t('employee.County')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="25"
                                    v-bind:Id="'input_e_contry'"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model="Employee.country"                    
                                    v-bind:Label="$t('Shared.Country')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="25"
                                    v-bind:Id="'input_e_country'"/>
                            </b-col> 
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model="Employee.fax"                    
                                    v-bind:Label="$t('Patient.LegalFax')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="15"
                                    v-bind:Id="'input_e_lf'"/>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model="Employee.dayPhone"                    
                                    v-bind:Label="$t('Shared.DayPhone')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="15"
                                    v-bind:Id="'input_e_dph'"/>
                            </b-col> 
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model="Employee.nightPhone"                    
                                    v-bind:Label="$t('Shared.NightPhone')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="15"
                                    v-bind:Id="'input_e_nph'"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model="Employee.cellPhone"                    
                                    v-bind:Label="$t('Shared.CellPhone')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="15"
                                    v-bind:Id="'input_e_cph'"/>
                            </b-col> 
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model="Employee.license"                    
                                    v-bind:Label="$t('employee.DrivLic')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="15"
                                    v-bind:Id="'input_e_licen'"/>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col lg="3" md="3" sm="12">         
                                <MdSelect
                                    v-bind:Label="$t('employee.phoneComp')"
                                    v-bind:Options="CellCompanyList"
                                    v-bind:Model="cellCompanyModel"
                                    v-bind:FieldDescription="'description'"
                                    v-bind:FieldCode="'code'"
                                    v-bind:Multiple="false"             
                                    :Input="ChangeDropDownCellCompany"
                                    v-bind:Id="'input_e_comph'"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit 
                                    v-bind:Label="$t('Contact.SSN')"
                                    v-bind:TypeProp="'text'" 
                                    v-bind:Name="'ssn'"
                                    v-bind:MaxLengthProp="10"
                                    v-model="Employee.ssn"                                 
                                    v-bind:Mask="'###-##-####'"
                                    v-bind:Id="'input_e_ssn'">                                     
                                </MdEdit>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit 
                                    v-bind:Label="$t('Shared.Dob')"
                                    v-bind:TypeProp="'date'" 
                                    v-bind:Name="'dob'"
                                    v-bind:Min="'1900-01-01'"
                                    v-bind:Max="'2200-12-31'"
                                    v-model="Employee.dob"
                                    v-bind:isRequired="true"
                                    v-bind:isValid="this.isDOBValid"                                    
                                    v-bind:Id="'input_employee_date'">                                     
                                </MdEdit>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">             
                                <MdRadioGroup
                                    v-bind:Label="$t('Shared.Gender')"
                                    v-model="Employee.gender"
                                    v-bind:Options="GenderList"
                                    v-bind:Name="'Gender'"                                   
                                />
                            </b-col> 
                        </b-row>
                        <b-row>
                            <b-col lg="3" md="3" sm="12">
                                <MdLookup
                                v-bind:Label="$t('employee.Taxonomy')"
                                v-bind:ModelCode="Employee.taxonomy"
                                v-bind:ModelDescription="dtaxonomy"
                                v-bind:RefCode="'Taxonomy'"
                                v-bind:Api="'Referrals'"
                                v-bind:FieldCode="'code'"
                                v-bind:FieldDescription="'name'"                              
                                :SearchTitle="'Taxonomy'"
                                :GetValues="onCodeConditionOk"
                                :InvalidFeedback="$t('employee.InvTaxCodeMsg')"
                                :SearchFieldCode="'Code'" />
                            </b-col>
                            <b-col lg="3" md="3" sm="12">         
                                <MdSelect
                                    v-bind:Label="$t('employee.Ethnicity')"
                                    v-bind:Options="EthnicityList"
                                    v-bind:Model="ethnicityModel"
                                    v-bind:FieldDescription="'description'"
                                    v-bind:FieldCode="'code'"
                                    v-bind:Multiple="false"             
                                    :Input="ChangeDropDownEthnicity"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdLookup
                                v-bind:Label="$t('employee.UserID')"
                                v-bind:ModelCode="Employee.userId"
                                v-bind:ModelDescription="dUserId"
                                v-bind:RefCode="'Users'"
                                v-bind:Api="'Users'"
                                v-bind:FieldCode="'code'"
                                v-bind:FieldDescription="'name'"                              
                                :SearchTitle="$t('menu.Users')"
                                :GetValues="onCodeConditionOk"
                                :InvalidFeedback="$t('Msg.InvalidUCode')"
                                :SearchFieldCode="'Code'" />
                            </b-col>
                            <b-col lg="3" md="3" sm="12">         
                                <MdSelect
                                    v-bind:Label="$t('employee.Credentials')"
                                    v-bind:Options="CredentialsList"
                                    v-bind:Model="credentialsModel"
                                    v-bind:FieldDescription="'description'"
                                    v-bind:FieldCode="'code'"
                                    v-bind:Multiple="false"             
                                    :Input="ChangeDropDownCredentials"/>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col lg="3" md="3" sm="12">         
                                <MdSelect
                                    v-bind:Label="$t('Shared.Status')"
                                    v-bind:Options="StatusList"
                                    v-bind:Model="StatusModel"
                                    v-bind:FieldDescription="'name'"
                                    v-bind:FieldCode="'code'"
                                    v-bind:Multiple="false"             
                                    :Input="ChangeDropDownStatus"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">         
                                <MdSelect
                                    v-bind:Label="$t('MdNote.Cosigner')"
                                    v-bind:Options="CosignerList"
                                    v-bind:Model="CosignerModel"
                                    v-bind:FieldDescription="'name'"
                                    v-bind:FieldCode="'code'"
                                    v-bind:Multiple="false"             
                                    :Input="ChangeDropDownCosigner"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit 
                                    v-bind:Label="$t('employee.HiredDate')"
                                    v-bind:Name="'HiredDate'"
                                    v-bind:TypeProp="'text'" 
                                    v-bind:Mask="'##/##/####'"
                                    v-bind:Min="'1900-01-01'"
                                    v-bind:Max="'2200-12-31'"
                                    v-model="HiredDate"
                                    v-bind:isValid="isHiredDateValid"
                                    v-bind:Id="'input_HiredDate'"/> 
                            </b-col> 
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit 
                                    v-bind:Label="$t('employee.TerminateDate')"
                                    v-bind:Name="'TerminateDate'"
                                    v-bind:TypeProp="'text'" 
                                    v-bind:Mask="'##/##/####'"
                                    v-bind:Min="'1900-01-01'"
                                    v-bind:Max="'2200-12-31'"
                                    v-model="TerminateDate"
                                    v-bind:isReadOnly="isReadOnlyTerminateDate"
                                    v-bind:isValid="isTerminateValid"
                                    v-bind:Id="'input_TerminateDate'"/> 
                            </b-col> 
                        </b-row>

                        <b-row>
                            <b-col lg="3" md="3" sm="12">         
                                <MdSelect
                                    v-bind:Label="$t('employee.CosInsAssig')"
                                    v-bind:Options="CosignerInsuranceList"
                                    v-bind:Model="CosignerInsuranceModel"
                                    v-bind:FieldDescription="'name'"
                                    v-bind:FieldCode="'code'"
                                    :IsDisabled="isReadOnlyCosignerInsurance"
                                    v-bind:Multiple="true"             
                                    :Input="ChangeDropDownCosignerInsurance"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model="Employee.emp_Prov"                    
                                    v-bind:Label="$t('employee.ProvNum')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="9"
                                    v-bind:Id="'input_e_pro'"/>
                            </b-col>
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model="Employee.npi"                    
                                    v-bind:Label="'NPI'"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="15"
                                    v-bind:Id="'input_e_npi'"/>
                            </b-col>                        

                            <b-col lg="3" md="3" sm="12">             
                                <MdRadioGroup
                                    v-bind:Label="$t('employee.AllowtoSelect')"
                                    v-model="programselect"
                                    v-bind:Options="ProgramSelectList"
                                    v-bind:Name="'Gender'"                                   
                                />
                            </b-col>
                        </b-row>
                        <b-row>                       
                        <b-col md="3" lg="3" sm="12" >
                                <MdCheckBox
                                    v-bind:Label="$t('employee.EnforceSeleLoc')"
                                    v-bind:Name="'checkbox-LocationAfterLogin'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="Employee.requiredLocation"/>
                            </b-col>
                             <b-col md="3" lg="3" sm="12" >
                                <MdCheckBox
                                    v-bind:Label="$t('employee.ShowOnly')"
                                    v-bind:Name="'checkbox-ShowOnlyPatientsAssignedInTheScheduler'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="Employee.showOnlyPatientFromScheduler"/>
                            </b-col>
                            <b-col md="3" lg="3" sm="12" >
                                <MdCheckBox
                                    v-bind:Label="$t('employee.EnforceSeleProg')"
                                    v-bind:Name="'checkbox-EnforceSelectingProgramAfterLogin'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="Employee.enforceSelectProgram"/>
                            </b-col>
                              <b-col md="3" lg="3" sm="12" >
                                <MdCheckBox
                                    v-bind:Label="$t('employee.Driver')"
                                    v-bind:Name="'checkbox-Driver'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="Employee.isSelfDriver"/>
                            </b-col>  
                        </b-row>

                        <b-row>
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model.number="Employee.daysBeforeAppt"                    
                                    v-bind:Label="$t('employee.DayBefore')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="10"
                                    v-bind:Id="'input_e_db'"/>
                            </b-col> 
                            <b-col lg="3" md="3" sm="12">
                                <MdEdit
                                    v-model.number="Employee.daysAfterAppt"                    
                                    v-bind:Label="$t('employee.DayAfter')"  
                                    v-bind:isRequired="false"
                                    v-bind:MaxLengthProp="10"
                                    v-bind:Id="'input_e_da'"/>
                            </b-col> 
                             <b-col md="3" lg="3" sm="12" >
                                <MdSignature
                                    v-bind:Title="$t('employee.SigEmpl')"
                                    v-bind:Id="'IdCanvasSignatureEmployee'"
                                    v-bind:Data64="Employee.employeesignature"
                                />
                            </b-col>       
                        </b-row>


     <MdTitle v-bind:Text="$t('Shared.Building')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <b-row>
          <div class="col-sm-12 col-md-3">                      
            <MdButton
              v-bind:Label="$t('employee.AddBuilding')" 
              v-bind:ClassProp="isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
              v-bind:IconClass="'ti-plus pr-1'"              
              v-on:click="CallModalBuilding"
              v-bind:isReadOnly="this.isReadOnly">
            </MdButton>
          </div>
          <div class="col-sm-12 col-md-9 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input 
                  type="text" 
                  class="form-control"
                  v-model="searchBuilding"   
                  @keyup.enter="FindBuilding">
                  <div class="input-group-append">
                    <div class="input-group-append">
                      <MdButton 
                        v-bind:Name="'btnsearchinputBuilding'"
                        v-bind:VariantProp="'btn btn-sm search-attached'"
                        v-bind:IconClass="'mdi mdi-magnify'"
                        v-on:click="FindBuilding">
                      </MdButton>
                    </div>
                  </div>
                </div>
        </div>
      </div>
      </b-row>
    <div class="row">
          <div class="col-md-12">
              <MdTable 
              v-bind:fields="fieldsBuilding"
              v-bind:items="itemsBuilding"
              v-bind:totalRows="totalRowsBuilding"
              v-bind:isReadOnly="false"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPageBuilding"
              v-bind:perPage="perPageBuilding"
              v-bind:pageOptions="pageOptionsBuilding"
              :onFiltered="onFilteredBuilding"
              :pageChanged="pageChangedBuilding"
              :deleteIconEvent="RemoveBuilding"
              v-bind:VisibleDeleteIcon="true"
              :onRowSelected="onRowSelectedBuilding"
              :perPageFunction="PerPageFunctionBuilding">
            </MdTable>
          </div>
    </div>


    <MdTitle v-bind:Text="$tc('Shared.Program')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
          <b-row>
          <div class="col-sm-12 col-md-3">                      
            <MdButton
              v-bind:Label="$t('employee.AddProgram')" 
              v-bind:ClassProp="isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
              v-bind:IconClass="'ti-plus pr-1'"              
              v-on:click="CallModalProgram"
              v-bind:isReadOnly="this.isReadOnly">
            </MdButton>
          </div>
          <div class="col-sm-12 col-md-9 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input 
                  type="text" 
                  class="form-control"
                  v-model="searchprogram"   
                  @keyup.enter="FindProgram">
                  <div class="input-group-append">
                    <div class="input-group-append">
                      <MdButton 
                        v-bind:Name="'btnsearchinputProgram'"
                        v-bind:VariantProp="'btn btn-sm search-attached'"
                        v-bind:IconClass="'mdi mdi-magnify'"
                        v-on:click="FindProgram">
                      </MdButton>
                    </div>
                  </div>
                </div>
        </div>
      </div>
      </b-row>
    <div class="row">
          <div class="col-md-12">
              <MdTable 
              v-bind:fields="fieldsProgram"
              v-bind:items="itemsProgram"
              v-bind:totalRows="totalRowsprogram"
              v-bind:isReadOnly="false"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPageProgram"
              v-bind:perPage="perPageProgram"
              v-bind:pageOptions="pageOptionsProgram"
              :onFiltered="onFilteredProgram"
              :pageChanged="pageChangedProgram"
              :deleteIconEvent="RemoveProgram"
              v-bind:VisibleDeleteIcon="true"
              :onRowSelected="onRowSelectedProgram"
              :perPageFunction="PerPageFunctionProgram">
            </MdTable>
          </div>
    </div>
<br><br>
                  <!--  <div class="row">
                        <div class="col-md-12">
                            <MdTableGenericFields 
                                v-bind:fields="fieldsTest"
                                v-bind:items="itemTest"
                                v-bind:totalRows="totalRowsprogram"                                
                                v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                                v-bind:currentPage="currentPageProgram"
                                v-bind:perPage="perPageProgram"
                                v-bind:pageOptions="pageOptionsProgram"
                                :onFiltered="onFilteredProgram"
                                :pageChanged="pageChangedProgram"
                                :deleteIconEvent="RemoveProgram"
                                v-bind:VisibleDeleteIcon="true"
                                :onRowSelected="onRowSelectedProgram"
                                :perPageFunction="PerPageFunctionProgram"
                            />
                            
                        </div>
                    </div>
                    -->

        <ModalEmployeeBuilding
            v-bind:TitleWindow="$t('Shared.Building')"          
            @getCodeConditionOK="OnReturnDataEmployeeBuilding($event)"
            v-bind:ModalId="this.modalIdEmployeeBuilding">
        </ModalEmployeeBuilding>

         <ModalEmployeeProgram
            v-bind:TitleWindow="$tc('Shared.Program')"          
            @getCodeConditionOK="OnReturnDataEmployeeProgram($event)"
            v-bind:ModalId="this.modalIdEmployeeProgram">
        </ModalEmployeeProgram>

        <ModalEmployeeDocument
            v-bind:TitleWindow="$t('employee.Document')"          
            @getCodeConditionOK="OnReturnDataEmployeeDocument($event)"
            v-bind:ModalId="this.modalIdEmployeeDocument">
        </ModalEmployeeDocument>

        <ModalEmployeeProviderId
            v-bind:TitleWindow="$t('employee.ProviderId')"          
            @getCodeConditionOK="OnReturnDataEmployeeProvider($event)"
            v-bind:ModalId="this.modalIdEmployeeProvider">
        </ModalEmployeeProviderId>




                        <b-row>
                        <span>-</span>
                        </b-row>

                    </div>
                    <div class="tab-pane fade" id="document" role="tabpanel">
                        
          <b-row>
          <div class="col-sm-12 col-md-3">                      
            <MdButton
              v-bind:Label="$t('PatientDocument.AddDocument')" 
              v-bind:ClassProp="isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
              v-bind:IconClass="'ti-plus pr-1'"              
              v-on:click="CallModalDocument"
              v-bind:isReadOnly="this.isReadOnly">
            </MdButton>
          </div>
          <div class="col-sm-12 col-md-9 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input 
                  type="text" 
                  class="form-control"
                  v-model="searchdocument"   
                  @keyup.enter="FindDocument">
                  <div class="input-group-append">
                    <div class="input-group-append">
                      <MdButton 
                        v-bind:Name="'btnsearchinputDocument'"
                        v-bind:VariantProp="'btn btn-sm search-attached'"
                        v-bind:IconClass="'mdi mdi-magnify'"
                        v-on:click="FindDocument">
                      </MdButton>
                    </div>
                  </div>
                </div>
        </div>
      </div>
      </b-row>
    <div class="row">
          <div class="col-md-12">
              <MdTable 
              v-bind:fields="fieldsDocuments"
              v-bind:items="itemsDocuments"
              v-bind:totalRows="totalRowsdocument"
              v-bind:isReadOnly="false"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPagedocument"
              v-bind:perPage="perPageDocument"
              v-bind:pageOptions="pageOptionsDocument"
              :onFiltered="onFilteredDocument"
              :pageChanged="pageChangedDocument"
              :deleteIconEvent="RemoveDocument"
              v-bind:VisibleDeleteIcon="true"
              :onRowSelected="onRowSelectedDocument"
              :perPageFunction="PerPageFunctionDocument">
            </MdTable>
          </div>
    </div>
                    </div>
                    <div class="tab-pane fade" id="providerid" role="tabpanel">
                        
                        <b-row>
          <div class="col-sm-12 col-md-3">                      
            <MdButton
              v-bind:Label="$t('employee.AddProviderID')" 
              v-bind:ClassProp="isReadOnly ? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
              v-bind:IconClass="'ti-plus pr-1'"              
              v-on:click="CallModalProvider"
              v-bind:isReadOnly="this.isReadOnly">
            </MdButton>
          </div>
          <div class="col-sm-12 col-md-9 mb-4">
            <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
              <div class="input-group">
                <input 
                  type="text" 
                  class="form-control"
                  v-model="searchprovider"   
                  @keyup.enter="FindProvider">
                  <div class="input-group-append">
                    <div class="input-group-append">
                      <MdButton 
                        v-bind:Name="'btnsearchinputProvider'"
                        v-bind:VariantProp="'btn btn-sm search-attached'"
                        v-bind:IconClass="'mdi mdi-magnify'"
                        v-on:click="FindProvider">
                      </MdButton>
                    </div>
                  </div>
                </div>
        </div>
      </div>
      </b-row>
    <div class="row">
          <div class="col-md-12">
              <MdTable 
              v-bind:fields="fieldsProvider"
              v-bind:items="itemsProvider"
              v-bind:totalRows="totalRowsProvider"
              v-bind:isReadOnly="false"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPageprovider"
              v-bind:perPage="perPageProvider"
              v-bind:pageOptions="pageOptionsProvider"
              :onFiltered="onFilteredProvider"
              :pageChanged="pageChangedProvider"
              :deleteIconEvent="RemoveProvider"
              v-bind:VisibleDeleteIcon="true"
              :onRowSelected="onRowSelectedProvider"
              :perPageFunction="PerPageFunctionProvider">
            </MdTable>
          </div>
    </div>


                    </div>  
                    <div class="tab-pane fade" id="signaturecapture" role="tabpanel">
                        <b-row>
                            <b-col md="3" lg="3" sm="12" >
                                <MdCheckBox
                                    v-bind:Label="$t('employee.CreateMedi')"
                                    v-bind:Name="'checkbox-CreateMedications'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="Employee.requestSigCreateMed"/>
                            </b-col> 
                            <b-col md="3" lg="3" sm="12" >
                                <MdCheckBox
                                    v-bind:Label="$t('employee.Mars')"
                                    v-bind:Name="'checkbox-Mars'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="Employee.requestSigMars"/>
                            </b-col>                   
                             <b-col md="3" lg="3" sm="12" >
                                <MdCheckBox
                                    v-bind:Label="$t('employee.ReItems')"
                                    v-bind:Name="'checkbox-ReceivedItems'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="Employee.requestSigReceivedItems"/>
                            </b-col> 
                            <b-col md="3" lg="3" sm="12">
                              <MdCheckBox
                                  v-bind:Label="$t('employee.Tars')"
                                  v-bind:Name="'checkbox-RequestSigTars'"
                                  v-bind:ClassProp="'AlignCheck'"
                                  v-bind:CheckedValue="true"
                                  v-bind:UncheckedValue="false"
                                  v-model="Employee.requestSigTars"/>
                          </b-col>
                            

                        </b-row>
                        <b-row>
                            <b-col md="3" lg="3" sm="12" >
                                <MdCheckBox
                                    v-bind:Label="$t('employee.FillOrders')"
                                    v-bind:Name="'checkbox-fillorders'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="Employee.requestSigFillOrders"/>
                            </b-col>
                            <b-col md="3" lg="3" sm="12" >
                                <MdCheckBox
                                    v-bind:Label="$t('employee.UnFillOrders')"
                                    v-bind:Name="'checkbox-undofillorders'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="Employee.requestSigUndoFillOrders"/>
                            </b-col>
                            <b-col md="3" lg="3" sm="12" >
                                <MdCheckBox
                                    v-bind:Label="$t('employee.ItemsRelocation')"
                                    v-bind:Name="'checkbox-RequestSigItemsRelocation'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="Employee.requestSigItemsRelocation"/>
                            </b-col>
                            <b-col md="3" lg="3" sm="12" >
                                <MdCheckBox
                                    v-bind:Label="$t('employee.ReturnedItems')"
                                    v-bind:Name="'checkbox-RequestSigReturnItems'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="Employee.requestSigReturnItems"/>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="3" lg="3" sm="12">
                                <MdCheckBox
                                    v-bind:Label="$t('employee.RemovedItems')"
                                    v-bind:Name="'checkbox-RequestSigRemoveItems'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="Employee.requestSigRemoveItems"/>
                            </b-col>
                            <b-col md="3" lg="3" sm="12">
                                <MdCheckBox
                                    v-bind:Label="$t('employee.CreateOrders')"
                                    v-bind:Name="'checkbox-RequestSigCreateORDERS'"
                                    v-bind:ClassProp="'AlignCheck'"
                                    v-bind:CheckedValue="true"
                                    v-bind:UncheckedValue="false"
                                    v-model="Employee.requestSigCreateORDERS"/>
                            </b-col>
                      
                            <b-col lg="3" md="3" sm="12">         
                                <MdSelect
                                    v-bind:Label="$t('employee.TySign')"
                                    v-bind:Options="TypeOfSignaturePadList"
                                    v-bind:Model="TypeOfSignaturePadModel"
                                    v-bind:FieldDescription="'name'"
                                    v-bind:FieldCode="'code'"
                                    :IsDisabled="false"
                                    v-bind:Multiple="false"             
                                    :Input="ChangeDropDownTypeOfSignaturePad"/>
                            </b-col>
                        </b-row>
                    </div> 
                </div>  
            </MdTabList>
        </b-col>
             
    </b-row>
       

    <div class="page-actions">      
     <MdButton
               v-bind:Label="$t('employee.List')"
               v-bind:Name="'btnbtn_save_Employeelist'"
               v-bind:ClassProp="'secondary mx-2 btn-login'"
               v-on:click="DiscardChanges"/>    
        <MdButton
            v-bind:Label="$t('Shared.Save')"
            v-bind:ClassProp="(this.isReadOnlyEmployee)?'primary mx-2':'primary btn-login mx-2'"
            v-on:click="SaveOrEdit"
            v-bind:isReadOnly="isReadOnlyEmployee"
            v-bind:Name="'btn_save_Employee'"/>
    </div>

</div>
</template>

<script>


import DBFunctions from '@/common/DBFunctions.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import EmployeeService from '@/services/employee.service'
import EnumService from '@/services/enum.service' 
import GlobalService from '@/services/global.service' 
import Utils from "@/common/utils";
import { EventBus } from '@/event-bus.js';
import moment from 'moment'
//import Utils from '@/common/utils'

let elemSaveBtn = '';
export default {
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();           
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  name: 'Employee',
 mixins: [ShowMessage, DBFunctions,Utils/*,EventBus*/],
  data(){
    return{
        isReadOnly:false,
        parameter:{
            SearchCriteria:'',
            PageNumber : 1,
            PageSize:0,         
        },
        searchdocument:"",
        modalIdEmployeeBuilding:"modalIdEmployeeBuilding",
        modalIdEmployeeProgram:"modalIdEmployeeProgram",
        modalIdEmployeeDocument:"modalIdEmployeeDocument",
        modalIdEmployeeProvider:"modalIdEmployeeProviderId",
        fieldsBuilding:[
            { key: 'buildingCode', sortable: true, label: this.$t('employee.BuilCode'), class: 'text-rigth'},
            { key: 'buildingName', sortable: true, label: this.$t('Shared.BuildingName'), class: 'text-rigth'}
        ],

        fieldsProgram:[
            { key: 'programCode', sortable: true, label: this.$t('Shared.ProgramCode'), class: 'text-rigth'},
            { key: 'programName', sortable: true, label: this.$t('MdPatientRegistrationInfo.ProgramName'), class: 'text-rigth'}
        ],

         fieldsDocuments:[
            { key: 'documentName', sortable: true, label: this.$t('employee.Document'), class: 'text-rigth'},
            { key: 'expirationDate', sortable: true, label: this.$t('employee.TerminateDate'), class: 'text-rigth'},
            { key: 'description', sortable: true, label: this.$t('Shared.Description'), class: 'text-rigth'},
         ],

          fieldsProvider:[
            { key: 'company', sortable: true, label: this.$t('employee.Company'), class: 'text-rigth'},
            { key: 'providerId', sortable: true, label: this.$t('employee.ProviderId'), class: 'text-rigth'}, 
          ],

       /* fieldsTest:[
            {key: 'code', sortable: false, type:"Lookup",label: 'Lookup', class: 'text-rigth'},
            {key: 'name',MaxLength:20, sortable: false, type:"Edit", Mode:"text", label: 'Edit', class: 'text-rigth'},
            {key: 'type',MaxLength:20, sortable: false, type:"DropDown",options:[{code:1,name:"Test1"},{code:2,name:"Test2"}], label: 'DropDown', class: 'text-rigth'},
            {key: 'date',MaxLength:20, sortable: false, type:"Edit",Mode:"date", label: 'Edit', class: 'text-rigth'},
        ],
        itemTest:
        [
            {code:"",name:"",type:"",date:"2024-02-02"},
            {code:"",name:"",type:"",date:"2025-02-02"},
            {code:"",name:"",type:"",date:"2026-02-02"},
        ],*/
        itemsProgram:[],
        TypeOfSignaturePadList:[
        {code:"Signotec",name:"Signotec"},
        {code:"Topaz T-S460-BSB-R",name:"Topaz T-S460-BSB-R"},
        {code:"",name:""}
        ],
        itemsBuilding:[],
        searchprovider:"",
        TypeOfSignaturePadModel:"",
       
        itemsDocuments:[],
        searchprogram:"",
        pageOptionsBuilding:[5,10,15],
        pageOptionsProgram:[5,10,15],
        pageOptionsDocument:[5,10,15],
       
        itemsProvider:[],
        totalRowsProvider:0,
        currentPageprovider:1,
        perPageProvider:10,
        pageOptionsProvider:[5,10,15],
        perPageDocument:10,
        currentPageBuilding:1,
        currentPageProgram:1,
        currentPagedocument:1,
        perPageBuilding:10,
        perPageProgram:10,
        totalRowsBuilding:0,
        totalRowsdocument:0,
        totalRowsprogram:0,
        ShowMessageIsEmpty:false,
        programselect:"", 
        searchBuilding:"",
        ProgramSelectList:[{ value: 'MP', text: this.$t('employee.MProgram') },{ value: 'OP', text: this.$t('employee.OProgram') }],   
        dtaxonomy:"", 
        HiredDate:"",
        credentialsModel:"", 
        TerminateDate:"",
        StatusList:[{name:this.$t('Shared.Active'),code:"Active"},{name:this.$t('employee.Terminated'),code:"Terminated"}],
         StatusModel:{name:this.$t('Shared.Active'),code:"Active"},
         CosignerList:[{name:"None",code:"None"},{name:this.$t('employee.NeedCosigner'),code:"NeedCosigner"},{name:this.$t('employee.IsCosigner'),code:"IsCosigner"}],
         CosignerModel:{name:"None",code:"None"},
        GenderList: EnumService.SexsMaleFemaleOnly,
        isHiredDateValid:true,
        isTerminateValid:true,
       Employee:{
        code:"",
        description:"",
        type:"", 
        empId:"", 
        firstname:"",
        lastname:"",  
        initial:"",  
        address1:"",
        address2:"",
        zip:"",
        city:"",
        state:"",
        country:"",
        county:"",
        fax:"",
        ssn:"",
        dob:null,
        gender:"",
        taxonomy:"",
        userId:"",
        TerminateDate:null,
        emp_Prov:"",
        npi:"",
        employeesignature:"",
        daysBeforeAppt:0,
        daysAfterAppt:0,
        requiredLocation:false,
        enforceSelectProgram:false,
        showOnlyPatientFromScheduler:false,
        isSelfDriver:false,
        requestSigCreateMed:false,
        requestSigMars:false,
        requestSigReceivedItems:false,
        requestSigFillOrders:false,
        requestSigUndoFillOrders:false,
        requestSigItemsRelocation:false,
        requestSigReturnItems:false,
        requestSigRemoveItems:false,
        requestSigCreateORDERS:false,
        requestSigTars:false,

       },
       Menu : this.$store.state.leftMenu,
       permission:[],
       isDOBValid: true,
       isReadOnlyCosignerInsurance:true,
       isReadOnlyTerminateDate:true,
     tablist: [
         { title: this.$t('Shared.General'), link: '#general', active: true},
         { title: this.$t('employee.Document'), link: '#document'},
         { title: this.$t('employee.ProviderId'), link: '#providerid'}, 
         { title: this.$t('employee.SigCapture'), link: '#signaturecapture'},          
         ], 
         TypeList:[],
         EthnicityList:/*this.$store.state.ethnicityList*/[],
         CellCompanyList:[],
         CredentialsList:[],
         CosignerInsuranceList:[],
         TypeModel:"",
         CosignerInsuranceModel:"",
         ethnicityModel:"",
         dUserId:"",
         cellCompanyModel:"",
       pendingChanges:false,
        isReadOnlyEmployee:false,
      isEmployeeValid:true,
      CodeExist:false,
        OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },
      emailValid:false,
      reg: /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/
    }
  },

  methods:{

    DiscardChanges(){
       this.checkExistFavorite('/app/employees');
       this.$router.push({ name: "employeeList" });
    },

      
  CanView(){
      return this.permission[0].roleCanView;
  },

  CallModalProvider(){
    this.$bvModal.show(this.modalIdEmployeeProvider);   
  },

  FindProvider(){

  },

  onRowSelectedDocument(){

  },

  OnReturnDataEmployeeDocument(){
    },

    OnReturnDataEmployeeProvider(){

    },

  PerPageFunctionDocument(){

  },
  ChangeDropDownTypeOfSignaturePad(){
    
  },

  FindDocument(){

  },

  pageChangedDocument(){

  },

  onFilteredDocument(){

  },

  RemoveDocument(){

  },

  CallModalDocument(){
    this.$bvModal.show(this.modalIdEmployeeDocument);   
  },

  OnReturnDataEmployeeBuilding(data){  
   this.itemsBuilding.push(data);   
  },

  OnReturnDataEmployeeProgram(data){
     this.itemsProgram.push(data);  
  },

  onFilteredProvider(){
},
pageChangedProvider(){
},
RemoveProvider(){
},
onRowSelectedProvider(){
},
PerPageFunctionProvider(){
},

  ChangeDropDownCosigner(e){
    //this.adm.admissionsource = e !== null ? e.code : "";  
      if(e !== null && e.code === "NeedCosigner"){         
         this.isReadOnlyCosignerInsurance = false;         
      }else{
         this.isReadOnlyCosignerInsurance = true;
      }   
  },

  ChangeDropDownCosignerInsurance(){
    /*if(e !== null && e.code === "Terminated"){         
         this.isReadOnlyCosignerInsurance = false;         
      }else{
         this.isReadOnlyCosignerInsurance = true;
      }  */
  },

  PerPageFunctionBuilding(){

  },

    getPosListBuilding(id){
        for(var i = 0; i < this.itemsBuilding.length; i++){
           if(this.itemsBuilding[i].buildingCode === id){
              return i;              
           }
        }
        return -1;
     },
     getPosListProgram(id){
        for(var i = 0; i < this.itemsProgram.length; i++){
           if(this.itemsProgram[i].programCode === id){
              return i;              
           }
        }
        return -1;
     },

   

  RemoveBuilding(e){
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
        title: this.$tc('Shared.DelRecord'),
        okTitle: this.$t('Shared.TitleDelete'),
        cancelTitle: this.$t('Shared.BtnCancel'),
        footerClass: 'p-2',
        titleClass: 'modal-title modal-type-warning'
      })
      .then(value => {
        if(value) {
       var i = this.getPosListBuilding(e.buildingCode);
        if(i !== -1){
           this.itemsBuilding.splice(i, 1);          
           this.pendingChanges = true;
           this.totalRowsBuilding = this.itemsBuilding.length;        
        }
        }
      })
},

pageChangedBuilding(){
},

onFilteredBuilding(){
},

  onRowSelectedBuilding(){

  },

  CallModalBuilding(){     
      this.$bvModal.show(this.modalIdEmployeeBuilding);      
  },

  FindBuilding(){

  },

  CallModalProgram(){
    this.$bvModal.show(this.modalIdEmployeeProgram); 
},

FindProgram(){
},

onFilteredProgram(){
},

pageChangedProgram(){
},

RemoveProgram(e){
    this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
        title: this.$tc('Shared.Program'),
        okTitle: this.$t('Shared.TitleDelete'),
        cancelTitle: this.$t('Shared.BtnCancel'),
        footerClass: 'p-2',
        titleClass: 'modal-title modal-type-warning'
      })
      .then(value => {
        if(value) {
       var i = this.getPosListProgram(e.programCode);
        if(i !== -1){
           this.itemsProgram.splice(i, 1);          
           this.pendingChanges = true;
           this.totalRowsprogram = this.itemsProgram.length;        
        }
        }
      })
},

onRowSelectedProgram(){
},

PerPageFunctionProgram(){
},

  ChangeDropDownStatus(e){    
    if(e !== null && e.code === "Terminated"){         
         this.isReadOnlyTerminateDate = false;         
      }else{
         this.isReadOnlyTerminateDate = true;
         this.Employee.TerminateDate = "";
         this.TerminateDate = "";
      }  
  },

  ChangeDropDownCredentials(){

  },

 ChangeDropDownEthnicity(){

  },
  ChangeDropDownType(){

  },

  ChangeDropDownCellCompany(){

  },

  onCodeConditionOk(){

  },
 formatterDate(value){
         return Utils.formatterDateToString(value);
    },

  getDefaultDateTime() {         
            GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {                
                  this.HiredDate = this.formatterDate(response.data); 
                  this.Employee.hiredDate = this.formatterDate(response.data); 
               }  
         })
         .catch(error => {
            if (error.response) {
               this.showMessage(this.$tc('Shared.Employee'),error,"error");
            }
         });
      },

    AddEmployee(){
      if(!this.CodeExist){
      this.$emit('load', true);
      EmployeeService.Add(this.Employee)
                .then((response) => {
                    if(response.status == '200'){
                        this.Employee = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);                       
                        this.$router.push({name: "employeeList"}).catch(()=>{});                      
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                             
                })				
                .catch((error) => {   
                    if (!error.response) {
                   this.showMessage(this.$tc('Shared.Employee'),this.$t('Msg.retrieveInfo'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$tc('Shared.Employee'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$tc('Shared.Employee'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$tc('Shared.Employee'),error.response.data.errorMessage,"error");
                     }
                     elemSaveBtn.removeAttribute("disabled"); 
                    this.$emit('load', false);
                }); 
      }else{
        this.showMessage(this.$tc('Shared.Employee'),this.$t('employee.EmplCodeExist'),"error");
      }
    },

     OnCodeBlur(value){           
          if(!this.$attrs.editMode){           
            if (value.trim()!== '') {          
            var parameter = {
               "code":value
            };
            EmployeeService.getEmployeeByCode(parameter)
                .then((response) => {
                    if(response.status == '200' && response.data){                     
                       if(response.data.length > 0){
                          this.showMessage(this.$tc('Shared.Employee'),this.$t('employee.EmplCodeExist'),"error");
                          this.CodeExist = true;
                       }else{
                           this.CodeExist = false;
                       } 
                    }  
                     this.$emit('load', false);                          
                })
                .catch((error) => {
                    this.$emit('load', false);                  
                  if(error.response.status !== 404 && error.response.status !== 500){
                      if(error.response) {
                        this.showMessage(this.$tc('Shared.Employee'),error,"error");
                    }
                  }
                    
                });  
            }
               }
    
       },


    EditEmployee(){
      this.$emit('load', true); 
      EmployeeService.Update(this.$route.params.id,this.Employee)
                .then((response) => {
                    if(response.status == '200'){
                       // this.Employee = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);                     
                        this.$router.push({name: "employeeList"}).catch(()=>{});                      
                        setTimeout(() => 
                        this.$bvToast.toast(this.$tc('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                             
                })
                .catch((error) => {   
                    if (!error.response) {
                   this.showMessage(this.$tc('Shared.Employee'),this.$t('Msg.NotAbleToSave'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$tc('Shared.Employee'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$tc('Shared.Employee'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$tc('Shared.Employee'),error.response.data.errorMessage,"error");
                     }
                     elemSaveBtn.removeAttribute("disabled"); 
                    this.$emit('load', false);
                }); 
    },

  getEmployee(){
      if(this.$attrs.editMode){
      this.$emit('load', true);     
      EmployeeService.Get(this.$route.params.id)
        .then((response) => {
            if(response.status == '200' && response.data){
              this.Employee = response.data; 
              if(this.Employee.employeesignature){
                EventBus.$emit('onSignatureLoad', this.Employee.employeesignature);   
              } 
            }   
              this.$emit('load', false);                     
        })
        .catch((error) => {                    
            if (!error.response) {
              this.showMessage(this.$tc('Shared.Employee'),this.$t('Msg.NotAbleToSave'),"error");
            }                      
            else if(error.response.status === 400 ){
              this.showMessage(this.$tc('Shared.Employee'),error.response.data.errorMessage,"error");
            }
            else if(error.response.status === 405 ){             
                  this.$router.push({name: "Main"});    
                    setTimeout(() => 
                        this.showPermissionMessage()
                        , 50);            
            } 
            else if(error.response.status === 500){
              this.showMessage(this.$tc('Shared.Employee'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
              this.showMessage(this.$tc('Shared.Employee'),error.response.data.errorMessage,"error");
            } 
            this.$emit('load', false);            
        }); 
    
      }
    },

    PrepareData(){  
     // this.Employee.dob = this.Employee.dob ? moment(this.HiredDate).format(): null; 
      this.Employee.hiredDate = this.HiredDate ? moment(this.HiredDate).format(): null; 
    },

      DoAction(){     
        !this.$attrs.editMode ? this.AddEmployee() : this.EditEmployee(); 
      },

      SaveOrEdit(){ 
        if(this.validate_entries()){
         if (this.validate_required()) {
            this.PrepareData();
            this.DoAction();
         } else {
            this.showMessage(this.$tc('Shared.Employee'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");          
         }
        } else {
            this.showMessage(this.$tc('Shared.Employee'),this.$t('Msg.InvalidFieldValuesDetected'),"errorAutoHide");
         }
      },

      validate_required() {
        this.isEmployeeValid = this.Employee.code.length > 0 ? true : false;   
        return this.isEmployeeValid;
      },
      validate_entries() {
         this.emailValid = (this.reg.test(this.Employee.email)) ? true : false;
         if( this.emailValid) {
               return true;
            } else {
               return false;   
            }
      },
       checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
      }
  },

  created(){
    this.getDefaultDateTime();
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);

    EventBus.$on("OnSubmitBase64", function (base64) {       
          this.Employee.employeesignature = base64;
     }.bind(this));  
    EventBus.$on("onChanged", function () {       
          this.checkUserAction();
     }.bind(this)); 
  },

  mounted(){
      this.OptionsRightMenu.ItemsList = [];
      let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
      this.getEmployee();
      setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_Employee');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );   
    
  },

    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
   
};
</script>


