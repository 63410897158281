import ApiService from '@/services/api.service'

const RaceService = {
    getAll( ) {    
      return ApiService.get('/races');      
    },
  
    get( id) {
      return ApiService.get(`/races/${id}`);
    },
  
    update(id, race) {
      const requestData = {
        method: 'put',
        url: "/race/",
        headers: {},
        params: { 
          id: id,
          race : race,
        }
      }
      return ApiService.customRequest(requestData);
    },
    
  }
  
  export default RaceService;