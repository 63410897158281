import ApiService from '@/services/api.service'

const QuickService = {
    getQhUserPreference( id) { 
      const requestData = {
        method: 'get',
        url: "/UserPreferences/",
        headers: {
        },
        params: { 
          UserId: id          
        }
      }     
      return ApiService.customRequest(requestData);         
    },
    
    addQhUserPreference(data){
      return ApiService.post(`/UserPreferences`,data)
    },

    updateQhUserPreference(recNo,data){
      return ApiService.put(`/UserPreferences/${recNo}`,data)
    },

    UpdateLastDailyNotes(recNo,data){
      return ApiService.put(`/UserPreferences/LastDailyNotes/${recNo}`,data)
    },

    UpdateLastLogNotes(recNo,data){
      return ApiService.put(`/UserPreferences/LastLogNotes/${recNo}`,data)
    }
    
}
  
  export default QuickService;