<template> 
<div :id="targetId" oncontextmenu="return false;">
  <div v-if="!this.$attrs.control.isReadOnly && !NoteIsVoid && !this.$attrs.IsChartReview && !this.isReadOnly">
    <ejs-contextmenu :id="targetId" :target="menuItems.length > 0 ? menuTargetId : null" :items='menuItems' :select='onSelect'></ejs-contextmenu>
  </div>
  <select
    v-bind:style="style"
    class="cursor-context-menu" 
    :ControlId="'select-'+controlId" 
    v-model="selected" 
    v-on:change="OnChange($event)"
    v-b-tooltip.hover :title="Hint"
    :disabled="ReadOnly || NoteIsVoid || this.$attrs.control.IsChartReview"
    :id="controlId">
 
    <option v-for="item in options" :key="item" :value="item">{{item}}</option>   
  </select>
  <!-- <VueContext ref="menu">
      <a class="dropdown-item" @click="showModalGetLastAnswers()">
        Get Last 10 Answers
      </a>
  </VueContext> -->
  <MdGetLastAnswers v-if="showGetLastAnswers"
    v-bind:ShowPopup="showGetLastAnswers"
    @getCancel="onCancelGetLastAnswers($event)"
    @getOK="onOKGetLastAnswers($event)"
    v-bind:ControlId="controlId"
    v-bind:PatientCode="PatientCode"
    v-bind:TransDate="TransDate"
  />
</div>
</template>

<script>
  import { EventBus } from '../../event-bus.js';
  import Vue from 'vue';
  import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";

  Vue.use(ContextMenuPlugin);
export default {
  props: {
    value: [String,Array],
    TransDate: String,
    NoteId: String,
    id: [String, Number],
    isReadOnly: Boolean,
    PatientCode: String,
    IsCommonPart: Boolean 
  },
mixins: [EventBus],
  data() {
    return {
      options: this.$attrs.control.allValues,
      NoteIsVoid:false,
      controlId:String(this.$attrs.control.controlID),
      selected:this.value,
      style:{
        position:'absolute',
        top: this.$attrs.control.controlTop+'px',
        width:this.$attrs.control.controlWidth+'px',
        left:this.$attrs.control.controlLeft+'px',
        height:this.$attrs.control.controlHeight+'px',
        borderColor: '#dc3545',
        fontSize:'',
        fontWeight: '',
        fontFamily:'',
        color:'',
        background:'',
        textDecorationLine:'',
        fontStyle:'',       
        borderStyle: this.$attrs.control.isRequired ==='Y' ? 'solid' : 'none',
        border: '1px solid #fff !important',
        borderBottom: '1px solid #ced4da !important'
      },
      DataValue: this.value,
      ReadOnly:this.$attrs.control.isReadOnly || this.isReadOnly ? true : false,
      Hint: this.$attrs.control.controlHint,
      showGetLastAnswers: false,
      //PatientCode: this.$route.params.id,
      isRequired: this.$attrs.control.isRequired,
      menuItems:[],
      targetId: "target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
      menuTargetId:"#target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID
    }
  },

  methods: {
    OnChange(event) {
      this.DataValue = event.target.value;
      this.$emit('change', event.target.value);
      this.$emit('input', event.target.value);
      this.style.border = event.target.value.length == 0 && this.isRequired ? 'solid 1px #dc3545 !important': 'solid 1px #fff !important'
      this.setChangedStatus()
    },

    InitializeVariables(){
       if(this.$attrs.control.controlFontForHtml){
          this.controlFont = this.$attrs.control.controlFontForHtml.split(',');
          var size = parseInt(this.controlFont[1]) + 2;
          this.style.fontFamily = this.controlFont[0];
          this.style.fontSize = size+'px !important';
          this.style.color = this.controlFont[3];         
          var Decorations = this.controlFont[2];
          this.style.fontWeight = Decorations.charAt(0) === '1'?'bold':'';
          this.style.fontStyle = Decorations.charAt(1) === '1'?'italic':'';
          this.style.textDecorationLine = Decorations.charAt(2) === '1'?'underline':'';
          this.style.textDecorationLine = Decorations.charAt(3) === '1'?this.style.textDecorationLine+ ' line-through':this.style.textDecorationLine;
       }
    },

    showModalGetLastAnswers() {
      this.showGetLastAnswers = true
    },

    onOKGetLastAnswers(value) {
      this.selected = value;
      this.$emit('input', value);
      this.setChangedStatus()
      this.showGetLastAnswers = false
    },

    onCancelGetLastAnswers(){
      this.showGetLastAnswers = false
    },

    handlerClick(evt){
      if(!this.ReadOnly) {
        this.$refs.menu.open(evt)
      }
    },

    setValid(state){
        this.style.border = state ? 'solid 1px #dc3545 !important': 'solid 1px #fff !important'
    },

    onSelect: function(args ) {
       if(args.item.id === 'gettenlastans') {
         this.showModalGetLastAnswers()
       }
    },

    loadMenuItems() {
      this.menuItems = []
      if (!this.IsCommonPart) {
        this.menuItems.push({text: this.$t('Shared.GetLast10'), id: "gettenlastans"})
      }
    },

    setChangedStatus(){
      var changed = {
        "ControlId":this.$attrs.control.controlID,
        "Changed":true,
        "NoteId":this.NoteId
      }
      EventBus.$emit('onControlWasChanged', changed);
    }
},

    created(){
       EventBus.$on("onSetReadOnly", function (e) {
      this.NoteIsVoid = e;
      }.bind(this));
  if(this.$attrs.NoteIsVoid){
    this.NoteIsVoid = this.$attrs.NoteIsVoid;
  }

},

  mounted() {
    this.options.unshift('')
    this.InitializeVariables();
    this.loadMenuItems()
  }
}

</script>
<style src="@/assets/css/clinical/multiselect.css">
@import "../../../assets/css/flex.css";
.scroll{
	overflow: hidden !important;
}
</style>