<template>
    <div class="row">
		<div class="col-12">
			<div class="accordion" role="tablist">
				<b-card no-body class="noteSearchPanel mb-0">
					<b-card-header
						header-tag="header"
						class="p-0"
						role="tab">
						<b-button
							block						
							class="collapseBTN text-truncate"
							v-on:click="Accordion1Click"
							><b-icon
								icon="arrow-down-right-circle"
								aria-hidden="true"
							></b-icon>
							{{ $t('ClinicalNotes.CLINICALNOTES') }}
							</b-button>
					</b-card-header>
					<b-collapse
						id="accordion-1"
						visible
						accordion="ClinicalNotes"
						:class="this.ClassAccordion1"
						role="tabpanel"
						v-show="ShowFilter && !ShowNote"
						>
						<b-card-body class="p-1">
							<b-card-text class="notesPanelsClinical cutescroll">
                                <div id="ClinicalNotesContainer">   
                                    <!-- <MdTitle v-bind:Text="$t('ClinicalNotes.CLINICALNOTES')" /> --> 
                                         <b-row>
                                             <b-col md="3" lg="3" sm="12">
                                                 <MdEdit 
                                                     v-bind:Label="$t('Shared.datefrom')" v-bind:TypeProp="'date'" v-bind:Name="'DateFrom'" v-model="DateFrom"
                                                     v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'" v-on:change="DateFromChange($event)"
                                                     v-bind:Id="'input_ClinicalNoteContainerdate_from'">                                     
                                                 </MdEdit>
                                             </b-col>
                                             <b-col md="3" lg="3" sm="12">
                                                 <MdEdit 
                                                     v-bind:Label="$t('Shared.dateTo')" v-bind:TypeProp="'date'" v-bind:Name="'DateTo'" v-model="DateTo"
                                                     v-bind:Min="'1900-01-01'" v-bind:Max="'2200-12-31'"	v-on:change="DateToChange($event)"
                                                     v-bind:Id="'input_ClinicalNoteContainerdate_to'">                                     
                                                 </MdEdit>
                                             </b-col>
                                             <b-col md="3" lg="3" sm="12">
                                                 <MdSelect
                                                     v-bind:Label="LabelTherapies" v-bind:Options="OptionTherapies" v-bind:Model="TherapiesVModel"
                                                     v-bind:Placeholder="selectPlaceholder" v-bind:FieldDescription="'name'" v-bind:Multiple="false"	
                                                     v-bind:FieldCode="'code'" :Input="ChangeDropDownTherapies" 
                                                     v-bind:Name="'therapies_ClinicalNoteContainerselect'" id="therapies_ClinicalNoteContainerselect">
                                                 </MdSelect>
                                             </b-col>
                                             <b-col md="3" lg="3" sm="12">
                                                 <MdSelect
                                                     v-bind:Label="$t('ClinicalNotes.NoteTypes')" v-bind:FieldDescription="'description'" v-bind:FieldCode="'notesid'"
                                                     v-bind:Options="OptionNoteTypes" v-bind:Model="NoteTypesVModel"	v-bind:Placeholder="selectPlaceholder"
                                                     v-bind:Multiple="true" :Input="ChangeDropDownNoteTypes"	v-bind:Name="'note_ClinicalNoteContainertypes_select'"
                                                     id="note_ClinicalNoteContainertypes_select">
                                                 </MdSelect>
                                             </b-col>
                                         </b-row>
                                 
                                         <b-row>
                                             <b-col md="3" lg="3" sm="12">
                                                 <MdSelect
                                                     v-bind:Label="$t('ClinicalNotes.Therapist')" v-bind:FieldDescription="'description'" v-bind:FieldCode="'code'"
                                                     v-bind:Options="OptionTherapist" v-bind:Model="TherapistVModel" v-bind:Placeholder="selectPlaceholder"
                                                     v-bind:Multiple="true" :Input="ChangeDropDownTherapist" v-bind:Name="'Therapist_ClinicalNoteContainer_select'"
                                                     id="Therapist_ClinicalNoteContainer_select">
                                                 </MdSelect>
                                             </b-col>
                                             <b-col md="3" lg="3" sm="12">
                                                 <MdSelect
                                                     v-bind:Label="$t('ClinicalNotes.Programs')" v-bind:FieldDescription="'description'" v-bind:FieldCode="'code'"
                                                     v-bind:Options="OptionPrograms" v-bind:Model="ProgramsVModel" v-bind:Placeholder="selectPlaceholder"
                                                     v-bind:Multiple="true" :Input="ChangeDropDownPrograms" v-bind:Name="'Programs_ClinicalNoteContainer_select'"
                                                     id="Programs_ClinicalNoteContainer_select">
                                                 </MdSelect>
                                             </b-col>
                                             <b-col md="3" lg="3" sm="12">
                                                 <MdSelect
                                                     v-bind:Label="$t('ClinicalNotes.Patients')" v-bind:FieldDescription="'description'" v-bind:FieldCode="'code'"
                                                     v-bind:Options="OptionPatients" v-bind:Model="PatientsVModel" v-bind:Placeholder="selectPlaceholder"
                                                     v-bind:Multiple="true" :Input="ChangeDropDownPatients" v-bind:Name="'Patients_ClinicalNoteContainer_select'"
                                                     id="Patients_ClinicalNoteContainer_select">
                                                 </MdSelect>
                                             </b-col>
                                             <b-col md="3" lg="3" sm="12">
                                                 <MdSelect
                                                     v-bind:Label="$t('ClinicalNotes.NoteStatus')" v-bind:Options="OptionNoteStatus"	v-bind:ModelArray="NoteStatusVModel"
                                                     v-bind:Placeholder="selectPlaceholder" v-bind:FieldDescription="'name'"	v-bind:Multiple="false"	v-bind:FieldCode="'value'"
                                                     v-bind:IfUsevalidation="false" :Input="ChangeDropDownNoteStatus" v-bind:Name="'notestatusClinicalNoteContainer_select'"
                                                     id="notestatusClinicalNoteContainer_select">
                                                 </MdSelect>
                                             </b-col>
                                         </b-row>
                                 
                                         <b-row>
                                             <b-col md="12">
                                                 <div class="text-center text-md-right mt-3">
                                                     <button 
                                                         v-on:click="SearchNotes"
                                                         type="button"
                                                         class="btn btn-primary btn-login mx-2 pull-right"												
                                                         id="btnsearchinput">
                                                         {{ $t('Shared.Search') }}
                                                     </button>
                                                 </div>
                                             </b-col>
                                         </b-row>
                                        
                                    </div>
								<b-row>
									<b-col sm="12" lg="12" md="12">
										<MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
										<MdTable
											v-bind:fields="fields"
											v-bind:items="items"
											v-bind:totalRows="totalRows"
											v-bind:useAddTime="true"
											v-bind:totalRowsSubItems="totalRowsSubItems"
											v-bind:showMessageIsEmpty="
												ShowMessageIsEmpty
											"
											v-bind:isReadOnly="false"
											v-bind:selectMode="
												selectMode
											"
											v-bind:currentPage="
												currentPage
											"
											v-bind:currentPageSubItems="currentPageSubItems"
											v-bind:perPage="perPage"
											v-bind:perPageSubItems="perPageSubItems"
											v-bind:pageOptions="
												pageOptions
											"
											v-bind:pageOptionsSubItems="
												pageOptionsSubItems
											"
											v-bind:refTable="refTable"
											
											:onRowSelected="
												onRowSelected
											"
											:onFiltered="onFiltered"
											:pageChanged="pageChanged"
											:pageChangedSubItems="pageChangedSubItems"
											v-bind:ShowDetails="true"
											:perPageFunction="
												PerPageFunction
											"
											:perPageFunctionSubItems="
												PerPageFunctionSubItems
											"
											:avisar="onResultados"
											:CheckClick="onCheck"
											:NotePatientInfo="PatientInfo"
										>
										</MdTable>
									</b-col>
								</b-row>
							</b-card-text>
						</b-card-body>
					</b-collapse>
				</b-card>

				<b-card no-body class="openNotesPanel mb-0">
					<b-card-header
						header-tag="header"
						class="p-0"
						role="tab">
						<b-button
							block							
							class="collapseBTN"
							v-on:click="ClickBtnAccordion2"
							><b-icon
								icon="arrow-down-right-circle"
								aria-hidden="true"
							></b-icon>
							{{ $t('ClinicalNotes.OPENNOTES') }}</b-button>
					</b-card-header>
					<b-collapse
						id="accordion-2"
						accordion="ClinicalNotes"
						role="tabpanel"
						:class="this.ClassAccordion2"
						v-show="ShowNote && !ShowFilter"
						ref="a2">
						<b-card-body class="p-1">
							<b-card-text class="notesPanels">
								<!-- OPEN NOTES CODE -->
								<div :class="this.ClassContainerNotes" ref="ContainerNotes">
								<MdNote
									v-for="(model, index) in this.ClinicalNotesListOpened"
									v-bind:NOTE="model.description"										
									v-bind:TransDate="model.date"
									v-bind:Time="model.Time"
									v-bind:EventScheduler="false"
									v-bind:Location="'ClinicalNotes'"
									v-bind:Header="model"
									v-bind:IsChartReview="false"
									v-bind:IsClinicalNote="true"
									v-bind:ProgramCode="ProgramCode"
									v-bind:CategoryCode="TherapiesVModel"									
									:key="model.Id"
									:id="model.Id"
									v-on:remove="CloseNote(index)"
									:model="model"
									v-bind:NotePatientInfo="PatientInfo">
								</MdNote>
								</div>
								<!-- END OPEN NOTES CODE -->
							</b-card-text>
						</b-card-body>
					</b-collapse>
				</b-card>
			</div>
		</div>
	</div>
</template>

<script>
import moment from "moment";
import EnumService from "@/services/enum.service";
import DBFunctions from '@/common/DBFunctions.js'
//import LocalStorage from '@/services/storage.service'
//import GlobalService from '@/services/global.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
//import Utils from "@/common/utils";
import { EventBus } from '@/event-bus.js';

export default {
  name: 'ClinicalNotesContainer',
  mixins: [DBFunctions,ShowMessage],
  data(){
    return{
        ClassAccordion1:'',
		ClassAccordion2:'',
        pageOptions: [5, 10, 15],
		pageOptionsSubItems: [5, 10, 15],
		totalRows: 0,
		totalRowsSubItems: 0,
		currentPage: 1,
		currentPageSubItems: 1,
		perPage: 10,
		perPageSubItems: 5,
		filter: null,
        items: [],
        ShowNote:false,
		ShowFilter:true,
		selectMode: "single",
        refTable: "selectableTable",
		CountNotesOpened:0,
        ShowMessageIsEmpty: false,
		fields: [
			{ key: 'show_details', label: this.$t('ClinicalNotes.ShowDetails'), sortable: true},
			{ key: 'description', label: this.$t('Shared.Description'), sortable: true},
			{ key: 'frequency', label: this.$t('Shared.Frequency'), sortable: true},
			{ key:'notesId', sortable: true, class:'hidden'},			
			{ key: 'isGroup', label: this.$t('ClinicalNotes.IsGroup'), sortable: true, formatter: value => {return value ? 'Y' : 'N'}},				//{key:'HeaderId',sortable: true, class:'hidden'},
		],
        DateFrom: moment(this.NewDate).format("YYYY-MM-DD"),
		DateTo: moment(this.NewDate).format("YYYY-MM-DD"),
        TherapiesVModel: "",
        LabelTherapies:'',
        ClassContainerNotes:'',
        OptionTherapies:[],
        ClinicalNotesListOpened:[],
        OptionNoteTypes:[],
        OptionPrograms:[],
        OptionTherapist:[],
        OptionPatients:[],
        OptionNoteStatus: EnumService.NoteStatus,
        NoteTypesVModel:"",
        TherapistVModel:"",
        ProgramsVModel:"",
        PatientsVModel:"",
        NoteStatusVModel:{value:0,name: this.$t('Shared.All')},
        selectPlaceholder: this.$t('ClinicalNotes.SelectOptions'),
        OptionsRightMenu: {
			visible: true,
			btnCNContainervisible: true,
			ItemsList: [],
			btnvisible: false,
			itemdisabled: false,
			PatientCode: ''
		},
    }
  },

  methods:{
    DateFromChange(){

    },
    DateToChange(){

    },
    ChangeDropDownTherapies(){

    },
    ChangeDropDownNoteTypes(){

    },
    ChangeDropDownTherapist(){

    },
    ChangeDropDownPrograms(){

    },
    ChangeDropDownPatients(){

    },
    ChangeDropDownNoteStatus(){

    },
    onCheck(param){	
		this.NotesId = param.notesId;
		this.currentPageSubItems = param.currentPage;
		this.perPageSubItems = 5;
		//this.getNoteAllDate(this.NotesId,this.currentPageSubItems,this.perPageSubItems);
	},
    onResultados(obj) {
		this.$emit('load', true);		
		obj.DateFrom = this.RegistrationInfo.dateFrom ? moment(this.RegistrationInfo.dateFrom).format(): null;
		this.ExistsNote = this.ClinicalNotesListOpened.some(nItem => ( ((nItem.notesId === obj.notesId) && (nItem.Id === obj.Id)) ||
																		(((nItem.frequency != 'DAILY MULTIPLES') && (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate)) ||
																		((nItem.frequency === 'DAILY MULTIPLES') && (nItem.notesId === obj.notesId) && (nItem.transDate === obj.transDate) && (nItem.noteTime === obj.noteTime)))));																	  
		
		if (!this.ExistsNote) {
			this.AdjustNoteContainer("ADD");
			this.ClinicalNotesListOpened.push(obj);
		}
		
		this.ClassAccordion2 = 'show';
        this.ShowNote = true;
		this.ShowFilter = false;
		// a short time for loading wheel
		setTimeout(() => this.$emit('load', false), 500);
	},   
    async getNoteOption(){
		this.NoteOptions = await this.getNoteOptions();   
		this.LabelTherapies = this.NoteOptions.useCategory?this.$t('ClinicalNotes.CATEGORIES'):this.$t('ClinicalNotes.THERAPIES');
    },
    onRowSelected(items) {
      this.selected = items
    },
    SearchNotes(){

    },
    pageChanged(page) {            
        this.currentPage = page;
		this.parameter.PageNumber = this.currentPage;        
      //this.getNoteList();
    },
	pageChangedSubItems(e) {            
      this.currentPageSubItems = e.page;	
      //this.getNoteAllDate(e.NoteId,this.currentPageSubItems,this.perPageSubItems);
    },

    PerPageFunctionSubItems(e){
		this.perPageSubItems = e.page;     
		//this.getNoteAllDate(e.NoteId,1,this.perPageSubItems);		
    },

    PerPageFunction(perpage){
        this.perPage = perpage;
        this.parameter.PageNumber = 1;
     // this.getNoteList();
    },
    onFiltered(filteredItems) {      
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    Accordion1Click(){
		this.ShowFilter = !this.ShowFilter;
		this.ShowNote = !this.ShowNote;	
	},
    ClickBtnAccordion2(){
		this.ShowFilter = !this.ShowFilter;
		this.ShowNote = !this.ShowNote;	
	},

  },

  async created(){
   await this.getNoteOption();
  },

  mounted(){
    let layoutStatus = this.$store.state.layoutStatus;
		this.OptionsRightMenu.btnCNContainervisible = true;
		//this.OptionsRightMenu.ItemsList = this.$store.state.itemListGroupNotes;     
		switch (layoutStatus) {
			case 'L0R0':
				this.$store.commit('setlayoutStatus', 'L0R1');
				break;
			default:
				this.$store.commit('setlayoutStatus', 'L1R1');
		}
		this.$emit('onMenuRightBarCNContainer', this.OptionsRightMenu);



    EventBus.$emit('onPatientClickClinicalNotesContainer', null);

  },

  destroyed() {
		this.$store.commit('setItemListCNContainer', []);
		this.OptionsRightMenu.ItemsList = [];
		this.OptionsRightMenu.Mode = false;
		this.OptionsRightMenu.visible = false;
		this.OptionsRightMenu.btnCNContainervisible = false;
		this.$emit('onMenuRightBarCNContainer', this.OptionsRightMenu);
  }

   
};
</script>