<template> 
      <fragment>
        <div :id="targetId" oncontextmenu="return false;">
          <div v-if="!isReadOnly && cmpLoaded">
            <ejs-contextmenu :id="targetId" :target="menuTargetId" :items='menuItems' :select='onSelect'></ejs-contextmenu>
          </div>  
          <div :class="this.classValue" :data-label="Label+ this.invalidFeedback" :id="'dv_'+Id">
            <input
                v-mask="this.Mask"
                :type="TypeProp"
                :maxlength="MaxLengthProp"          
                :readonly="isReadOnly"
                :min="Min"
                :max="Max"
                :step="StepProp"
                :ref="Name"
                :id="Id"
                class="form-control" 
                :placeholder="Placeholder"
                :autocomplete="autocomplete"
                v-on:blur="onBlur"
                v-on:change="OnChange"
                v-on:keyup.enter="PressEnter"
                :aria-describedby="Name"
                v-bind:value="value"
                v-on:keypress="$emit('keypress', $event)"
                v-on:input="onInput"
                v-on:focus="$emit('focus', $event.target.value)"
                >
          </div>
        </div>      
     </fragment>         
</template>

<script>
import Utils from '../../../common/utils'
import { EventBus } from '@/event-bus.js';
import Vue from 'vue';
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";
Vue.use(ContextMenuPlugin);

export default {
  name: 'MdEdit',
   props: {     
     TypeProp:String,
     StepProp:String,
     MaxLengthProp: Number,
     isReadOnly:Boolean,
     Min:String,
     Max:String,
     isRequired:Boolean,
     value:[String, Number, Date],
     Name:String,
     Id:String,
     isValid:Boolean,
     Label:String,
     Placeholder:String,
     Autocomplete:Boolean,
     Mask: String,
     Tag: String,
     OnPressEnter: Function,
     OnAuxFunc:Function,
     IsAux:Boolean,
     NoValidateChanges:Boolean,
     isValidDoB:Boolean,
   },

   data(){
     return {
      Count:0,
      classValue:"textlabel",
      autocomplete:this.Autocomplete ? 'on' : 'off',
      invalidFeedback:"",
      menuItems:[],
      targetId: "target-"+this.Id,
      menuTargetId:"#target-"+this.Id,
      cmpLoaded: false
     }
   },

watch: {
    isValid: function (newValue) {
        this.classValue= newValue ? "textlabel" : "textlabel-invalid"
        this.invalidFeedback = newValue ? "": ": "+this.$t('Msg.RequiredField')
    },
    isValidDoB: function (newValue) {
      this.classValue= newValue ? "textlabel" : "textlabel-invalid"
      this.invalidFeedback = newValue ? "": ": "+this.$t('Msg.InvalidField')
    }
  },

methods:{

  setValid(state){       
      this.classValue= state ? "textlabel" : "textlabel-invalid"
      this.invalidFeedback = state ? "": ": "+this.$t('Msg.RequiredField')
    },

  PressEnter(){
    if(this.OnPressEnter){
      this.OnPressEnter();
    }
    
  },
    checkIsRequired(evt){     
      let Invalid = false;
      if(evt.target.value){
         if(this.TypeProp == 'text') {
        Invalid = evt.target.value.trim() == "" && this.isRequired ? true : false;
        this.invalidFeedback = Invalid ? ": "+ this.$t('Msg.RequiredField'): ""
      }
      else if(this.TypeProp == 'date') {
        Invalid = !Utils.isValidDate(evt.target.value) && this.isRequired ? true : false;
        this.invalidFeedback = Invalid ? ": "+ this.$t('Msg.InvalidField'): ""
      }
      else if(this.TypeProp == 'email') {
        //eslint-disable-next-line
        const reg = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        if (evt.target.value.length > 0) {
          Invalid = (reg.test(evt.target.value)) ? false : true;         
        }
        this.invalidFeedback = Invalid ? ": "+ this.$t('Msg.InvalidField'): ""        
      }
      }
      this.classValue= Invalid ? "textlabel-invalid" : "textlabel"
    },

    checkIsRequiredSave(value,type,id,label){      
      let Invalid = false; 
      if(type == 'email') {
        //eslint-disable-next-line
        const reg = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        if (value.length > 0) {
          Invalid = (reg.test(value)) ? false : true;         
        }       
        var div = document.getElementById('dv_'+id);       
        div.setAttribute("data-label", Invalid ? label+": "+ this.$t('Msg.InvalidField'): label);        
      }
       document.getElementById('dv_'+id).className = Invalid ? "textlabel-invalid" : "textlabel";  
    },

    validateDate(date) {
      if (this.Max && this.Min && date) {
        this.classValue = (date > this.Max || date < this.Min) ? "textlabel-invalid" : "textlabel"
        this.invalidFeedback = (date > this.Max || date < this.Min) ? " - "+ this.$t('Msg.DateOutRange'): ""
      }
      if (!date) {
        this.invalidFeedback = ''
      }
    },

    OnChange(evt){
       this.checkIsRequired(evt)
       this.$emit('change',evt.target.value)
     },

    onBlur(evt) {
      this.checkIsRequired(evt)
      if(this.TypeProp == 'date') {
        this.validateDate(evt.target.value)
      }
      this.$emit('blur', evt.target.value)
    },

    setFocus(){
       this.$refs[this.Name].focus();
    },

    onInput(evt){
      this.Count++;
     if(!this.Mask || this.Count > 1){
        this.$emit('input', evt.target.value)
      var changed = {
        "Changed":true,
        "ComponentName":this.Name,
        "Tag": this.Tag,
        "Value" : evt.target.value,
      } 
      if(!this.NoValidateChanges){
        EventBus.$emit('onChanged', changed);
      }   

      if(this.IsAux){
        this.OnAuxFunc();
      }
      
     }
    },

    onSelect: function(args ) {
       if(args.item.id === 'recording') {
        EventBus.$emit('speechBarLabel', 'stop recording');
        this.$root.$refs.MdTextSpeech.toggleSpeechBar()
        this.$root.$refs.MdTextSpeech.startSpeechToText(this.Name);
      }
      if(args.item.id === 'speech') {
        EventBus.$emit('speechBarLabel', 'stop speech');
        this.$root.$refs.MdTextSpeech.toggleSpeechBar()
        this.$root.$refs.MdTextSpeech.startSpeech(this.value);
      }
    },
    loadMenuItems() {
      if(this.$root.$refs.MdTextSpeech.isSpeechRecognitionSupported) {
        this.menuItems.push({text: this.$t('MdTextSpeech.record'), id: "recording"})
      }
      this.menuItems.push({text: this.$t('MdTextSpeech.speech'), id: "speech"})
    },
    setValueS2T( sp ){
      let sp2t = ''
      var changed = {}
      if(this.value) {
        sp2t += Array.from (this.value + ""+sp).join("");
      } else {
        sp2t = Array.from (sp).join("");
      }
      this.Value = sp2t
      this.$emit('input', sp2t);
      changed = {
        "Changed":true,
        "ComponentName":this.Name,
        "Tag": this.Tag,
        "Value" : sp2t
      } 
      EventBus.$emit('onChanged', changed);
    }
  },
  mounted(){
    this.cmpLoaded = 'MdTextSpeech' in Vue.options.components
    if (this.cmpLoaded) this.loadMenuItems()
  },
  created() {

    EventBus.$on("onCheckValid", function (value,type,id,label) {
       this.checkIsRequiredSave(value,type,id,label);
    }.bind(this));

      EventBus.$on("receivedS2T", function (event) {
        if( event.ControlId == this.Name) { 
            this.setValueS2T( event.finalSentence )
        }
      }.bind(this))
  }
};

</script>

<style>
 @import '../../../assets/css/Patients/Patient.css';
</style>


