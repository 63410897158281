import ApiService from '@/services/api.service'

const DischargeService = {
    UpdateDischarge(recordId,discharge){
        return ApiService.put(`/ProgramDischarges/${recordId}`, discharge);
    }, 
   getDischarge(recordId){
        return ApiService.get(`/ProgramDischarges/${recordId}`);
   }
  }
  
  export default DischargeService;