<template>
    <div>    
      <b-modal :id="modalId" no-close-on-backdrop no-close-on-esc size="xl" centered scrollable  :title="TitleWindow" ref="modalprior" @close="Close" @ok="handleOk" @cancel="this.emitEventCancel"> 
      <form ref="form" @submit.stop.prevent="handleSubmit">
       <b-row>
          <b-col lg="3" md="3" sm="12" v-if="this.Obj.priorAuthorizationBaseOn !== 'NONE'">
            <MdLookup
              v-bind:ModelCode="Obj.entityCode"
              v-bind:ModelDescription="Obj.entityDescription"
              v-bind:RefCode="'Lookup'"
              v-bind:Api="Api"
              v-bind:isRequired="this.Obj.priorAuthorizationBaseOn !== 'NONE' ? true : false" 
              v-bind:Fields="BaseFields"
              v-bind:Label="$t('Insurance.'+this.Obj.priorAuthorizationBaseOn)"
              v-bind:FieldCode="'code'"                    
              v-bind:FieldDescription="'name'"  
              v-bind:Stacked="false"  
              v-bind:isValid="isLookupValid"                
              :SearchTitle="this.Obj.priorAuthorizationBaseOn"
              :GetValues="onCodeConditionOk"
              :InvalidFeedback="$t('Msg.InvalidCode')"
              :SearchFieldCode="'Code'"                     
            />
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('Shared.datefrom')"
                v-bind:TypeProp="'date'"             
                v-bind:Name="'datefrom'"
                v-bind:isRequired="Obj.unit === null || Obj.unit === '' ? true : false"
                v-bind:Min="'1900-01-01'"
                v-bind:isValid="isDateFromValid"
                v-bind:Max="'2200-12-31'"
                v-model="Obj.datefrom"
                v-bind:Id="'input_date_from'">                                     
            </MdEdit>
        </b-col>
        <b-col lg="3" md="3" sm="12">
            <MdEdit 
                  v-bind:Label="$t('Shared.dateTo')"
                  v-bind:TypeProp="'date'"                  
                  v-bind:Name="'dateto'"
                  v-bind:isValid="isDateToValid"
                  v-bind:isRequired="Obj.unit === null || Obj.unit === '' ? true : false"
                  v-bind:Min="'1900-01-01'"                 
                  v-bind:Max="'2200-12-31'"
                  v-model="Obj.dateto"
                  v-bind:Id="'input_date_to'">                                     
            </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
                    <MdCheckBox
                      v-bind:Label="$t('Shared.Active')" 
                      v-bind:Name="'checkbox-isactiveprior'"
                      v-bind:ClassProp="'AlignCheck'"
                      v-bind:CheckedValue="true"
                      v-bind:UncheckedValue="false"
                      v-model="Obj.isActive"                     
                    />
        </b-col>
        </b-row>
        <b-row>
        <b-col lg="3" md="3" sm="12">
          <MdEdit 
              v-bind:Label="$t('Insurance.DayUnit')"
              v-bind:TypeProp="'number'" 
              v-bind:isRequired="(Obj.datefrom === null || Obj.datefrom === '') && (Obj.dateto === null || Obj.dateto === '') ? true : false"
              v-bind:Name="'Unit'"   
              v-bind:Min="'0.00'" 
              v-bind:Max="'999.99'"
              v-on:blur="focusOut"
              v-bind:StepProp="'0.01'"
              v-bind:isValid="isUnitValid"                
              v-model.number="Obj.unit"
              v-bind:Id="'input_day_unit'">                                     
          </MdEdit>
        </b-col> 
        <b-col lg="3" md="3" sm="12" v-if="this.ModeEdit">
          <MdEdit 
                v-bind:Label="$t('Insurance.QTYUsed')"
                v-bind:TypeProp="'text'" 
                v-bind:Name="'qty'" 
                v-bind:isReadOnly="true"         
                v-model="Obj.quantityUsed"
                v-bind:Id="'input_qty_used'">                                     
          </MdEdit>
        </b-col>                           
        <b-col lg="3" md="3" sm="12">
          <MdEdit 
                v-bind:Label="$t('Insurance.PriorAuthorization')"
                v-bind:TypeProp="'text'" 
                v-bind:isRequired="true"
                v-bind:Name="'priorauthorization'" 
                v-bind:isValid="isPriorAuthoValid"                        
                v-model="Obj.priorauthorization"
                v-bind:Id="'input_prior_autho'">                                     
          </MdEdit>
        </b-col>
       </b-row>
      
      </form>
     </b-modal>   
    </div>
</template>
 
 <script>
 import Utils from "@/common/utils";
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import { EventBus } from '@/event-bus.js';
 
 export default {
   name: 'ModalPriorAuthorization',
     mixins: [ShowMessage],  
    props: {
      ModalId: String,      
      TitleWindow:String,
      ModeEdit:[Boolean],        
    },
    data(){
        return {
            rowSelected:[],
            Api:'',            
            modalId:this.ModalId,
            currentPage:1,
            perPage:10,
            count:0,     
            isLookupValid:true,  
            isDateFromValid:true,
            isDateToValid:true,
            isUnitValid:true,
            isPriorAuthoValid:true,
            Obj:{
                "Action":"",
                "datefrom":"",
                "dateto":"",
                "entityCode":"",
                "entityDescription":"",
                "status":"",
                "isActive":false,
                "priorauthorization":"",
                "quantityUsed":0,
                "recid":"",
                unit:0,
                "priorAuthorizationBaseOn":""
            },
             BaseFields:[
                { key: 'name', sortable: true, label: this.$t('Shared.Name') },
                { key: 'code', sortable: true, label: this.$t('Shared.Code')}
            ],
           selectedItems:[], 
        }
    },
 
    methods:{

      FixedTwo(x) {
        return Number.parseFloat(x).toFixed(2);
      },

      focusOut () {
           // this.Obj.unit=this.Obj.unit.match(/^\d+\.?\d{0,2}/);
           this.Obj.unit = this.FixedTwo(this.Obj.unit);
          },
 
      checkFormValidity() {
        let valid = true;
        if(this.Obj.priorAuthorizationBaseOn !== 'NONE' && (this.Obj.entityCode === null || this.Obj.entityCode === "")){
         valid = false;
         this.isLookupValid = false;
       }else{
        valid = true;
         this.isLookupValid = true;
       }

       if(this.Obj.priorauthorization === null || this.Obj.priorauthorization === ""){
         valid = false;
         this.isPriorAuthoValid = false;
       }else{
        valid = true;
         this.isPriorAuthoValid = true;
       }
       
       if( (this.Obj.datefrom === '' || this.Obj.datefrom === null) && (this.Obj.dateto === '' || this.Obj.dateto === null) &&
       (this.Obj.unit === null || this.Obj.unit === "") ){
        valid = false;
        this.isDateFromValid = false;
        this.isDateToValid = false;
        this.isUnitValid = false;
       }else if( (this.Obj.datefrom !== '' && this.Obj.datefrom !== null) && (this.Obj.dateto === '' || this.Obj.dateto === null)
       ){
        valid = false;      
        this.isDateToValid = false;       
       }
       else if( (this.Obj.datefrom === '' || this.Obj.datefrom === null) && (this.Obj.dateto !== '' && this.Obj.dateto !== null)
       ){
        valid = false;
        this.isDateFromValid = false;       
       }      
       else if((this.Obj.datefrom && this.Obj.dateto) && !Utils.isDateFromAndDateTo(this.Obj.datefrom,this.Obj.dateto)) {
          valid = false;
        }
       else{
        valid = true;
        this.isDateFromValid = true;
        this.isDateToValid = true;
        this.isUnitValid = true;
       }
       return valid;
       },
 
       handleOk(bvModalEvt) {      
         bvModalEvt.preventDefault();      
         this.handleSubmit()
       },
       handleSubmit() {      
        if (!this.checkFormValidity()) {
          this.emitEventOK();
          return
        }
        this.emitEventOK();
       },

      emitEventOK(){        
       if(this.Obj.priorAuthorizationBaseOn !== 'NONE' && (this.Obj.entityCode === null || this.Obj.entityCode === "")){
         this.showMessage(this.$t('Insurance.PriorAuthorization'),this.$t('Msg.CheckRequiredFields'),"error");
       }

       if(this.Obj.priorauthorization === null || this.Obj.priorauthorization === ""){
         this.showMessage(this.$t('Insurance.PriorAuthorization'),this.$t('Msg.CheckRequiredFields'),"error");
       }
       
      else if( (this.Obj.datefrom === '' || this.Obj.datefrom === null) && (this.Obj.dateto === '' || this.Obj.dateto === null) &&
       (this.Obj.unit === null || this.Obj.unit === "") ){
        this.showMessage(this.$t('Insurance.PriorAuthorization'),this.$t('Msg.CheckRequiredFields'),"error");
       }else if( (this.Obj.datefrom !== '' && this.Obj.datefrom !== null) && (this.Obj.dateto === '' || this.Obj.dateto === null)
       ){
        this.showMessage(this.$t('Insurance.PriorAuthorization'),this.$t('Msg.CheckRequiredFields'),"error");
       }
       else if((this.Obj.datefrom === '' || this.Obj.datefrom === null) && (this.Obj.dateto !== '' && this.Obj.dateto !== null)
       ){
        this.showMessage(this.$t('Insurance.PriorAuthorization'),this.$t('Msg.CheckRequiredFields'),"error");
       }
       else if((this.Obj.datefrom && this.Obj.dateto) && !Utils.isDateFromAndDateTo(this.Obj.datefrom,this.Obj.dateto)) {
          this.showMessage(this.$t('Insurance.PriorAuthorization'),this.$t('Msg.DateOutRange'),"error");
        }   
       else{   
        if(this.Obj.unit === "" || this.Obj.unit === null){
          this.Obj.unit = 0;
        }  
        if(this.Obj.isActive){
          this.Obj.status = "Active";
        }else{
          this.Obj.status = "Inactive";
        }
        this.$emit('getCodeConditionOK', this.Obj);
         this.$nextTick(() => {
          this.$bvModal.hide(this.ModalId);
        })
      
     
       } 
      },
       
       onCodeConditionOk(result){      
          var type = result.FieldName;       
          if(type.indexOf('Lookup') >= 0){
              switch (type) {
                default:
                   if(result.code){
                    this.Obj.entityCode = result.code;  
                    this.Obj.entityDescription = result.description;               
                   }else{
                     this.Obj.entityCode = "";
                     this.Obj.entityDescription = "";
                   }
              }
          }
      },
 
       emitEventCancel(){
         setTimeout(() => this.ResetPrior(),200); 
       }, 
 
       funcDestination(value){
         this.selectedItems = value;
       },
 
       pageChanged (page) {         
         this.currentPage = page;    
       },
 
       PerPageFunction(perpage){
        this.perPage = perpage;
        this.currentPage = 1;
       },
 
       Search(){
         this.currentPage = 1; 
       },
 
        formatterDate(value){
          return Utils.formatterDate(value);
       },
 
       ResetPrior(){    
            this.Obj.datefrom = "";
            this.Obj.dateto = "";
            this.Obj.entityCode = "";
            this.Obj.entityDescription = "";
            this.Obj.isActive = "";
            this.Obj.priorauthorization = "";
            this.Obj.quantityUsed = "";
            this.Obj.recid = "";
            this.Obj.unit = "";
          
       },
       Close(){
         this.ResetPrior();
       },

    },

    mounted(){  
         EventBus.$on("OnReceivedData", function (data) {  
           this.Obj = data.row; 
           this.Obj.datefrom = this.Obj.datefrom ? this.formatterDate(this.Obj.datefrom):"";
           this.Obj.dateto = this.Obj.dateto ? this.formatterDate(this.Obj.dateto):"";
           if(this.Obj.priorAuthorizationBaseOn){
            if(this.Obj.priorAuthorizationBaseOn.trim().toUpperCase() === "CPT"){
                this.Api = "CPTs";
            }
            if(this.Obj.priorAuthorizationBaseOn.trim().toUpperCase() === "THERAPY"){
                this.Api = "Therapies";
            } 
            if(this.Obj.priorAuthorizationBaseOn.trim().toUpperCase() === "CATEGORY"){
                this.Api = "Categories";
            }   
           }
            
         }.bind(this));
    
       this.$refs.modalprior.$on("hidden.bs.modal", this.ResetPrior);
     
   
    },
 
     beforeDestroy () {     
       EventBus.$off('EmitDefaultDate');  
       EventBus.$off('OnReceivedData');       
     },
  
    
 };
 </script>