import ApiService from '@/services/api.service'

const DiagnosisService = {

    getAll(Resource,parameter){
      const requestData = {
        method: 'get',
        url: Resource,
        headers: {},
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,
          Version : parameter.Version,
          PageNumber : parameter.PageNumber
        }
      }
      return ApiService.customRequest(requestData);
    },


    Post(data){
       return ApiService.post(`/AdmissionDiagnoses`, data);
    },

    Put(id,data){
       return ApiService.put(`/AdmissionDiagnoses/${id}`,data);
    }


}

export default DiagnosisService;