import ApiService from '@/services/api.service'

const ClaimFileCreations = {
    getPrimaryInsurances(){
        return ApiService.get('/ClaimFileCreations/InsuranceFilter');
    },
    getInsurancesCategory(){
        return ApiService.get('/ClaimFileCreations/InsuranceCategoryFilter');
    },
    getPatientFilter(){
        return ApiService.get('/ClaimFileCreations/PatientFilter');
    },
    getProgramFilter(){
        return ApiService.get('/ClaimFileCreations/ProgramFilter');
    },
    getDoctorFilter(){
        return ApiService.get('/ClaimFileCreations/DoctorFilter');
    },
    getTherapistFilter(){
        return ApiService.get('/ClaimFileCreations/TherapistFilter');
    },
    getFacilityFilter(){
        return ApiService.get('/ClaimFileCreations/FacilityFilter');
    },
    getCPTFilter(){
        return ApiService.get('/ClaimFileCreations/CPTFilter');
    },
    getPOSFilter(){
        return ApiService.get('/ClaimFileCreations/POSFilter');
    },
    getProviders(){
        return ApiService.get('/ClaimFileCreations/ProviderFilter');
    },
    getDefaultTemplateClearingHouse(){
        return ApiService.get('/ClaimFileCreations/DefaultValuesFilter?InsuranceCode=CLEARING');
    },

    getAllFiles(parameter){          
            var param = {};
            var _Url = '/FileCreationBatches';
            param.SearchCriteria = parameter.SearchCriteria,
            param.PageSize = parameter.PageSize,
            param.PageNumber = parameter.PageNumber           
          const requestData = {
            method: 'get',
            url: _Url,
            params: param
          }
          return ApiService.customRequest(requestData);
      
    },

    getAll(parameter){      
        var param = {};
        var _Url = '/ClaimFileCreations/';
        if(parameter.DateFrom)
            param.DateFrom = parameter.DateFrom;
        if(parameter.DateTo)
            param.DateTo = parameter.DateTo;
        
        param.SendOptions = parameter.SendOptions;
        param.Template = parameter.Template;
        param.ShowUserDefinedCPT = parameter.ShowUserDefinedCPT;
        param.InputTypeOfBill = parameter.InputTypeOfBill;
        param.UseLineServiceItems = parameter.UseLineServiceItems;
        param.OrderByLineNo = parameter.OrderByLineNo;
        param.HideZeroBalance = parameter.HideZeroBalance;
        param.IsSolo = parameter.IsSolo;
        param.SetPrintFlag = parameter.SetPrintFlag;
        param.EscFlag = parameter.EscFlag;
        param.UseTherapyProvider = parameter.UseTherapyProvider;
        param.InsuranceType = parameter.InsuranceType;
        param.PreviousBatchRecId = parameter.PreviousBatchRecId;
        

        if(parameter.PrimaryInsurancesCodeList.length > 0){
            if(!_Url.includes("?")){
                _Url +="?"+parameter.PrimaryInsurancesCodeList;
            }else{
                _Url +=parameter.PrimaryInsurancesCodeList;
            }
        }
           

        if(parameter.PatientCodeList.length > 0){
            if(!_Url.includes("?")){
                _Url +="?"+parameter.PatientCodeList;
            }else{
                _Url +=parameter.PatientCodeList;
            }
        }
            

        if(parameter.PrimaryInsurancesCategoriesCodeList.length > 0){
            if(!_Url.includes("?")){
                _Url +="?"+parameter.PrimaryInsurancesCategoriesCodeList;
            }else{
                _Url +=parameter.PrimaryInsurancesCategoriesCodeList;
            }
        }
            
        
        if(parameter.FacilityCodeList.length > 0){
            if(!_Url.includes("?")){
                _Url +="?"+parameter.FacilityCodeList;
            }else{
                _Url +=parameter.FacilityCodeList;
            }
        }
            
        if(parameter.PlaceOfServiceCodeList.length > 0){
            if(!_Url.includes("?")){
                _Url +="?"+parameter.PlaceOfServiceCodeList;
            }else{
                _Url +=parameter.PlaceOfServiceCodeList;
            }
        }
            

        if(parameter.ProgramCodeList.length > 0){
            if(!_Url.includes("?")){
                _Url +="?"+parameter.ProgramCodeList;
            }else{
                _Url +=parameter.ProgramCodeList;
            }
        }
           

        if(parameter.TherapistCodeList.length > 0){
            if(!_Url.includes("?")){
                _Url +="?"+parameter.TherapistCodeList;
            }else{
                _Url +=parameter.TherapistCodeList;
            }
        }
           

        if(parameter.DoctorsCodeList.length > 0){
            if(!_Url.includes("?")){
                _Url +="?"+parameter.DoctorsCodeList;
            }else{
                _Url +=parameter.DoctorsCodeList;
            }
        }
         
        
        if(parameter.CPTCodeList.length > 0){
            if(!_Url.includes("?")){
                _Url +="?"+parameter.CPTCodeList;
            }else{
                _Url +=parameter.CPTCodeList;
            }
        }
            
        
        if(parameter.PreviousBatchRecId){
            if(!_Url.includes("?")){
                _Url +="?"+parameter.PreviousBatchRecId;
            }else{
                _Url +="?"+parameter.PreviousBatchRecId;
            }
        }
            

           
        

       // param.PrimaryInsurancesCodeList = parameter.PrimaryInsurancesCodeList;         
      /*  param.PrimaryInsurancesCategoriesCodeList = parameter.PrimaryInsurancesCategoriesCodeList;  
        param.InsuranceType = parameter.InsuranceType;           
        param.PatientCodeList = parameter.PatientCodeList;
        param.DoctorsCodeList = parameter.DoctorsCodeList;
        param.CPTCodeList = parameter.CPTCodeList;
        param.TherapistCodeList = parameter.TherapistCodeList;
        param.ProgramCodeList = parameter.ProgramCodeList;
        param.FacilityCodeList = parameter.FacilityCodeList;
        param.PlaceOfServiceCodeList = parameter.PlaceOfServiceCodeList;
        param.SendOptions = parameter.SendOptions;
        param.Template = parameter.Template;
        param.ShowUserDefinedCPT = parameter.ShowUserDefinedCPT;
        param.InputTypeOfBill = parameter.InputTypeOfBill;
        param.UseLineServiceItems = parameter.UseLineServiceItems;
        param.OrderByLineNo = parameter.OrderByLineNo;
        param.HideZeroBalance = parameter.HideZeroBalance;
        param.IsSolo = parameter.IsSolo;
        param.SetPrintFlag = parameter.SetPrintFlag;
        param.EscFlag = parameter.EscFlag;
        param.UseTherapyProvider = parameter.UseTherapyProvider;*/
 
      const requestData = {
        method: 'get',
        url: _Url,
       params: param
      }
      return ApiService.customRequest(requestData);
    }, 

    getTypeofBill(ProviderCode,SeqNo){
        return ApiService.get(`/ClaimFileCreations/DefaultTypeOfBill?BillingFacilityProviderCode=${ProviderCode}&SeqNoList=${SeqNo}`); 
    },

    CreateFile(data){     
        return ApiService.post(`/ClaimFileCreations`,data);
    },

    
    
}

export default ClaimFileCreations;