import ApiService from '@/services/api.service'

const ModifierService = {
    getAll(parameter){
        const requestData = {
            method: 'get',
            url: "/modifiers/",       
            params: {
              SearchCriteria: parameter.SearchCriteria,
              PageSize: parameter.PageSize,
              PageNumber: parameter.PageNumber,  
            }
          }
          return ApiService.customRequest(requestData);
    }, 
    getModifierByCode(parameter){
        const requestData = {
          method: 'get',
          url: "/modifiers/",
          headers: {
          },
          params: {          
            code: parameter.code           
          }
        }     
        return ApiService.customRequest(requestData);       
      },
    AddModifier(data){
        return ApiService.post(`/modifiers`, data);
    },
    DeleteModifier(Id){      
        return ApiService.delete(`/modifiers/${Id}`);
    },

    UpdateModifier(Id,data){
        return ApiService.put(`/modifiers/${Id}`,data);
    },   
    GetModifier(Id){     
        return ApiService.get(`/modifiers/${Id}`);
    },
  }


  
  export default ModifierService;