<template>
    <div >
      <b-modal v-if="showFilters"
        id="incidentReport" 
        size="xl" 
        centered 
        :title="$t('Incident.IncReportTitle')" 
        :visible="true"
        :no-close-on-backdrop="true" 
        @ok="onClickShowReport" 
        @cancel="onClickClose"
        @close="onClickClose">


        <div class="flex-container">
        <b-row>
          <b-col lg="6" md="6" sm="6">
            <MdEdit 
              v-bind:Label="$t('Shared.datefrom')"
              v-bind:TypeProp="'date'" 
              v-bind:Name="'datefrom'"
              v-bind:Min="'1900-01-01'"
              v-bind:Max="'2200-12-31'"
              v-model="dateFrom">                                     
            </MdEdit>
          </b-col>
          <b-col lg="6" md="6" sm="6">
            <MdEdit 
              v-bind:Label="$t('Shared.dateTo')"
              v-bind:TypeProp="'date'" 
              v-bind:Name="'dateto'"
              v-bind:Min="'1900-01-01'"
              v-bind:Max="'2200-12-31'"
              v-model="dateTo">                                     
            </MdEdit>
          </b-col>      
          <b-col lg="6" md="6" sm="6">
            <MdSelect
              v-bind:Label="$t('Shared.Category')"
              v-bind:Options="IncidentOptions"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'code'"
              v-bind:Model="CategoryModel"  
              v-bind:Multiple="true" 
              :Input="ChangeIncident"
              v-bind:AllowEmpty="true"/>
          </b-col>
          <b-col lg="6" md="6" sm="6" v-if="VisibleReport">
            <MdSelect
              v-bind:Label="$t('Incident.IncReporter')"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Options="ReporterOptions"
              v-bind:Model="ReporterModel"											
              v-bind:Multiple="true"
              v-bind:AllowEmpty="true"
              :Input="ChangeReporter"
              v-bind:Name="'incident_report_select'"
              id="incident_report_select"/>
          </b-col>        
          <b-col lg="6" md="6" sm="6">
            <MdSelect
              v-bind:Label="$t('Incident.IncSeverity')"
              v-bind:Options="SeverityOptions"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'code'"
              v-bind:AllowEmpty="true"
              v-bind:Multiple="true"
              v-bind:Model="SeverityModel"  
              :Input="ChangeSeverity"/>
          </b-col>
          <b-col lg="6" md="6" sm="6">
            <MdSelect
              v-bind:Label="$t('Shared.Room')"
              v-bind:Options="RoomsOptions"
              v-bind:Model="RoomModel"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Multiple="true"
              v-bind:AllowEmpty="true"
              :Input="ChangeRoom"/>
          </b-col>          
          <b-col lg="6" md="6" sm="6">
            <MdSelect
              v-bind:Label="$tc('Shared.Patient')"
              v-bind:Options="PatientsOptions"
              v-bind:Model="PatientModel"
              v-bind:FieldDescription="'patientname'"
              v-bind:FieldCode="'patientcode'"
              v-bind:Multiple="true"
              v-bind:AllowEmpty="true"
              :Input="ChangePatients"/>
          </b-col>
          <b-col lg="6" md="6" sm="6">
            <MdSelect
              v-bind:Label="$t('Shared.Status')"
              v-bind:Options="StatusOptions"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'value'"
              v-bind:AllowEmpty="true"
              v-bind:Multiple="true"
              v-bind:Model="StatusModel"  
              :Input="ChangeStatus"/>
          </b-col>            
        </b-row>
      </div>


      </b-modal>
      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        :title="reportTitle"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
      >
        <ReportViewer
          ref="ICRMultipleRep"
          :ReportName="'ICRMultipleReport'"
          :ReportTitle="reportTitle"
          :IsAnAPIReport="true"
        />
    </DxPopup>
    </div>
</template>

<script>
    import Vue from 'vue'
    import { DxPopup } from 'devextreme-vue/popup';
    import EmployeeService from '@/services/employee.service'
    import ShowMessage from '@/components/messages/ShowMessage.js'
    import IncidentSetup from '@/services/incidentsetup.service'
    import EnumService from "@/services/enum.service";
    import RoomService from '@/services/rooms.service'
    import PatientService from '@/services/patient.service' 
    import Utils from '../../../common/utils'
    import IncidentReportSeveritySetupService from '@/services/incidentreportseveritysetup.service'

    export default Vue.extend({
        name: "ICRMultipleRep",
        mixins: [ ShowMessage, Utils ],
        components: { DxPopup },
        props: {
          closeWindow: Function,
          reportTitle: String,
          VisibleReport:Boolean,
          IncidentID: String
        },
        data() {
            return {
                isReportVisible: false,
                reportParams: [],
                dateFrom: Utils.formatterDate(Date()),
                dateTo: Utils.formatterDate(Date()),
                showFilters: true,
                IncidentOptions: [],
                ReporterOptions: [],
                SeverityOptions: [],
                RoomsOptions: [],
                StatusOptions: EnumService.IncidentStatusOptionsModalReport,
                PatientsOptions: [],
                CategoryModel: '',
                ReporterModel: '',
                SeverityModel: '',
                RoomModel: '',
                StatusModel: '',
                PatientModel: '',
                parameter:{
                  SearchCriteria:'',
                  PageNumber : null,
                  PageSize:null,
                  Status: true,
                },
                paramCategory: '',
                paramReporter: '',
                paramSeverity: '',
                paramRoom: '',
                paramStatus: '',
                paramPatients: '',
                fullParam: {
                  PageSize:0,
                  PageNumber:0
                },
            }
        },
        methods: {

            onShowing(e) {
              let contentElement = e.component.content();
              let titleElement = e.component._$title[0]
              contentElement.style.padding = '0px';
              titleElement.style.padding = '0px 20px'
            },

            onHiddenReport() {
              this.isReportVisible = false
              this.closeWindow()
            },

            onClickClose() {
              this.closeWindow()
              this.showFilters = false
            },

            onClickShowReport() {
              this.reportParams = []

              this.reportParams.push({ paramName: 'DateFrom', paramValue: this.dateFrom})
              this.reportParams.push({ paramName: 'DateTo', paramValue: this.dateTo})
              this.reportParams.push({ paramName: 'CategoryCode', paramValue: this.paramCategory})
              this.reportParams.push({ paramName: 'ReporterCode', paramValue: this.paramReporter})
              this.reportParams.push({ paramName: 'SeverityCode', paramValue: this.paramSeverity})
              this.reportParams.push({ paramName: 'RoomCode', paramValue: this.paramRoom})
              this.reportParams.push({ paramName: 'StatusCode', paramValue: this.paramStatus})
              this.reportParams.push({ paramName: 'PatientCode', paramValue: this.paramPatients})
              this.reportParams.push({ paramName: 'IncidentID', paramValue: this.IncidentID})

              this.isReportVisible = true
              this.$refs.ICRMultipleRep.buildDocument(this.reportParams)
            },

            getIncidentSetup() {
              IncidentSetup.getAll(this.parameter)
                  .then((response) => { 
                    if(response.data && response.data.length > 0){   
                      this.IncidentOptions = response.data;
                    }
                  })
                  .catch(error => {
                    if (error.response) {
                      this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
                    } 
                  });  
            },

            getIncidentReportReporter() {     
              EmployeeService.getEmployeesAll()
              .then((response) => { 
                if(response.data && response.data.length > 0){   
                  this.ReporterOptions = response.data;
                }
              })
              .catch(error => {
                if (error.response) {
                  this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
                } 
              });
            },

            getIncidentReportSeverity() {
              IncidentReportSeveritySetupService.getSeverityAll()
                  .then((response) => { 
                    if(response.data && response.data.length > 0){   
                      this.SeverityOptions = response.data;
                    }
                  })
                  .catch(error => {
                    if (error.response) {
                      this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
                    } 
                  });  
            },

            getIncidentReportRooms() {
              RoomService.getAllRooms()
                  .then((response) => { 
                    if(response.data && response.data.length > 0){   
                      this.RoomsOptions = response.data;      
                    }
                  })
                  .catch(error => {
                    if (error.response) {
                      this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
                    } 
                  });  
            },

            getPatients(){   
              PatientService.getAll(this.fullParam)
              .then((response) => {
              if(response.status == '200' && response.data && response.data.length > 0) {
                this.PatientsOptions = response.data; 
                this.PatientsOptions.forEach(function(element) {	
                    element.patientname = element.firstname + " " + element.lastname;   
                });       
              }       
              })
              .catch(error => {      
                if (!error.response) {
                    // network error
                    this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Msg.retrieveInfo'),"error");
                } else {
                    this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
                }
              });    
            },

            ChangeIncident(item) {
              if(item != null){
                this.CategoryModel = item;
                
                this.paramCategory = [];
                let selectedCategory = [];
                item.forEach(function(item) {   
                  selectedCategory.push(item.code);               
                });
                this.paramCategory = selectedCategory;
              }else{
                this.CategoryModel = null;
              }
            },

            ChangeReporter(item) {   
              if(item != null){
                this.ReporterModel = item;
                
                this.paramReporter = [];
                let selectedReporter = [];
                item.forEach(function(item) {   
                  selectedReporter.push(item.code);               
                });
                this.paramReporter = selectedReporter;
              }else{
                this.ReporterModel = null; 
              }  
            },

            ChangeSeverity(item) {
              if(item != null){
                this.SeverityModel = item;

                this.paramSeverity = [];
                let selectedSeverity = [];
                item.forEach(function(item) {   
                  selectedSeverity.push(item.code);               
                });
                this.paramSeverity = selectedSeverity;
              }else{
                this.SeverityModel = null;
              }
            },

            ChangeRoom(item) {
              if(item != null){
                this.RoomModel = item;

                this.paramRoom = [];
                let selectedRoom = [];
                item.forEach(function(item) {   
                  selectedRoom.push(item.code);               
                });
                this.paramRoom = selectedRoom;
              }else{
                this.RoomModel = null;
              }
            },

            ChangeStatus(item) {
              if(item != null){
                this.StatusModel = item;

                this.paramStatus = [];
                let selectedStatus = [];
                item.forEach(function(item) {   
                  selectedStatus.push(item.value);               
                });
                this.paramStatus = selectedStatus;
              }else{
                this.StatusModel = null;
              }
            },

            ChangePatients(item) {
              if(item != null){
                this.PatientModel = item;
                
                this.paramPatients = [];
                let selectedPatients = [];
                item.forEach(function(item) {   
                  selectedPatients.push(item.patientcode);               
                });
                this.paramPatients = selectedPatients;
              }else{
                this.PatientModel = null;
              }
            },

            RemoveItem() {
              let selected = this.StatusOptions.find(el => el.value === 'all')
              if (selected)
                this.StatusOptions.splice(this.StatusOptions.indexOf(selected),1)
            }
        },
        
        mounted () {
          this.getIncidentSetup()
          this.getIncidentReportRooms()
          this.getIncidentReportReporter()
          this.getIncidentReportSeverity()
          this.getPatients()
          this.RemoveItem()

          if (this.IncidentID) {
            this.showFilters = false
            this.onClickShowReport()
          }
        },
    })
</script>
<style>
  .dxrd-report-preview-holder {  
      height: 94% !important; 
  } 
</style>
