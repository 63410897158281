import ApiService from '@/services/api.service'


const MdDrugFreqSetupDetService = {
    getAll( parameter) {          
      const requestData = {
        method: 'get',
        url: "/MdDrugFreqSetupDets/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber        
        }
      }     
      return ApiService.customRequest(requestData);         
    },

    getAllByCode( parameter) {          
      const requestData = {
        method: 'get',
        url: "/MdDrugFreqSetupDets/",
        headers: {
        },
        params: { 
         Code:parameter.Code
        }
      }     
      return ApiService.customRequest(requestData);         
    },


  
    get(id) {
      return ApiService.get(`/MdDrugFreqSetupDets/${id}`);
    },

    add(data){
      return ApiService.post(`/MdDrugFreqSetupDets`,data)
    },

    delete(id){
      return ApiService.delete(`/MdDrugFreqSetupDets/${id}`)
    },
    
    update(id,data){
      return ApiService.put(`/MdDrugFreqSetupDets/${id}`,data)
    },
   
}
  
  export default MdDrugFreqSetupDetService;