<template>
<div id="ContainerManageClaimBatches">
       <h4 class="page-title text-truncate">
                Manage Claim Batches               
            </h4>
    <div class="row mb-3">
                <div class="col-md-12">
                 <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                        <div class="input-group">

                <input 
                  type="text" 
                  class="form-control"
                    v-model="parameter.SearchCriteria"
                    @keyup.enter="getFiles()"
                    ref="inputSearchCriteriaFiles"
                    id="inputSearchCriteriaFiles"
                >
                <div class="input-group-append">
                    <button class="btn btn-sm search-attached" type="button" v-on:click="getFiles()"
                      id="buttonSearchCriteriaFiles"
                    >
                        <i class="mdi mdi-magnify"></i>
                    </button>
                </div>
          
          
            </div>
                </div>
            </div>
         </div>
              <MdTable 
              v-bind:fields="fieldsFiles"
              v-bind:items="itemsFiles"
              v-bind:totalRows="totalRowsFiles"
              v-bind:isReadOnly="false"
              v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
              v-bind:currentPage="currentPageFiles"
              v-bind:perPage="perPageFiles"
              v-bind:FieldsDate="FieldsDateArrFiles"
              v-bind:pageOptions="pageOptionsFiles"             
              v-bind:VisibleEditIcon="true"
              :viewIconEvent="ViewDetails"
              :IconEvent1="IconEvent1"
              :IconEvent2="IconEvent2"
              :IconEvent3="IconEvent3"
              v-bind:VisibleDeleteIcon="true"
              :onRowSelected="onRowSelectedFiles"
              :onFiltered="onFilteredFiles"
              :pageChanged="pageChangedFiles"
              :perPageFunction="PerPageFunctionFiles">
            </MdTable>
</div>
</template>

<script>

import ClaimFileCreations from '@/services/claimfilecreations.service';
import DBFunctions from '@/common/DBFunctions.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import { EventBus } from '../../../event-bus.js';
import Utils from '@/common/utils'

export default {
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  name: 'ManageClaimBatches',
 mixins: [ShowMessage, DBFunctions,EventBus],
  data(){
    return{
        parameter:{
            SearchCriteria:'',
            PageNumber : 1,
            PageSize:0,         
        },
        permission:[],
        Menu : this.$store.state.leftMenu,
        itemsFiles:[],
        totalRowsFiles:0,
        perPageFiles:10,
        ShowMessageIsEmpty: false,
        FieldsDateArrFiles:['filedate'],
        currentPageFiles:1,
        pageOptionsFiles:[5, 10, 15],
        fieldsFiles:[                
            { key: "fileName", sortable: true, label: this.$t('HouseFile.FileName'), class: "text-rigth" },
            { key: "recID", sortable: true, label: "recID", class: "text-rigth hidden" },
            { key: "filedate", sortable: true, label: this.$t('HouseFile.FileDate'), class: "text-rigth" },
            {key: "batchno", sortable: true, label: this.$t('HouseFile.BatchNo'), class: "text-rigth" },                        
        ],

        OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },

    }
  },

  methods:{
        CanView(){
            return this.permission[0].roleCanView;
        },
        getFiles() {
            this.$emit('load', true);
            this.parameter.PageSize = this.perPageFiles;
            this.parameter.PageNumber = this.currentPageFiles; 
            ClaimFileCreations.getAllFiles(this.parameter)
                .then((response) => {
                if (response.data) {
                    this.itemsFiles = response.data;
                    if(this.itemsFiles.length > 0){  
                        this.ShowMessageIsEmpty = false;                      
                        this.itemsFiles.forEach(function(element) {
                          if (element.filedate)	
                              element.filedate = Utils.formatterDateToString(element.filedate);  
                             
                        });
                        let headerString = response.headers["x-pagination"];
                        this.Header = JSON.parse(headerString);
                        this.totalRowsFiles = this.Header.TotalCount;                        
                    }else{
                        this.ShowMessageIsEmpty = true;
                    }
                    this.$emit('load', false);
                }
            })
                .catch((error) => {
                    this.$emit('load', false);
                if (error.response) {
                    this.showMessage(this.$t('manageclaimbatches.getFilesErrorMsg'), error, "error");
                }
            });
        },

        onRowSelectedFiles(){

        },
        onFilteredFiles(){

        },
        ViewDetails(){

        },

        IconEvent1(){

        },
         IconEvent2(){

        },
         IconEvent3(){

        },

        pageChangedFiles(page){
            this.currentPageFiles = page;    
            this.getFiles();     
        },
        PerPageFunctionFiles(perpage){
            this.perPage = perpage;  
            this.currentPage = 1;
            this.getFiles();  
        },
  },

  created(){
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
  },

  mounted(){
          this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
    this.getFiles();
  },

    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
   
};
</script>


