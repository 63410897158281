<template>
   <div>
    <b-modal :id="ModalId" :visible="true" no-close-on-backdrop no-close-on-esc size="md" :title="TitleWindow" ref="modalparticipantvisitor" @close="this.emitEventCancel" @ok="this.emitEventOK" @cancel="this.emitEventCancel"> 
      <b-row>
        <b-col md="6" lg="6" sm="12">
          <MdSelect
            v-bind:Label="$t('Incident.IncPartType')"
            v-bind:Options="ParticipantList"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'code'"
            v-bind:Model="modelParticipantType"                       
            v-bind:Multiple="false"
            :Input="ChangeParticipantType"
            v-bind:isRequired="true" 
            v-bind:isValid="isParticipantTypeValid"/>
        </b-col>
        <b-col md="6" lg="6" sm="12">
          <MdEdit 
            v-bind:Label="$t('Incident.IncPartStatementDate')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'date'"
            v-model="visitorItems.statementDate"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-bind:isRequired="true" 
            v-bind:isValid="isStatementDateValid">                                     
          </MdEdit>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">         
          <MdEdit 
            v-bind:Label="$t('Incident.IncPartVisitor')"
            v-bind:TypeProp="'text'" 
            v-bind:Name="'visitor'"
            v-model="visitorItems.entityName"
            v-on:change="ChangeVisitor"
            v-bind:isRequired="true"
            v-bind:MaxLengthProp="50" 
            v-bind:isValid="isVisitorValid"
            v-bind:Id="'input_IncPartVisitor_id'">                                     
          </MdEdit>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6" lg="6" sm="12">
          <MdCheckBox
            v-bind:Label="$t('Incident.IncPartArrest')"
            v-bind:Name="'PoliceArrest4'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="visitorItems.arrestMade"
            v-on:change="ChangePoliceArrest"/>
        </b-col>
        <b-col md="6" lg="6" sm="12">
          <MdCheckBox
            v-bind:Label="$t('Incident.IncPartVisit')"
            v-bind:Name="'VisittoER4'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="visitorItems.ervisit"
            v-on:change="ChangeVisittoER"/>
        </b-col>
      </b-row>
      <MdTitle v-bind:Text="$t('Incident.IncPartPersonalSta')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
      <b-row>
        <b-col md="12" lg="12" sm="12">
          <MdMemo
            v-bind:Name="'textarea_personalstatement'"
            v-bind:Rows="'8'"
            v-bind:MaxRows="'6'"
            v-bind:isRequired="true"
            v-bind:isValid="isPersonalStatementValid"
            v-model="visitorItems.personalStatement"/>
        </b-col>
      </b-row>
      <MdTitle v-bind:Text="$t('Incident.IncPartConRecommendations')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
      <b-row>
        <b-col md="12" lg="12" sm="12">
          <MdMemo
            v-bind:Name="'textarea_resourcecomments'"
            v-bind:Rows="'8'"
            v-bind:MaxRows="'6'"
            v-model="visitorItems.observation"/>
        </b-col>
      </b-row>
    </b-modal>   
   </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import IncidentReport from '@/services/incidentreport.service'
import GlobalService from '@/services/global.service'
import Utils from '@/common/utils'

export default {
  name: 'ModalParticipantVisitor',
    mixins: [ShowMessage],  
   props: {
     ModalId: String,
     TitleWindow: String,
     IncidentID: String,
     ModeEdit: Boolean,
     VisitorItems: Object,
     minObsevationStatement:Number,
     minPersonalStatement:Number
   },
   data(){
       return {
        ParticipantList: [],
        modelParticipantType:{code:"",description:""},
        participantType: '',
        personalstatementdate: null,
        personalstatementdatenotes: '',
        resourcecomments: '',
        visitor: '',
        visitorItems: {
          statementDate:null,
          arrestMade:false,
          entityCode:"",
          entityName:"",
          entityType:"",
          ervisit:false,
          isRequired:false,
          observation:"",
          participationType:"",
          personalStatement:"",       
          recordId:null
        },
        isParticipantTypeValid: true,
        isStatementDateValid: true,
        isVisitorValid: true,
        isPersonalStatementValid: true,
        APIDateTime: null
       }
   },

   methods:{

    Message(min,text1,text2){
      return text1+" "+min+" "+text2; 
    },
    
      validateFields() {
        let personalStatement = this.visitorItems.personalStatement ? this.visitorItems.personalStatement.replace(/<[^>]*>?/g, ''):"";
        let observation = this.visitorItems.observation ? this.visitorItems.observation.replace(/<[^>]*>?/g, '') :"";
        this.isParticipantTypeValid = this.visitorItems.participationType && this.visitorItems.participationType != '' || this.visitorItems.participationType != undefined ? true : false
        this.isStatementDateValid = Utils.ValidateDate(this.visitorItems.statementDate) ? true : false
        this.isVisitorValid = this.visitorItems.entityName && this.lenWithoutSpaces(this.visitorItems.entityName) > 0 ? true : false
        this.isPersonalStatementValid = this.visitorItems.personalStatement && this.visitorItems.personalStatement.trim() != '' ? true : false

        if (this.isPersonalStatementValid == false)
          this.showMessage(this.$t('Incident.IncReportTitle'),this.$t('Incident.IncPartProvidPerStaMsg'),"errorAutoHide");

        let validpersonalStatement = false;
        let validobservation = false;
      
      if(this.minPersonalStatement !== null && this.minPersonalStatement !== ""){
        validpersonalStatement = personalStatement.length >= this.minPersonalStatement;
      }else{
        validpersonalStatement = true;
      }

      if(this.minObsevationStatement !== null && this.minObsevationStatement !== ""){
        validobservation = observation.length >= this.minObsevationStatement;
      }else{
        validobservation = true;
      }

      if(validpersonalStatement == false){        
        this.showMessage(this.$t('Incident.IncReportTitle'),this.Message(this.minPersonalStatement,this.$t('Incident.IncPartProvidPerStaC1Msg'),this.$t('Incident.IncPartProvidPerStaC2Msg')) ,"errorAutoHide");
      }

      if(validobservation == false){        
        this.showMessage(this.$t('Incident.IncReportTitle'),this.Message(this.minObsevationStatement,this.$t('Incident.IncPartProvidRecoC1Msg'),this.$t('Incident.IncPartProvidRecoC2Msg')) ,"errorAutoHide");
      }


        return this.isParticipantTypeValid && this.isStatementDateValid && this.isVisitorValid && this.isPersonalStatementValid && validpersonalStatement && validobservation
      },
      
      ChangePoliceArrest() {
      },

      ChangeVisittoER() {
      },

      ChangeVisitor(value) {
        this.isVisitorValid = value ? true : false
      },
      
      ChangeParticipantType(value) {
        this.modelParticipantType = value !== null ? value : {code:'',description:''};
        this.isParticipantTypeValid = value && value.code ? true : false;  
        this.visitorItems.participationDescription = value ? value.description : null;
        this.visitorItems.participationType = value ? value.code : null
        this.visitorItems.entityCode = ' '
      },
      
       emitEventOK(bvModalEvent){
        if (this.validateFields()) {       
          if (this.ModeEdit == false) {
              this.visitorItems.entityType = 'V'
              this.visitorItems.incidentId = this.IncidentID             
              this.visitorItems.recId = Utils.generateRecID()
              this.visitorItems.contactName = ''
              this.visitorItems.employeeName = ''
              this.visitorItems.lastEditDateTime = null
              this.visitorItems.recordId = ''
              this.visitorItems.sysUser = ''
            }        
            this.$emit('getCodeConditionOK',this.visitorItems);
        } else {
          bvModalEvent.preventDefault()
        }
      },
      
      emitEventCancel(){
        this.$emit('getCodeConditionCANCEL');
      }, 

      getParticipationTypeVisitors() {
        IncidentReport.getParticipationTypeVisitors()
          .then((response) => {
            if(response.status == '200') {  
              this.ParticipantList = response.data;
            }                             
          })
          .catch((error) => {
            if (error.response) {
              this.showMessage(this.$t('Incident.IncPartVisiTypeMsg'),error,"error");
            }
          });
      },

       getDateTime() { 
         GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {
                this.APIDateTime = Utils.formatterDateTimeToString(response.data);
                this.visitorItems.statementDate = Utils.formatterDate(response.data)
               }  
            })
            .catch(error => {
             if(error.response.status === 400 ){
                 this.showMessage(this.$t('Incident.IncReportTitle'),error.response.data.errorMessage,"error");
              }else{
                this.showMessage(this.$t('Incident.IncReportTitle'),error,"error");
              }
            });   
      },

      lenWithoutSpaces(str) {
        return str.replace( /\s/g, '' ).length;
      }
   },

  mounted(){ 
    this.$refs.modalparticipantvisitor.$on("hidden.bs.modal");
    this.getParticipationTypeVisitors()
    if (this.ModeEdit) {
      let visitorItems = {...this.VisitorItems}
      this.visitorItems.arrestMade = visitorItems.arrestMade
      this.visitorItems.ervisit = visitorItems.ervisit
      this.visitorItems.observation = visitorItems.observation
      this.visitorItems.participationType = visitorItems.participationType
      this.visitorItems.participationDescription = visitorItems.participationDescription
      this.visitorItems.patientName = visitorItems.patientName
      this.visitorItems.personalStatement = visitorItems.personalStatement
      this.visitorItems.statementDate = Utils.formatterDate(visitorItems.statementDate)
      this.visitorItems.entityCode = visitorItems.entityCode
      this.visitorItems.contactName = visitorItems.contactName
      this.visitorItems.employeeName = visitorItems.employeeName
      this.visitorItems.entityType = visitorItems.entityType
      this.visitorItems.incidentId = visitorItems.incidentId
      this.visitorItems.id = visitorItems.id
      this.visitorItems.lastEditDateTime = visitorItems.lastEditDateTime
      this.visitorItems.recId = visitorItems.recId
      this.visitorItems.recordId = visitorItems.recordId
      this.visitorItems.sysUser = visitorItems.sysUser
      this.visitorItems.version = visitorItems.version
      this.visitorItems.entityName = visitorItems.entityName
      this.modelParticipantType = {code:this.visitorItems.participationType,description:visitorItems.participationDescription}
    }else{
      this.getDateTime();
    }
  },
};
</script>