<template>
    <div class="list-box-wrapper">
      <div>
        <div class="textlabel" :data-label="this.labelsource"></div>
        <ul class="list-box">            
            <input
            :type="'text'"
            class="form-control" 
            placeholder="Search"
            v-on:input="OnInputSource"
            >
          <li
            v-for="(item,key) in source"
            v-bind:key="key"
            class="list-item"
            v-bind:style="{backgroundColor: item.selected ? '#D9F4FB':''}"
            @click="selectSource(key)"
          >{{item.label}}</li>
        </ul>
        <div class="bulk-action">
          <div class="btn-action select-all" @click="selectAllSource">{{$t('Shared.All')}}</div>
          <div class="btn-action deselect-all" @click="deSelectAllSource">None</div>
        </div>
      </div>
      <div class="actions">
        <div class="btn-action" @click="moveDestination">
          <img src="@/assets/images/angle-right-solid.svg" height="18">
        </div>
        <div class="btn-action" @click="moveAllDestination">
          <img src="@/assets/images/angle-double-right-solid.svg" height="18">
        </div>
        <div class="btn-action" @click="moveSource">
          <img src="@/assets/images/angle-left-solid.svg" height="18">
        </div>
        <div class="btn-action" @click="moveAllSource">
          <img src="@/assets/images/angle-double-left-solid.svg" height="18">
        </div>
      </div>
      <div>
        <div class="textlabel" :data-label="this.labeldestination"></div>
        <ul class="list-box">
         <input
            :type="'text'"
            class="form-control" 
            placeholder="Search"
            v-on:input="OnInputDestination"
            >
          <li
            v-for="(item,key) in destination"
            v-bind:key="key"
            class="list-item"
            v-bind:style="{backgroundColor: item.selected ? '#D9F4FB':''}"
            @click="selectDestination(key)"
          >{{item.label}}</li>
        </ul>
        <div class="bulk-action">
          <div class="btn-action select-all" @click="selectAllDestination">{{$t('Shared.All')}}</div>
          <div class="btn-action deselect-all" @click="deSelectAllDestination">None</div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      source: Array,
      destination: Array,
      labelsource:String,
      labeldestination:String,
    },
    mounted: function() {
      console.log(this);
    },
    data(){
        return{
            SearchSource:"",
            SearchDestination:"",
            CopySource : this.source,
            CopyDestination : this.destination,
            isEmptyDestination:this.destination.length > 0 ? false : true,//false,
            isEmptySource:this.source.length > 0 ? false : true,//false,
        }
    },
    methods: {

    OnInputSource:function(e){        
        let destination = this.destination;
        let source = [];
        if(this.destination.length != (this.CopyDestination.length + this.CopySource.length)){
        if(e.target.value){        
            this.CopySource.map(function(f){
                if(f.label.toUpperCase().includes(e.target.value.toUpperCase())){         
                    source.push(f);
                }
            });
       
         }else{
            source = this.CopySource;
        }
        this.$emit("onChangeList", {
          source,
          destination
        });    
    }
     },

     OnInputDestination:function(e){
        let destination = []
        let source = this.source;
        //if(this.destination.length > 0 ){
        if(e.target.value){        
            this.CopyDestination.map(function(f){
                if(f.label.toUpperCase().includes(e.target.value.toUpperCase())){         
                    destination.push(f);
                }
            });
       
         }else{
            destination = this.CopyDestination;
        }
        this.$emit("onChangeList", {
          source,
          destination
        });
    //}
     },

     UpdateSourcesandDestination(){
       this.isEmptyDestination = this.destination.length > 0 ? false : true;   
       this.isEmptySource = this.source.length > 0 ? false : true;

       this.CopyDestination = [...this.destination];
       this.CopySource = [...this.source];
     },

      moveDestination: function() {
        let selected = this.source.filter(f => f.selected);
        selected = selected.map(item => ({
          ...item,
          selected: false
        }));
        let destination = [...selected, ...this.destination];
        let source = this.source.filter(f => !f.selected);
        this.$emit("onChangeList", {
          source,
          destination
        });
        setTimeout(() => 
        this.UpdateSourcesandDestination()
        , 1500);  
        
      },
      moveSource: function() {
        let selected = this.destination.filter(f => f.selected);
        selected = selected.map(item => ({
          ...item,
          selected: false
        }));
        let source = [...selected, ...this.source];
        let destination = this.destination.filter(f => !f.selected);
        this.$emit("onChangeList", {
          source,
          destination
        });
        setTimeout(() => 
        this.UpdateSourcesandDestination()
        , 1500);
      },
      moveAllDestination: function() {
        let destination = [
          ...this.source.map(item => ({ ...item, selected: false })),
          ...this.destination
        ];
        let source = [];
        this.$emit("onChangeList", {
          source,
          destination
        });       
        setTimeout(() => 
        this.UpdateSourcesandDestination()
        , 1500);    
      },
      moveAllSource: function() {
        let source = [
          ...this.destination.map(item => ({ ...item, selected: false })),
          ...this.source
        ];
        let destination = [];
        this.$emit("onChangeList", {
          source,
          destination
        });
        setTimeout(() => 
        this.UpdateSourcesandDestination()
        , 1500);          
      },
      selectDestination: function(key) {
        let source = this.source;
        let destination = this.destination.map((i, k) => {
          if (k === key) {
            i.selected = !i.selected;
          }
          return i;
        });
        this.$emit("onChangeList", {
          source,
          destination
        });
      },
      selectSource: function(key) {
        let destination = this.destination;
        let source = this.source.map((i, k) => {
          if (k === key) {
            i.selected = !i.selected;
          }
          return i;
        });
        this.$emit("onChangeList", {
          source,
          destination
        });
      },
      selectAllSource: function() {
        let source = this.source.map(item => ({ ...item, selected: true }));
        let destination = this.destination;
        this.$emit("onChangeList", {
          source,
          destination
        });
      },
      deSelectAllSource: function() {
        let source = this.source.map(item => ({ ...item, selected: false }));
        let destination = this.destination;
        this.$emit("onChangeList", {
          source,
          destination
        });
      },
      selectAllDestination: function() {
        let destination = this.destination.map(item => ({
          ...item,
          selected: true
        }));
        let source = this.source;
        this.$emit("onChangeList", {
          source,
          destination
        });
      },
      deSelectAllDestination: function() {
        let destination = this.destination.map(item => ({
          ...item,
          selected: false
        }));
        let source = this.source;
        this.$emit("onChangeList", {
          source,
          destination
        });
      }
    }
  };
  </script>
  
  <style lang="scss">
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
  .bulk-action {
    display: flex;
    .select-all {
      margin-right: 0.5rem;
    }
    .deselect-all {
      margin-left: 0.5rem;
    }
  }
  .list-box-wrapper {
    font-family: sans-serif;
    width: 100%;
    display: flex;
    align-items: center;
    & > div {
      flex: 1;
    }
    .actions {
      flex-grow: 0;
      padding: 0 1rem;
      .btn-action {
        margin-bottom: 0.5rem;
      }
    }
    .list-box {
      height: 250px;
      overflow: auto;
      list-style: none;
      padding: 0;
      border: solid 1px #cccccc;
      border-radius: 3px;
      .list-item {
        padding: 0.5rem 1rem;
        border-bottom: solid 1px #cccccc;
        cursor: pointer;
        &:last-child {
          border: none;
        }
      }
    }
  }

  /*.btn-login{
	background-color: #0F9CD2;
}

.btn-login:hover{
	background-color: #026EB5;
}*/
.btn-action:hover {
    background-color: #026EB5;
}
  .btn-action {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    display: block;
    width: 100%;
    color: #fff;
    background-color: #0F9CD2;
    border-color: #0F9CD2;
    cursor: pointer;
    img {
      vertical-align: middle;
    }
  }
  .deselect-all {
    background-color: #6c757d;
    border-color: #6c757d;
  }
/*  .textlabel, .textlabel-invalid:before {
    padding: 0em 0 !important;
}*/
  </style>
  