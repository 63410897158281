<template>
    <div id="ContainerDailyNote">
       <div class="row page-height">
          <div class="col-12">
 
             <h4 v-if="this.Mode" class="page-title text-truncate">
                {{ $t('Medication.Edit') }}  -
                   <span v-if="this.RegistrationInfo.patientName">{{this.RegistrationInfo.patientName+' - '}}</span>
                   <span v-if="this.$route.params.id">{{this.$route.params.id + ' - '}}</span>
                   <span v-if="this.RegistrationInfo.dob"> {{ $t('Shared.Dob') }} {{this.RegistrationInfo.dob}}</span>
                   <span v-if="this.RegistrationInfo.ageFormatted"> {{ $t('Shared.Age') }} {{this.RegistrationInfo.ageFormatted}}</span>
                   <span v-if="this.RegistrationInfo.programName"> - {{this.RegistrationInfo.programName}}  </span>
                   <span v-if="this.RegistrationInfo.dateFrom">- {{this.RegistrationInfo.dateFrom}}  </span>
                   <span v-if="this.RegistrationInfo.dateTo">- {{this.RegistrationInfo.dateTo}}  </span>
                   <span v-else>- {{ $t('Shared.Active') }} </span>        
             </h4>
             <h4 v-if="!this.Mode" class="page-title text-truncate">
                {{ $t('Medication.Add') }} -
                   <span v-if="this.RegistrationInfo.patientName">{{this.RegistrationInfo.patientName+' - '}}</span>
                   <span v-if="this.$route.params.id">{{this.$route.params.id + ' - '}}</span>
                   <span v-if="this.RegistrationInfo.dob"> {{ $t('Shared.Dob') }} {{this.RegistrationInfo.dob}}</span>
                   <span v-if="this.RegistrationInfo.ageFormatted"> {{ $t('Shared.Age') }} {{this.RegistrationInfo.ageFormatted}}</span>
                   <span v-if="this.RegistrationInfo.programName"> - {{this.RegistrationInfo.programName}}  </span>
                   <span v-if="this.RegistrationInfo.dateFrom">- {{this.RegistrationInfo.dateFrom}}  </span>
                   <span v-if="this.RegistrationInfo.dateTo">- {{this.RegistrationInfo.dateTo}}  </span>
                   <span v-else>- {{ $t('Shared.Active') }} </span>      
             </h4>
             <div class="flex-container">
             <b-row>

            <MdPatientAllergies	
                  v-bind:NoUseClass="true"
                  v-bind:UseColumnDinamic="true"
                  v-bind:Column="'col-md-12'"
                  v-bind:PatientCode="this.$route.params.id">						
				</MdPatientAllergies>



                <b-col md="3" lg="3" sm="12">
                  <MdEdit 
                  v-bind:Label="$tc('Shared.Employee')"
                  v-bind:Name="'Empl'"
                  v-bind:TypeProp="'text'"
                  v-model="Medication.employeeCode"
                  v-bind:isReadOnly="true"
                  v-bind:Id="'input_empl_id'"
               /> 
               </b-col>
                 <b-col lg="3" md="3" sm="12">
                    <MdEdit 
                       v-bind:Label="$t('Medication.Created')"
                       v-bind:Name="'created'"
                       v-bind:TypeProp="'text'"                      
                       v-bind:Min="'1900-01-01'"
                       v-bind:Max="'2200-12-31'"
                       v-model="Medication.dateTimeCreated"
                       v-bind:isReadOnly="true"
                    />   
                 </b-col>
                 <b-col md="3" lg="3" sm="12">
                    <MdSelect
                       v-bind:Label="$t('Medication.OrderType')"
                       v-bind:Options="OrderTypeList"
                       v-bind:FieldDescription="'description'"
                       v-bind:FieldCode="'code'"
                       v-bind:Model="Medication.orderType"    
                       v-bind:Multiple="false"                                
                       :Input="ChangeDropDownOrderType"
                       :IsDisabled="false"
                    />
                 </b-col>
                 <b-col v-if="false">
                    <MdCheckBox
                    v-bind:Label="$t('MdNote.Sign')"
                    v-bind:Name="'SignMedication'"
                    v-bind:ClassProp="'AlignCheck'"
                    v-bind:CheckedValue="true"
                    v-bind:UncheckedValue="false"
                    v-bind:isReadOnly="false"
                    v-model="Medication.sign"
                    v-on:change="onChangeSign"
                    v-bind:Tag="'SignMed'"
                  />
                 </b-col>
                                        
                <b-col lg="3" md="3" sm="12">
                        <MdLookupMultiple
                              v-bind:ModelCode="Medication.drugCode"
                              v-bind:ModelDescription="Medication.drugName"
                              v-bind:Label="$t('Medication.DrugName')"                             
                              v-bind:RefCode="'drugname'" 
                              v-bind:Api="DrupNameCall"
                              v-bind:OptionParamList="ParamList"                                                   
                              v-bind:FieldCode="'code'"   
                              v-bind:modelFilter="modelFilter"                         
                              v-bind:FieldDescription="'name'"
                              :IsDisabled="false"    
                              :ChangeFilter="ChangeFilter"
                              :SearchTitle="$t('Medication.DrugName')"
                              :GetValues="onCodeConditionOk"                     
                              :SearchFieldCode="'Code'"
                           />
                     </b-col>    
                     <b-col lg="1" md="1" sm="12">
                        <MdEdit 
                       v-bind:Label="$t('Medication.Days')"
                       v-bind:Name="'Days'"
                       v-bind:TypeProp="'number'"                      
                       v-bind:Min="'1'"
                       v-bind:Max="'1000'"
                       v-model="Medication.day"
                       v-bind:isReadOnly="false"
                    />   
                     </b-col>    
                     <b-col lg="2" md="2" sm="12">
                        <MdEdit 
                       v-bind:Label="$t('Medication.Dosage')"
                       v-bind:Name="'Dosage'"
                       v-bind:TypeProp="'number'"                      
                       v-bind:Min="'1'"
                       v-bind:Max="'1000'"
                       v-model="Medication.doseQty"
                       v-bind:isReadOnly="false"
                    />   
                     </b-col>  
                     <b-col lg="2" md="2" sm="12">
                        <MdEdit 
                        v-bind:Label="$t('Medication.DailyAmount')"
                        v-bind:Name="'DailyAmount'"
                        v-bind:TypeProp="'number'"                      
                        v-bind:Min="'1'"
                        v-bind:Max="'1000'"
                        v-model="Medication.qtytoTake"
                        v-bind:isReadOnly="false"
                     />  
                     </b-col> 
                     <b-col lg="1" md="1" sm="12">
                        <MdEdit 
                        v-bind:Label="$t('Medication.TotalQty')"
                        v-bind:Name="'totalqty'"
                        v-bind:TypeProp="'number'"                      
                        v-bind:Min="'1'"
                        v-bind:Max="'1000'"
                        v-model="Medication.quantityOrdered"
                        v-bind:isReadOnly="false"
                     />   
                     </b-col>   
                     <b-col lg="3" md="3" sm="12">
                        <MdLookup
                        v-bind:ModelCode="Medication.frequency"
                        v-bind:ModelDescription="Medication.frequencyDescription"
                        v-bind:Label="$t('Shared.Frequency')"
                        v-bind:RefCode="'Frequency'"  
                        v-bind:AuxFields="this.FrequencyAuxFields"                     
                        v-bind:Api="'VW_MdDrugFreqSetupHdrOnlyAdministrations'"
                        v-bind:FieldCode="'code'"                        
                        v-bind:FieldDescription="'description'"
                        :IsDisabled="false"    
                        :SearchTitle="$t('Shared.Frequency')"
                        :GetValues="onCodeConditionOk"                     
                        :SearchFieldCode="'Code'"
                     />
                     </b-col>     
                     
                     <b-col lg="1" md="1" sm="12">
                        <MdEdit 
                        v-bind:Label="$t('Medication.Mode')"
                        v-bind:Name="'Mode'"
                        v-bind:TypeProp="'text'"
                        v-model="Medication.mode"
                        v-bind:isReadOnly="true"
                        v-bind:Id="'input_mode_id'"/>
                    
                     </b-col>  
                     <b-col lg="1" md="1" sm="12">
                        <MdButton
                        v-bind:Label="$t('Medication.Dosage-Time')"
                        v-bind:isReadOnly="!this.EnabledDosageBtn"
                        v-bind:Name="'btn_dosagetime'"
                        v-bind:ClassProp="'secondary mt-3 btn-login'"
                        v-on:click="OpenDosageTime"/>                    
                     </b-col>   
           
               <b-col lg="3" md="3" sm="12">
                  <MdSelect
                  v-bind:Label="$tc('Shared.ActionsTxt')"
                  v-bind:Options="ActionList"
                  v-bind:FieldDescription="'description'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="Medication.action"    
                  v-bind:Multiple="false"                                
                  :Input="ChangeDropDownAction"
                  :IsDisabled="false"
                 />
               </b-col>
               <b-col md="3" lg="3" sm="12">
                  <MdCheckBox
                  v-bind:Label="$t('Medication.NoStartDate')"
                  v-bind:Name="'shownostartdate'"
                  v-bind:ClassProp="'AlignCheck'"
                  v-bind:CheckedValue="true"
                  v-bind:UncheckedValue="false"
                  v-bind:isReadOnly="false"
                  v-model="Medication.showNoStartDate"
                  v-on:change="onChangeShowNoStartDate"
                  v-bind:Tag="'shownostartdate'"
                />
              </b-col>
               <b-col md="2" lg="2" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('Medication.StartDate')"
                  v-bind:Name="'sDate'"
                  v-bind:TypeProp="'date'"                      
                  v-bind:Min="'1900-01-01'"
                  v-bind:Max="'2200-12-31'"
                  v-model="Medication.startDate"
                  v-bind:isReadOnly="false"
               />   
              </b-col>
              <b-col md="1" lg="1" sm="12">
                  <MdEdit 
                  v-bind:Label="$t('MdCurrentAppointment.StarTime')"
                  v-bind:Name="'sTime'"
                  v-bind:TypeProp="'text'"                      
                  v-bind:Min="'1900-01-01'"
                  v-bind:Max="'2200-12-31'"
                  v-model="Medication.startTime"
                  v-bind:isReadOnly="false"
               />                
              </b-col>

              <b-col md="3" lg="3" sm="12" v-if="this.PharmacyOption.showNoEndDate">
               <MdCheckBox
               v-bind:Label="$t('Medication.NoEndDate')"
               v-bind:Name="'shownoenddate'"
               v-bind:ClassProp="'AlignCheck'"
               v-bind:CheckedValue="true"
               v-bind:UncheckedValue="false"
               v-bind:isReadOnly="false"
               v-model="Medication.showNoEndDate"
               v-on:change="onChangeShowNoEndDate"
               v-bind:Tag="'shownoenddate'"
             />
           </b-col> 
              
                <b-col md="2" lg="2" sm="12">
                    <MdEdit 
                    v-bind:Label="$t('Medication.EndDate')"
                    v-bind:Name="'eDate'"
                    v-bind:TypeProp="'date'"                      
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"
                    v-model="Medication.endDate"
                    v-bind:isReadOnly="false"
                 />   
                </b-col>                
                <b-col md="1" lg="1" sm="12">
                    <MdEdit 
                    v-bind:Label="$t('MdCurrentAppointment.EndTime')"
                    v-bind:Name="'eTime'"
                    v-bind:TypeProp="'text'"                      
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"
                    v-model="Medication.endTime"
                    v-bind:isReadOnly="false"
                 />   
                </b-col>
                <b-col md="3" lg="3" sm="12">
                    <MdSelect
                       v-bind:Label="$t('Medication.Usage')"
                       v-bind:Options="UsageList"
                       v-bind:FieldDescription="'name'"
                       v-bind:FieldCode="'value'"
                       v-bind:Model="UsageModel"    
                       v-bind:Multiple="false"                                
                       :Input="ChangeDropDownUsage"
                       :IsDisabled="false"
                    />
                </b-col>
                <b-col md="3" lg="3" sm="12">
                    <MdCheckBox
                    v-bind:Label="$t('Medication.PatientOwned')"
                    v-bind:Name="'patientowned'"
                    v-bind:ClassProp="'AlignCheck'"
                    v-bind:CheckedValue="true"
                    v-bind:UncheckedValue="false"
                    v-bind:isReadOnly="false"
                    v-model="Medication.isPatientOwnMedication"
                    v-on:change="onChangePatientOwned"
                    v-bind:Tag="'SignMed'"
                  />
                </b-col>
            
            
                <b-col md="3" lg="3" sm="12">
                    <MdEdit 
                    v-bind:Label="$t('Medication.Form')"
                    v-bind:Name="'Form'"
                    v-bind:TypeProp="'text'"                      
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"
                    v-model="Medication.form"
                    v-bind:isReadOnly="true"
                    v-bind:Id="'input_form_id'"
                 />   
                </b-col>
                <b-col md="3" lg="3" sm="12">
                    <MdSelect
                    v-bind:Label="$t('Medication.RouteN')"
                    v-bind:Options="RouteNameList"
                    v-bind:FieldDescription="'description'"
                    v-bind:FieldCode="'code'"
                    v-bind:Model="Medication.route"    
                    v-bind:Multiple="false"                                
                    :Input="ChangeDropDownRouteName"
                    :IsDisabled="false"
                 />
                </b-col>
                <b-col md="3" lg="3" sm="12">
                    <MdSelect
                    v-bind:Label="$t('Medication.When')"
                    v-bind:Options="WhenList"
                    v-bind:FieldDescription="'description'"
                    v-bind:FieldCode="'code'"
                    v-bind:Model="Medication.whenSig"    
                    v-bind:Multiple="false"                                
                    :Input="ChangeDropDownWhen"
                    :IsDisabled="false"
                 />
                </b-col>
            
                <b-col md="3" lg="3" sm="12">
                    <MdSelect
                    v-bind:Label="$t('Medication.Prescriber')"
                    v-bind:Options="PrescriberList"
                    v-bind:FieldDescription="'prescriberName'"
                    v-bind:FieldCode="'prescriberCode'"
                    v-bind:Model="Medication.prescriberCode"    
                    v-bind:Multiple="false"                                
                    :Input="ChangeDropDownPrescriber"
                    :IsDisabled="false"
                 />
                </b-col>
                <b-col lg="3" md="3" sm="12">
                  <MdLookup
                  v-bind:ModelCode="Medication.diagCode"
                  v-bind:ModelDescription="Medication.diagDescription"
                  v-bind:Label="$t('MdPatientDiagnosis.Diagnosis')"
                  v-bind:RefCode="'Diagnosis'"                       
                  v-bind:Api="'DiagnosisICD10Providers'"
                  v-bind:FieldCode="'code'"                        
                  v-bind:FieldDescription="'name'"
                  :IsDisabled="false"    
                  :SearchTitle="$t('MdPatientDiagnosis.Diagnosis')"
                  :GetValues="onCodeConditionOk"                     
                  :SearchFieldCode="'Code'"
               />
               </b-col>   
               <b-col lg="3" md="3" sm="12">
                  <MdLookup
                  v-bind:ModelCode="Medication.indicationCode"
                  v-bind:ModelDescription="Medication.indicationDescription"
                  v-bind:Label="$t('Medication.Indications')"
                  v-bind:RefCode="'Indications'"                       
                  v-bind:Api="'MdRxIndications'"
                  v-bind:FieldCode="'code'"                        
                  v-bind:FieldDescription="'name'"
                  :IsDisabled="false"    
                  :SearchTitle="$t('Medication.Indications')"
                  :GetValues="onCodeConditionOk"                     
                  :SearchFieldCode="'Code'"
               />
               </b-col>   

                <b-col md="3" lg="3" sm="12">
                    <MdSelect
                    v-bind:Label="$t('Medication.RxStatus')"
                    v-bind:Options="RxStatusList"
                    v-bind:FieldDescription="'name'"
                    v-bind:FieldCode="'value'"
                    v-bind:Model="RxStatusModel"    
                    v-bind:Multiple="false"                                
                    :Input="ChangeDropDownRxStatus"
                    :IsDisabled="false"
                 />
                </b-col>
                <b-col md="3" lg="3" sm="12">
                    <MdEdit 
                    v-bind:Label="$t('Medication.StopDate')"
                    v-bind:Name="'sDate'"
                    v-bind:TypeProp="'date'"                      
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"
                    v-model="Medication.stopDate"
                    v-bind:isReadOnly="this.RxStatusModel.value !== 'Inactive'"
                 />   
                </b-col>
                <b-col md="3" lg="3" sm="12">
                    <MdEdit 
                    v-bind:Label="$t('Medication.StopTime')"
                    v-bind:Name="'sTime'"
                    v-bind:TypeProp="'text'"                      
                    v-bind:Min="'1900-01-01'"
                    v-bind:Max="'2200-12-31'"
                    v-model="Medication.stopTime"
                    v-bind:isReadOnly="this.RxStatusModel.value !== 'Inactive'"
                 />   
                </b-col>
           
            </b-row>
            </div>


             <div class="row" v-bind:style="style">
                <div class="col-12 mt-2">
                <MdButton
                   v-bind:Label="$t('Shared.Back')"
                   v-bind:Name="'btn_list_medications'"
                   v-bind:ClassProp="'secondary mx-2 btn-login'"
                   v-on:click="DiscardChanges"/>
             
                <MdButton
                   v-bind:Label="$t('Shared.Save')"
                   v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
                   v-on:click="SaveOrEdit"
                   v-bind:isReadOnly="this.isReadOnly"
                   v-bind:Name="'btn_save_medication'"/>
                </div>
             </div>
          </div>
       </div>
 
    </div>

 </template>
 
 <script>
 
 import ShowMessage from '@/components/messages/ShowMessage.js'
 import MedicationService from '@/services/medication.service'
 import InventoryItemService from '@/services/inventoryitem.service'
 import DrugFreqSetupHdrService from '@/services/drugfreqsetuphdrs.service'
 import DrugFreqSetupDetService from '@/services/drugfreqsetupdet.service'
 import SingleSignOnService from '@/services/singlesignon.service'
 import PharmacyOptions from '@/services/pharmacyoption.service'
 import DBFunctions from '@/common/DBFunctions.js'
 import { EventBus } from '@/event-bus.js';
 import EnumService from '@/services/enum.service' 
 import Utils from "@/common/utils";
 //import DailyNotesService from '@/services/dailynotes.service'
 import moment from "moment";
 import LocalStorage from '@/services/storage.service';
 import GlobalService from '@/services/global.service'
 

 export default {
    beforeRouteEnter(to, from, next) {
         next(async vm => {
             const iUA = await vm.$store.state.isUseDailyNotes;
             if(iUA) next()
             else next('/accessdenied');
         }) 
    },
 
    beforeRouteLeave(to, from, next) {
     if (!this.isReadOnly && this.pendingChanges) {
         this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
           title: this.$t('Medication.Medications'),
           okTitle: this.$t('Shared.BtnSave'),
           cancelTitle: this.$t('Shared.BtnDismiss'),
           footerClass: 'p-2',
           titleClass: 'modal-title modal-type-warning'
         })
           .then(value => {
             if(!value){
               next();
             } else {
               this.SaveOrEdit();
               next();
             }
           })		
     } else {
         next()
     }
     },
 
    mixins: [ShowMessage, DBFunctions],  
    name: 'MedicationDetails',
     data() {
       return { 
         Mode:this.$attrs.editMode,  
         Loading:true,
         isReadOnly: false,
         pendingChanges: false,
         PharmacyOption:{},
         OptionsRightMenu:{
          visible:true,
          ItemsList:[],
          btnvisible:true,
          itemdisabled: false,       
         },
         FrequencyAuxFields:['hoursBetweenDoses','mode','recurEveryDays','timesPerDay'],
         RegistrationInfo: {},
         InventoryItem:{
            DispensingQty:1,
            DispensingCapacity:1,
            Dispensinguom:"QTY",
            IsReusable:false
         },
         EmpList:[],
         dDiagnosis:"",
         dIndications:"",
         Medication:{
            action:"",
            admissionRecordId:"",
            dateTimeCreated: null,
            dateTimeExpired: null,
            day:0,
            diagCode:"",
            dispensingCapacity:0,
            dosage:"",
            doseQty: 0,
            drugCode:"",
            drugName:"",
            employeeCode:"",
            endDate:null,
            endTime:null,
            externalCreator: "",
            form: "",
            frequency:"",
            headerId: "",
            indicationCode:null,
            internalStatus: "",
            isInpatient: false,
            isPatientOwnMedication: false,
            isReusable: false,
            isSigned: false,
            issueType: "",
            issuedDate:null,
            medication:"",
            mode: "",
            notes: "",
            notesPlaintext:"",
            orderType: "",
            patientCode: "",
            pharmacistCode:"",
            pharmacistNotes: "",
            pharmacyAddress: "",
            pharmacyId: "",
            pharmacyName: "",
            prescriber: "",
            prescriberCode:"",
            qtytoTake:0,
            quantity: 0,
            quantityOrdered:0,
            recordId: "",
            refills:0,
            route: "",
            rxAutoStatus: "",
            rxNorm: "",
            rxStatus: "",
            rxType: "",
            showNoEndDate: false,
            showNoStartDate:false,
            sig: "",
            source: "",
            sourceRecordId:"",
            startTime: null,
            stopDate: null,
            stopTime: null,
            strength:null,
            timeToTake: 0,
            transmissionStatus: "",
            unit: "",
            whenSig: "",
            marsTimeList:[
               /*{adminTime:null,qty:1,recNo:0},*/
            ]
         },
         EnabledDosageBtn:false,
         OrderTypeList:[],
         WhenList:[],
         WhenModel:"",
         PrescriberList:[],
         RouteNameList:[],
         UsageList:EnumService.ClinicalMedicationUsage,
         RxStatusList:EnumService.ClinicalMedicationRxStatus,
         ActionList:[],
         DrugDescription:"",
         PrescriberModel:"",
         RxStatusModel:{value:'Active',name: this.$t('Shared.Active')},
         ActionModel:"",
         RouteNameModel:"",
         UsageModel:{value:'Internal',name:'Internal'},
         DrupNameCall:"VW_AllDrugLists",
         dFrequency:"",
         ParamList:[
            {
            code:1,description:this.$t('Medication.Param.AllDrugs'),resource:"VW_AllDrugLists"
            },
            {
            code:2,description:this.$t('Medication.Param.DrugsHistory'),resource:"VW_PatientDrugHistories"
            },
            {
            code:3,description:this.$t('Medication.Param.DrugByDiag'),resource:"VW_PatientDrugByDiagCodes"
            },
            {
            code:4,description:this.$t('Medication.Param.PrefDrugs'),resource:"VW_UserPreferredDrugLists"
            },
            
        ],

         modelFilter:{code:1,description:this.$t('Medication.Param.AllDrugs'),resource:"VW_AllDrugLists"},

         //Models
         OrderTypeModel:"",
         
         style:{
          boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important',
          width: '100%',
          backgroundImage: '-webkit-linear-gradient(268deg, #FAFAFA, #FAFAFAEE)',
          borderRadius: '0 0 14px 14px',
          borderTop: '1px solid #EEE',
          padding: '10px',
          textAlign: 'right'     
          },
      
         UserId: LocalStorage.getUserId(),
       }
     },
 
 
     methods:{
       DiscardChanges() {
          this.$router.push({
             name: "Clinical-Medications",
             params: { id: this.$route.params.id, recordid: this.$route.params.recordid },
          });
       },
       
       ChangeDropDownEmpl(){

       },

       ChangeDropDownOrderType(){

       },
       
       onChangeSign(){

       },



       async onCodeConditionOk(result){
         var type = result.FieldName;
         if(type.indexOf('Frequency')  >= 0 ){
              switch (type) {               
               default:
                  if(result.AuxFields[1] && result.AuxFields[1] === 'TIMED'){
                    this.EnabledDosageBtn = true;
                    var temp = await this.GetAdminTimeByCode(result.code);
                    var arrtemp = [];
                    temp.forEach(element => {
                     arrtemp.push( {recordId:this.$route.params.recordid,adminTime:element.adminTime,qty:1,recNo:0},);
                    });
                    this.Medication.marsTimeList = arrtemp;  
                    
                    var DosesBefore = this.DosesBeforeStartTime();
                    console.log(DosesBefore);                   
                  }else{
                     this.EnabledDosageBtn = false;
                     this.Medication.marsTimeList = [];
                  }       
             }
         }
       },

       ChangeDropDownPrescriber(){

       },

       ChangeDropDownUsage(){

       },
       onChangeShowNoEndDate(){

       },
       ChangeDropDownRxStatus(s){
         this.RxStatusModel = s != null ? s : {value:"Active",name:this.$t('Shared.Active')};

         if(this.RxStatusModel.value === "Inactive"){
            this.getDefaultDateTime();
         }else{
            this.Medication.stopDate = null;
            this.Medication.stopTime = null;
         }
        },
       onChangePatientOwned(){

       },

       ChangeDropDownAction(){

       },

       ChangeDropDownRouteName(){

       },

       ChangeDropDownWhen(){

       },

       onChangeShowNoStartDate(){

       },

       OpenDosageTime(){

       },

       async GetAdminTimeByCode(Code){ 
         var result = {}; 
         var ListingRequest = {
            Code : Code
         };      
         await DrugFreqSetupDetService.getAllByCode(ListingRequest)
            .then((response) => {      
               if (response.data) {                    
                result = response.data;                
               }  
         })
         .catch(error => {
            if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
         });  
         return result;        
       },

       async GetDispensingInfo(DrugCode){ 
         var result = false;        
         await InventoryItemService.get(DrugCode)
            .then((response) => {      
               if (response.data) {                    
                  result = response.data.dispensingQty > 0  && response.data.dispensingCapacity > 0 && response.data.dispensinguom !== "" ? true : false;                                  
                  if(result){
                     this.InventoryItem = response.data;
                  }                     
               }  
         })
         .catch(error => {
            if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
         });  
         return result;        
       },

       async GetDrugFreqSetupIsTimed(FreqCode){ 
         var result = false;        
         await DrugFreqSetupDetService.get(FreqCode)
            .then((response) => {      
               if (response.data != null && response.data.code != "") {                    
                    result = true;    
               }  
         })
         .catch(error => {
            if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
         });  
         return result;        
       },
       

       async GetDrugFreqSetupRecurEveryDays(Frequency){ 
         var result = 0;        
         await DrugFreqSetupHdrService.get(Frequency)
            .then((response) => {      
               if (response.data) {                    
                    result = response.data.recurEveryDays;                               
               }  
         })
         .catch(error => {
            if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
         });  
         return result;        
       },

       DosesBeforeStartTime(){
         var arr = this.Medication.marsTimeList;
         var sum = 0;
         arr.forEach(element => {
            var adt = moment(element.adminTime).format("HH:mm");
            var st = this.Medication.startTime;
            if(adt < st){
              sum+=element.qty;
            }  
         });
         return sum;
       },

       /*DosesAfterEndTime(RecordId,MarsTimeTableName,EndTime){

       },*/

       async GetQuantityToOrdered(RecordId,Days,DailyAmount,DrugCode,Frequency,/*StartTime,EndTime,*/
       DispensingQTY,DispensingCapacity,DispensingReusability,/*DispensingUOM,MarsTimeTmpTableName*/){

         var QuantityOrdered = 0;
         var FoundDispensingInfo = await this.GetDispensingInfo(DrugCode);
         var days = 0;
         var fqy = 0;
         var qy = 0;
         var DosesBefore = 0; 
         var DosesAfter = 0;
         var RecurEveryDays = await this.GetDrugFreqSetupRecurEveryDays(Frequency);
         qy = parseFloat( DailyAmount );

         if(parseInt(this.Medication.day) <= RecurEveryDays ) 
            days = 1;
         else
            days = Math.ceil(parseInt(this.Medication.day) / RecurEveryDays );

         if(FoundDispensingInfo){
            fqy = qy / DispensingCapacity;
            qy = Math.ceil(Math.round( qy * 1000 ) / Math.round( DispensingCapacity * 1000 ));

               if ( DispensingReusability === 'Y')
                  QuantityOrdered = days * fqy;
               else
                  QuantityOrdered = days * qy;

         }else{
            QuantityOrdered = days * qy;
         }

         if ( this.GetDrugFreqSetupIsTimed(Frequency) && RecordId.trim() != ""  ){
            //DosesBefore = this.DosesBeforeStartTime(RecordId,MarsTimeTableName,StartTime);
           // DosesAfter = this.DosesAfterEndTime(RecordId,MarsTimeTableName,EndTime);

            if(FoundDispensingInfo){
               if ( DispensingReusability === 'Y' ){
                  DosesBefore = DosesBefore / DispensingCapacity;
                  DosesAfter  = DosesAfter / DispensingCapacity;
               }else{
                  DosesBefore = Math.ceil(Math.round( DosesBefore * 1000 ) / Math.round( DispensingCapacity * 1000 ));
                  DosesAfter  = Math.ceil(Math.round( DosesAfter * 1000 ) / Math.round( DispensingCapacity * 1000 ));
               }
            }

            QuantityOrdered = QuantityOrdered - DosesBefore - DosesAfter;

         }

         QuantityOrdered = Math.ceil(QuantityOrdered);
         if (QuantityOrdered < 0 )
               QuantityOrdered = 0;


         return QuantityOrdered;

       },

       getDefaultDateTime() {        
            GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {  
                  this.Medication.stopDate = Utils.formatterDate(response.data);
                  this.Medication.stopTime = Utils.formatTimeHm(response.data);           
               }  
         })
         .catch(error => {
            if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
         }); 
        
      },

       getMedicationByRecId(){
         if(this.Mode){
            MedicationService.get( this.$route.params.recId )
               .then((response) => {
                  if(response.status == '200' && response.data){                   
                    var med = response.data;
                    console.log(med);
                    med.dateTimeCreated = Utils.formatterDateTimeToString(med.dateTimeCreated);  
                    med.endTime = Utils.formatTimeHm(med.endTime);
                    med.startTime = Utils.formatTimeHm(med.startTime);
                    med.endDate = Utils.formatterDate(med.endDate);
                    med.startDate = Utils.formatterDate(med.issuedDate);
                    med.stopDate = Utils.formatterDate(med.stopDate);
                    med.stopTime = Utils.formatTimeHm(med.stopTime);
                   // console.log(this.PrescriberList);
                    //this.PrescriberModel = {prescriberCode:med.prescriberCode,prescriberName:med.prescriber};
                    this.Medication = med;         
                  }
               })
               .catch((error) => {
                  if (!error.response) {
                     this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
                  } else {
                     this.showMessage(this.$t('Medication.Medications'),error,"error");
                  }
               });
         }
       },

       getPharmacyOptions(){              
         PharmacyOptions.getPharmacyOptions()
            .then((response) => {
               if(response.status == '200' && response.data && response.data.length > 0){                
                  this.PharmacyOption = response.data[0];   
               }
            })
            .catch((error) => {
               if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
               });        
      },

      
      getPrescribers(){              
         SingleSignOnService.getSingleSignOn()
            .then((response) => {
               if(response.status == '200' && response.data){                
                  this.PrescriberList = response.data;
               }
            })
            .catch((error) => {
               if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
               });        
      },

       getRouteName(){   
         var param = {
            CodeType:"ROUTE",
            GroupCreation:"SIG"
         };     
         GlobalService.getCustomGlobalCodes(param)
            .then((response) => {
               if(response.status == '200' && response.data){
                 this.RouteNameList = response.data; 
                 
                 this.RouteNameList.forEach(element => {           
                  element.code = element.description;                 
                 });

               }
            })
            .catch((error) => {
               if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
               });        
      },

      getAction(){   
         var param = {
            CodeType:"ACTION",
            GroupCreation:"SIG"
         };     
         GlobalService.getCustomGlobalCodes(param)
            .then((response) => {
               if(response.status == '200' && response.data){
                 this.ActionList = response.data;    
                 this.ActionList.forEach(element => {           
                  element.code = element.description;                
                 });          
               }
            })
            .catch((error) => {
               if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
               });        
      },

      getWhen(){   
         var param = {
            CodeType:"WHEN",
            GroupCreation:"SIG"
         };     
         GlobalService.getCustomGlobalCodes(param)
            .then((response) => {
               if(response.status == '200' && response.data){
                 this.WhenList = response.data; 
                 this.WhenList.forEach(element => {           
                  element.code = element.description.toUpperCase();
                  element.description = element.description.toUpperCase();
                 });               
               }
            })
            .catch((error) => {
               if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
               });        
      },

      getOrderType(){   
         var param = {
            CodeType:"ORDERTYPE",
            GroupCreation:null
         };     
         GlobalService.getCustomGlobalCodes(param)
            .then((response) => {
               if(response.status == '200' && response.data){
                 this.OrderTypeList = response.data;              
               }
            })
            .catch((error) => {
               if (!error.response) {            
              this.showMessage(this.$t('Medication.Medications'),this.$t('Msg.RetrieveInformation'),"error");
          } else {
              this.showMessage(this.$t('Medication.Medications'),error,"error");
          }
               });        
      },

       ChangeFilter(m){      
        this.modelFilter = m;
       },
 
       SaveOrEdit(){
         /*if(!this.isReadOnly) {
          if (this.validate_entries()) {
             this.$emit('load', true);
             elemSaveBtn.setAttribute("disabled",'');
             this.pendingChanges = false         
             if (this.Mode) { 
                this.UpdateDailyNote()           
             } else {            
                this.AddDailyNote()
             }
          }      
          else {
             this.showMessage(this.$t('DailyNote.Dn'), this.$t('DailyNote.MandatoryValue'),"errorAutoHide");
          }
         }*/
       },
 
       beforeWindowUnload(e) {
             if (this.pendingChanges) {
                 e.preventDefault()
                 e.returnValue = ''	
             }
       },
 
 
     },
 
     async mounted(){ 
     
        this.OptionsRightMenu.visible = true;
      this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
      this.$emit('getBtnRightMenu', this.OptionsRightMenu);    
       this.isReadOnly = await this.$store.state.isDailyNotesEHRReadOnly;
       this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
       if(this.RegistrationInfo.dateFrom)
             this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
         if(this.RegistrationInfo.dateTo)
             this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');	
       if(this.RegistrationInfo.dob)
             this.RegistrationInfo.dob =  moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');
 
       this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;


       this.getMedicationByRecId();
       /*if (this.Mode) {
          this.$emit('load', true);
          this.getDailyNoteById() 
       }
       setTimeout( function() {
          elemSaveBtn = document.getElementById('btn_save_dailynote');
          elemSaveBtn.setAttribute('disabled','');
       }, 50, this );*/
     },
 
    async created () {
      await this.getPharmacyOptions();
      this.getPrescribers(); 
      this.getRouteName();
      this.getAction();
      this.getWhen();
      this.getOrderType();
        EventBus.$on("onChanged", function (e) {       
           this.pendingChanges = e.Changed;         
        }.bind(this));
      window.addEventListener('beforeunload', this.beforeWindowUnload)
     },

     destroyed(){
        this.OptionsRightMenu.ItemsList = [];
        this.OptionsRightMenu.Mode = false;
        this.OptionsRightMenu.visible = false; 
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
      },
 
     beforeDestroy () {
       window.removeEventListener('beforeunload', this.beforeWindowUnload)
     },
   } 
 </script>
 
 
 