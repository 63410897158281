<template>
   <div>
    <b-modal :id="modalId" size="md" centered scrollable  :title="TitleWindow" @ok="this.emitEventOK" @cancel="this.emitEventCancel" @hide="resetModal"> 
      <b-row style="width:450px;">      
        <b-col lg="6" md="6" sm="12">
             <MdSelect
                v-bind:Label="$t('employee.Company')"
                v-bind:Options="CompanyList"
                v-bind:Model="CompanyModel"
                v-bind:FieldDescription="'name'"
                v-bind:FieldCode="'code'"
                :IsDisabled="false"
                v-bind:Multiple="false"             
                :Input="ChangeDropDownCompany"/>
        </b-col> 
        <b-col lg="6" md="6" sm="12">
            <MdEdit 
                v-bind:Label="$t('employee.ProviderId')"              
                v-bind:Name="'providerId'"
                v-bind:Min="'1900-01-01'"
                v-bind:Max="'2200-12-31'"
                v-model="ProviderId"
                v-bind:isReadOnly="false"
                v-bind:Id="'employee_providerId'">                                     
            </MdEdit>
        </b-col>       
      </b-row>      
    </b-modal>
   </div>
</template>

<script>
export default {
  name: 'ModalEmployeeProviderId',
   props: {
     ModalId: String,
     TitleWindow:String
   },
   data(){
       return { 
          modalId:this.ModalId, 
          CompanyList:[],
          CompanyModel:"", 
          ProviderId:"",
             
       }
   },

   methods:{

    emitEventOK(){      
       this.$emit('getCodeConditionOK', {Company:this.CompanyModel,Provider:this.ProviderId});
    },
  
    emitEventCancel(){

    },

    ChangeDropDownCompany(){

    },

    resetModal(){

    },

   },

   mounted(){
 
      this.$root.$on('bv::modal::show', () => {     
        this.$nextTick(() => {
          setTimeout(500);
        });
      });
   }
   
};
</script>