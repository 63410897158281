<template>
<div>        
       <div :style="style">    
      <canvas 
        :id="this.$attrs.HeaderId+'_'+this.controlId"       
        class="signature"
        v-on:mousedown="handleMouseDown" 
        v-on:mouseup="handleMouseUp" 
        v-on:mousemove="handleMouseMove" 
        v-on:mouseout="handleMouseOut" 
        v-on:touchstart="touchstart"      
        v-on:touchend="touchend"      
        v-on:touchmove="touchmove"           
        :width="style.width"         
        :height="style.height" 
         :style="stylecanvas">        
      </canvas>
    </div>
      <div class="s-header" v-bind:style="styletitle">
            <span class="s-title">{{this.Title}}</span>
            <div class="btn-group btn-group-sm float-sm-right" role="group" aria-label="Signature options">
              <b-button v-if="!ReadOnly && !NoteIsVoid" size="sm" @click="checkSigWeb" class="table-title-btn">{{$t('Sign')}}</b-button>             
              <b-button v-if="!ReadOnly && !NoteIsVoid" size="sm" @click="Clear" class="table-title-btn">{{$t('MdEditTableCN.Clear')}}</b-button>
              <b-button v-if="!ReadOnly && !NoteIsVoid" size="sm" v-on:click="Undo" class="table-title-btn">{{$t('MdSignatureCaptureCN.Undo')}}</b-button>
            </div>
        </div>
</div>
 
</template>

<script>
import { EventBus } from '../../event-bus.js';
import SigWebFunc from '../MedEZComponents/signature/SigWebFunc.js';
import ShowMessage from '@/components/messages/ShowMessage.js';
import DBFunctions from '@/common/DBFunctions.js';
export default {
  mixins: [ShowMessage, DBFunctions],
  name: 'MdSignatureCapture',
  props: { 
    value: [String], 
    NoteId: String,
    isReadOnly: Boolean
  },
  data(){
    return{   
      CanNotDraw:false,
      CanvasToBMP_dly : 9,
      controlId:this.$attrs.control.controlID,
      Title:'',
      stylebtn:{
        top: this.$attrs.control.controlTop+1+'px',
        position:'absolute',
        left: ((this.$attrs.control.controlLeft+this.$attrs.control.controlWidth)-69)+'px'
       },
       styletitle:{
        position:'absolute',
        width: this.$attrs.control.controlWidth+'px',
        top: this.$attrs.control.controlTop+'px',
        left: this.$attrs.control.controlLeft+'px'
       },
       stylecanvas:{backgroundColor:"#FFF"},
       style:{
        position:'absolute',
        top: this.$attrs.control.controlTop+16+'px',
        width:this.$attrs.control.controlWidth+'px',
        height:(this.$attrs.control.controlHeight-16)+'px',
        left:this.$attrs.control.controlLeft+'px',
        pointerEvents: ''
      },
      Hint: this.$attrs.control.controlHint,
      dataUrl:"",
      drawing : false,
      mouse : {x: 0, y: 0},
      previous : {x: 0, y: 0},
      points: [],
      pathsry :[],
      NoteIsVoid:false,
      ReadOnly: this.$attrs.control.isReadOnly || this.isReadOnly,
      topaz_flag: false
    }
  },
 computed: {
    currentMouse: function () {
      var c = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
      var rect = c.getBoundingClientRect();      
      return {
        x: this.mouse.current.x - rect.left,
        y: this.mouse.current.y - rect.top
      }
    }
  },
methods: {
    drawPaths: function () {        
         var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
         var ctx = canvas.getContext("2d"); 
         ctx.clearRect(0,0,canvas.width,canvas.height);  
        this.pathsry.forEach(path=>{
          ctx.beginPath();
          ctx.moveTo(path[0].x,path[0].y);  
          for(let i = 1; i < path.length; i++){
            ctx.lineTo(path[i].x,path[i].y); 
          }
          ctx.stroke();
        })
    },

    Undo(){  
      this.pathsry.splice(-1,1);  
      this.drawPaths();
    },
    oMousePos(canvas, evt) {
      var ClientRect = canvas.getBoundingClientRect();
        return {
          x: Math.round(evt.clientX - ClientRect.left),
          y: Math.round(evt.clientY - ClientRect.top)
        }
  },
    handleMouseDown: function (event) {      
      var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);   
      canvas.style = ""; 
      event.preventDefault() 
      this.drawing = true; 
      this.previous = {x:this.mouse.x,y:this.mouse.y};
      this.mouse = this.oMousePos(canvas, event);
      this.points = [];
      this.points.push({x:this.mouse.x,y:this.mouse.y});

    },

  /*getTouchPos: function (canvasDom, touchEvent) {
    var rect = canvasDom.getBoundingClientRect();
    return {
      x: touchEvent.touches[0].clientX - rect.left,
      y: touchEvent.touches[0].clientY - rect.top
    };
},*/

    touchstart: function(event){
      var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
      canvas.style = "";
      //mousePos = getTouchPos(canvas, event);
      event.preventDefault()
      var touch = event.touches[0];
      var mouseEvent = new MouseEvent("mousedown", {
          clientX: touch.clientX,
          clientY: touch.clientY
      });

       canvas.dispatchEvent(mouseEvent);
    },

    touchend:function(event){
      var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
      canvas.style = "";
      event.preventDefault()
       var mouseEvent = new MouseEvent("mouseup", {});
       canvas.dispatchEvent(mouseEvent);
    },

    touchmove: function(event){
      var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
      canvas.style = "";
      event.preventDefault()
       var touch = event.touches[0];
      var mouseEvent = new MouseEvent("mousemove", {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
    canvas.dispatchEvent(mouseEvent);
    },

     handleMouseOut: function(event){
      this.drawing = false;
      event.preventDefault()
    },

    handleMouseUp: function (event) {   
     this.drawing=false;     
     this.pathsry.push(this.points);
     this.Save();
     event.preventDefault()
    },
    handleMouseMove: function (event) {   
      var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);    
      var ctx = canvas.getContext("2d");
      event.preventDefault()
      if(!this.drawing)
          return;
     
        ctx.lineWidth = 3;
        this.previous = {x:this.mouse.x,y:this.mouse.y};
        this.mouse = this.oMousePos(canvas, event);
        this.points.push({x:this.mouse.x,y:this.mouse.y});
        ctx.beginPath();
        ctx.moveTo(this.previous.x,this.previous.y);
        ctx.lineTo(this.mouse.x,this.mouse.y);
        ctx.stroke();
      
    },

    InitializeVariables(){
        this.Title = this.$attrs.control.controlCaption;     
        this.Extension = this.$attrs.control.extension;
        this.Extension = this.Extension?this.Extension.slice(1):'';
        this.style.pointerEvents = this.$attrs.control.isReadOnly || this.NoteIsVoid || this.isReadOnly ? 'none' : ''
       },

    toArrayBuffer: function(canvas) {
        var w = canvas.width,
        h = canvas.height,
        w4 = w * 4,
        idata = canvas.getContext("2d").getImageData(0, 0, w, h),
        data32 = new Uint32Array(idata.data.buffer), // 32-bit representation of canvas

        stride = Math.floor((32 * w + 31) / 32) * 4, // row length incl. padding
        pixelArraySize = stride * h,                 // total bitmap size
        fileLength = 122 + pixelArraySize,           // header size is known + bitmap

        file = new ArrayBuffer(fileLength),          // raw byte buffer (returned)
        view = new DataView(file),                   // handle endian, reg. width etc.
        pos = 0, x, y = 0, p, s = 0, a, v;

        // write file header
        setU16(0x4d42);          // BM
        setU32(fileLength);      // total length
        pos += 4;                // skip unused fields
        setU32(0x7a);            // offset to pixels

        // DIB header
        setU32(108);             // header size
        setU32(w);
        setU32(-h >>> 0);        // negative = top-to-bottom
        setU16(1);               // 1 plane
        setU16(32);              // 32-bits (RGBA)
        setU32(3);               // no compression (BI_BITFIELDS, 3)
        setU32(pixelArraySize);  // bitmap size incl. padding (stride x height)
        setU32(2835);            // pixels/meter h (~72 DPI x 39.3701 inch/m)
        setU32(2835);            // pixels/meter v
        pos += 8;                // skip color/important colors
        setU32(0xff0000);        // red channel mask
        setU32(0xff00);          // green channel mask
        setU32(0xff);            // blue channel mask
        setU32(0xff000000);      // alpha channel mask
        setU32(0x57696e20);      // " win" color space

        // bitmap data, change order of ABGR to BGRA
        while (y < h) {
          p = 0x7a + y * stride; // offset + stride x height
          x = 0;
          while (x < w4) {
            v = data32[s++];                     // get ABGR
            a = v >>> 24;                        // alpha channel
            view.setUint32(p + x, (v << 8) | a); // set BGRA
            x += 4;
          }
          y++
    }

    return file;

    // helper method to move current buffer position
    function setU16(data) {view.setUint16(pos, data, true); pos += 2}
    function setU32(data) {view.setUint32(pos, data, true); pos += 4}
  },

    toDataURL: function(canvas) {
      var buffer = new Uint8Array(this.toArrayBuffer(canvas)),
          bs = "", i = 0, l = buffer.length;
      while (i < l) bs += String.fromCharCode(buffer[i++]);
      return "data:image/bmp;base64," + btoa(bs);
    },

    Save(){	
        var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
        var image = canvas.toDataURL();
        var objectdata = {
          "ControlId":this.$attrs.control.controlID,
          "base64":image,
          "Extension":".png",
          "Type":"TIssSignatureCapture"
        };    
        EventBus.$emit('onSignatureDone', objectdata);
       
        this.setChangedStatus()
    },

     setValid(state){
     //this.stylecanvas.border = state ? 'solid 1px #dc3545 !important': 'solid 1px red !important' 
     //this.stylecanvas.outline = state ? 'solid 1px #dc3545 !important': '' 
     document.getElementById(this.$attrs.HeaderId+'_'+this.controlId).style = state ? "outline: solid 1px red !important" :"";
     // this.style.outline = state ? '' : 'red 1px solid';   
    

      //this.style.borderColor = state ? '#dc3545 !important': 'red !important';


    },

    EmitClear(){
      EventBus.$emit('onSignatureClear', this.$attrs.control.controlID); 
    },


    
		Clear(){			
      var canvas = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
      var ctx = canvas.getContext('2d');
      if(this.topaz_flag == true){
        SigWebFunc.onClear()
      }
      else{
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        var w = canvas.width;
        canvas.width = 1;
        canvas.width = w;
        this.EmitClear();
      }
      //this.setChangedStatus()
		},

    InitializeModelCanvas(){
      if(this.value){ 
        var objectdata = {
          "ControlId":this.$attrs.control.controlID,
          "base64":this.dataUrl,
          "Extension":".bmp"          
        };    
        EventBus.$emit('onSignatureDone', objectdata);                      
      }
    },

    canvasImage: function(base64){
      var x = document.getElementById(this.$attrs.HeaderId+'_'+this.controlId);
      var canvas = x.getContext('2d'); 
      var image = new Image();
      image.src = base64;
      image.addEventListener("load",function(){
        canvas.drawImage(image,0,0);              
      },false);
    },

    setChangedStatus(){
      var changed = {
          "ControlId":this.$attrs.control.controlID,
          "Changed":true,
          "NoteId":this.NoteId
      }
      EventBus.$emit('onControlWasChanged', changed);
    },
    
    checkSigWeb(){
      var response = SigWebFunc.startTablet(this.$attrs.HeaderId+'_'+this.controlId)
      this.showMessage(this.$t('Topaz Signature'), response.message, response.type)
      if(response.type != "error"){
        this.topaz_flag = true
      }
    },
  },

  created(){
    this.CanvasToBMP_dly = 9;
    EventBus.$on("onSetReadOnly", function (e) {
      this.NoteIsVoid = e;
      this.style.pointerEvents = this.$attrs.control.isReadOnly || this.NoteIsVoid || this.isReadOnly ? 'none' : ''
      }.bind(this));

    if(this.$attrs.NoteIsVoid){
      this.NoteIsVoid = this.$attrs.NoteIsVoid;
    }
    this.InitializeVariables();
  },

	mounted(){   
    if(this.value){      
       this.dataUrl = "data:image/bmp;base64,"+this.value;      
       this.canvasImage(this.dataUrl);
    }
    this.InitializeModelCanvas();
	}
};
</script>

<style>
.signature {
  border: solid 1px #e0e0e0;
  border-radius: 0 0 5px 5px;
  background-image: linear-gradient(130deg, white, rgb(241, 253, 255));
  background-origin: border-box;
  background-clip: content-box, border-box;
}
.s-header{
  background-color: #c5c5c5;
  padding: 2px 2px 0 15px;
  border: 0;
  border-radius: 5px 5px 0 0;
  text-align: left;
}
.s-title{
  color: #FFF;
  text-transform: capitalize;
  overflow-x: hidden;
  cursor: default;
}
.signature + div button{
  background: #e6e3e3 !important;
  padding: 0px 10px !important;
  color: #999 !important;
  text-transform: capitalize;
  transition: all 0.3s ease-out;
  border: none;
}
.signature + div button:hover{
  background-color: #007EA8 !important;
  color: #FFF !important;
}


.canvas-wrapper:after {cursor: not-allowed;    pointer-events: none}
</style>
