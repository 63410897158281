import ApiService from '@/services/api.service'

const SchedulerOptionsService = {
    getAll() { 
      const requestData = {
        method: 'get',
        url: "/SchedulerOptions/",
        headers: {
        },
      }     
      return ApiService.customRequest(requestData);         
    },
  }
  
  export default SchedulerOptionsService;