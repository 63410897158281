<template>
  <div id="ReportDailyServiceLog">
    <MdTitle v-bind:Text="$t('dailyServiceLog.RepTitle')" />
      <b-row>
        <b-col md="3" lg="3" sm="12">
          <MdEdit 
            v-bind:Label="$t('Shared.datefrom')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'DateFrom'"
            v-model="DateFrom"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-on:change="DateFromChange($event)"
            v-bind:isValid="this.isDateFromValid">                                     
          </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
          <MdEdit 
            v-bind:Label="$t('Shared.dateTo')"
            v-bind:TypeProp="'date'" 
            v-bind:Name="'DateTo'"
            v-model="DateTo"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-on:change="DateToChange($event)"
            v-bind:isValid="this.isDateToValid">                                     
           </MdEdit>
        </b-col>
        <b-col lg="3" md="3" sm="12">         
          <MdSelect
            v-bind:Label="$tc('Shared.Patient',2)"
            v-bind:Options="PatientsList"
            v-bind:Model="PatientCodeModel"
            v-bind:FieldDescription="'patientName'"
            v-bind:FieldCode="'patientcode'"
            v-bind:Multiple="true"             
            :Input="ChangeDropDownPatients"
            :CustomLabel="customLabelPatients"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$tc('Shared.Program',2)"
            v-bind:Options="ProgramList"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Multiple="true"
            v-bind:Model="ProgramModel"  
            :Input="ChangeDropDownPrograms"
            :CustomLabel="customLabelNameCode"/>
        </b-col>
      </b-row> 
      <b-row>
        <b-col lg="3" md="3" sm="12">         
          <MdSelect
            v-bind:Label="$t('dailyServiceCharge.BillTher')"
            v-bind:Options="therapistList"
            v-bind:Model="billingTherapists"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Multiple="true"             
            :Input="ChangeBillingTherapists"
            :CustomLabel="customLabelNameCode"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('dailyServiceCharge.SignedTher')"
            v-bind:Options="therapistList"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Multiple="true"
            v-bind:Model="signedTherapists"  
            :Input="ChangeSignedTherapists"
            :CustomLabel="customLabelNameCode"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('Shared.Therapies')"
            v-bind:Options="TherapiesList"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Model="TherapiesModel"  
            v-bind:Multiple="true" 
            :Input="ChangeDropDownTherapies"
            :CustomLabel="customLabelNameCode"/>
        </b-col>
        <!-- 
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="'Facilities'"
            v-bind:Options="facilitiesOptions"
            v-bind:FieldDescription="'company'"
            v-bind:FieldCode="'code'"
            v-bind:Model="FacilitiesModel"  
            v-bind:Multiple="true" 
            :Input="ChangeFacilities"/>
        </b-col>
        -->
      </b-row>
      <MdTitle v-bind:Text="$t('dailyServiceCharge.DisplayOpt')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
      <b-row>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('dailyServiceCharge.GroupBy')"
            v-bind:Options="groupByOptions"
            v-bind:FieldDescription="'text'"
            v-bind:FieldCode="'value'"
            v-bind:Model="groupBy"  
            v-bind:Multiple="false" 
            :Input="ChangeGroupBy"
            v-bind:AllowEmpty="false"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('dailyServiceLog.Source')"
            v-bind:Options="sourceOptions"
            v-bind:FieldDescription="'text'"
            v-bind:FieldCode="'value'"
            v-bind:Model="SourceModel"  
            v-bind:Multiple="false" 
            :Input="ChangeSource"
            v-bind:AllowEmpty="false"/>
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$t('dailyServiceCharge.Include')"
            v-bind:Options="showOptions"
            v-bind:FieldDescription="'text'"
            v-bind:FieldCode="'value'"
            v-bind:Model="showModel"  
            v-bind:Multiple="false" 
            :Input="ChangeShow"
            v-bind:AllowEmpty="false"/>
        </b-col>
        <b-col md="3" lg="3" sm="12">
          <MdCheckBox
            v-bind:Label="$t('dailyServiceCharge.FiltersReport')"
            v-bind:Name="'IncludeFiltersInReport'"
            v-bind:ClassProp="'AlignCheck'"
            v-bind:CheckedValue="true"
            v-bind:UncheckedValue="false"
            v-model="IncludeFiltersInReport"
            v-on:change="ChangeIncludeFiltersInReport"/>
        </b-col>
      </b-row>
      <div class="page-actions">
         <MdButton
          v-bind:Label="$t('Shared.Back')"
          v-bind:ClassProp="'primary btn-login mx-2'"
          v-on:click="reportList"
          v-bind:Name="'btn_ReportList'"/> 
        <MdButton
          v-bind:Label="$t('Shared.Report')"
          v-bind:ClassProp="'primary btn-login mx-2'"
          v-on:click="print"
          v-bind:Name="'btn_Print'"/>   
      </div>
      <DxPopup
        :visible="isReportVisible"
        :drag-enabled="true"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :hide-on-parent-scroll="true"
        title="Daily Service Log Report"
        @hidden="onHiddenReport"
        :resize-enabled="true"
        shading-color="rgba(0, 0, 0, 0.5)"
        height="94vh"
        width="97vw"
        @showing="onShowing"
      >
        <ReportViewer
          ref="dailyServiceLog"
          :ReportName="$t('dailyServiceLog.DaLogsMsgTitle')"
          :ReportTitle="'Daily Service Log Report'"
          :IsAnAPIReport="true"
        />
    </DxPopup>
  </div>
</template>

<script>
import Utils from '@/common/utils'
import PatientService from '@/services/patient.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import ProgramService from '@/services/programs.services'
import ClinicalService from '@/services/clinical.service'
import EmployeeService from '@/services/employee.service';
import { DxPopup } from 'devextreme-vue/popup';
import DBFunctions from '@/common/DBFunctions.js'

export default {
  name: 'DailyServiceLog',
  mixins: [ShowMessage,Utils,DBFunctions],
  components: { DxPopup },
  data(){
    return{
      OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },
        isReportVisible: false,
        facilitiesOptions: [],
        groupBy: {
          "value":"patients",
          "text":this.$tc('Shared.Patient',2)
        },
        groupByOptions: [
          { value: 'patients', text: this.$tc('Shared.Patient',2) },
          { value: 'billingtherapist', text: this.$t('dailyServiceCharge.BillTherapist')  },
          { value: 'programs', text: this.$tc('Shared.Program',2)  },
          { value: 'therapies', text: this.$t('Shared.Therapies') },
          { value: 'signedtherapist', text: this.$t('dailyServiceCharge.SigTherapist')  }         
        ],
        showModel: {
          "value":"all",
          "text":this.$t('Shared.All')
        },
        showOptions: [
          { value: 'all', text: this.$t('Shared.All') },
          { value: 'billed', text: this.$t('modalClaimDetail.Billed') },
          { value: 'unbilled', text: this.$t('dailyServiceCharge.Unbilled') },
        ],
        SourceModel: {
          "value":"all",
          "text":this.$t('Shared.All')
        },
        sourceOptions: [
          { value: 'all', text: this.$t('Shared.All') },
          { value: 'clinicalnotes', text: this.$t('ClinicalNotes.ClinicalNotes') },
          { value: 'attendance', text: this.$t('dailyServiceLog.Attendance') },
        ],
        therapistList:[],
        billingTherapists: '',
        signedTherapists: '',
        DateFrom: Utils.formatterDate(Date()),
        DateTo: Utils.formatterDate(Date()),
        PatientCodeModel:'',
        ProgramModel:'',
        TherapiesModel:'',
        FacilitiesModel:'',
        PatientsList:[],
        ProgramList:[],
        TherapiesList:[],
        parameter:{
          SearchCriteria:'',
          PageNumber : null,
          PageSize:null,
          Status: true,
        },
        isDateFromValid: true,
        isDateToValid: true,
        reportParams: [],
        IncludeFiltersInReport: false,
        params: {
          "PatientCode": [],
          "ProgramCode": [],
          "BillingTherapists": [],
          "SignedTherapists": [],
          "TherapiesCode": [],
          "Facilities": [],
          "DateFrom": Utils.formatterDate(Date()),
          "DateTo": Utils.formatterDate(Date()),
          "GroupBy": 'patients',
          "Source": 'all',
          "Show": 'all',
          "IncludeFiltersInReport": false
        },
    }
  },
  methods:{

    customLabelNameCode ({ name, code }) {
      return `${name} – ${code}`
    },

    customLabelPatients ({ patientName, patientcode }) {
      return `${patientName} – ${patientcode}`
    },

    print() {
      this.isDateFromValid = true
      this.isDateToValid = true
      
      if (this.DateFrom && this.DateTo) {
        if (Utils.isDateFromAndDateTo(this.DateFrom,this.DateTo)) {
          this.onClickShowReport()
        } else {
          this.showMessage(this.$t('dailyServiceLog.DaLogsMsgTitle'),this.$t('Msg.DateOutRange'),"error");
        }
      } else {
        this.isDateFromValid = this.DateFrom ? true: false
        this.isDateToValid = this.DateTo ? true : false
        this.showMessage(this.$t('dailyServiceLog.DaLogsMsgTitle'),this.$t('Msg.CheckRedLabeledFields'),"error");
      }
    },

    onHiddenReport() {
      this.isReportVisible = false
      //this.closeWindow()
    },

    onShowing(e) {
      let contentElement = e.component.content();
      let titleElement = e.component._$title[0]
      contentElement.style.padding = '0px';
      titleElement.style.padding = '0px 20px'
    },

    getAllEmployee() {
      EmployeeService.getAllEmployees()
        .then((response) => { 
        if(response.data && response.data.length > 0){
          this.therapistList = response.data;
        }             
        })
        .catch((error) => {
        if (error.response) {
          this.showMessage(this.$t('dailyServiceLog.DaLogsMsgTitle'),error,"error");
        }
        });
    },
    
    DateFromChange(value) {
      this.params.DateFrom = value
    },

    DateToChange(value) {
      this.params.DateTo = value
    },

    ChangeDropDownPatients(values) {
      this.params.PatientCode = this.getItemsByComma(values, 'patientcode')
    },

    ChangeDropDownPrograms(values) {
      this.params.ProgramCode = this.getItemsByComma(values, 'code')
    },

    ChangeBillingTherapists(values) {
      this.params.BillingTherapists = this.getItemsByComma(values, 'code')
    },

    ChangeSignedTherapists(values) {
      this.params.SignedTherapists = this.getItemsByComma(values, 'code')
    },

    ChangeDropDownTherapies(values) {
      this.params.TherapiesCode = this.getItemsByComma(values, 'code')
    },

    ChangeGroupBy(item) {
      this.params.GroupBy = item.value
    },

    ChangeSource(item) {
      this.params.Source = item.value
    },

    ChangeShow(item) {
      this.params.Show = item.value
    },

    ChangeFacilities(values) {
      this.params.Facilities = this.getItemsByComma(values, 'code')
      this.FacilitiesModel = this.getItemsByComma(values, 'code')
    },

    ChangeIncludeFiltersInReport(value) {
      this.params.IncludeFiltersInReport = value
    },

    getPatients() {              
     this.parameter.PageSize = this.perPage;
     this.parameter.PageNumber = this.currentPage;        
      PatientService.getAll(this.parameter)
      .then((response) => {
       if(response.status == '200' && response.data && response.data.length > 0){
        this.PatientsList = response.data; 
        this.PatientsList.forEach(function(element) {
            element.patientName = element.firstname + " " + element.lastname;   
          });
        }      
        this.$emit('load', false);
      })
      .catch(error => {
        //this.Loading = false;
        this.$emit('load', false);
        if (!error.response) {
            // network error
            this.showMessage(this.$t('dailyServiceLog.DaLogsMsgTitle'),this.$t('Msg.retrieveInfo'),"error");
        } else {
            this.showMessage(this.$t('dailyServiceLog.DaLogsMsgTitle'),error,"error");
        }
      });
    },

      getTherapies() {
        ClinicalService.getTherapies()
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              this.TherapiesList = response.data;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('dailyServiceLog.DaLogsMsgTitle'),error,"error");
            } 
          });  
      },

      getPrograms() {
        ProgramService.getAll(this.parameter)
          .then((response) => { 
            if(response.data && response.data.length > 0){   
                this.ProgramList = response.data;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('dailyServiceLog.DaLogsMsgTitle'),error,"error");
            } 
          });  
      },

      getFacilities() { 
        PatientService.getFacilities('')
          .then((response) => {
            if(response.status == '200' && response.data && response.data.length > 0){
              this.facilitiesOptions = response.data;
            }
          })
          .catch(error => {
            if (!error.response) {
            // network error
              this.showMessage(this.$t('dailyServiceLog.DaLogsMsgTitle'),this.$t('Msg.RetrieveInformation'),"error");
            } else {
              this.showMessage(this.$t('dailyServiceLog.DaLogsMsgTitle'),error,"error");
            }
          });
      },

      getItemsByComma(values, field) {
        let len = values.length;
        let valuesSeparated = [];
        if (len > 0){
          var i = 0;
          values.forEach(function(e) {   
          if(i < len-1){
            valuesSeparated += e[field]+",";
          }else{
            valuesSeparated += e[field];
          }
          i++;                    
          });
          return valuesSeparated;
        }else{
          return [];
        }
      },

      reportList() {
        this.checkExistFavorite('/app/reports/billing');
        this.$router.push({ name: "reportListBilling" }).catch(()=>{});
      },
    
      onClickShowReport() {

        this.reportParams = []
        
        this.reportParams.push({ paramName: 'patientCode', paramValue: this.params.PatientCode})
        this.reportParams.push({ paramName: 'programCode', paramValue: this.params.ProgramCode})
        this.reportParams.push({ paramName: 'billingTherapists', paramValue: this.params.BillingTherapists})
        this.reportParams.push({ paramName: 'signedTherapists', paramValue: this.params.SignedTherapists})
        this.reportParams.push({ paramName: 'therapiesCode', paramValue: this.params.TherapiesCode})
        this.reportParams.push({ paramName: 'facilities', paramValue: this.params.Facilities})
        this.reportParams.push({ paramName: 'dateFrom', paramValue: this.params.DateFrom})
        this.reportParams.push({ paramName: 'dateTo', paramValue: this.params.DateTo})
        this.reportParams.push({ paramName: 'groupBy', paramValue: this.params.GroupBy})
        this.reportParams.push({ paramName: 'source', paramValue: this.params.Source})
        this.reportParams.push({ paramName: 'show', paramValue: this.params.Show})
        this.reportParams.push({ paramName: 'includeFiltersInReport', paramValue: this.params.IncludeFiltersInReport})

        this.isReportVisible = true
        this.$refs.dailyServiceLog.buildDocument(this.reportParams)
      },   

 },

  created() {
    this.getPatients();
    this.getPrograms();
    this.getTherapies();
    this.getAllEmployee();
    //this.getFacilities()
  },

  mounted() {
    this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
  }
};
</script>
<style>
  #fileList{
    padding: 1em;
  }
</style>