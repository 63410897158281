<template>
    <div class="col-md-4 grid-margin stretch-card">
                  <div class="card activeCard">
                      <div class="card-body">
                 <div class="d-flex align-items-start justify-content-between pointer">
             
                      <h6 v-on:click="GoToScheduler" class="card-title flex-grow lower-line text-truncate">{{ $t('MdAppointmentByDay.AppointmentssAt') }} </h6>                                                      
                  <i class="mdi mdi-arrow-right-drop-circle text-corporate" v-on:click="GoToScheduler"></i>
                  <div class="text-center" v-if="VisibleSpinner" style="margin-top:-2px; margin-left:3px;">
                    <b-spinner variant="primary" small label="Text Centered"></b-spinner>
                  </div> 
                  </div>                 
                  <div class="text-muted p-fixed cutescroll">
                   
                     <p v-if="List.length === 0">{{ $t('MdAppointmentByDay.NoKnown') }}</p>
                      <ul>	
                          <li v-for="(h, index) in List" :key="`h.id-${index}`">
                           ({{h.startTime}} - {{h.endTime}}) / {{h.appointmentTypeName}}
                            </li>											
                      </ul>
                     
                      </div>
                      </div>
                  </div>
                  </div>
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
import SchedulerService from '@/services/schedulers.service'
import Utils from '@/common/utils.js'

export default {
  props: {
   PatientCode: String,
 },

  mixins: [DBFunctions],
  data() {
    return {
      List:[],   
      VisibleSpinner:false,  
    }
  },
   
methods:{

  GoToScheduler(){		
			this.$router.push({ name: "scheduler"}).catch(()=>{});
  },

  async getAppt(){     
    var param = {		
      LoadEmployeebydefault: true,
      IsAppointmentByDay: true, 	
		};
    this.VisibleSpinner = true;
      await SchedulerService.getApptCurrentDayByEmployee(param)
                .then((response) => {
                     if(response.status == '200' && response.data ){
                       this.List = response.data;    
                       this.List.forEach(function(element) {
                              if (element.startTime){
                                element.transDate = Utils.formatterDateToString(element.startTime);
                                element.startTime = Utils.formatTime(element.startTime);
                              }	                               
                              if (element.endTime)	
                                element.endTime = Utils.formatTime(element.endTime);
                             
                            });	   
                            this.VisibleSpinner = false;               
                     }    
                     this.VisibleSpinner = false;                
                })
                .catch((error) => {
                  this.VisibleSpinner = false;     
                    if (error.response) {
                        this.showMessage('Error',error,"error");
                    }
                });  
    },
  },


mounted() {
  this.getAppt();
}
}

</script>