<template>
  <div id="ContainerGeneralNotes">
    <div class="row page-height">
      <div class="col-12">
        <MdTitle v-bind:Text="'General Notes'" />
        <div class="form-group">
            <div class="textlabel" data-label="Write note here">
                <textarea class="form-control" id="idNoteGeneral" rows="4"></textarea>
            </div>    
        </div>
        <div class="space-actions"></div>
        <div class="page-actions">
            <MdButton 
            v-bind:Label= "$t('Shared.Save')"
            v-bind:Name="'btn-GeneralNotes'"
            v-bind:ClassProp="'btn btn-primary btn-login mx-0'"
            v-bind:IconClass="'ti-save pr-1'"/>
        </div>
       </div>
    </div>
  </div> 
</template>

<script>

export default {
  name: 'GeneralNotes',
   
};
</script>


