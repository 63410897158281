<template>
   <div>
    <b-modal :id="modalId" size="md" centered scrollable  :title="TitleWindow" @ok="this.emitEventOK" @cancel="this.emitEventCancel" @hide="resetModal"> 
      <b-row style="width:450px;">      
        <b-col lg="8" md="8" sm="12">
          <MdLookup
                v-bind:Label="$t('Shared.Building')"
                v-bind:ModelCode="BuildingCode"
                v-bind:ModelDescription="dBuilding"
                v-bind:RefCode="'Building'"
                v-bind:Api="'Buildings'"
                v-bind:FieldCode="'code'"
                v-bind:FieldDescription="'name'"                              
                :SearchTitle="$t('Shared.Building')"
                :GetValues="onCodeConditionOk"
                :InvalidFeedback="'Invalid Building Code'"
                :SearchFieldCode="'Code'" />
        </b-col>       
      </b-row>
    </b-modal>
   </div>
</template>

<script>
export default {
  name: 'ModalEmployeeBuilding',
   props: {
     ModalId: String,
     TitleWindow:String
   },
   data(){
       return { 
          modalId:this.ModalId, 
          BuildingCode:"",
          dBuilding:""
       }
   },

   methods:{

    emitEventOK(){
       // EventBus.$emit('getCodeConditionOK', {buildingCode:this.BuildingCode,buildingName:this.dBuilding});
       this.$emit('getCodeConditionOK', {buildingCode:this.BuildingCode,buildingName:this.dBuilding});
    },
  
    emitEventCancel(){

    },

    onCodeConditionOk(result){
        var type = result.FieldName;       
             switch (type) {
                case "Building": 
                  this.BuildingCode = result.code;                    
                  this.dBuilding = result.description;                    
                  break;
             }
    },

    resetModal(){

    },

   },

   mounted(){
 
      this.$root.$on('bv::modal::show', () => {     
        this.$nextTick(() => {
          setTimeout(500);
        });
      });
   }
   
};
</script>