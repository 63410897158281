import ApiService from '@/services/api.service'


const DailyNotesService = {
    getAll( parameter) { 
         
      const requestData = {
        method: 'get',
        url: "/DailyNotes/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          RecordId : parameter.RecordId,
          NoteType : parameter.NoteType
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  
    get( id) {
      return ApiService.get(`/DailyNotes/${id}`);
    },

    addDailyNote(data){
      return ApiService.post(`/DailyNotes`,data)
    },

    deleteDailyNote(id){
      return ApiService.delete(`/DailyNotes/${id}`)
    },
    
    update(id,DailyNote){
      return ApiService.put(`/DailyNotes/${id}`,DailyNote)
    },

    updateIsVoid(recNo,data){
      return ApiService.put(`/DailyNotes/IsVoid/${recNo}`,data)
    },

    updateComment(recNo,noteEmployeeCode,data){
      return ApiService.put(`/DailyNotes/Comment/${recNo}/${noteEmployeeCode}`,data)
    }
}
  
  export default DailyNotesService;