<template>
  <div :id="targetId" oncontextmenu="return false;">
    <div v-if="!this.$attrs.control.isReadOnly && !NoteIsVoid && !this.$attrs.IsChartReview && !this.isReadOnly">
      <ejs-contextmenu :id="targetId" :target="menuItems.length > 0 ? menuTargetId : null" :items='menuItems' :select='onSelect'></ejs-contextmenu>
    </div>
          <b-form-textarea
            v-b-tooltip.hover :title="Hint"      
            :max-rows="MaxRows"
            :class="componentClass"
            :maxlength="this.$attrs.control.controlMaxLenght"
            v-model="Value"
            v-on:change="OnChange"
            v-bind:style="style"
            no-resize
            :ControlId="controlID"
            :readonly="this.$attrs.control.isReadOnly || NoteIsVoid || this.$attrs.control.IsChartReview || this.isReadOnly"
            v-on:blur="OnBlur($event)"
            v-on:input="onInput"
            :id="this.$attrs.HeaderId+'-'+this.$attrs.control.controlID"
          ></b-form-textarea>
    <MdPatientDiagCodes v-if="activateModalDiagCodes"
      v-bind:ModalId="controlID"
      v-bind:DateFrom="this.DateFrom"
      v-bind:TitleWindow="outComeTitle"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCancel="onCancel($event)"
    ></MdPatientDiagCodes>
    <MdPatientInsurances v-if="activateModalInsurance"
      v-bind:ModalId="controlID"
      v-bind:TitleWindow="outComeTitle"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCancel="onCancel($event)"
    ></MdPatientInsurances>
    <MdPatientContacts v-if="activateModalContacts"
      v-bind:ModalId="controlID"
      v-bind:TitleWindow="outComeTitle"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCancel="onCancel($event)"
    ></MdPatientContacts>
    <MdPatientAllergiesOutCome v-if="activateModalAllergies"
      v-bind:ModalId="controlID"
      v-bind:TitleWindow="outComeTitle"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCancel="onCancel($event)"
    ></MdPatientAllergiesOutCome>
    <MdGenericOutCome v-if="activateModalGeneric"
      v-bind:ModalId="controlID"
      v-bind:TitleWindow="outComeTitle"
      v-bind:outComeCode="outComeCode"
      v-bind:outComeDescription="outComeDescription"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCancel="onCancel($event)"
    ></MdGenericOutCome>
    <MdPainAssessmentNotesOutCome v-if="activeModalPainAssessment"
      v-bind:ModalId="controlID"
      v-bind:TitleWindow="outComeTitle"
      v-bind:outComeCode="outComeCode"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCancel="onCancel($event)"
    ></MdPainAssessmentNotesOutCome>
    <MdPatientVitalSingOutCome v-if="activeModalVitalSign"
      v-bind:ModalId="controlID"
      v-bind:TitleWindow="outComeTitle"
      v-bind:outComeCode="outComeCode"
      v-bind:ComponentKind="'Memo'"
      @getCodeConditionOK="onCodeConditionOk($event)"
      @getCancel="onCancel($event)"
    ></MdPatientVitalSingOutCome>
    <!-- <VueContext ref="menu">
        <a class="dropdown-item" @click="showModalGetLastAnswers()">
           Get 10 Last Answers
        </a>
        <a v-if="outComeDescription" class="dropdown-item" @click="showModal()">
           {{outComeDescription}}
        </a>
    </VueContext> -->

    <MdGetLastAnswers v-if="showGetLastAnswers"
      @getCancel="onCancelGetLastAnswers($event)"
      @getOK="onOKGetLastAnswers($event)"
      v-bind:ControlId="controlID"
      v-bind:PatientCode="PatientCode"
      v-bind:TransDate="TransDate"
    />
    <!-- <MdTextSpeech></MdTextSpeech> -->
  </div>
</template>

<script>
  import Utils from '@/common/utils.js'
  import { EventBus } from '../../event-bus.js';
  import Vue from 'vue';
  import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";

  Vue.use(ContextMenuPlugin);
  export default {
    mixins: [EventBus],
    props: {     
     MaxRows: String,
     Rows: String,
     MaxLength: String,
     isReadOnly:Boolean,
     value:[String],
     Name:String,
     Placeholder:String,
     TransDate: String,
     NoteId: String,
     DateFrom:String,
     id: [String, Number],
     PatientCode: String,
     IsCommonPart: Boolean 
   },
    data() {
      return {
         Value: this.value,
          NoteIsVoid:false,        
         style: {
          position:'absolute',
          border: 'solid 1px #e0e0e0 !important',
          borderRadius: '5px',
          padding: '4px 6px',
          backgroundImage: 'linear-gradient(130deg, white, rgb(241, 253, 255))',
          top: this.$attrs.control.controlTop+'px',          
          width:this.$attrs.control.controlWidth+'px',
          height:this.$attrs.control.controlHeight+'px',
          left:this.$attrs.control.controlLeft+'px',
          fontSize:'',
          fontWeight: '',
          fontFamily:'',
          cursor: 'default',
          color:'',
          textDecorationLine:'',
          fontStyle:'',
          textAlign: 'left',
          borderColor: '#e0e0e0 !important',    
        },
         Hint: this.$attrs.control.controlHint,
         controlID:String(this.$attrs.control.controlID),
         outComeDescription: Utils.strToTitleCase(this.$attrs.control.outComeDescription),
         outComeTemplateCode:this.$attrs.control.outComeTemplateCode,
         outComeTitle: Utils.strToTitleCase(this.$attrs.control.outComeDescription),
         outComeCode:this.$attrs.control.outComeCode,
         Invalid:false,
         isRequired: this.$attrs.control.isRequired,
         activateModalInsurance:false,
         activateModalContacts:false,
         activateModalDiagCodes:false,
         activateModalAllergies:false,
         activateModalGeneric:false,
         activeModalPainAssessment:false,
         activeModalVitalSign:false,
         showGetLastAnswers: false,
         //PatientCode: this.$route.params.id,
         componentClass: this.$attrs.control.outComeCode ? 'cutescroll cursor-context-menu outcome' : 'cutescroll cursor-context-menu',
         menuItems:[],
         targetId: "target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
         menuTargetId:"#target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
         cmpLoaded : false
      }
    },

  methods:{

     deActivateModal(){
       if(this.outComeTemplateCode === 'TFRMMDPATIENTCONTACTS')
       this.activateModalContacts = false
      else if(this.outComeTemplateCode === 'TFRMPatientInsurances')
       this.activateModalInsurance = false
      else if(this.outComeTemplateCode === 'TFRMPatientDiagCodes')
       this.activateModalDiagCodes = false
      else if(this.outComeTemplateCode === 'TFRMALLERGIESEHR')
       this.activateModalAllergies = false
      else if(this.outComeTemplateCode === 'TfmMdNoteOutComeStudies')
       this.activateModalGeneric = false
      else if(this.outComeTemplateCode === 'TFRMMdPainAssessmentNotes')
       this.activeModalPainAssessment = false
      else if(this.outComeTemplateCode === 'TFRMMdVitalSignsNotes')
       this.activeModalVitalSign = false
     },

     onCancel(){
       this.deActivateModal()
     },

     OnChange(value){
       this.Invalid = value.length == 0 && this.$attrs.control.isRequired ? true : false;
       this.style.borderColor = this.Invalid ? '#dc3545 !important': '#e0e0e0 !important'
       this.$emit('input',value)
     },

     onCodeConditionOk(result) {
       if(result) {
          this.Value = result;
          this.setChangedStatus( result )
       }
       this.deActivateModal()
     },

     OnBlur(evt){
       this.$emit('blur',evt.target.value)
       this.Invalid = evt.target.value.length == 0 && this.isRequired ? true : false;
       this.style.borderColor = this.Invalid ? '#dc3545 !important': '#e0e0e0 !important'
     },

     InitializeVariables(){
       if(this.$attrs.control.controlColorForHtml)
          this.style.backgroundColor = this.$attrs.control.controlColorForHtml;
       else if(this.$attrs.control.isReadOnly || this.isReadOnly)
          this.style.backgroundColor = '#e9ecef !important'
       if(this.$attrs.control.controlFontForHtml){
          this.controlFont = this.$attrs.control.controlFontForHtml.split(',');
          var size = parseInt(this.controlFont[1]) + 2;
          this.style.fontFamily = this.controlFont[0];
          this.style.fontSize = size+'px !important';
          this.style.color = this.controlFont[3];
          var Decorations = this.controlFont[2];
          this.style.fontWeight = Decorations.charAt(0) === '1'?'bold':'';
          this.style.fontStyle = Decorations.charAt(1) === '1'?'italic':'';
          this.style.textDecorationLine = Decorations.charAt(2) === '1'?'underline':'';
          this.style.textDecorationLine = Decorations.charAt(3) === '1'?this.style.textDecorationLine+ ' line-through':this.style.textDecorationLine;
       }
       this.setAlignment();
       this.cmpLoaded = 'MdTextSpeech' in Vue.options.components;
    },

    setAlignment() {
       if(this.$attrs.control.alignment) {
          if(this.$attrs.control.alignment === 'taCenter')
            this.style.textAlign = 'center'
          if(this.$attrs.control.alignment === 'taLeftJustify')
            this.style.textAlign = 'left'
          if(this.$attrs.control.alignment === 'taRightJustify')
            this.style.textAlign = 'right'
       }
     },

    showModal() {
      if(this.outComeTemplateCode === 'TFRMMDPATIENTCONTACTS')
       this.activateModalContacts = true
      else if(this.outComeTemplateCode === 'TFRMPatientInsurances')
       this.activateModalInsurance = true
      else if(this.outComeTemplateCode === 'TFRMPatientDiagCodes')
       this.activateModalDiagCodes = true
      else if(this.outComeTemplateCode === 'TFRMALLERGIESEHR')
       this.activateModalAllergies = true 
      else if(this.outComeTemplateCode === 'TfmMdNoteOutComeStudies')
       this.activateModalGeneric = true
      else if(this.outComeTemplateCode === 'TFRMMdPainAssessmentNotes')
       this.activeModalPainAssessment = true
      else if(this.outComeTemplateCode === 'TFRMMdVitalSignsNotes')
       this.activeModalVitalSign = true  
    },

    showModalGetLastAnswers() {
      this.showGetLastAnswers = true
    },

    onCancelGetLastAnswers(){
      this.showGetLastAnswers = false
    },

    onOKGetLastAnswers(value) {
      this.Value = value
      this.showGetLastAnswers = false
      this.setChangedStatus( value )
    },

    handlerClick(evt){
      if(!this.$attrs.control.isReadOnly && !this.isReadOnly) {
        this.$refs.menu.open(evt)
      }
    },

    setValid(state){
        this.style.borderColor = state ? '#dc3545 !important': '#e0e0e0 !important'
    },

    setChangedStatus( val ){
      this.$emit('input', val);
      var changed = {
        "ControlId":this.$attrs.control.controlID,
        "Changed":true,
        "NoteId":this.NoteId
      }
      EventBus.$emit('onControlWasChanged', changed);
    },

    onInput(value){
       this.setChangedStatus(value)
    },

    onSelect: function(args ) {
       if(args.item.id === 'gettenlastans') {
         this.showModalGetLastAnswers()
       }
       if(args.item.id === 'outcome') {
         this.showModal()
       }
       if(args.item.id === 'recording') {
          EventBus.$emit('speechBarLabel', 'stop recording');
          this.$root.$refs.MdTextSpeech.toggleSpeechBar()//Utils.toggleSpeechBar();
          this.$root.$refs.MdTextSpeech.startSpeechToText(this.$attrs.control.controlID)
       }
       if(args.item.id === 'speech') {
          EventBus.$emit('speechBarLabel', 'stop speech');
          this.$root.$refs.MdTextSpeech.toggleSpeechBar();
          this.$root.$refs.MdTextSpeech.startSpeech(this.Value)
       }
    },

    loadMenuItems() {
      this.menuItems = []
      if (!this.IsCommonPart) {
        this.menuItems.push({text: this.$t('Shared.GetLast10'), id: "gettenlastans"})
      }
      if(this.outComeDescription) {
        this.menuItems.push({text: this.outComeDescription, id: "outcome"})
      }
      if( this.cmpLoaded ) {
        if(this.$root.$refs.MdTextSpeech.isSpeechRecognitionSupported) {
          this.menuItems.push({text: this.$t('MdTextSpeech.record'), id: "recording"})
        }
        this.menuItems.push({text: this.$t('MdTextSpeech.speech'), id: "speech"})
      }
    },
    setValueS2T( sp ){
      let sp2t = ''
      if(this.value) {
        sp2t += Array.from (this.value + ""+sp).join("");
      } else {
        sp2t = Array.from (sp).join("");
      }
      this.Value = sp2t
      this.setChangedStatus( sp2t )
    }
  },

  created(){
    EventBus.$on("onSetReadOnly", function (e) {
      this.NoteIsVoid = e;
    }.bind(this));

    if(this.$attrs.NoteIsVoid){
      this.NoteIsVoid = this.$attrs.NoteIsVoid;
    }

    EventBus.$on("receivedS2T", function (event) {
      if( event.ControlId == this.$attrs.control.controlID) {
        this.setValueS2T( event.finalSentence )
      }
    }.bind(this))
},

  mounted(){
   this.InitializeVariables();
   this.loadMenuItems()
  }
}
</script>

<style>
.scroll{
	overflow: hidden !important;
}
</style>