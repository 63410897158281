<template>
 <fragment v-bind:style="style">     
        <Listbox 
            v-b-tooltip.hover :title="Hint"
            :disabled="this.$attrs.control.isReadOnly || this.isReadOnly"
            :listStyle="style" 
            v-on:input="$emit('input', listboxVM)" 
            v-model="listboxVM" 
            :multiple="true" 
            :options="dataItem" optionLabel="text">
          <template #option="slotProps">           
              <span :style="slotProps.option.style">{{slotProps.option.text}}</span>
          </template>         
        </Listbox>
    </fragment>
</template>

<script>

export default {
  name: 'MdListBox',
  props: {     
      value: Array,
      isReadOnly: Boolean,      
   },

  data(){
    return{
      listboxVM: this.value,
      color:'',
      style:{
      position:'absolute',          
      top: this.$attrs.control.controlTop+'px',
      width:this.$attrs.control.controlWidth+'px',
      height:this.$attrs.control.controlHeight+'px',
      left:this.$attrs.control.controlLeft+'px',
      overflowY:'auto',
      overflowX: 'auto',
      fontSize:'',
      fontWeight: '',
      color:'',
      background:'',
      textDecorationLine:'',
      fontStyle:''          
      },
      dataItem:[],      
      Hint: this.$attrs.control.controlHint,
    }
  },
  methods:{

  getObjectByText(text){
    var l = this.dataItem.length;
    var obj = {};
   for(var i = 0; i < l; i++){
     if(this.dataItem[i].text === text){
       obj = this.dataItem[i];
       break;
     }
   }
   return obj;
  },

    PopulateArrayValue(){
      var l = this.value.length;
      var obj = {};
      var arr = [];
      if(l > 0){
          for(var i = 0; i < l; i++){
            var item = this.getObjectByText(this.value[i]);
            obj.text = this.value[i];
            obj.id = item.id;
            obj.style = item.style;
            arr.push(obj);
            obj = {};
          }
      }
    },

    InitializeVariables(){   
      var color = '';     
      if(this.$attrs.control.controlColorForHtml){
         this.style.backgroundColor = this.$attrs.control.controlColorForHtml+' !important';         
       }   
      if(this.$attrs.control.controlFontForHtml){
         this.controlFont = this.$attrs.control.controlFontForHtml.split(',');
         var size = parseInt(this.controlFont[1]);
         this.style.fontFamily = this.controlFont[0];
         this.style.fontSize = size+'px !important';
         this.style.color = this.controlFont[3];
         color = 'color:' +this.controlFont[3];        
         var Decorations = this.controlFont[2];
         this.style.fontWeight = Decorations.charAt(0) === '1'?'bold':'';
         this.style.fontStyle = Decorations.charAt(1) === '1'?'italic':'';
         this.style.textDecorationLine = Decorations.charAt(2) === '1'?'underline':'';
         this.style.textDecorationLine = Decorations.charAt(3) === '1'?this.style.textDecorationLine+ ' line-through':this.style.textDecorationLine;
       
       }

       var temparr = this.$attrs.control.contents;
       this.index = this.$attrs.control.controlItemIndex;
       var result = [];
       
         temparr.forEach(function(element,index) { 
              var obj = { 'text':element, 'id':index,'style':color};
              result.push(obj);
           })
           this.dataItem = result;
            if(this.index !== -1){
              if(this.value.length > 0){
                this.listboxVM = this.value;
              }else{
                  this.listboxVM.push(this.dataItem[this.index]);
              }
            }else{
              this.listboxVM = this.value;
            }
    },
  },

  created(){
   this.InitializeVariables();
  },
  mounted(){

  }
   
};
</script>
<style>
  @import '../../assets/css/listbox/prime/theme.css';
</style>


