<template>
<div id="ContainerHcpcsCPT">
      <!-- <h4 v-if="this.Mode" class="page-title text-truncate"> Edit CPT & HCPCS & Revenue </h4>
       <h4 v-if="!this.Mode" class="page-title text-truncate"> Add CPT & HCPCS & Revenue </h4>  -->


      <h4 v-if="this.$attrs.editMode" class="page-title text-truncate">
        {{$t('cpt.Edit')}}
      </h4>
      <h4 v-if="!this.$attrs.editMode" class="page-title text-truncate">
        {{$t('cpt.Add')}}          
      </h4> 


        <b-row>
            <b-col lg="3" md="3" sm="12">
                 <MdEdit
                     v-model="HCPCSCPT.cpt"                    
                     v-bind:Label="$t('Shared.CPT')"          
                     v-bind:isReadOnly="this.$attrs.editMode"
                     v-bind:isRequired="true"
                     v-bind:MaxLengthProp="5"
                     v-bind:isValid="isHCPCSCPTValid"
                     v-on:blur="OnCodeBlur(HCPCSCPT.cpt)"
                     :InvalidFeedback="$t('cpt.isRequiredMsg')"
                     :SearchFieldCode="'cpt'"
                     v-bind:Id="'input_cpt'"
                  />
            </b-col>
            <b-col lg="3" md="3" sm="12">
                 <MdEdit 
                  v-bind:Label="$t('Shared.Category')"
                  v-bind:TypeProp="'text'"
                  v-bind:MaxLengthProp="2"
                  v-bind:Name="'Cat'"
                  v-model.number="HCPCSCPT.category"
                  v-bind:isReadOnly="isReadOnlyHCPCSCPT"
                  v-bind:Id="'input_catego'"
               >                                     
               </MdEdit>
            </b-col>
             <b-col lg="3" md="3" sm="12">
                 <MdLookup
                     v-bind:ModelCode="HCPCSCPT.ValCode"
                     v-bind:ModelDescription="dValCode"
                     v-bind:Label="$t('Shared.ValueCode')"
                     v-bind:RefCode="'ValCode'"
                     v-bind:Fields="ValCodeFields"
                     v-bind:Api="'valuecodes'"
                     v-bind:FieldCode="'code'"
                     v-bind:AuxFields="this.ValCodeAuxFields"
                     v-bind:FieldDescription="'shortDescription'"
                     :IsDisabled="isReadOnlyHCPCSCPT"    
                     :SearchTitle="$t('Shared.ValueCode')"
                     :GetValues="onCodeConditionOk"
                      v-bind:isRequired="true"
                     v-bind:isValid="isValCodeValid"
                     :InvalidFeedback="$t('cpt.ValRequired')"
                     :SearchFieldCode="'Code'"
                  />
            </b-col>
            <b-col lg="3" md="3" sm="12">
                <MdSelect
                  v-bind:Label="$t('Shared.Type')"
                  v-bind:Options="KindList"
                  v-bind:FieldDescription="'description'"
                  v-bind:FieldCode="'code'"
                  v-bind:Model="kindModel"                       
                  v-bind:Multiple="false"
                  :Input="ChangeDropDownKindList"
                  :IsDisabled="isReadOnlyHCPCSCPT"
               /> 
            </b-col>
        </b-row>
       
        <b-row>
            <b-col lg="3" md="3" sm="12">
                <MdEdit 
                  v-bind:Label="$t('cpt.Description1')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'Description1'"  
                  v-bind:MaxLengthProp="70"  
                  v-model="HCPCSCPT.description1"
                  v-bind:isReadOnly="isReadOnlyHCPCSCPT"
                  v-bind:isValid="isDescription1Valid"
                  :InvalidFeedback="$t('cpt.des1IsRequiredMsg')"
                  v-bind:Id="'input_des1'"
                  >                                     
               </MdEdit>
            </b-col>
             <b-col lg="3" md="3" sm="12">
                <MdEdit 
                  v-bind:Label="$t('cpt.Description2')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'Description2'" 
                  v-bind:MaxLengthProp="70" 
                  v-model="HCPCSCPT.description2"
                  v-bind:isReadOnly="isReadOnlyHCPCSCPT"
                  v-bind:Id="'input_des2'"
                  >                                     
               </MdEdit>
            </b-col>
            <b-col lg="3" md="3" sm="12">
                <MdEdit 
                  v-bind:Label="$t('cpt.Description3')"
                  v-bind:TypeProp="'text'" 
                  v-bind:Name="'Description3'"  
                  v-bind:MaxLengthProp="70" 
                  v-model="HCPCSCPT.description3"
                  v-bind:isReadOnly="isReadOnlyHCPCSCPT"
                  v-bind:Id="'input_des3'"
                  >                                     
               </MdEdit>
            </b-col>
        </b-row>


    <div class="page-actions">      
     <MdButton
               v-bind:Label="$t('cpt.CptList')"
               v-bind:Name="'btncptlist'"
               v-bind:ClassProp="'secondary mx-2 btn-login'"
               v-on:click="DiscardChanges"/>    
        <MdButton
            v-bind:Label="$t('Shared.Save')"
            v-bind:ClassProp="(this.isReadOnlyHCPCSCPT)?'primary mx-2':'primary btn-login mx-2'"
            v-on:click="SaveOrEdit"
            v-bind:isReadOnly="isReadOnlyHCPCSCPT"
            v-bind:Name="'btn_save_hcpcscpt'"/>
    </div>

</div>
</template>

<script>


import DBFunctions from '@/common/DBFunctions.js';
import ShowMessage from '@/components/messages/ShowMessage.js'
import CPTService from '@/services/cpt.service'
import { EventBus } from '../../../../event-bus.js';
//import Utils from '@/common/utils'

let elemSaveBtn = '';
export default {

    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            //console.log(vm);
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },

  name: 'CPT',
 mixins: [ShowMessage, DBFunctions/*,EventBus*/],
  data(){
    return{
        parameter:{
            SearchCriteria:'',
            PageNumber : 1,
            PageSize:0,         
        },
        pendingChanges:false,
         Menu : this.$store.state.leftMenu,
        permission:[],
        kindModel:{code:'CPT',description:this.$t('Shared.CPT')},
        HCPCSCPT:{
            cpt:'',
            ValCode:'',
            category:'',   
            kind:'CPT',       
            description1:'',
            description2:'',
            description3:''
        },
        dCPPHCPCS:'',
        dValCode:'',
        HCPCSCPTAuxFields:[],
        KindList:[
            {code:'CPT',description:this.$t('Shared.CPT')},
            {code:'HCPCS',description:this.$t('Shared.HCPCS')},
            {code:'REV',description: this.$t('cpt.Revenue')},
            {code:'UDEF',description: this.$t('cpt.UserDefined')},
            ],
        ValCodeAuxFields:[],
        ValCodeFields:['code','shortDescription'],
        CPTHCPCSFields:[],
        isReadOnlyHCPCSCPT:false,
        isHCPCSCPTValid:true,
        isDescription1Valid:true,
        CodeExist:false,
        isValCodeValid:true,
        OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },

    }
  },

  methods:{
    onCodeConditionOk(){

    },
    ChangeDropDownKindList(kind){
      if(kind){
        this.kindModel = kind;
        this.HCPCSCPT.kind = this.kindModel.code;
      }
      
    },

    CanView(){
      return this.permission[0].roleCanView;
  },

    DiscardChanges(){
       this.checkExistFavorite('/app/cpt');
       this.$router.push({name: "hcpcscpt"});
    },

    getCPT(){
      if(this.$attrs.editMode){
      this.$emit('load', true);     
      CPTService.GetCPT(this.$route.params.id)
        .then((response) => {
            if(response.status == '200' && response.data){
              this.HCPCSCPT = response.data;  
            }   
              this.$emit('load', false);                     
        })
        .catch((error) => {                    
            if (!error.response) {
              this.showMessage(this.$t('Shared.CPT'),this.$t('Msg.NotAbleToSave'),"error");
            }                      
            else if(error.response.status === 400 ){
              this.showMessage(this.$t('Shared.CPT'),error.response.data.errorMessage,"error");
            }
            else if(error.response.status === 405 ){             
                  this.$router.push({name: "Main"});    
                    setTimeout(() => 
                        this.showPermissionMessage()
                        , 50);            
            } 
            else if(error.response.status === 500){
              this.showMessage(this.$t('Shared.CPT'),this.$t('Msg.ThereWereSomeError'),"error");
            } else{
              this.showMessage(this.$t('Shared.CPT'),error.response.data.errorMessage,"error");
            } 
            this.$emit('load', false);            
        }); 
    
      }
    },

    AddCPT(){
      if(!this.CodeExist){
      this.$emit('load', true);
      CPTService.AddCPT(this.HCPCSCPT)
                .then((response) => {
                    if(response.status == '200'){
                        this.HCPCSCPT = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);
                        /*if(!this.OffRoute){
                           this.GotoAdmissionList();
                        }*/
                        this.$router.push({name: "hcpcscpt"}).catch(()=>{});
                      
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                             
                })
                .catch((error) => {
                    if (!error.response) {
                      this.showMessage(this.$t('Shared.CPT'),this.$t('Msg.NotAbleToInsert'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('Shared.CPT'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                      this.showMessage(this.$t('Shared.CPT'),this.$t('Msg.ThereWereSomeError'),"error");
                    } else{
                        this.showMessage(this.$t('Shared.CPT'),error.response.data.errorMessage,"error");
                    } 
                    elemSaveBtn.removeAttribute("disabled");
                    this.$emit('load', false);
                }); 
      }else{
         this.showMessage(this.$t('Shared.CPT'),this.$t('cpt.CodeExistMsg'),"error");
      }
    },


    EditCPT(){
      this.$emit('load', true);
      CPTService.UpdateCPT(this.$route.params.id,this.HCPCSCPT)
                .then((response) => {
                    if(response.status == '200'){
                        this.HCPCSCPT = response.data; 
                        this.pendingChanges = false;   
                        this.$emit('load', false);                       
                        this.$router.push({name: "hcpcscpt"}).catch(()=>{});
                      
                        setTimeout(() => 
                        this.$bvToast.toast(this.$t('Msg.SavedSuccessfully'), {
                         title: this.$t('Msg.Saving'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                    }                             
                })
                .catch((error) => {
                    if (!error.response) {
                   this.showMessage(this.$t('Shared.CPT'),this.$t('Msg.NotAbleToInsert'),"error");
                      }                      
                     else if(error.response.status === 400 ){
                        this.showMessage(this.$t('Shared.CPT'),error.response.data.errorMessage,"error");
                     } else if(error.response.status === 500){
                        this.showMessage(this.$t('Shared.CPT'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Shared.CPT'),error.response.data.errorMessage,"error");
                     }
                    elemSaveBtn.removeAttribute("disabled");  
                    this.$emit('load', false);
                }); 
    },

     DoAction(){       
        !this.$attrs.editMode ? this.AddCPT() : this.EditCPT(); 
      },

      SaveOrEdit(){ 
         if (this.validate_required()) {
            this.DoAction();
         } else {
            this.showMessage(this.$t('Shared.CPT'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");          
         }
      },

    validate_required() {
        this.isHCPCSCPTValid = this.HCPCSCPT.cpt.length > 0 ? true : false;   
        this.isDescription1Valid = this.HCPCSCPT.description1.length > 0 ? true : false;
        return this.isHCPCSCPTValid && this.isDescription1Valid;
      },

      OnCodeBlur(value){           
          if(!this.$attrs.editMode){           
            if (value.trim()!== '') {          
            var parameter = {
               "code":value
            };
            CPTService.getCPTByCode(parameter)
                .then((response) => {
                    if(response.status == '200' && response.data){
                      // this.$emit('load', false);  
                       if(response.data.length > 0){
                          this.showMessage(this.$t('Shared.CPT'),this.$t('cpt.CodeExistMsg'),"error");
                          this.CodeExist = true;
                       }else{
                           this.CodeExist = false;
                       } 
                    }  
                     this.$emit('load', false);                          
                })
                .catch((error) => {
                    this.$emit('load', false);                  
                  if(error.response.status !== 404 && error.response.status !== 500){
                      if(error.response) {
                        this.showMessage(this.$t('Shared.CPT'),error,"error");
                    }
                  }
                    
                });  
            }
               }
    
       },
      checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
      }
  },

  created(){
     EventBus.$on("onChanged", function () {       
          this.checkUserAction();
     }.bind(this));
     this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);      
  },

  mounted(){
      this.OptionsRightMenu.ItemsList = [];
       let layoutStatus = this.$store.state.layoutStatus;
       switch(layoutStatus) {
        case 'L0R1':
          this.OptionsRightMenu.favButtonAtion = false;
          this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        case 'L1R0':
        case 'L1R1':
            this.OptionsRightMenu.btnvisible = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
        default:
            this.OptionsRightMenu.favButtonAtion = false;
            this.$emit('getVisibleMenu', this.OptionsRightMenu);
      }
      this.getCPT();
      setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_hcpcscpt');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );      
  },

    destroyed(){
      this.OptionsRightMenu.ItemsList = [];
      this.OptionsRightMenu.Mode = false;
      this.OptionsRightMenu.visible = false; 
      this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
   
};
</script>


