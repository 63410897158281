<template>
<div id="ReportClinicalNotes">
    <MdTitle v-bind:Text="$t('rClinicalNotes.CliNotesReport')" />
    <b-row>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('Shared.datefrom')"
                v-bind:TypeProp="'date'" 
                v-bind:Name="'DateFrom'"
                v-model="DateFrom"
                v-bind:Min="'1900-01-01'"
                v-bind:Max="'2200-12-31'"
                v-on:change="DateFromChange($event)"
                v-bind:isValid="this.isDateFromValid">                                     
            </MdEdit>
        </b-col>
        <b-col md="3" lg="3" sm="12">
            <MdEdit 
                v-bind:Label="$t('Shared.dateTo')"
                v-bind:TypeProp="'date'" 
                v-bind:Name="'DateTo'"
                v-model="DateTo"
                v-bind:Min="'1900-01-01'"
                v-bind:Max="'2200-12-31'"
                v-on:change="DateToChange($event)"
                v-bind:isValid="this.isDateToValid">                                     
            </MdEdit>
        </b-col>
        <b-col lg="3" md="3" sm="12">         
            <MdSelect
              v-bind:Label="$tc('Shared.Patient',2)"
              v-bind:Options="PatientsList"
              v-bind:Model="PatientCodeModel"
              v-bind:FieldDescription="'patientName'"
              v-bind:FieldCode="'patientcode'"
              v-bind:Multiple="true"             
              :Input="ChangeDropDownPatients"
              :CustomLabel="customLabelPatients"/>
          </b-col>
           <b-col lg="3" md="3" sm="12">
             <MdSelect
                v-bind:Label="$tc('Shared.Program',2)"
                v-bind:Options="ProgramList"
                v-bind:FieldDescription="'name'"
                v-bind:FieldCode="'code'"
                v-bind:Multiple="true"
                v-bind:Model="ProgramModel"  
                :Input="ChangeDropDownPrograms"               
             />
           </b-col>			
    </b-row> 
    <b-row>
        <b-col lg="3" md="3" sm="12">
          <MdSelect
            v-bind:Label="$tc('Shared.Therapist',2)"
            v-bind:Options="TherapistList"
            v-bind:FieldDescription="'fullName'"
            v-bind:FieldCode="'code'"
            v-bind:Model="TherapistModel"  
            v-bind:Multiple="true" 
            :Input="ChangeDropDownTherapist"             
          />
        </b-col>
        <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Shared.Therapies')"
              v-bind:Options="TherapiesList"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Model="TherapiesModel"  
              v-bind:Multiple="true" 
              :Input="ChangeDropDownTherapies" 
            />
          </b-col> 
        <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Label="$t('Shared.NoteType')"
              v-bind:Options="NoteTypeList"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'notesid'"
              v-bind:Model="NoteTypeModel"  
              v-bind:Multiple="true" 
              :Input="ChangeDropDownNoteType"             
            />
          </b-col>
          <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Name="'SortOrder'"
              v-bind:Label="$t('rClinicalNotes.SortOrder')"
              v-bind:Options="SortOrderList"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'value'"
              v-bind:Model="SortOrderModel"  
              v-bind:Multiple="false" 
              :Input="ChangeDropDownSortOrder"
            />
          </b-col> 
    </b-row>  
    <b-row>
        <b-col lg="3" md="3" sm="12">
            <MdSelect
              v-bind:Name="'ExportPDFOptions'"
              v-bind:Label="$t('rClinicalNotes.ExpOpt')"
              v-bind:Options="ActionList"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'value'"
              v-bind:Model="ActionModel"  
              v-bind:Multiple="false" 
              :Input="ChangeDropDownAction"
              v-bind:isValid="this.isExportPDFOptionsValid"             
            />
        </b-col>
        <b-col lg="3" md="3" sm="12">
            <MdSelect
              ref="ExportPDFNameFormat"
              v-bind:Label="$t('rClinicalNotes.PdfName')"
              v-bind:Options="FormatSelectionList"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'code'"
              v-bind:Model="FormatSelectionModel"  
              v-bind:Multiple="false" 
              :Input="ChangeDropDownFormatSelection"
              v-bind:isValid="this.isFormatSelectionValid"             
          />
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdCheckBox
              v-bind:Label="$t('rClinicalNotes.IncVoid')"
              v-bind:Name="'IncludeVoid'"
              v-bind:ClassProp="'AlignCheck'"
              v-bind:CheckedValue="true"
              v-bind:UncheckedValue="false"
              v-model="IncludeVoid"                 
           />
        </b-col>
        <b-col lg="3" md="3" sm="12">
          <MdCheckBox
              v-bind:Label="$t('rClinicalNotes.IncPending')"
              v-bind:Name="'IncludePending'"
              v-bind:ClassProp="'AlignCheck'"
              v-bind:CheckedValue="true"
              v-bind:UncheckedValue="false"
              v-model="IncludePending"                 
           />
        </b-col>
    </b-row>
      <div class="page-actions">
        <MdButton
          v-bind:Label="$t('Shared.Back')"
          v-bind:ClassProp="'primary btn-login mx-2'"
          v-on:click="reportList"
          v-bind:Name="'btn_ReportList'"/>
        <MdButton
          v-bind:Label="$t('rClinicalNotes.ViewReport')"
          v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
          v-on:click="Print"
          v-bind:isReadOnly="isReadOnly"
          v-bind:Name="'btn_Print'"
        />
        <MdButton
          v-bind:Label="$t('rClinicalNotes.ExpReport')"
          v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
          v-on:click="Export"
          v-bind:isReadOnly="isReadOnly"
          v-bind:Name="'btn_Export'"
        />        
      </div>
      <NotesCompositeRep v-if="isReportVisible" 
        :closeWindow="closeReport"
        :reportTitle="reportTitle"
        :fileList="fileList"
      />
      <b-modal size="md" :hide-header-close="true" cancel-variant="primary" :cancel-title="$t('rClinicalNotes.DOWNLOAD')" :ok-title="modalButtonLabel" :ok-only="buttonOK" :no-close-on-backdrop="true" :no-close-on-esc="true" scrollable centered title="Creating Report" :visible="showModalProgress" @ok="emitEventOK" @hide="emitEventHide" @cancel="emitEventCancel">
      <div v-if="showProgressBar" id="ContainerProgress">
        <MdProgress
          v-bind:Label="progressLabel"
          v-bind:Max="maxValue"
          v-bind:ShowProgress="true"
          v-model="progressValue"
          v-bind:valueErr="ProgressValueErr"                 
        />
        <div v-if="progressLabel2" class="row">
          <small class="col-12">
            {{progressLabel2}}
          </small>
        </div>
        
      </div>
      <div v-if="this.failedList">
        <MdMemo
          v-model="this.failedList"
          v-bind:isReadOnly="true"
          v-bind:Rows="'10'"
        />
      </div>
      </b-modal>
</div>
</template>

<script>
import EnumService from '@/services/enum.service'
import Utils from '@/common/utils'
import PatientService from '@/services/patient.service'
import ShowMessage from '@/components/messages/ShowMessage.js'
import TherapistService from '@/services/therapist.service'
import ProgramService from '@/services/programs.services'
import GlobalService from '@/services/global.service'
import ClinicalNotesReportService from '@/services/clinicalnotesreport.service'
import DBFunctions from '@/common/DBFunctions.js'

export default {
  name: 'ReportClinicalNotes',
  mixins: [ShowMessage,Utils,DBFunctions],
  data(){
    return{
        isReadOnly:false,
        DateFrom:null,
        DateTo:null,
        PatientCodeModel:'',
        ProgramModel:'',
        TherapistModel:'',
        NoteTypeModel:'',
        TherapiesModel:'',
        SortOrderModel:{
          "value":"DATE_TIME_PATIENTCODE_THERAPISTSCODE",
          "name":this.$t('ENUM.ReporSort.A')
        },
        ActionModel:{
          "value":"EXPPDFPAT",
          "name":this.$t('rClinicalNotes.ExpByPatient')
        },
        FormatSelectionModel:'',
        IncludeVoid:false,
        Combined:false,
        IncludeReprinted:true,
        IncludePending:false,
        PatientsList:[],
        ProgramList:[],
        TherapistList:[],
        NoteTypeList:[],
        TherapiesList:[],
        SortOrderList:EnumService.ReportClinicalNotesSortOrder,
        FormatSelectionList:[],
        ActionList:EnumService.ReportClinicalNotesActions,
        OptionsRightMenu:{
          visible:false,
          ItemsList:[],
          btnvisible:false,
          itemdisabled: false,
          PatientCode:'',
          Mode:this.edit_mode,
          favButtonAtion:false          
        },
        Menu : this.$store.state.leftMenu, 

        parameter:{
          SearchCriteria:'',
          PageNumber : null,
          PageSize:null,
          Status: true,
        },
        notesReportParams:{
          DateFrom:'',
          DateTo: '',
          PatientCodeList: [],
          TherapistCodeList: [],
          ProgramCodeList: [],
          TherapiesCodeList: [],
          NoteTypeList: [],
          IncludeVoid: false,
          IncludePending: false,
          PDFExport: false,
          PDFNameFormatCode: 'EXPPDFPAT',
          PDFNameFormatSelection: '',
          OrderBy: 'DATE_TIME_PATIENTCODE_THERAPISTSCODE'
        },
      progressValue: 0,
      ProgressValueErr: 0,
      ClinicalNotesReport: [],
      maxValue: 0,
      timer: null,
      sec: 0,
      min: 0,
      hour: 0,
      progressLabel: '',
      isReportVisible: false,
      reportTitle: this.$t('rClinicalNotes.NotesRepTitle'),
      fileList: [],
      failedList: '',
      pdfExportPath: '',
      isExportPDFOptionsValid: true,
      isFormatSelectionValid: true,
      isDateFromValid: true,
      isDateToValid: true,
      showModalProgress: false,
      progressLabel2: '',
      modalButtonLabel: this.$t('Shared.BtnCancel'),
      cancelGuid: '',
      buttonOK: true,
      showProgressBar: true,
      isCompleted: false
    }
  },
  methods:{

    emitEventOK() {
      if (this.timer) {
        this.cancelMonitor()
      }
      this.showModalProgress = false
    },

    emitEventHide() {
      this.showModalProgress = false
    },

    emitEventCancel() {
      if (this.pdfExportPath)
        window.open(this.pdfExportPath, '_blank', 'noreferrer');
    },

    DateFromChange(){

    },

    DateToChange(){

    },

    getItemsByComma(values, field) {
      let len = values.length;
      let valuesSeparated = [];
      if (len > 0){
        var i = 0;
        values.forEach(function(e) {   
         if(i < len-1){
          valuesSeparated += e[field]+",";
         }else{
          valuesSeparated += e[field];
         }
         i++;                    
        });
        return valuesSeparated;
      }else{
        return [];
      }
    }, 

    ChangeDropDownPatients(values){
      this.notesReportParams.PatientCodeList = this.getItemsByComma(values, 'patientcode')
    },

    ChangeDropDownPrograms(values){
      this.notesReportParams.ProgramCodeList = this.getItemsByComma(values, 'code')
    },

    ChangeDropDownTherapist(values){
      this.notesReportParams.TherapistCodeList = this.getItemsByComma(values, 'code')
    },

    ChangeDropDownNoteType(values){
      this.notesReportParams.NoteTypeList = this.getItemsByComma(values, 'notesid')
    },

    ChangeDropDownTherapies(values){
      this.notesReportParams.TherapiesCodeList = this.getItemsByComma(values, 'code')
    },

    ChangeDropDownSortOrder(item){
      this.notesReportParams.OrderBy = item ? item.value : null
			this.SortOrderModel =  item ? item : null;
    },

    ChangeDropDownAction(value){
      this.notesReportParams.PDFNameFormatCode = value ? value.value : null
      this.isExportPDFOptionsValid = value ? true : ''
      this.ActionModel =  value ? value : null;
      this.FormatSelectionModel = null
      this.$refs.ExportPDFNameFormat.clearSelection()
      this.getFormatSelection(value ? value.value : null)
    },

    ChangeDropDownFormatSelection(value){
      this.notesReportParams.PDFNameFormatSelection = value ? value.description : null
      this.isFormatSelectionValid = value ? true : ''
    },

    Print() {
      this.isExportPDFOptionsValid = true
      this.isFormatSelectionValid = true
      this.isDateFromValid = true
      this.isDateToValid = true
      
      if (this.DateFrom && this.DateTo) {
        if (Utils.isDateFromAndDateTo(this.DateFrom,this.DateTo)) {
          this.$emit('load', true);
          this.notesReportParams.PDFExport = false
          this.getClinicalNotesReport()
        } else {
          this.showMessage(this.$t('rClinicalNotes.CliNotesReport'),this.$t('Msg.DateOutRange'),"error");
        }
      } else {
        this.isDateFromValid = this.DateFrom ? true: false
        this.isDateToValid = this.DateTo ? true : false
        this.showMessage(this.$t('rClinicalNotes.CliNotesReport'),this.$t('Msg.CheckRedLabeledFields'),"error");
      } 
    },

    Export() {
      if (this.notesReportParams.PDFNameFormatCode && this.notesReportParams.PDFNameFormatSelection) {
        if (Utils.isDateFromAndDateTo(this.DateFrom,this.DateTo)) {
          this.notesReportParams.PDFExport = true
          this.$emit('load', true);
          this.getClinicalNotesReport()
        } else {
          this.showMessage(this.$t('rClinicalNotes.CliNotesReport'),this.$t('Msg.DateOutRange'),"error");
        }
      } else {
        this.isExportPDFOptionsValid = this.notesReportParams.PDFNameFormatCode && 
                                       this.notesReportParams.PDFNameFormatCode.length > 0 ? true : false,
        this.isFormatSelectionValid = this.notesReportParams.PDFNameFormatSelection && 
                                      this.notesReportParams.PDFNameFormatSelection.length > 0 ? true : false
        this.isDateFromValid = this.DateFrom ? true: false
        this.isDateToValid = this.DateTo ? true : false                                  
        this.showMessage(this.$t('rClinicalNotes.CliNotesReport'),this.$t('Msg.CheckRedLabeledFields'),"error");
      }
    },

    getPatients() {              
     this.parameter.PageSize = this.perPage;
     this.parameter.PageNumber = this.currentPage;        
      PatientService.getAll(this.parameter)
      .then((response) => {
       if(response.status == '200' && response.data && response.data.length > 0){
        this.PatientsList = response.data; 
        this.PatientsList.forEach(function(element) {
            element.patientName = element.firstname + " " + element.lastname;   
          });
        }      
        this.$emit('load', false);
      })
      .catch(error => {
        //this.Loading = false;
        this.$emit('load', false);
        if (!error.response) {
            // network error
            this.showMessage(this.$t('rClinicalNotes.CliNotesReport'),this.$t('Msg.retrieveInfo'),"error");
        } else {
            this.showMessage(this.$t('rClinicalNotes.CliNotesReport'),error,"error");
        }
      });
    },

    getTherapists(){
        TherapistService.getAllTherapistReport()
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              let arr = response.data;
              for(var i = 0; i<arr.length; i++){
                arr[i].fullName = arr[i].firstName+" "+arr[i].lastName;
              }
              this.TherapistList = arr;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('rClinicalNotes.CliNotesReport'),error,"error");
            } 
          });  
      },

      getTherapies(){
        GlobalService.getTherapies()
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              this.TherapiesList = response.data;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('rClinicalNotes.CliNotesReport'),error,"error");
            } 
          });  
      },

      getNoteTypes(){
        GlobalService.getNoteTypesOnClinicalNotesReport(true,'FormDesingerNotes')
          .then((response) => { 
            if(response.data && response.data.length > 0){   
              this.NoteTypeList = response.data;             
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('rClinicalNotes.CliNotesReport'),error,"error");
            } 
          });  
      },

      getPrograms(){
        ProgramService.getAll(this.parameter)
          .then((response) => { 
            if(response.data && response.data.length > 0){   
                this.ProgramList = response.data;
            }
          })
          .catch(error => {
            if (error.response) {
              this.showMessage(this.$t('rClinicalNotes.CliNotesReport'),error,"error");
            } 
          });  
      },

      getFormatSelection(formatCode){
        if (formatCode)
          this.$emit('load', true);
        GlobalService.getFormatSelection(formatCode)
          .then((response) => { 
            if(response.data){   
              this.FormatSelectionList = response.data;
              this.$emit('load', false);           
            }
          })
          .catch(error => {
            if (error.response) {
              this.$emit('load', false);
              this.showMessage(this.$t('rClinicalNotes.CliNotesReport'),error,"error");
            } 
          });  
      },

      monitorClinicalNotesReport() {
         let counterDone = 0
         let counterFail = 0
         let urlZip = ''
         ClinicalNotesReportService.monitor(this.ClinicalNotesReport)
         .then((response) => {
            if(response.status == '200'){
              this.ClinicalNotesReport = response.data
              this.ClinicalNotesReport.forEach(element => {
                if (element.done === true) {
                  if (element.patientCode)
                    this.progressLabel2 = `${element.noteTitle} - ${Utils.formatterDateToString(element.transDate)} - ${element.patientCode}`
                if (element.url)
                  urlZip = element.url
                counterDone++
                } 
                else if (element.failed) 
                  counterFail++
              });
              this.progressValue = counterDone
              this.ProgressValueErr = counterFail
              if (counterDone === this.maxValue) {
                this.showFailedNotes()
                if (this.notesReportParams.PDFExport == true && this.isCompleted == false) {
                  this.buttonOK = false
                  this.failedList += `${this.$t('rClinicalNotes.ExpNotes')} ${counterDone - 1}\n\n`
                  this.failedList += `${this.$t('rClinicalNotes.Timeelapsed')} ${this.getElapseTime(this.hour,this.min,this.sec)}\n\n`
                  this.failedList += `${this.$t('rClinicalNotes.CompressFile')}`  
                  this.pdfExportPath = urlZip ? `${this.$store.state.apiurl.replace('/api/', '')}${urlZip}` : ''
                  this.isCompleted = true 
                }
                this.stopProgress()
                
                if (this.notesReportParams.PDFExport == false) {
                  this.fillFileList()
                  this.onShowReport()
                }
              }
            }
          })
         .catch((error) => {
            if (!error.response) {
            // network error
              this.stopProgress(this.$t('Msg.retrieveInfo'))
            } else {
              this.stopProgress(error)
            }
          });
      },

      cancelMonitor() {
         ClinicalNotesReportService.cancel(this.cancelGuid)
         .then((response) => {
            if(response.status == '200'){
              this.stopProgress(this.$t('rClinicalNotes.Canceled'))
            }
          })
         .catch((error) => {
            if (!error.response) {
            // network error
              this.stopProgress(this.$t('Msg.retrieveInfo'))
            } else {
              this.stopProgress(error)
            }
          });
      },

      showFailedNotes() {
        let counterFail = 0
        this.ClinicalNotesReport.forEach(element => {
          if (element.failed && element.patientCode) {
            counterFail++
            if (counterFail == 1)
              this.failedList = this.$t('rClinicalNotes.ImportantErrMsg')+'\n\n'
            this.failedList += `■ Note No. ${counterFail}\n‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾\n ${element.noteTitle}\n ${this.$t('Shared.PatientCode')}: ${element.patientCode}  Date: ${Utils.formatterDateToString(element.transDate)}\n\n\n`
          }
        });
      },

      fillFileList() {
        this.ClinicalNotesReport.forEach(element => {
          if (element.done && element.patientCode) {
            let transDate = Utils.formatDateCustom(element.transDate)
            let noteTime = Utils.formatTimeCustom(element.noteTime)
            let pattern = `${element.patientCode.trim()}-${element.formId.trim()}-${transDate}-${noteTime}-${element.isVoid}-${element.headerId}-${element.rowVersion}`
            this.fileList.push(pattern)
          } 
        });
      },

      getClinicalNotesReport() {
        this.notesReportParams.DateFrom = this.DateFrom;
        this.notesReportParams.DateTo = this.DateTo;
        this.notesReportParams.IncludeVoid = this.IncludeVoid;
        this.notesReportParams.IncludePending = this.IncludePending;
        ClinicalNotesReportService.getAll(this.notesReportParams)
          .then((response) => {
          if(response.status == '200' && response.data && response.data.length > 0){
            this.ClinicalNotesReport = response.data;
            this.startProcess()
            this.maxValue = response.data.length
          } else {
            this.$emit('load', false);
            this.showMessage(this.$t('rClinicalNotes.CliNotesReport'),this.$t('rClinicalNotes.NoInfound'),'warning');
          }      
          this.$emit('load', false);
          })
          .catch(error => {
            //this.Loading = false;
            this.$emit('load', false);
            if (!error.response) {
            // network error
              this.showMessage(this.$t('rClinicalNotes.CliNotesReport'),this.$t('Msg.retrieveInfo'),"error");
            } else {
              this.showMessage(this.$t('rClinicalNotes.CliNotesReport'),error,"error");
            }
          });
      },

      runMonitor() {
        this.timer = setInterval(()=> this.checkMonitor(), 2000)
      },

      stopProgress(msg) {
        clearInterval(this.timer)
        this.sec = 0
        this.min = 0
        this.hour = 0
        this.fileList = []
        this.failedList += msg ? `${msg}\n\n` : ''
        this.progressLabel2 = ''
        this.modalButtonLabel = this.$t('Shared.Close')
        this.showProgressBar = false
        this.isCompleted = false
      },

      async startProcess() {
        this.sec = 0
        this.min = 0
        this.hour = 0
        this.buttonOK = true
        this.fileList = []
        this.failedList = ''
        this.progressValue = 0
        this.progressLabel2 = ''
        this.showProgressBar = true
        this.modalButtonLabel = this.$t('Shared.BtnCancel')
        this.progressLabel = 'Processing...'
        this.pdfExportPath = ''
        this.$emit('load', true);
        this.showModalProgress = true
        await this.runMonitor()
        this.ClinicalNotesReport.forEach(element => {
          if (element.guid) {
            this.cancelGuid = element.guid
          }
        });
      },

      checkMonitor() {
        this.sec+=2
        if(this.sec >= 59){
          this.sec = 0;
          this.min++;
        }
        if(this.min >= 59){
          this.min = 0;
          this.hour++;
        }
        this.monitorClinicalNotesReport()
        this.progressLabel = `Processed ${this.progressValue} files of ${this.maxValue - 1} total - Time elapsed: ${this.getElapseTime(this.hour,this.min,this.sec)}`
      },

      closeReport() {
        this.isReportVisible = false
      },

      getElapseTime(hour,min,sec) {
        const nSec = (sec <= 9)? sec="0"+sec : sec
        const nMin = (min <= 9)? min="0"+min : min
        const nHour = (hour <= 9)? hour="0"+hour : hour
        return `${nHour}:${nMin}.${nSec}`
      },

      onShowReport() {
        if (!this.failedList)
          this.showModalProgress = false
        this.isReportVisible = true
      },

      reportList() {
        this.checkExistFavorite('/app/reports/notes');
        this.$router.push({ name: "reportListNotes" }).catch(()=>{});
      },
      customLabelPatients ({ patientName, patientcode }) {
        return `${patientName} – ${patientcode}`
      }
  },

  created(){
    this.getPatients();
    this.getTherapists();
    this.getPrograms();
    this.getTherapies();
    this.getNoteTypes();
    this.getFormatSelection('EXPPDFPAT');
  },

  mounted(){
    this.OptionsRightMenu.ItemsList = [];
    let layoutStatus = this.$store.state.layoutStatus;
    switch(layoutStatus) {
      case 'L0R1':
        this.OptionsRightMenu.favButtonAtion = false;
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
      case 'L1R0':
      case 'L1R1':
        this.OptionsRightMenu.btnvisible = false;
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
        break;
      default:
        this.OptionsRightMenu.favButtonAtion = false;
        this.$emit('getVisibleMenu', this.OptionsRightMenu);
    }
  }
};
</script>
<style>
  #fileList{
    padding: 1em;
  }
</style>