import ApiService from '@/services/api.service'

const EmployeeService = {

    getAll(parameter){
        const requestData = {
            method: 'get',
            url: "/Employees/",       
            params: {
              SearchCriteria: parameter.SearchCriteria,
              PageSize: parameter.PageSize,
              PageNumber: parameter.PageNumber,  
            }
          }       
          return ApiService.customRequest(requestData);
    }, 

    getEmployeesAll(){
      return ApiService.get(`/Employees`);
    },

    Add(data){
        return ApiService.post(`/Employees`, data);
    },
    Delete(Id){      
        return ApiService.delete(`/Employees/${Id}`);
    },
    Update(Id,data){
        return ApiService.put(`/Employees/${Id}`,data);
    },   
    Get(Id){     
        return ApiService.get(`/Employees/${Id}`);
    },
    getEmployeeByCode(parameter){
      const requestData = {
        method: 'get',
        url: "/Employees/",
        headers: {
        },
        params: {          
          code: parameter.code           
        }
      }     
      return ApiService.customRequest(requestData);       
    },

    getEmployees(UserId){
        return ApiService.get(`/Employees/?userid=${UserId}`);
    },

    getEmployeeCosigner(){
      return ApiService.get(`/Employees?Cosigner=true`);
  },

    getTherapist(){
        return ApiService.get(`/Employees/?ShowInNotes=true`);
    },
    getAllEmployees(){
        return ApiService.get(`/Employees/?Active=true`);
    },
    getAllEmployeeByType(){
        return ApiService.get(`/Employees?Type=T`);
    },

    getAllEmployeeByTypeParameter(type){
      return ApiService.get(`/Employees?Type=${type}`);
    },

    getEmployeeGroupNotes() {
        var param = {};
        var _Url = '/Employees/';     
      const requestData = {
        method: 'get',
        url: _Url,
        params: param
      }
      return ApiService.customRequest(requestData);
    },

}

export default EmployeeService;