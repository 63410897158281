<template>
  <div>
      <b-modal id="PasswordModal" size="md" :visible="true" :no-close-on-backdrop="true" :no-close-on-esc="true" :hide-header-close="true" centered :title="$t('insuranceSetup.Password')" @ok="this.emitEventOK" @cancel="this.emitEventCancel">
      <div v-if="errorMessage" class="pt-3">
          <div id="alert" class="alert alert-fill-danger" role="alert">
            <i class="mdi mdi-alert-circle"></i>
            {{ errorMessage }}
          </div>
      </div>
      <b-row>
         <b-col lg="12" md="12" sm="12">
            <MdPassword 
               v-bind:Label="$t('MdPasswordDlg.EnterPassword')"
               v-bind:TypeProp="'password'" 
               v-bind:Name="'password'"
               v-bind:Autocomplete="true"
               v-bind:NoValidateChanges="NoValidateChanges"
               v-bind:MaxLengthProp="45"
               v-model="password"
               ref="inputPassword"
               v-bind:Id="'inputPassword'"
               v-bind:isEndControl="true"
               v-bind:EndControlFunction="checkPassword"
               >                                     
            </MdPassword>
         </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import UserService from '@/services/user.service'
import StorageService from '@/services/storage.service'
  export default {
  props: {
    Operation:String,
    Type: String,
    UserId: String,
    VerificationType:String,
    ShowPopup: Boolean,
    NoValidateChanges: Boolean,  
  },
  
    mixins: [ShowMessage],
    data() {
      return { 
        password: '',
        errorMessage: null,
      }
    },
    
    methods:{
      emitEventOK(){
        this.checkPassword()
      },

      emitEventCancel(){
        this.$emit('getModalPassword', {"status":false,"type":this.Type,"Event":"Cancel"});
      }, 

      checkPassword() {
        const password = this.password;
        if(this.VerificationType === 'SupervisorPassword'){
          if(password !== ''){
            var data = {
              SupervisorPassword : password
            };
            UserService.verifySupervisorPassword(data)
               .then((response) => {
                 if(response.status == '200'){   
                  this.$emit('getModalPassword', {"status":response.data,"type":this.Type,"Event":"Ok","Operation":this.Operation});             
                  /*if(response.data === true){
                    this.$emit('getModalPassword', {"status":true});
                  }else{
                    this.$emit('getModalPassword', {"status":false});
                  }*/              
                   
                 }
               })
                .catch((error) => {
                   if (!error.response) {
                      this.showMessage(this.$t('Shared.VerifyPassword'),this.$t('MdPasswordDlg.NoAblePasswordMsg'),"error");
                  } else {
                      this.$emit('getModalPassword',{"status":false,"type":this.Type,"Event":"Ok","Operation":this.Operation,"message":error.response.data.message});
                  }
                });
          }else{             
            this.$emit('getModalPassword',{"status":false,"type":this.Type,"Event":"Ok","Operation":this.Operation});        
          }
        }else{
          const clientId = StorageService.getClientId();         
        if( clientId !== '' && this.UserId !== '' && password !== '' ){
         UserService.verifyPassword( clientId, this.UserId, password)
               .then((response) => {
                 if(response.status == '200'){
                   this.$emit('getModalPassword', {"status":true,"type":this.Type,"Event":"Ok","Operation":this.Operation});
                 }
               })
                .catch((error) => {
                   if (!error.response) {
                      this.showMessage(this.$t('Shared.VerifyPassword'),this.$t('MdPasswordDlg.NoAblePasswordMsg'),"error");
                  } else {
                      this.$emit('getModalPassword',{"status":false,"type":this.Type,"Event":"Ok","Operation":this.Operation,"message":error.response.data.message});
                  }
                });
        } else {
          this.$emit('getModalPassword',{"status":false,"type":this.Type,"Event":"Ok","Operation":this.Operation});
        }
        }

       


      }



    },

    mounted(){
      this.$nextTick(() => {
        setTimeout(() =>  this.$refs.inputPassword.setFocus(), 500);
      });

      /*window.addEventListener('keyup', event => {
        if (event.keyCode === 13) { 
          this.emitEventOK()
        }
      })*/
    }
  }

</script>