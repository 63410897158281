<template>
   <div id="ContainerDailyNote">
      <div class="row page-height">
         <div class="col-12">

            <h4 v-if="this.Mode" class="page-title text-truncate">
               {{ $t('DailyNote.EditDn') }} -
                  <span v-if="this.RegistrationInfo.patientName">{{this.RegistrationInfo.patientName+' - '}}</span>
                  <span v-if="this.$route.params.id">{{this.$route.params.id + ' - '}}</span>
                  <span v-if="this.RegistrationInfo.dob"> {{ $t('Shared.Dob') }} {{this.RegistrationInfo.dob}}</span>
                  <span v-if="this.RegistrationInfo.ageFormatted"> {{ $t('Shared.Age') }} {{this.RegistrationInfo.ageFormatted}}</span>
                  <span v-if="this.RegistrationInfo.programName"> - {{this.RegistrationInfo.programName}}  </span>
                  <span v-if="this.RegistrationInfo.dateFrom">- {{this.RegistrationInfo.dateFrom}}  </span>
                  <span v-if="this.RegistrationInfo.dateTo">- {{this.RegistrationInfo.dateTo}}  </span>
                  <span v-else>- {{ $t('Shared.Active') }} </span>        
            </h4>
            <h4 v-if="!this.Mode" class="page-title text-truncate">
               {{ $t('DailyNote.AddDn') }} -
                  <span v-if="this.RegistrationInfo.patientName">{{this.RegistrationInfo.patientName+' - '}}</span>
                  <span v-if="this.$route.params.id">{{this.$route.params.id + ' - '}}</span>
                  <span v-if="this.RegistrationInfo.dob"> {{ $t('Shared.Dob') }} {{this.RegistrationInfo.dob}}</span>
                  <span v-if="this.RegistrationInfo.ageFormatted"> {{ $t('Shared.Age') }} {{this.RegistrationInfo.ageFormatted}}</span>
                  <span v-if="this.RegistrationInfo.programName"> - {{this.RegistrationInfo.programName}}  </span>
                  <span v-if="this.RegistrationInfo.dateFrom">- {{this.RegistrationInfo.dateFrom}}  </span>
                  <span v-if="this.RegistrationInfo.dateTo">- {{this.RegistrationInfo.dateTo}}  </span>
                  <span v-else>- {{ $t('Shared.Active') }} </span>      
            </h4>
            <MdTitle v-bind:Text="NoteTypeName ? NoteTypeName : null" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
            <div class="row">
               <b-col lg="3" md="3" sm="12">
               <MdEdit 
                  v-bind:Label="$t('VitalSign.DateAndTime')"
                  v-bind:Name="'DateAndTime'"
                  v-bind:TypeProp="'text'" 
                  v-bind:Mask="'##/##/#### ##:## AA'"
                  v-bind:Min="'1900-01-01'"
                  v-bind:Max="'2200-12-31'"              
                  v-bind:isRequired="true"
                  v-bind:isValid="IsValidDateandTime"
                  v-model="DailyNote.serviceDate"
                  v-bind:isReadOnly="isReadOnly"
               >                                     
               </MdEdit>
            </b-col>
            </div>
            <div class="row">
               <div class="col-12 mt-2">
                  <MdRichEdit
                     v-model="DailyNote.comments"
                     v-bind:isReadOnly="isReadOnly"
                     v-bind:Height="'60vh'"
                     v-bind:id="'edit_dailynote'"
                  />
               </div>
            </div>           
         </div>
      </div>
      <div class="space-actions"></div>
      <div class="page-actions">
         <MdButton
         v-bind:Label="$t('Shared.Back')"
         v-bind:Name="'btn_list_dailynotes'"
         v-bind:ClassProp="'secondary mx-2 btn-login'"
         v-on:click="DiscardChanges"/>
   
      <MdButton
         v-bind:Label="$t('Shared.Save')"
         v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
         v-on:click="SaveOrEdit"
         v-bind:isReadOnly=this.isReadOnly
         v-bind:Name="'btn_save_dailynote'"/>
      </div>
      <MdPasswordDlg v-if="showSignPassword" 
        v-bind:Type="TypePassWord"
        v-bind:UserId="UserIdSelected"
        @getModalPassword="onModalPasswordResult($event)"
    />
   </div>
</template>

<script>

import ShowMessage from '@/components/messages/ShowMessage.js'
import DBFunctions from '@/common/DBFunctions.js'
import EnumService from '@/services/enum.service' 
import { EventBus } from '@/event-bus.js';
import DailyNotesService from '@/services/dailynotes.service'
import moment from "moment";
import LocalStorage from '@/services/storage.service';
import GlobalService from '@/services/global.service' 
import Utils from '@/common/utils'

let elemSaveBtn = '';
export default {
   beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iUA = await vm.$store.state.isUseDailyNotes;
            if(iUA) next()
            else next('/accessdenied');
        }) 
   },

   beforeRouteLeave(to, from, next) {
	if (!this.isReadOnly && this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('DailyNote.Dn'),
          okTitle: this.$t('Shared.BtnSave'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              next();
            } else {
              this.SaveOrEdit();
              next();
            }
          })		
	} else {
		next()
	}
    },

   mixins: [ShowMessage, DBFunctions],  
   name: 'DailyNoteDetails',
    data() {
      return { 
        Mode:this.$attrs.editMode,  
        Loading:true,
        isReadOnly: false,
        pendingChanges: false,
        OptionsRightMenu:{
         visible:true,
         ItemsList:[],
         btnvisible:true,
         itemdisabled: false,
         PatientCode:''         
        },
        RegistrationInfo: {},
        CommentsModel: null,
        DailyNote: {
           'noteType': this.$route.params.notetype,
           'patientCode': this.$route.params.id,
           'recordId': this.$route.params.recordid,
           'comments': null
        },
        UpdateComments: {
           'comments': null,
           'serviceDate': null
        },
        NoteTypeName: '',
        style:{
         boxShadow: '0 0.125rem 0.25rem rgb(0 0 0 / 8%) !important',
         width: '100%',
         backgroundImage: '-webkit-linear-gradient(268deg, #FAFAFA, #FAFAFAEE)',
         borderRadius: '0 0 14px 14px',
         borderTop: '1px solid #EEE',
         padding: '10px',
         textAlign: 'right'     
         },
        showSignPassword: false,
        TypePassWord:'',
        UserIdSelected:'',
        UserId: LocalStorage.getUserId(),
        IsValidDateandTime: true,
      }
    },


    methods:{
      DiscardChanges() {
         this.$router.push({
            name: "Clinical-daily-note",
            params: { id: this.$route.params.id, recordid: this.$route.params.recordid },
         });
      },

      getDailyNoteById(){
         DailyNotesService.get( this.$route.params.dailynoteid )
            .then((response) => {
               if(response.status == '200' && response.data){
                 this.DailyNote = response.data;
                 this.DailyNote.serviceDate = Utils.formatterDateToString(response.data.serviceDate) + ' ' + Utils.formatTime(response.data.serviceTime)
                 this.$emit('load', false);
               }
            })
            .catch((error) => {
               this.$emit('load', false);
               if (!error.response) {
                  // network error
                  this.showMessage(this.$t('DailyNote.Dn'),this.$t('Msg.retrieveInfo'),"error");
               } else {
                  this.showMessage(this.$t('DailyNote.Dn'),error,"error");
               }
            });
      },
 
      AddDailyNote(){
         this.$emit('load', true);
         this.DailyNote.serviceDate = moment(this.DailyNote.serviceDate).format('YYYY-MM-DDTHH:mm:ss');
         DailyNotesService.addDailyNote(this.DailyNote)
         .then((response) => {           
             if(response.status == '200'){
                  this.$emit('load', false);
                  this.pendingChanges = false;                          
                 if(this.$route.name === 'dailynote-add'){
                        this.$router.push({
                           name: "Clinical-daily-note",
                           params: { id: this.$route.params.id, recordid: this.$route.params.recordid },
                        });
                     }                    
                    setTimeout(() => 
                        this.$bvToast.toast(this.$t('DailyNote.DnSavedSuccessfully'), {
                         title: this.$t('DailyNote.SavingDn'),
                          autoHideDelay: 5000,
                          variant: "success"
                        })
                        , 50); 
                }
            })
         .catch((error) => {
            this.$emit('load', false);
            elemSaveBtn.removeAttribute("disabled");
            if (!error.response) {
               this.showMessage(this.$t('DailyNote.Dn'), this.$t('Msg.NotAbleToInsert'),"error");
            }          
            else if (error.response.data && error.response.data.errorMessage) {
               this.showMessage(this.$t('DailyNote.Dn'),error.response.data.errorMessage,"error");
            }
            else {
               this.showMessage(this.$t('DailyNote.Dn'),this.$t('Msg.ThereWereSomeError'),"error");
            }
         });
      },

      UpdateDailyNote(){
         this.$emit('load', true);
         this.UpdateComments.comments = this.DailyNote.comments
         this.UpdateComments.serviceDate = moment(this.DailyNote.serviceDate).format('YYYY-MM-DDTHH:mm:ss');
         
         DailyNotesService.updateComment(this.$route.params.dailynoteid, this.$route.params.noteEmployeeCode, this.UpdateComments)
        .then((response) => {
            if(response.status == '200'){
               this.pendingChanges = false;
               if(this.$route.name === 'dailynote-details'){
                  this.$router.push({
                     name: "Clinical-daily-note",
                     params: { id: this.$route.params.id, recordid: this.$route.params.recordid },
                  });     
               }                    
                  setTimeout(() => 
                     this.$bvToast.toast(this.$t('DailyNote.DnSavedSuccessfully'), {
                         title: this.$t('DailyNote.SavingDn'),
                          autoHideDelay: 5000,
                          variant: "success"
                     })
                  , 50); 
                }
            })
        .catch((error) => {
            this.$emit('load', false);
            elemSaveBtn.removeAttribute("disabled");
            if (!error.response) {
               this.showMessage(this.$t('DailyNote.Dn'), this.$t('Msg.NotAbleToUpdate'),"error");
            }          
            else if (error.response.data && error.response.data.errorMessage) {
               this.showMessage(this.$t('DailyNote.Dn'),error.response.data.errorMessage,"error");
            }
            else {
               this.showMessage(this.$t('DailyNote.Dn'),this.$t('Msg.ThereWereSomeError'),"error");
            }
        });
      },

      SaveOrEdit(){
        if(!this.isReadOnly) {
         if (this.validate_entries()) {
            this.$emit('load', true);
            elemSaveBtn.setAttribute("disabled",'');
            this.pendingChanges = false         
            if (this.Mode) { 
               this.UpdateDailyNote()           
            } else {            
               this.AddDailyNote()
            }
         }      
         else {
            this.showMessage(this.$t('DailyNote.Dn'), this.$t('DailyNote.MandatoryValue'),"errorAutoHide");
         }
        }
      },

      beforeWindowUnload(e) {
			if (this.pendingChanges) {
				e.preventDefault()
				e.returnValue = ''	
			}
      },
      
      validate_entries() {
         this.IsValidDateandTime = this.DailyNote.serviceDate ? true : false
         return this.IsValidDateandTime && this.DailyNote.comments ? true : false
      },

      checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
      },

      onModalPasswordResult(result) {
        if(result.type === 'Sign'){
          if(result.status !== true) {
            this.showMessage(this.$t('Shared.VerifyPassword'),result.message,"error");
          }else{
            this.AddDailyNote()
          }
        }
        this.showSignPassword = false;
      },

      onSignPassword() {
        this.TypePassWord = 'Sign'
        this.UserIdSelected = this.UserId  
        this.showSignPassword = true        
      },

      async getDateTime() { 
         await GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {
                  this.DailyNote.serviceDate = moment(response.data).format('MM/DD/YYYY hh:mm A');                  
               }
         })
         .catch(error => {
            if (error.response) {
               this.showMessage(this.$t('DailyNote.Dn'),error,"error");
            }
         }); 
      },
    },

    async mounted(){
      this.OptionsRightMenu.visible = true;
    this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    this.$emit('getBtnRightMenu', this.OptionsRightMenu);
    this.isReadOnly = this.$store.state.isDailyNotesEHRReadOnly;

      this.NoteTypeName = this.$route.params.noteTypeName,
      this.isReadOnly = await this.$store.state.isDailyNotesEHRReadOnly;
      this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
      if(this.RegistrationInfo.dateFrom)
			this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
		if(this.RegistrationInfo.dateTo)
			this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');	
      if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;
      if (this.Mode) {
         this.$emit('load', true);
         this.getDailyNoteById() 
      }
      setTimeout( function() {
         elemSaveBtn = document.getElementById('btn_save_dailynote');
         elemSaveBtn.setAttribute('disabled','');
      }, 50, this );
    },

    created () {
       EventBus.$on("onChanged", function (e) {       
          this.pendingChanges = e.Changed
          this.checkUserAction();
     }.bind(this));
     window.addEventListener('beforeunload', this.beforeWindowUnload)
     if (!this.Mode)
         this.getDateTime();
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload)
    },
  } 
</script>


