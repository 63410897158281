import ApiService from '@/services/api.service'

const Companies = {

    getAll(){
        return ApiService.get('/Companies');
    },

    get( id) {
        return ApiService.get(`/Companies/${id}`);
      },

    update(id, company) { 
        const requestData = {
          method: 'put',
          url: `/Companies/${id}`,
          headers: {},
          data: company
        }
        return ApiService.customRequest(requestData);
      }
}

export default Companies;