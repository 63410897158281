<template>
    <div id="ContainerPatientePharmacy">
        <ePharmacyBase v-bind:Origin="'Patient'" />
    </div>
</template>

<script>

import DBFunctions from '@/common/DBFunctions.js'
import EnumService from '@/services/enum.service' 
//import { EventBus } from '@/event-bus.js';
export default {
    beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
  name: 'ePharmaryPatient',
   mixins: [DBFunctions],
   data(){
    return {
        permission:[],
        Menu : this.$store.state.leftMenu,
        OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false              
      },
    }
   },

   methods:{
    CanView(){
      return this.permission[0].roleCanView;
    },
   },

   mounted(){
    this.OptionsRightMenu.visible = true;
    this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    this.$emit('getBtnRightMenu', this.OptionsRightMenu);
   },

   created(){
   
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);    
   /* EventBus.$on("ShowWeel", function (isShow) { 
        isShow ? this.$emit('load', true) : this.$emit('load', false);     
     }.bind(this));*/
    },
};
</script>