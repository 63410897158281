import ApiService from '@/services/api.service'

const ClinicalNotesReportService = {
  getAll(parameter) { 
    var param = {};
    var _Url = '/ClinicalNotesReport';
    param.DateFrom = parameter.DateFrom,
    param.DateTo = parameter.DateTo,
    param.PatientCodeList = parameter.PatientCodeList
    param.TherapistCodeList = parameter.TherapistCodeList
    param.ProgramCodeList = parameter.ProgramCodeList
    param.TherapiesCodeList = parameter.TherapiesCodeList
    param.NoteTypeList = parameter.NoteTypeList
    param.IncludeVoid = parameter.IncludeVoid
    param.IncludePending = parameter.IncludePending
    param.PDFExport = parameter.PDFExport
    param.PDFNameFormatCode = parameter.PDFNameFormatCode
    param.PDFNameFormatSelection = parameter.PDFNameFormatSelection
    param.OrderBy = parameter.OrderBy


    const requestData = {
      method: 'get',
      url: _Url,
      params: param
    }
    return ApiService.customRequest(requestData);
  },

  monitor(data){
    return ApiService.post(`/ClinicalNotesReport/Monitor`,data)
  },

  cancel(guid){
    return ApiService.delete(`ClinicalNotesReport/Cancel/${guid}`)
  },
}
  
  export default ClinicalNotesReportService;