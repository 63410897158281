import ApiService from '@/services/api.service'

const TherapyService = {

   /* getTherapyGroupNotes(param) {        
        var _Url = '/therapies/groupnotes/';        
      const requestData = {
        method: 'get',
        url: _Url,
        params: param
      }
      return ApiService.customRequest(requestData);
    },*/

    // return ApiService.get(`/PatientVitalSigns/${id}`);

    getTherapyGroupNotes(ProgramCodes) {
      return ApiService.get(`/therapies/groupnotes/${ProgramCodes}`);
    },
    getTherapyTreatmentPlan(admid) {
      return ApiService.get(`/therapies/?CanUseInTreatmentPlan=true&AdmissionId=${admid ?? ""}`);
    },
    get(id) {
      return ApiService.get(`/therapies/${id}`);
    },    
}

export default TherapyService;