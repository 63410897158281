<template>
  <div :id="targetId" v-bind:style="stylediv" oncontextmenu="return false;">
    <div v-if="!this.$attrs.control.isReadOnly && !NoteIsVoid && !this.$attrs.IsChartReview && !this.isReadOnly">
      <ejs-contextmenu :id="targetId" :target="menuItems.length > 0 ? menuTargetId : null" :items='menuItems' :select='onSelect'></ejs-contextmenu>
    </div>
            <!-- <h4 class="page-title text-truncate"><b-button size="sm" @click="addNewEmptyRecord">+</b-button></h4> -->
            <div class="table-notes-title" v-bind:style="styleTitle">
              <div class="text-truncate t-header">{{this.Title}}</div>
              <div class="btn-group btn-group-sm float-sm-right" role="group" aria-label="Table options">
                 <!--<b-button :disabled="!CanDelete || TableIsReadOnly" size="sm" @click="Refresh" class="table-title-btn">Refresh</b-button>-->
                <b-button :hidden="!CanDelete || TableIsReadOnly || !CanCalculate || NoteIsVoid" size="sm" @click="Save" class="table-title-btn">{{$t('MdEditTableCN.Calculate')}}</b-button>
                <b-button :hidden="!CanDelete || TableIsReadOnly || NoteIsVoid" size="sm" @click="Clear" class="table-title-btn">{{$t('MdEditTableCN.Clear')}}</b-button>
                <b-button :hidden="!CanInsert || TableIsReadOnly || NoteIsVoid" size="sm" @click="addNewEmptyRecord(false)" class="table-title-btn">{{$t('Shared.Add')}}</b-button>
                <b-button :hidden="!CanDelete || TableIsReadOnly || NoteIsVoid" size="sm" @click="DeleteRecord" class="table-title-btn">{{$t('Shared.Delete')}}</b-button>               
              </div>
            </div>
            <b-table
                :sticky-header="this.tableHeight+'px'"
                v-b-tooltip.hover :title="Hint"
                selectable
                class="table table-sm  medezTables noteTable cutescroll cursor-context-menu"
                :select-mode="fMultiSelect"
                :items="items"
                :fields="fFields"
                :current-page="cPage"                
                @row-selected="rowSelected"
                responsive
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="filtered"
                v-bind:style="style"
                :id="controlID"
            >
            <template #cell(actions)="row">
                <b-icon v-if="deleteIconEvent && !isReadOnly"  icon="trash-fill" @click="deleteButton(row.item)" v-b-tooltip.hover.top="'Delete'" class="rounded-circle tableActionBTN-danger ml-1" animation="fade" scale=".5" font-scale="1.8" variant="light"></b-icon>
            </template>
            <template v-slot:cell()="row">  
                <input
                type="text"
                class="form-control"                 
                :readonly="row.field.isReadOnly || TableIsReadOnly || NoteIsVoid" 
                v-if="row.field.lookUpValues === null && row.field.dataType !=='INT' && row.field.dataType !=='FLOAT' && row.field.dataType !=='MONEY' && row.field.dataType !=='TIME' && row.field.dataType !== 'DATETIME' && row.field.dataType !== 'dateTime' && row.field.dataType !== 'datetime' && row.field.dataType !== 'DATE'" 
                :maxlength="row.field.MaxLength"      
                v-model="row.item[row.field.key]"
                v-mask="row.field.mask ? row.field.mask : null"
                :key="row.field.key"               
                v-on:focus="OnFocusRow(row)"                           
                v-on:blur="OnBlur(row,$event)"
                v-on:change="cellChange()"
                v-on:keyup.down="keyDown(row.field.key + '_' + row.index)"
                v-on:keyup.up="keyUp(row.field.key + '_' + row.index)"
                v-on:keyup.esc="keyEsc(row.field.key + '_' + row.index)"
                :ref="NoteId + '_' + controlID + '_' + row.field.key + '_' + row.index"
                :id="NoteId + '_' + controlID + '_' + row.field.key + '_' + row.index"
                v-bind:style="'border: 0px solid transparent !important; padding: 0 0px !important; min-width: '+ getMinCellWidth(row) + 'px'"
                v-on:input="onInput($event)"
                />  
                <input
                type="number"
                class="form-control"                 
                :readonly="row.field.isReadOnly || TableIsReadOnly || NoteIsVoid" 
                v-if="row.field.lookUpValues === null && row.field.dataType ==='INT' || row.field.dataType ==='FLOAT' || row.field.dataType ==='MONEY'" 
                :maxlength="row.field.MaxLength"      
                v-model="row.item[row.field.key]"
                v-mask="row.field.mask ? row.field.mask : null"
                :key="row.field.key"               
                v-on:focus="OnFocusRow(row)"                           
                v-on:blur="OnBlur(row,$event)"
                v-on:change="cellChange()"
                v-on:keyup.down="keyDown(row.field.key + '_' + row.index)"
                v-on:keyup.up="keyUp(row.field.key + '_' + row.index)"
                v-on:keyup.esc="keyEsc(row.field.key + '_' + row.index)"
                :ref="NoteId + '_' + controlID + '_' + row.field.key + '_' + row.index"
                :id="NoteId + '_' + controlID + '_' + row.field.key + '_' + row.index"
                v-bind:style="'border: 0px solid transparent !important; padding: 0 0px !important; min-width: '+ getMinCellWidth(row) + 'px'"
                v-on:input="onInput($event)"
                />   
                
                <input
                type="time"  
                class="form-control time"           
                :readonly="row.field.isReadOnly || TableIsReadOnly || NoteIsVoid" 
                v-if="row.field.dataType === 'TIME'"
                v-mask="row.field.mask ? row.field.mask : null"                
                :maxlength="row.field.MaxLength"
                v-model="row.item[row.field.key]"
                :key="row.field.key"  
                :ref="NoteId + '_' + controlID + '_' + row.field.key + '_' + row.index"
                :id="NoteId + '_' + controlID + '_' + row.field.key + '_' + row.index"
                v-on:focus="OnFocusRow(row)"                           
                v-on:blur="checkInput($event,row)"
                v-on:input="onInput($event)"
                v-on:change="cellChange()"
                v-on:keyup.down="keyDown(row.field.key + '_' + row.index)"
                v-on:keyup.up="keyUp(row.field.key + '_' + row.index)"
                v-on:keyup.esc="keyEsc(row.field.key + '_' + row.index)"
                v-bind:style="'padding: 0 0px !important; width:100%'"          
                /> 

                <input
                type="text"   
                :class="DateTimeClass"                    
                :readonly="row.field.isReadOnly || TableIsReadOnly || NoteIsVoid" 
                v-if="row.field.dataType === 'DATETIME' || row.field.dataType === 'DATE' || row.field.dataType === 'dateTime' || row.field.dataType === 'datetime'"
                v-mask="row.field.mask ? row.field.mask : row.field.dataType === 'DATETIME' || row.field.dataType === 'dateTime' || row.field.dataType === 'datetime'  ? '##/##/#### ##:## AA' : '##/##/####'"                
                :maxlength="row.field.MaxLength"
                v-model="row.item[row.field.key]"
                :key="row.field.key"  
                :ref="NoteId + '_' + controlID + '_' + row.field.key + '_' + row.index"
                :id="NoteId + '_' + controlID + '_' + row.field.key + '_' + row.index"  
                v-on:focus="OnFocusRow(row)"                           
                v-on:blur="OnBlurDateTime($event,row)"
                v-on:input="onInput($event)"
                v-on:change="cellChange()"
                v-on:keyup.down="keyDown(row.field.key + '_' + row.index)"
                v-on:keyup.up="keyUp(row.field.key + '_' + row.index)"
                v-on:keyup.esc="keyEsc(row.field.key + '_' + row.index)" 
                v-bind:style="styleDateTime" 
                /> 
                      
                <select :disabled="TableIsReadOnly || NoteIsVoid" 
                v-if="row.field.lookUpValues" 
                v-model="row.item[row.field.key]"
                v-on:change="ChangeSelect()">
                <option v-for="item in row.field.lookUpValues" :key="item.value">{{item.value}}
                </option></select>             
            </template>
            </b-table>
             <b-table v-if="showTableAggregation"
                v-b-tooltip.hover :title="Hint"
                class="table table-sm medezTables noteTable cutescroll"
                :items="itemsTableAggregation"
                :fields="fFields"
                responsive
                v-bind:style="styleTableAggregation"
                thead-class="hidden_header"
            >     
            <template v-slot:cell()="row">
                <b-form-input 
                  :disabled="true" 
                  v-model="row.item[row.field.key]"
                  v-bind:style="'border: 0px solid transparent !important; padding: 0 0px !important;'"
                  :ref="'agg_' + NoteId + '_' + controlID + '_'+ row.field.key"
                  :name="'agg_' + NoteId + '_' + controlID + '_'+ row.field.key"
                  />
            </template>
            </b-table>
           <MdGetLastAnswers v-if="showGetLastAnswers"
              v-bind:ShowPopup="showGetLastAnswers"
              @getCancel="onCancelGetLastAnswers($event)"
              @getOK="onOKGetLastAnswers($event)"
              v-bind:ControlId="controlID"
              v-bind:PatientCode="PatientCode"
              v-bind:TransDate="TransDate"
            /> 
            <!-- <VueContext ref="menu">
                <a class="dropdown-item" @click="showModalGetLastAnswers()">
                  Get 10 Last Answers
                </a>
            </VueContext> -->
  </div>
</template>
<script>
import ShowMessage from '@/components/messages/ShowMessage.js'
import Utils from '../../common/utils'
import { EventBus } from '../../event-bus.js';
import moment from "moment";
import Vue from 'vue';
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";

Vue.use(ContextMenuPlugin);
  export default {
    mixins: [ShowMessage,EventBus],
    props: {  
     fields: Array,
     totalRows: Number,
     isReadOnly: Boolean,
     deleteIconEvent: Function,
     onRowSelected: Function,
     currentPage: Number,
     onFiltered: Function,
     perPage: Number,
     pageChanged: Function,
     perPageFunction: Function,
     value: [Array,String,Number,null],
     TransDate: String,
     NoteId: String,
     id: [String, Number],
     PatientCode: String,
     IsCommonPart: Boolean
   },
    data() {
      return {
          time: '',
          DateTimeClass:"form-control",
          styleDateTime:"border: 0px solid transparent !important; padding: 0 0px !important; width:100%'",
          isValidTime: true,
          filter: null,
          filterOn: [],
          NoteIsVoid:false,
          Title:'',
          pPage: this.perPage,
          cPage: this.currentPage,
          fFields:[],
          CanDelete:false,
          CanInsert:false,
          CanCalculate:false,
          TableIsReadOnly:false,
          TableIsrequired:false,
          items:this.value,
          fMultiSelect: 'single',
          pageOptions: [5, 10, 15 ],
          lookUpValues:{},
          MaxRecId:-1,
          PositionsByRows:[],
          ColumnsCalulated:[],
          cal:[],
          stylediv:{
            position:'absolute',
            top: this.$attrs.control.controlTop+'px',
            width:this.$attrs.control.controlWidth+'px',           
            left:this.$attrs.control.controlLeft+'px',          
          },
           style: {
            //position:'absolute',
            top: this.$attrs.control.controlTop+'px',
            width:this.$attrs.control.controlWidth+'px',
            height: this.tableHeight+'px',
            left:this.$attrs.control.controlLeft+'px',            
            border: 'solid 1px #e0e0e0',
            //overflowX: 'hidden',
           },
           showTableAggregation: false,
           itemsTableAggregation: [],
           aggregationValues: {},
           styleTableAggregation: {
            top: this.$attrs.control.controlTop+'px',
            width:this.$attrs.control.controlWidth+'px',
            height:49+'px',
            left:this.$attrs.control.controlLeft+'px',
            overflow:'auto',
            overflowY: 'auto',
            overflowX:'auto',
          },
           Hint: this.$attrs.control.controlHint,
           isNewRecord: false,
           showGetLastAnswers: false,
           //PatientCode: this.$route.params.id,
           controlID:String(this.$attrs.control.controlID),
           tableHeight: this.$attrs.control.controlHeight - 29,
           styleTitle: {
             backgroundColor: ''
           },
           menuItems:[],
           targetId: "target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
           menuTargetId:"#target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID
      }
    },
    
    watch: {
      items(newValue) {
        this.styleTitle.backgroundColor = newValue && newValue.length == 0 && this.TableIsrequired ? '#dc3545 !important': ''
      }
    },

    methods:{
      OnFocusRow(row){       
        this.index = row.index;      
      },

      ResetPositions(){
        for(var i = 0; i < this.PositionsByRows.length; i++){
          if(this.PositionsByRows[i] !== -1){
            this.PositionsByRows[i] = -1;
            break;
          }
        }
      },

      IfContaintColumn(){
        var e = this.cal;
        var result = false;
         this.fFields.forEach(function(element) {
           if(e.includes(element.key)){
             result = true;            
           }
          }); 
          return result;
      },

      ResetCalculate(column){ 
         if(column === this.getColumnsCalculated()){
          this.cal = this.getCalculated();
        }      
      },

      OnKeyPress(row){
         if(!this.IfContaintColumn()){ 
           row.item[this.getColumnsCalculated()] = eval(this.cal.toString());  
         }
      },

      CanExecute(){
        var l = this.PositionsByRows.length;
        var result = false;
        if(l > 0){
          var first = this.PositionsByRows[0];
          for(var i = 1; i < l; i++){           
            if(this.PositionsByRows[i] === first){
              result = true;
            }else{
              result = false;
              break;
            }
          }
        }
        return result;         
      },

      OnChange(column){
        if(column === this.getColumnsCalculated()){
          this.cal = this.getCalculated();
        }
      },



    checkInput : function(e,row){        
      let input = document.getElementById(e.target.id);     
      var isValid = input.checkValidity();      
        if(!isValid) {        
          this.showMessage("Field "+row.field.label,"Invalid Time","error");         
        }else{
          this.Save();
        }
        this.reSizeAggregationTable();  
    },

      OnBlur(row,e){       
        if(e.target.value !== ''){
          this.Save();
          if(row.field.dataType !== null){
              if(row.field.dataType.toLowerCase() === 'datetime'.toLowerCase()){                 
                  if(!Utils.isValidDate(row.value)){           
                    e.target.focus();
                    e.target.value = '';          
                    this.showMessage(this.$t('MdEditTableCN.Field')+" "+row.field.label,this.$t('Msg.InvalidDate'),"error");
                  }
              }         
          }
        }
        this.reSizeAggregationTable()  
      },

      EvalClientValidation(row){        
        let cal = "";
        let isValid = 0;     
        if(row.field.clientValidation !== null && row.field.clientValidation !== ""){        
          cal = row.field.clientValidation.replaceAll(row.field.key,""+row.value+"");
          isValid = eval(cal);
        }

        return isValid;
      },

      OnBlurDateTime(e,row){  
        let input = document.getElementById(e.target.id);           
        if(e.target.value !== ''){
          this.Save();
          if(row.field.dataType !== null){
              if(row.field.dataType.toLowerCase() === 'datetime'.toLowerCase()){  
                           
                if(!Utils.IsValidDateAndRange(row.value)){  
                    input.style = "border: 1px solid red !important;padding: 0 0px !important; width:100%";                    
                    e.target.focus();
                    e.target.value = '';          
                    this.showMessage(this.$t('MdEditTableCN.Field')+" "+row.field.label,this.$t('Msg.InvalidDate'),"error");
                  }else{  
                    if(row.field.clientValidation !== null && row.field.clientValidation !== ""){     
                    let isValid = this.EvalClientValidation(row);     
                     if(isValid !== 1){
                        let message = row.field.validationMessage ? row.field.validationMessage : row.field.label+"  must within the month of the service.";
                        input.style = "border: 1px solid red !important;padding: 0 0px !important; width:100%"; 
                        e.target.value = ''; 
                        row.item[row.field.key] = '';
                        e.target.focus();                   
                        this.showMessage(this.$t('MdEditTableCN.Field')+" "+row.field.label,message,"error"); 
                    }else{
                      input.style = "border: 0px solid transparent !important; padding: 0 0px !important; width:100%"; 
                    } 
                  }else{
                    input.style = "border: 0px solid transparent !important; padding: 0 0px !important; width:100%"; 
                  }
                              
                  }
              }         
          }
        }else{
          input.style = "border: 0px solid transparent !important; padding: 0 0px !important; width:100%";  
        }
        this.reSizeAggregationTable()  
      },

      CalculateMaxRecId(){
        if(this.value.length > 0) {
          var arr = [];
          this.value.forEach(function(element) {
            arr.push(element.RecID);
          }); 
          this.MaxRecId = Math.max.apply(null, arr) + 1;
        }
        else
          this.MaxRecId = 1
      },

      HideColumnRecId(){       
        var temparr = this.fFields;      
           temparr.forEach(function(element) {          
          //  if(element.width){
          //    element.thStyle = { width: element.width+'px',minWidth: element.width+'px'}
          //  }  
          if(element.dataType !== null){
              if(element.key.toLowerCase() === 'RecID'.toLowerCase()){
                  element.class = 'hidden';                           
              }
              if(element.dataType.toLowerCase() === 'varchar'.toLowerCase()){
                  element.MaxLength = element.dataSize;
              }
          }          
          
          }); 

          this.fFields = temparr;
      },

      //---------------------------------------------------------------------------------------
      /**********************************Functions Calculate**********************************/

     getCalculated(){
        var s = [];
          this.fFields.forEach(function(element) {          
          if(element.calculate !== null && element.calculate !== ""){
                // s = element.calculate;   
             s.push({key:element.key,calculate:element.calculate});  
              }
          }); 
          return s;        
      },

      HasColumnCalulated(){
        var result = false;
         this.fFields.forEach(function(element) {
           if(element.calculate !== null && element.calculate !== ""){
             result = true;            
           }
          });
          this.CanCalculate = result;
      },

      PopulateColumnsCalculated(){
        var arr = [];
        if(this.cal){
           var temparr = this.fFields;
           var temp_cal = this.cal;
           temparr.forEach(function(element) {            
              for(var i = 0; i < temp_cal.length; i++){
                if(temp_cal[i].calculate.includes(element.key)){
                    arr.push(element.key);
                    break;
                }
              }
           })
        }
        this.ColumnsCalulated = arr;
      },

      ValidateAM_PM(value){
        if(value != null && value != undefined){
          if (value.includes(' ')){
            var date = moment(value.split(' ')[0]);
            if(date.isValid()){
              if(value.includes('am') || value.includes('AM') || value.includes('Am') || value.includes('aM')){
                  var valueAM = value.substring(0, value.length - 2);                  
                  return valueAM.trim()+" am"; 
              }
              if(value.includes('pm') || value.includes('PM') || value.includes('Pm') || value.includes('pM')){
                  var valuePM = value.substring(0, value.length - 2);                
                  return valuePM.trim()+" pm"; 
              }
            }
          }
          else{
              if(value.includes('am') || value.includes('AM') || value.includes('Am') || value.includes('aM')){
                  var timeValueAM = value.substring(0, value.length - 2);                  
                  return timeValueAM.trim()+" am"; 
              }
              if(value.includes('pm') || value.includes('PM') || value.includes('Pm') || value.includes('pM')){
                  var timeValuePM = value.substring(0, value.length - 2);                
                  return timeValuePM.trim()+" pm"; 
              }            
          }
         return value;
      }
      },

      CalculateAllRows(){
        var l = this.value.length;       
        var ll = this.ColumnsCalulated.length;        
        var currentRow = [];
        for(var i = 0; i < l; i++){
          currentRow = this.value[i];          
      for(var j = 0; j < this.cal.length; j++){
          for(var k = 0; k < ll; k++){  
           if(this.cal[j].calculate.includes(this.ColumnsCalulated[k])){
             var column = this.ColumnsCalulated[k];            
             currentRow[column] = this.ValidateAM_PM(currentRow[column]);                 
             var date = "";
             if(currentRow[column] != undefined && currentRow[column] != null){            
              if(currentRow[column].indexOf('/') !== -1){
                date = currentRow[column];
              }else{
                date = moment().format("MM/DD/YYYY")+" "+currentRow[column];
              }              
             }else{
              if(currentRow[column]){
                date = moment().format("MM/DD/YYYY")+" "+currentRow[column]; 
              }                
             }             
             this.cal[j].calculate = this.cal[j].calculate.replace(this.ColumnsCalulated[k],"'"+date+"'");            
            }
          }        
        currentRow[this.cal[j].key] = eval(this.cal[j].calculate);
        this.isAggregation();
        this.cal = this.getCalculated(); 
      }    
  }       
},

getColumnsCalculated(){
        var s = "";
          this.fFields.forEach(function(element) {
          if(element.calculate !== null && element.calculate !== ""){
                 s = element.key;               
              }
          }); 
          return s;  
      },



      //---------------------------------------------------------------------------------------

      HasFieldTypeDateTime(){
         var temparr = this.fFields;
         var result = false;         
           temparr.forEach(function(element) { 
             if(element.dataType !== null){
                if(element.dataType.toLowerCase() === 'datetime'.toLowerCase()){
                    result = true;
                }
             }
              
           })
           return result;
      },

      GetFieldsTypeDateTime(){
          var temparr = this.fFields;
         var result = [];         
           temparr.forEach(function(element) {
             if(element.dataType !== null){
                if(element.dataType.toLowerCase() === 'datetime'.toLowerCase()){
                    result.push(element.key);
                }
             } 
              
           })
           return result;
      },

      FormatToDateTime(){
        var l = this.value.length;       
        var listcolumns = this.GetFieldsTypeDateTime();
        var ll = listcolumns.length;
        var currentRow = [];
        for(var i = 0; i < l; i++){
          currentRow = this.value[i];
          for(var j = 0; j < ll; j++){
              var column = listcolumns[j];
            if(Utils.isValidDate(currentRow[column])){   
                  currentRow[column] = Utils.formatterDateTimeToString(currentRow[column]);
              }          
          }
          
        }
      },

      ConvertFieldToUpper(){
        this.fFields.forEach(function(element) {
          element.dataType = element.dataType.toUpperCase();
        })
      },


      InitializeVariables(){
        this.Title = this.$attrs.control.controlCaption;
        this.CanDelete = this.$attrs.control.tableCanDelete ? true: false;
        this.CanInsert = this.$attrs.control.tableCanInsert ? true: false;
        this.TableIsReadOnly = this.$attrs.control.isReadOnly || this.isReadOnly;
        this.TableIsrequired = this.$attrs.control.isRequired;
        
       if(this.$attrs.control.table){
          this.fFields = this.$attrs.control.table.columns;
          this.ConvertFieldToUpper();
          this.isAggregation();
          this.FormatToDateTime();
          //this.ChangeTypeColumn();
          this.cal = this.getCalculated();
          this.HasColumnCalulated();         
          this.PopulateColumnsCalculated();
          this.HideColumnRecId();
        }
        if(this.items.length==0 && !this.TableIsReadOnly)
          this.addNewEmptyRecord(true)
      },

    editButton(row) {
        this.editIconEvent(row);
    },

    deleteButton(row) {
        this.deleteIconEvent(row);
    },

    rowSelected(/*items*/) {
      // this.onRowSelected(items);
    },

    filtered(filteredItems) {
        this.onFiltered(filteredItems);
    },

    fpageChanged(page) {
        this.pageChanged(page);
    },

    pageFunction(perpage) {
        this.perPageFunction(perpage);
    },

    otherButton(row) {
      this.otherIconEvent(row);
    },

    viewButton(row) {
      this.viewIconEvent(row);
    },

    async addNewEmptyRecord(firstTime) {
      if(!firstTime){
        this.setChangedStatus()
      }
      this.cal = this.getCalculated();
      this.CalculateMaxRecId();
      let newFields = {};
      var maxRec = this.MaxRecId;
      this.fFields.forEach(element => {      
        newFields[element.key] = element.defaultValue?element.defaultValue:'';
        if(element.key.toLowerCase() === 'RecID'.toLowerCase()){
           newFields[element.key] = maxRec;
        }
      });
      await this.items.push(newFields);
      if(!firstTime && this.items.length > 0) {
        this.setFirstCellPos(this.items.length - 1)
      }
    },

    Refresh(){

    },
    
    Clear(){
      this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteAllRecord'), {
					title: this.$tc('Shared.DelRecord',2),
					okTitle: this.$t('Shared.TitleDeleteAll'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning'
				})
          .then(value => {
            if(value) {
              this.items = [];
              this.$emit('input', this.items);
              this.setChangedStatus();
              //this.aggregationValues = {};
              this.itemsTableAggregation = [];
             // this.isAggregation();
            }
          })
    },

    DeleteRecord(){      
      if(this.index !== -1){
        this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title: this.$tc('Shared.DelRecord'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning'
				})
          .then(value => {
            if(value){
                this.items.splice(this.index, 1);
                this.isAggregation();
                this.reSizeAggregationTable()
                this.setChangedStatus()
            }
          })
      }
    },

    reSizeAggregationTable() {
      let columns = this.$attrs.control.table.columns;
      if(columns && columns.length > 0) {
        columns.forEach(element => {
          let field = this.NoteId + '_' + this.controlID + '_' + element.key + '_' + 0
          if (document.getElementById(field) && document.getElementById(field).getBoundingClientRect) {
            let rect = document.getElementById(field).getBoundingClientRect();
            if (this.$refs[field].$el && this.$refs['agg_' + this.NoteId + '_' + this.controlID + '_'+ element.key]) {
              this.$refs['agg_' + this.NoteId + '_' + this.controlID + '_'+ element.key].$el.style.width = rect.width + 'px'
            }
          }
        });
      }
    },

    isAggregation() {
      let columns = this.$attrs.control.table.columns;
      if(columns && columns.length > 0) {
        columns.forEach(element => {
          if(element.agregation) {
            this.showTableAggregation = true;
            this.getAggregation(element.agregation,element.key)
          }
        });
        this.style.height =  this.showTableAggregation ? this.tableHeight-49+'px' : this.tableHeight+'px'
        this.itemsTableAggregation = [];
        this.itemsTableAggregation.push(this.aggregationValues);
      }
    },

    getAggregation(aggregationType,columnName) {
      if(this.showTableAggregation) {
        let agrValue = 0.00;
        if(aggregationType === 'Sum') {
          this.items.forEach(element => {
            if(Number.isInteger(parseInt(element[columnName]))) {
              //agrValue = agrValue + parseInt(element[columnName]);
              agrValue = (parseFloat(agrValue) + parseFloat(element[columnName])).toFixed(2);
            }
          });
        }
        if(aggregationType === 'Cou') {
          this.items.forEach(element => {
            if(element[columnName])
             agrValue = agrValue + 1 ;
          });
        }
        if(aggregationType === 'Avg') {
          let itemCount = 0;
          this.items.forEach(element => {
            if(element[columnName])
             if(Number.isInteger(parseInt(element[columnName]))) {
               itemCount = itemCount + 1;
              // agrValue = agrValue + parseInt(element[columnName]);
              agrValue = (parseFloat(agrValue) + parseFloat(element[columnName])).toFixed(2);
            }
          });         
          agrValue = !isNaN(agrValue / itemCount) ? agrValue / itemCount : 0
        }        
        if(aggregationType === 'Sum' || aggregationType === 'Avg'){           
         var result = agrValue.toString().indexOf('.') > 0 && agrValue.toString().split('.')[1] == '00' ? true : false;        
          this.aggregationValues[columnName] = !result && agrValue.toString().indexOf('.') > 0 ? parseFloat(agrValue).toFixed(2) : parseFloat(agrValue).toFixed();
        }else{
          this.aggregationValues[columnName] =  parseInt(agrValue);
        }
      }
    },

    Save(){
      this.CalculateAllRows();
    },

    cellChange() {    
     this.setChangedStatus();
      if(this.showTableAggregation) {
        this.isAggregation();
        this.reSizeAggregationTable()
      }
    },

    delFirstEmptyRow() {
      let busyCell = 0
      this.fFields.forEach(element => {
        if(element.key != 'RecID')
           if(this.items[0][element.key].length != 0)
            busyCell = busyCell + 1
        });
        if(busyCell == 0) {
          this.items.splice(0, 1)
          this.setFirstCellPos(0)
        }
        this.isNewRecord = false
    },

    setFirstCellPos(index) {
      this.$refs[this.NoteId + '_' + this.controlID + '_' + this.fFields[0].key+'_'+index].focus()
    },

    keyDown(cellID) {
      let fieldName = cellID.split("_")[0] 
      let currIndex = cellID.split("_")[1]
      let nextIndex = parseInt(currIndex) + 1
      if(this.isNewRecord && currIndex == 0) {
        this.delFirstEmptyRow()
      } else {
        if(this.$refs[this.NoteId + '_' + this.controlID + '_' + fieldName+'_'+nextIndex])
          this.$refs[this.NoteId + '_' + this.controlID + '_' + fieldName+'_'+nextIndex].focus()
        else {
          this.addNewEmptyRecord(false)
          this.isNewRecord = true
        }
      }
    },

    keyUp(cellID) {
      let fieldName = cellID.split("_")[0] 
      let currIndex = cellID.split("_")[1]
      let prevIndex = parseInt(currIndex) - 1
      if(this.$refs[this.NoteId + '_' + this.controlID + '_' + fieldName+'_'+prevIndex])
          this.$refs[this.NoteId + '_' + this.controlID + '_' + fieldName+'_'+prevIndex].focus()
    },

    keyEsc(cellID) {
      let currIndex = cellID.split("_")[1]
      if(this.isNewRecord && currIndex == 0)
        this.delFirstEmptyRow()
    },
    showModalGetLastAnswers() {
      this.showGetLastAnswers = true
    },

    onCancelGetLastAnswers(){
      this.showGetLastAnswers = false
    },

    onOKGetLastAnswers(value) {    
      this.items = value;
      this.$emit('input', value);
      this.isAggregation();
      this.reSizeAggregationTable()
      this.setChangedStatus()
      this.showGetLastAnswers = false
    },

    handlerClick(evt){
      if(!this.TableIsReadOnly) {
        this.$refs.menu.open(evt)
      }
    },

    setValid(state,isTable){
      if(isTable == "Table"){
        this.styleTitle.backgroundColor = state ? '#dc3545 !important': ''
      }else{
        for(var i = 0; i < this.fFields.length; i++){
          if(this.fFields[i].isRequired ){
            this.fFields[i].thClass = state ? "RequiredClass" :"";
          }
        }
      }
        
    },

    getMinCellWidth(row){
      let mult = row.field.dataType == 'INT' ? 8 : 6
      return row.item[row.field.key] && row.item[row.field.key].length > row.field.label.length ? row.item[row.field.key].length * mult : row.field.label.length * mult
    },

    setChangedStatus(){
      var changed = {
        "ControlId":this.$attrs.control.controlID,
        "Changed":true,
        "NoteId":this.NoteId
      }
      EventBus.$emit('onControlWasChanged', changed);
    },

    onInputTime(event){   
      let input = document.getElementById(event.target.id);
      var isValid = input.checkValidity(); 
      this.setChangedStatus()              
      if (!event.target.value.length && !isValid) {
          this.showMessage("Field Time","Invalid Time","error");
      }  
    },

    ChangeSelect(){
      this.setChangedStatus();
    },

    onInput(e){      
      if(e.inputType !== undefined){
        this.setChangedStatus()
      }      
    },

    onSelect: function(args ) {     
       if(args.item.id === 'gettenlastans') {
         this.showModalGetLastAnswers()
       }
    },

    loadMenuItems() {
      this.menuItems = []
      if (!this.IsCommonPart) {
        this.menuItems.push({text: this.$t('Shared.GetLast10'), id: "gettenlastans"})
      }
    }
 },
  created() {
    EventBus.$on("onSetReadOnly", function (e) {
      this.NoteIsVoid = e;
      }.bind(this));
    if(this.deleteIconEvent ) {
      this.fFields.unshift({ key: 'actions', label: this.$tc('Shared.ActionsTxt',2)});
    }
      if(this.$attrs.NoteIsVoid){
    this.NoteIsVoid = this.$attrs.NoteIsVoid;
  }
  },
  mounted(){    
    this.InitializeVariables();
    this.loadMenuItems()
    this.$nextTick(function () {
      this.reSizeAggregationTable()
    })    
  }
}

</script>
<style>
.hidden_header {
  visibility: collapse;
}
.scroll{
	overflow: hidden !important;
}
.time:invalid {
  border: 1px solid red !important;
}

.DateTimeOK{
  border: 0px solid transparent !important;
  padding: 0 0px !important;
  width:100%
}
.DateTimeInvalid{
  border: 1px solid red !important;
  padding: 0 0px !important;
  width:100%
}
</style>
