import ApiService from '@/services/api.service'

const ReasonService = {
    getAll() {    
      return ApiService.get('/Reasons?Active=true&ReasonType=CHECKIN');      
    },
  
    get( id) {
      return ApiService.get(`/races/${id}`);
    },
  
    
  }
  
  export default ReasonService;