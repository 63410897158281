<template>

<div class="col-md-12 grid-margin stretch-card">
		<div v-if="this.Police.isPoliceInvolved" class="card activeCard">
			<div class="card-body">
				<div  class="d-flex align-items-start justify-content-between pointer">
					<h6 class="card-title flex-grow lower-line text-truncate">{{$t('Incident.IncPolice')}}</h6>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.Police.isPoliceInvolved">
					<small>{{$t('Incident.IncPoliceInvolved')}}</small><br>
					<b>{{this.Police.isPoliceInvolved ? $t('Shared.TitleYes') : 'No'}}</b>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.Police.isPoliceInvolved">
					<small>{{$t('Incident.IncPoliceNotified')}}</small><br>
					<b>{{this.Police.wasPoliceNotified ? $t('Shared.TitleYes'): 'No'}}</b>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.Police.isPoliceInvolved">
					<small>{{$t('Incident.IncPartArrest')}}</small><br>
					<b>{{this.Police.arrestMade ? $t('Shared.TitleYes') : 'No'}}</b>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.Police.policeName">
					<small>{{$t('Shared.Name')}}</small><br>
					<b>{{this.Police.policeName}}</b>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.Police.badgeNumber">
					<small>{{$t('Incident.IncBadge')}}</small><br>
					<b>{{this.Police.badgeNumber}}</b>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.Police.reportNumber">
					<small>{{$t('Incident.IncReportNo')}}</small><br>
					<b>{{this.Police.reportNumber}}</b>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.Police.phoneNumber">
					<small>{{$t('Shared.Phone')}}</small><br>
					<b>{{this.Police.phoneNumber}}</b>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.Police.stationName">
					<small>{{$t('Incident.IncStation')}}</small><br>
					<b>{{this.Police.stationName}}</b>
				</div>
				<div class="float-left col-md-4 text-muted p-item" v-if="this.Police.comments">
					<small>{{$t('PatientDocument.Comments')}}</small><br>
					<b>{{this.Police.comments}}</b>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
    props: {
		Police: Object,
	},

    data() {
      return {
      }
    },
    
  methods:{
  },

  mounted() {
  }
}

</script>