import ApiService from '@/services/api.service'

const DocumentTypeService = {

    getAll(parameter){
        const requestData = {
            method: 'get',
            url: "/DocumentTypes/",
            headers: {
            },
            params: { 
              SearchCriteria: parameter.SearchCriteria                  
            }
          }     
          return ApiService.customRequest(requestData);       
    },   
 
    Add(data){
        return ApiService.post(`/DocumentTypes`, data);
    },
  
    Update(Id,data){
        return ApiService.put(`/DocumentTypes/${Id}`,data);
    }, 

}

export default DocumentTypeService;