<template>
    <div id="admissionClinical">
        <!--<h1>Welcome to admission clinical</h1>-->
        <AdmissionBase
            v-bind:editMode="this.$attrs.editMode"
        />
    </div>
</template>

<script>
import AdmissionBase from '@/components/pages/admission/Admission'
import EnumService from "@/services/enum.service";
export default {  
  name: 'AdmissionClinical',
  components:{AdmissionBase},

  data(){
    return{
        OptionsRightMenu:{
			visible:true,
			ItemsList:[],
			btnvisible:true,
			itemdisabled: false,
			PatientCode:''         
		},
    }
  },

  methods:{

  },

  created(){

  },

  mounted(){
    this.OptionsRightMenu.visible = true;    
	this.OptionsRightMenu.btnvisible = true;
	this.OptionsRightMenu.itemdisabled = false;
	this.$emit('getVisibleMenu', this.OptionsRightMenu);
	this.$emit('getBtnRightMenu', this.OptionsRightMenu);
	this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
	this.$emit('getVisibleMenu', this.OptionsRightMenu);	
  }
   
};
</script>


