import ApiService from '@/services/api.service'


const InsuranceService = {
  getAllPatientInsurances(parameter) {
    const requestData = {
      method: 'get',
      url: "/PatientInsurances/",
      headers: {
        
      },
      params: {
        SearchCriteria: parameter.SearchCriteria,
        PageSize: parameter.PageSize,
        PageNumber: parameter.PageNumber,
        PatientCode: parameter.PatientCode,
        InsuranceCode : parameter.InsuranceCode,
        InsType:parameter.Type,
      }
    }
    return ApiService.customRequest(requestData);
  },

  getAllInsurances(parameter) {
    const requestData = {
      method: 'get',
      url: "/Insurances/",
      headers: {
        
      },
      params: {
        SearchCriteria: parameter.SearchCriteria,
        PageSize: parameter.PageSize,
        PageNumber: parameter.PageNumber  
      }
    }
    return ApiService.customRequest(requestData);
  },

  getInsurancePriSec(parameter) {
    const requestData = {
      method: 'get',
      url: "/Insurances/InsurancePriSec",
      headers: {
        
      },
      params: {
        Ins_Type: parameter.Ins_Type  
      }
    }
    return ApiService.customRequest(requestData);
  },
  ///api/PatientInsurances/{patientId}:{insuranceId}
  getPatientInsurancesById(idInsurance) {
    return ApiService.get(`/PatientInsurances/${idInsurance}`);
  },

  ///api/PatientInsurances/{patientId}:{insuranceId}
  addPatientInsurances(newInsurances) {
    return ApiService.post(`/PatientInsurances/`,newInsurances);
  },

  ///api/PatientInsurances/{patientId}:{insuranceId}
  deletePatientInsurances(insuranceId) {
    return ApiService.delete(`/PatientInsurances/${insuranceId}`);
  },

  ///api/PatientInsurances/{patientId}:{insuranceId}
  updatePatientInsurances(insuranceId, InsuranceObject) {
    return ApiService.put(`/PatientInsurances/${insuranceId}`,InsuranceObject);
  },

  //---- INSURANCE ----\\

  //http://localhost:5000/api/Insurances
  getAllInsurance() {
    return ApiService.get(`/Insurances`);
  },

  //http://localhost:5000/api/Insurances/id
  getInsuranceByCode(idInsurance) {   
    return ApiService.get(`/Insurances/${idInsurance}`);
  },

  deleteInsurance(insuranceId) {
    return ApiService.delete(`/Insurances/${insuranceId}`);
  },

 
  //---- INSURANCE CATEGORY ----\\

  ///api/InsuranceCategories
  getInsuranceCategory() {
    return ApiService.get(`/InsuranceCategories`);
  },

  ///api/InsuranceCategories/{id}
  getInsuranceCategoryById(id) {
    return ApiService.get(`/InsuranceCategories/${id}`);
  },

  //---- INSURANCE Medicare Types ----\\

  ///api/InsuranceMedicareTypes
  getInsuranceMedicareTypes() {
    return ApiService.get(`/InsuranceMedicareTypes`);
  },

  ///api/InsuranceMedicareTypes/{id}
  getInsuranceMedicareTypesById(id) {
    return ApiService.get(`/InsuranceMedicareTypes/${id}`);
  },

  //---- PATIENTS INSURANCE Types ----\\
  ///api/PatientInsuranceTypes
  getPatientInsuranceTypes() {
    return ApiService.get(`/PatientInsuranceTypes`);
  },

  ///api/PatientInsuranceTypes/{id}
  getPatientInsuranceTypesById(id) {
    return ApiService.get(`/PatientInsuranceTypes/${id}`);
  },

  //---- PATIENTS INSURANCE RELATIONS ----\\
  //PatientInsuranceRelations
  getPatientInsuranceRelations() {
    return ApiService.get(`/PatientInsuranceRelations`);
  },

  getPatientInsuranceRelationsById(id) {
    return ApiService.get(`/PatientInsuranceRelations/${id}`);
  },

  //---- PATIENTS INSURANCE RELATIONS ----\\


}

export default InsuranceService;