<template>
    <div id="PatientDocument">     
        <div class="row">
            <div class="col-12">
              <h4 class="page-title text-truncate"> {{ $tc('PatientDocument.PatientDoc',2) }} - 
                <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName+' '}}</span> -
                <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
                <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
                <span v-if="this.PatientTitle.ageFormatted"> {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
                <span v-if="this.PatientTitle.programName"> - {{this.PatientTitle.programName}}  </span>
                <span v-if="this.PatientTitle.dateFrom">- {{this.PatientTitle.dateFrom}}  </span>
                <span v-if="this.PatientTitle.dateTo">- {{this.PatientTitle.dateTo}}  </span>
                <span v-else v-show="IsClinical">- {{ $t('Shared.Active') }}  </span>
              </h4>
            </div>
        </div>

        <div class="row">      
            <div class="col-sm-12 col-md-3" >                      
                <MdButton
                  v-bind:Label="$t('PatientDocument.AddDocument')" 
                  v-bind:ClassProp="this.isReadOnly? 'btn btn-primary my-3 btn-block' : 'btn btn-primary my-3 btn-block btn-login'"
                  v-on:click="AddDocument"
                  v-bind:IconClass="'ti-plus pr-1'"
                  v-bind:isReadOnly="this.isReadOnly"
                  v-bind:Name="'btn_add_document'">
                </MdButton>
            </div>              
            <div class="col-sm-12 col-md-9 mb-4">
                <div class="textlabel" :data-label="this.$t('Shared.SearchElements')">
                    <div class="input-group">
                        <input 
                          type="text" 
                          class="form-control"
                            v-model="parameter.SearchCriteria"
                            @keyup.enter="SearchDocuments()"
                        >
                        <div class="input-group-append">
                              <MdButton 
                              v-bind:Name="'btnsearchinput'"
                              v-bind:VariantProp="'btn btn-sm search-attached'"
                              v-bind:IconClass="'mdi mdi-magnify'"
                              v-on:click="SearchDocuments()">
                            </MdButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>

       
        <div class="row">
          <b-col md="3" lg="3" sm="12">
              <MdEdit 
                  v-bind:Label="$t('Shared.datefrom')"
                  v-bind:TypeProp="'date'" 
                  v-bind:Name="'DateFrom'"
                  v-model="DateFrom"
                  v-bind:Min="'1900-01-01'"
                  v-bind:Max="'2200-12-31'"
                  v-on:blur="OnDateFromBlur"
                  @input="DateFromChange"
                  v-bind:Id="'input_date_from'">                                     
              </MdEdit>
          </b-col>
          <b-col md="3" lg="3" sm="12">            
              <MdEdit 
                v-bind:Label="$t('Shared.dateTo')"
                v-bind:TypeProp="'date'" 
                v-bind:Name="'dateto'"
                v-bind:Min="'1900-01-01'"
                v-bind:Max="'2200-12-31'"
                v-model="DateTo"
                v-on:blur="OnDateToBlur"
                @input="DateToChange"
              v-bind:Id="'input_date_to'"/> 
          </b-col>
          <b-col md="3" lg="3" sm="12">
            <MdSelect
              v-bind:Label="$t('PatientDocument.DocumentType')"
              v-bind:Options="OptionDocumentTypes"
              v-bind:FieldDescription="'description'"
              v-bind:FieldCode="'code'"
              v-bind:Model="DocumentTypesVModel" 
              :Input="ChangeDropDownDocumentTypes"                      
              v-bind:Multiple="false"
              id="document_types_select"/>
          </b-col>
          <b-col md="3" lg="3" sm="12">    
            <MdSelect
              v-bind:Label="$t('PatientDocumentList.DocumentStatus')"
              v-bind:Options="OptionExpired"
              v-bind:FieldDescription="'name'"
              v-bind:FieldCode="'code'"
              v-bind:Model="ExpiredVModel"                       
              v-bind:Multiple="false"
              :Input="ChangeDropDownExpired"
              id="document_expired_select"
            /> 
          </b-col>
      </div>
       

       <!-- <LoadDocument v-bind:Records="this.files" />-->

       <div class="row">
        <div class="col-12">
            <div>
              <MdTitle v-bind:Text="$t('Shared.SearchResult')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
              <!--<div class="">
                <div class="row">
                  <div class="col-md-12">-->
                      <MdTable 
                        v-bind:fields="fields"
                        v-bind:items="items"
                        v-bind:totalRows="totalRows"
                        v-bind:showMessageIsEmpty="ShowMessageIsEmpty"
                        v-bind:currentPage="currentPage"
                        v-bind:perPage="perPage"
                        v-bind:pageOptions="pageOptions"
                        v-bind:refTable="'tablePatientDocument'"
                        v-bind:FieldsDate="FieldsDateArr"
                        :viewIconEvent="PatientDocumentView"                      
                        :editIconEvent="EditDocument"                        
                        v-bind:VisibleEditIcon="!this.isReadOnly"
                        :deleteIconEvent="DeleteDocument"
                        v-bind:VisibleDownloadIcon="true"
                        v-bind:IsDocumentManagement="true"
                        :downloadIconEvent="DownloadIcon"
                        v-bind:VisibleDeleteIcon="!this.isReadOnly"
                        :onRowSelected="onRowSelected"
                        :onFiltered="onFiltered"
                        :pageChanged="pageChanged"
                        :perPageFunction="PerPageFunction">
                      </MdTable>
                    <!--</div>
                  </div>
                </div> -->
            </div>
        </div>
    </div>

  <DxPoPup v-if="isVisible" 
    :Title="$tc('PatientDocument.PatientDoc',2)"
    :closeWindow="closeRepWindow"
    :isVisible="isVisible"
    :Url="this.FullUrl"
  />

  <MdPasswordDlg v-if="showSignPassword" 
    v-bind:Type="TypePassWord"
    v-bind:UserId="UserIdSelected"
    v-bind:VerificationType="'SupervisorPassword'"
    @getModalPassword="onModalPasswordResult($event)"
  />
    </div> 
</template>

<script>

import ShowMessage from "@/components/messages/ShowMessage.js";
import DBFunctions from '@/common/DBFunctions.js'
import EnumService from '@/services/enum.service'
import DocumentManagement from '@/services/documentmanagement.service'
import DocumentType from '@/services/documenttype.service'
import moment from 'moment'
import Utils from '@/common/utils'

export default {
  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPIn = await vm.$store.state.isUsePatientDocument;
            if(iPIn) next()
            else next('/accessdenied');
        }) 
  },
  name: 'PatientDocumentList',
  mixins: [ShowMessage, DBFunctions,Utils],
  data(){
    return{
      IsClinical:false,
      RegistrationInfo:{},
      infoList:[],
        DateFrom:null,
        DateTo:null,
        isReadOnly:false,
        UploadDate:null,
        files:[], 
        TypePassWord:'Sign',
        ClassExpired:'',
        UserIdSelected:'',
        Doc:{},
        Id:'',
        FullUrl:"",
        isVisible:false,
        showSignPassword:false,
        modalIdDocuments:"modalIdPatientDocument",   
        parameter:{
        SearchCriteria:'',
        PageNumber : 1,
        DocumentType:"",
        PageSize:0,
        Expired:1,
        Status: true,       
      },
      seqNo:0,
      FieldsDateArr:['scanneddate','expirationDate'],
        OptionDocumentTypes:[],
        ExpiredVModel:{code:1,name: this.$t('Shared.All')},
        IsExpired:false,
        OptionExpired:[{"code":1,"name": this.$t('Shared.All') },
                      {"code":2,"name":this.$t('PatientDocumentList.DocExpired')},
                      {"code":3,"name":this.$t('PatientDocumentList.DocUnexpired')}],
        DocumentTypesVModel:{code:'All',description: this.$t('Shared.All')},
    PatientTitle:{      
       programName:'',
       fullName:'',
       admitDate:'',
       dischargeDate:'',
       dob:''
     },
     fields:[        
         
        { key: 'description', sortable: true, label: this.$t('PatientDocument.DocumentType'), class: 'text-rigth' },
        { key: 'seqno', sortable: true, label: 'seqno', class: 'text-rigth hidden' },
        { key: 'scanneddate', sortable: true, label: this.$t('HouseFile.FileDate'), class: 'text-rigth' },
        { key: 'expirationDate', sortable: true, label: this.$t('PatientDocument.ExpirationDate'), class: 'text-rigth'},
        { key: 'comments', sortable: true, label: this.$t('PatientDocument.Comments'), class: 'text-rigth' },
       /* { key: 'sysUser', sortable: true, label: 'User', class: 'text-rigth' },*/
        { key: 'fileName', sortable: true, label: this.$t('HouseFile.FileName'), class: 'text-rigth' },

     ],
     items:[],
     pageOptions: [5, 10, 15 ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
     ShowMessageIsEmpty:false,
     OptionsRightMenu:{
            visible:true,
            ItemsList:[],
            btnvisible:true,
            itemdisabled: false,
            PatientCode:''         
        },  
    }
  },
  methods:{

    DocumentExpired(value){ 
      if(value !== null && value !== '' && value !== undefined){
        if(Utils.ValidateDate(value)){
          let exp = moment.utc(value);
          let now = moment().utc();
          if(now.isAfter(exp)){           
            return true;
          }else{           
            return false;
          }
        }
      }     
      return false;      
    },

    onModalPasswordResult(result){      
      if(result.Event === 'Ok'){   
        if(result.status === true) {
         this.$emit('load', true);  
         if(this.Id){
         DocumentManagement.delete(this.Id)
                .then(() => {
                  const index = this.items.findIndex(item => item.seqno === this.Id) 
                  if (~index) {                   
                    this.items.splice(index, 1);                   
                  }
                  this.getDocuments(); 
                  this.$emit('load', false);  
                })
                .catch((error) => {
                  this.$emit('load', false);  
                    if(error.response.status === 400 ){
                     this.showMessage(this.$t('PatientDocumentList.DeleteDocument'),error.response.data.errorMessage,"error");
                     }else{
                        this.showMessage(this.$t('PatientDocumentList.DeleteDocument'),error,"error");
                     }               
                });
        }
      }else{
        this.showMessage(this.$t('PatientDocumentList.DeleteDocument'),this.$t('Msg.InvalidSupervisorPass'),"error");
      }
    }
     /* if(result.type === 'Sign'){
          if(result.status !== true) {
            this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");
            this.Header.isSigned = false;
            this.$set(this.DataNote.Header, 'Issigned', false);
          }else{
            this.Header.isSigned = true;
            this.$set(this.DataNote.Header, 'Issigned', true);
          }
        }

         if(result.type === 'Cosign'){
          if(result.status !== true) {
            this.showMessage(this.$t('MdNote.VerifyPassword'),result.message,"error");
            this.Header.isCoSigned = false;
            this.$set(this.DataNote.Header, 'IsCoSigned', false);
          }else{
            this.Header.isCoSigned = true;
            this.$set(this.DataNote.Header, 'IsCoSigned', true);
          }
        }

        this.showSignPassword = false;*/
        this.showSignPassword = false;
    },

    closeRepWindow() {
        this.isVisible = false;
      },

    DateFromChange(e){
        this.DateFrom = e;
        //this.getDocuments();  
    },
    DateToChange(e){
        this.DateTo = e;
       // this.getDocuments();  
    },

    OnDateFromBlur(){
        this.getDocuments(); 
    },

    OnDateToBlur(){
        this.getDocuments(); 
    }, 

    ChangeDropDownDocumentTypes(t){
        this.DocumentTypesVModel = t ? t : {code:'',description:''}; 
        this.getDocuments();        
    },

    ChangeDropDownExpired(e){
        this.ExpiredVModel = e ? e : {code:1,name:'All'};
        this.getDocuments();
    },
    SearchDocuments(){
        this.getDocuments();
    },

    async Download(url, filename) {          
           const data = await fetch(url)
           const blob = await data.blob()
           const objectUrl = URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.setAttribute('href', objectUrl)
          link.setAttribute('download', filename)
          link.style.display = 'none'
          document.body.appendChild(link)                          
          link.click()                          
          document.body.removeChild(link)
      },

    VerifyExtension(ext){
      if(ext){
        if(ext.toLowerCase() === ".pdf" || ext.toLowerCase() === ".jpeg" ||
       ext.toLowerCase() === ".jpg" || ext.toLowerCase() === ".png" || 
       ext.toLowerCase() === ".bmp" || ext.toLowerCase() === ".svg" ||
       ext.toLowerCase() === ".gif" || ext.toLowerCase() === ".eps"){
        return true;
       }
       return false;
      }
      return false;
    },

    PatientDocumentView(row){     
      this.seqNo = row.seqno;       
      var baseurl = this.$store.state.apiurl.replace('/api/', '');      
        this.$emit('load', true);           
        DocumentManagement.GetPatientUrl(this.seqNo)
      .then((response) => {
        if(response.status == '200'){           
          this.Doc = response.data;  
          this.FullUrl = baseurl+response.data.url;  

          if(this.VerifyExtension(this.Doc.extension)){
            this.isVisible = true;
          }
          else{          
            this.Download(this.FullUrl,row.fileName);
            this.Load = false;         
          }
                 
        }
        this.$emit('load', false);
   
      })
      .catch(error => {
        if (!error.response) {                      
                      this.showMessage(this.$t('PatientDocumentList.GetDocuments'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('PatientDocumentList.GetDocuments'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$t('PatientDocumentList.GetDocuments'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('PatientDocumentList.GetDocuments'),error.response.data.errorMessage,"error");
                     } 
                    this.$emit('load', false);
      });
  
 

    },

    EditDocument(row){
      this.seqNo = row.seqno;      
       this.Mode = "Edit";
       if(this.$route.name.includes('patient')){   
        this.$router.push({ name: "patient-document-detail", params: { seqNo: this.seqNo } });
        }
        else{		
          this.$router.push({ name: "Clinical-document-detail", params: { id: this.$route.params.id,seqNo: this.seqNo,recordid:this.$route.params.recordid } });       
        }
      
    },

    DeleteDocument(row){
      this.Id = row.seqno;     
           this.$bvModal.msgBoxConfirm(this.$t('Msg.DeleteRecord'), {
					title: this.$t('PatientDocumentList.DeleteDocument'),
					okTitle: this.$t('Shared.TitleDelete'),
					cancelTitle: this.$t('Shared.BtnCancel'),
					footerClass: 'p-2',
					titleClass: 'modal-title modal-type-warning',
          id: 'modal_delete_document'
				})
          .then(value => {
            if(value){             
              this.showSignPassword = true;        
            }
          }) 
    },

    DownloadIcon(row){    
      this.getDocument(row);
    },

    getDocument(row) {
        this.$emit('load', true);
        var baseurl = this.$store.state.apiurl.replace('/api/', '');             
        DocumentManagement.GetPatientUrl(row.seqno)
      .then((response) => {
        if(response.status == '200'){ 
        this.FullUrl = baseurl+response.data.url;   
        this.Download(this.FullUrl,row.fileName);  
        this.Load = false;         
       }
        this.$emit('load', false);
   
      })
      .catch(error => {
        if (!error.response) {                      
                      this.showMessage(this.$t('Msg.GetDocument'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('Msg.GetDocument'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$t('Msg.GetDocument'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('Msg.GetDocument'),error.response.data.errorMessage,"error");
                     } 
                    this.$emit('load', false);
      });   
    },

    onRowSelected(){

    },

    onFiltered(){

    },

    pageChanged (page) {            
      this.currentPage = page; 
      this.parameter.PageNumber = this.currentPage;     
      this.getDocuments();
    },

    PerPageFunction(perpage){
      this.perPage = perpage;    
      this.parameter.PageNumber = 1;
      this.getDocuments();
    },

    AddDocument(){
      this.Mode = "Add";
       if(this.$route.name.includes('patient-document')){   
        this.$router.push({ name: "patient-document-add", params: { id: this.$route.params.id } });
        }
        else{		
          this.$router.push({ name: "Clinical-document-add", params: { id: this.$route.params.id,recordid:this.$route.params.recordid } });       
        }
      
    },

    async GetPatientByCode(){
       this.infoList = await this.getInfoTitle(this.$route.params.id);
      if(this.infoList.length > 0){
          this.PatientTitle.programName = '';
          this.PatientTitle.admitDate = '';
          this.PatientTitle.dischargeDate = '';
         this.PatientTitle.age = this.infoList[0].age;
         this.PatientTitle.ageFormatted = this.infoList[0].ageFormatted;
          if(this.infoList[0].dob){
              this.PatientTitle.dob = moment(this.infoList[0].dob).format('MM/DD/YYYY');
          }   
          this.PatientTitle.fullName = this.infoList[0].firstname + ' '+ this.infoList[0].lastname;
      }
    },

    getDocuments() {   
        this.$emit('load', true);
        this.parameter.EntityCode = this.$route.params.id; 
        this.parameter.Expired = this.ExpiredVModel.code;

        this.parameter.DocumentType = this.DocumentTypesVModel.code === 'All' ? '' : this.DocumentTypesVModel.code;
        this.parameter.DateFrom = this.DateFrom; //2022-10-25T11:53:30.407
        this.parameter.DateTo = this.DateTo; 
        this.parameter.PageSize = this.perPage;
        DocumentManagement.getAll(this.parameter)
      .then((response) => {
        if(response.status == '200'){
         this.items = response.data;   
         
         this.items.forEach(function(element) {
                          if (element.scanneddate)	
                              element.scanneddate = Utils.formatterDateTimeToString(element.scanneddate);
                          if (element.expirationDate)	
                              element.expirationDate = Utils.formatterDateToString(element.expirationDate);

                        //  element.expired = this.DocumentExpired(element.expirationDate);    
                          if(element.expirationDate !== null && element.expirationDate !== '' && element.expirationDate !== undefined){
                            if(Utils.ValidateDate(element.expirationDate)){
                              let date = moment();                             
                              let exp = moment.utc(element.expirationDate +" UTC").format("YYYY-MM-DD HH:mm");
                              let now = moment().utc(date);
                                if(now.isAfter(exp)){           
                                  element.expired = "Document Expired";
                                }else{           
                                  element.expired = "Document Not Expired";
                                }
                            }
                          } 
                              
                        });
         
         let headerString = response.headers["x-pagination"];
         this.Header = JSON.parse(headerString);
         this.totalRows = this.Header.TotalCount;
         this.$emit('load', false);
         if(this.items.length === 0){
          this.ShowMessageIsEmpty = true;
        } 
        }
   
      })
      .catch(error => {
        if (!error.response) {                     
                      this.showMessage(this.$tc('PatientDocument.PatientDoc'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$tc('PatientDocument.PatientDoc'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$tc('PatientDocument.PatientDoc'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$tc('PatientDocument.PatientDoc'),error.response.data.errorMessage,"error");
                     } 
                    this.$emit('load', false);
      });
    },

    getDocumentTypes() {   
        this.$emit('load', true);           
        DocumentType.getAll(this.parameter)
      .then((response) => {
        if(response.status == '200'){           
         this.OptionDocumentTypes = response.data;        
         this.OptionDocumentTypes.unshift({code:'All',description: this.$t('Shared.All')});  
         this.$emit('load', false);
        }
   
      })
      .catch(error => {
        if (!error.response) {                     
                      this.showMessage(this.$t('PatientDocument.DocumentType'),this.$t('Msg.retrieveInfo'),"error");
                    }                      
                    else if(error.response.status === 400 ){
                      this.showMessage(this.$t('PatientDocument.DocumentType'),error.response.data.errorMessage,"error");
                    } else if(error.response.status === 500){
                        this.showMessage(this.$t('PatientDocument.DocumentType'),this.$t('Msg.ThereWereSomeError'),"error");
                     } else{
                         this.showMessage(this.$t('PatientDocument.DocumentType'),error.response.data.errorMessage,"error");
                     } 
                    this.$emit('load', false);
      });
    },
    PopulatePatientTitle(){
        if(this.RegistrationInfo.dateFrom)
            this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
        if(this.RegistrationInfo.dateTo)
            this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');	
      if(this.$route.name.includes('Clinical-document')){ 
          this.PatientTitle.programName = this.RegistrationInfo.programName;
          this.PatientTitle.dateFrom = this.RegistrationInfo.dateFrom;
          this.PatientTitle.dateTo = this.RegistrationInfo.dateTo;
          this.PatientTitle.fullName = this.RegistrationInfo.patientName;
          this.PatientTitle.dob = moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');
          this.PatientTitle.age = this.RegistrationInfo.age;
          this.PatientTitle.ageFormatted = this.RegistrationInfo.ageFormatted;
      }
      if(this.$route.name.includes('patient-document')){         
          this.PatientTitle.programName = '';
          this.PatientTitle.dateFrom = '';
          this.PatientTitle.dateTo = '';
          if(this.infoList.length > 0){
            this.PatientTitle.dob = this.infoList[0].dob;             
            this.PatientTitle.fullName = this.infoList[0].firstname + ' '+ this.infoList[0].lastname;
          }   
      }
    },
  },
  async mounted(){
    this.OptionsRightMenu.visible = true;   
    this.OptionsRightMenu.ItemsList = this.$route.name.includes('Clinical-document') ? EnumService.ClinicalItems : EnumService.PatientItems;
    this.$emit("getVisibleMenu", this.OptionsRightMenu); 

    this.isReadOnly = await this.$store.state.isPatientDocumentEHRReadOnly;    
    this.OptionsRightMenu.Mode = true; 
   if(this.$route.name.includes('patient-document')){  
        this.GetPatientByCode();
     } 

    if(this.$route.name.includes('Clinical-document')){      
        this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
         this.IsClinical = this.RegistrationInfo.dateTo?false:true;
         if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;

    }

    this.PopulatePatientTitle();

    this.getDocumentTypes();   
    this.getDocuments();
  },
  created(){

  }
   
};
</script>



