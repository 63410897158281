<template>
   <div id="ContainerDischarge">
    <div class="row">
      <div class="col-12">
        <h4 class="page-title text-truncate">
			{{ $t('discharge.Discharge') }} - 
		<span v-if="this.RegistrationInfo.patientName">{{this.RegistrationInfo.patientName+' - '}}</span>
		<span span v-if="this.$route.params.id">{{this.$route.params.id + ' - '}}</span>
		<span v-if="this.RegistrationInfo.dob"> {{ $t('Shared.Dob') }} {{this.RegistrationInfo.dob}}</span>
		<span v-if="this.RegistrationInfo.ageFormatted"> {{ $t('Shared.Age') }} {{this.RegistrationInfo.ageFormatted}}</span>
		<span v-if="this.RegistrationInfo.programName"> - {{this.RegistrationInfo.programName}}  </span>
		<span v-if="this.RegistrationInfo.dateFrom">- {{this.RegistrationInfo.dateFrom}}  </span>
		<span v-if="this.RegistrationInfo.dateTo">- {{this.RegistrationInfo.dateTo}}  </span>
		<span v-else>- {{ $t('Shared.Active') }} </span>
		</h4>   
      </div>
    </div>
      <!-- !! Important ,Added for testing purpose -->
          <div style='display:none;'><MdEdit 
                v-bind:TypeProp="'text'" 
                v-model="this.$route.params.recordid "
                v-bind:Id="'input_discharge_id'">                                     
              </MdEdit>
          </div>
          <!-- End -->
    <b-row>   
     <b-col lg="4" md="3" sm="12">
          <MdEdit 
            v-bind:Label="$t('discharge.CurrentProgram')"
            v-bind:Name="'Program'"
            v-bind:TypeProp="'text'"       
            v-model="currentProgram"
            v-bind:isReadOnly="true"
            v-bind:Id="'input_dprogram_id'"
          /> 
        </b-col>    
       <b-col lg="4" md="4" sm="12">
          <MdEdit 
            v-bind:Label="$t('Shared.datefrom')"
            v-bind:Name="'DATEFROM'"
            v-bind:TypeProp="'text'" 
            v-bind:Mask="'##/##/####'"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-model="DateFrom"
            v-bind:isReadOnly="true"
          /> 
        </b-col>        
         <b-col lg="4" md="4" sm="12">
          <MdEdit 
            v-bind:Label="$t('discharge.LastTransactionDate')"
            v-bind:Name="'LastTransactionDate'"
            v-bind:TypeProp="'text'" 
            v-bind:Mask="'##/##/####'"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-model="LastTransactionDate"
            v-bind:isReadOnly="true"
             v-bind:Id="'last_tr'"
          /> 
        </b-col>
    </b-row>
    <MdTitle v-bind:Text="$t('discharge.DischargeINFO')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
    <b-row> 
    <b-col lg="4" md="4" sm="12">
          <MdEdit 
            v-bind:Label="$t('discharge.DISCHARGEDATEFROM')"
            v-bind:Name="'DISCHARGEDATEANDTIME'"
            v-bind:TypeProp="'text'" 
            v-bind:Mask="'##/##/#### ##:## AA'"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-model="DateTo"
            v-bind:isValid="isDischargeDateToValid"
            v-bind:isRequired="true"
            v-bind:isReadOnly="isReadOnly"
            v-bind:Id="'d_date_discharge'"
          /> 
    </b-col>     
    <b-col lg="4" md="4" sm="12">
          <MdSelect
            v-bind:Label="$t('discharge.ASSESSMENT')"
            v-bind:Options="Assessement"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'code'"
            v-bind:Model="discharge.assessmentCode"                       
            v-bind:Multiple="false"
            :Input="ChangeDropDownAssessement"
            :IsDisabled="isReadOnly"
            v-bind:Name="'assessement_select'"
            id="assessement_select"
          />
     </b-col> 
      <b-col lg="4" md="4" sm="12">
          <MdSelect
            v-bind:Label="$t('Shared.Status')"
            v-bind:Options="Status"
            v-bind:FieldDescription="'description'"
            v-bind:FieldCode="'code'"
            v-bind:Model="discharge.statusCode"                       
            v-bind:Multiple="false"
            :Input="ChangeDropDownStatus"
            :IsDisabled="isReadOnly" 
            v-bind:isRequired="true"
            v-bind:isValid="this.isStatusValid"
            v-bind:FeedBackText="$t('discharge.StatusRequired')"
            v-bind:Name="'status_select'"
            id="status_select"
          />
     </b-col> 
    </b-row>
    <b-row>
       <b-col lg="4" md="4" sm="12">
          <MdSelect
            v-bind:Label="$t('discharge.PERSONALEFFECTS')"
            v-bind:Options="PersonalEffects"
            v-bind:FieldDescription="'name'"
            v-bind:FieldCode="'code'"
            v-bind:Model="discharge.patientContactId"                       
            v-bind:Multiple="false"
            :Input="ChangeDropDownPersonalEffects"
            :IsDisabled="isReadOnly"
          />
     </b-col> 
     <b-col lg="4" md="4" sm="12">
          <MdEdit 
            v-bind:Label="$t('discharge.PERSONALEFFECTSSENT')"
            v-bind:Name="'PersonalEffectsOn'"
            v-bind:TypeProp="'text'" 
            v-bind:Mask="'##/##/#### ##:## AA'"
            v-bind:Min="'1900-01-01'"
            v-bind:Max="'2200-12-31'"
            v-model="PersonalEffectDate"
            v-bind:isReadOnly="isReadOnly"
          /> 
    </b-col>    
    </b-row>
    <MdTitle v-bind:Text="$t('discharge.DISCHARGENOTES')" v-bind:isSubtitle="true" v-bind:Name="'T_TitleSearch'"/>
    <b-row>
       <b-col lg="12" md="12" sm="12">
               <MdMemo
                 v-bind:Name="'textarea_discharge'"
                 v-bind:Rows="'5'"
                 v-bind:MaxRows="'6'"
                 v-bind:MaxLength="'500'"
                 v-model="discharge.dischargeNotes"
               />                                     
            </b-col>
    </b-row>
    <div class="page-actions">
              <MdButton
                v-bind:Label="$t('discharge.UNDODISCHARGE')"
                v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
                v-on:click="Undo"
                v-bind:isReadOnly="isReadOnly || this.discharge.status === 'A' || !isSupervisor"
                v-bind:Name="'btn_patient_undodischarge'"
              />
              <MdButton
                v-bind:Label="$t('Shared.Save')"
                v-bind:ClassProp="(this.isReadOnly)?'primary mx-2':'primary btn-login mx-2'"
                v-on:click="Save"
                v-bind:isReadOnly="isReadOnly || this.DisabledBtnSaveDischarge"
                v-bind:Name="'btn_save_patient_discharge'"
              />
      </div>
   </div>
</template>

<script>
import EnumService from '@/services/enum.service' 
import DBFunctions from '@/common/DBFunctions.js'
import GlobalService from '@/services/global.service' 
import StorageService from '@/services/storage.service'
import DischargeService from '@/services/discharge.service' 
import Utils from "@/common/utils";
import { EventBus } from '@/event-bus.js';
import moment from "moment";

let elemSaveBtn = '';
export default {
  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },
beforeRouteLeave(to, from, next) {
	if (!this.isReadOnly && this.pendingChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$t('discharge.Discharge'),
          okTitle: this.$t('Shared.BtnSave'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){
              next();
            } else {
               this.Save();
               next();
            }
          })		
	} else {
		next()
	}
    },
  name: 'Discharge',
  mixins: [DBFunctions],
   data(){
       return {
        permission:[],
        isSupervisor:StorageService.IsSupervisor(),
        Menu : this.$store.state.leftMenu,
        RegistrationInfo:{},
        Assessement:[],
        Status:[],
        PersonalEffects:[],
        LastTransactionDate:"",
        DateFrom:"",
         Required:true,
        currentProgram:"",
        DateTo:null,
        DisabledBtnSaveDischarge:false,
        isDischargeDateToValid:false,
        isStatusValid:true,
        PersonalEffectDate:null,
        discharge:{  
          "status":"",
          "recordId":this.$route.params.recordid,        
          "dateTo":null,
          "assessmentCode":"",
          "statusCode":"",
          "patientContactId":"",
          "personalEffectSentDate":null,
          "dischargeNotes":""
        },
        OptionsRightMenu:{
          visible:true,
          ItemsList:[],
          btnvisible:true,
          itemdisabled: false,         
        },  
        isReadOnly:false,
        pendingChanges: false,
       }
   },
   methods:{
    formatterDate(value){
         return Utils.formatterDateTimeToString(value);
    },
    CanView(){
      return this.permission[0].roleCanView;
    },

    validate_required(){
       this.isDischargeDateToValid = this.DateTo ? true : false;
        this.isStatusValid = this.discharge.statusCode && this.discharge.statusCode.length > 0 ? true : false
       // this.isProgramCodeValid = this.transfer.programcode && this.transfer.programcode.length > 0 && this.ProgramCodeWrittedIsValid? true : false;         
        return this.isDischargeDateToValid && this.isStatusValid;
    },

    validateDate(){
      return Utils.IsValidDateAndRange(this.DateTo) ? true : false;
    },

     Save(){
       
       this.$emit('load', true);
       this.discharge.status = "I";
       if(this.validate_required()){
          elemSaveBtn.setAttribute("disabled","disabled");
         if(this.validateDate()){
           this.UpdateDischarge();
         }else{
            this.$emit('load', false);
            elemSaveBtn.removeAttribute("disabled");
            this.showMessage(this.$t('discharge.Discharge'), this.$t('Msg.InvalidDate'), "errorAutoHide");          
         }
         
       }else{
         this.$emit('load', false);
         elemSaveBtn.removeAttribute("disabled");
         this.showMessage(this.$t('discharge.Discharge'), this.$t('Msg.CheckRedLabeledFields'), "errorAutoHide");          
      }  
       
    },

    Undo(){
       this.discharge.status = "A";
       this.UpdateDischarge();      
    },

    async GetAssessementDischarge(){
      this.Assessement = await this.getAssessmentDischarge();
    },

    async GetStatusDischarge(){
      this.Status = await this.getStatusDischarge();
    },
   
    async GetPersonalEffects(){
      this.PersonalEffects = await this.getPatientContacts(this.$route.params.id);
    },

     getLastTransactionDate() {       
          GlobalService.getAdmissionLastTransactionDate(this.$route.params.recordid)
            .then((response) => {      
               if (response.data) {              
                this.LastTransactionDate = response.data ? this.formatterDate(response.data): ""; 
               }  
         })
         .catch(error => {
           if(error.response.status === 400 ){
                 this.showMessage(this.$t('discharge.LastTransactionDate'),error.response.data.errorMessage,"error");
            }else{
               this.showMessage(this.$t('discharge.LastTransactionDate'),error,"error");
            }
         });         
      },

        getDateTime() {        
             GlobalService.getDateTime()
            .then((response) => {      
               if (response.data) {
                 //this.DateTo = this.formatterDate(response.data);
                 this.DateTo = moment(response.data).format('MM/DD/YYYY hh:mm A');
               }  
         })
         .catch(error => {
             if(error.response.status === 400 ){
                 this.showMessage(this.$t('discharge.GetDateTime'),error.response.data.errorMessage,"error");
            }else{
               this.showMessage(this.$t('discharge.GetDateTime'),error,"error");
            }
         }); 
         
      },

      
    UpdateDischarge(){     
      this.discharge.dateTo =  this.DateTo ? moment(this.DateTo).format('YYYY-MM-DDTHH:mm:ss') :null;
      this.discharge.personalEffectSentDate =  this.PersonalEffectDate ? moment(this.PersonalEffectDate).format('YYYY-MM-DDTHH:mm:ss') :null;
      DischargeService.UpdateDischarge(this.$route.params.recordid,this.discharge)
        .then((response) => {
              if(response.status == '200'){
                this.pendingChanges = false;                
                this.DisabledBtnSaveDischarge = this.discharge.status === "A" ? false : true; 
                this.$emit('load', false);  
                if(this.discharge.status === "A"){                
                setTimeout(() => 
                  this.$bvToast.toast(this.$t('discharge.DischargeUndoSuccessfully'), {
                    title: this.$t('discharge.SavingDischarge'),
                    autoHideDelay: 5000,
                    variant: "success"
                  })
                , 50); 
                }else{
                    setTimeout(() => 
                  this.$bvToast.toast(this.$t('discharge.DischargeSavedSuccessfully'), {
                    title: this.$t('discharge.SavingDischarge'),
                    autoHideDelay: 5000,
                    variant: "success"
                  })
                , 50); 
                }
              }                             
            })
            .catch((error) => {
              elemSaveBtn.removeAttribute("disabled");
               if (!error.response) {
                   this.showMessage(this.$t('discharge.AddDischarge'),this.$t('Msg.NotAbleToInsert'),"error");
                 }                 
              else if(error.response.status === 400 ){
                 this.showMessage(this.$t('discharge.AddDischarge'),error.response.data.errorMessage,"error");
              }else if(error.response.status === 500){
                this.showMessage(this.$t('discharge.AddDischarge'),this.$t('Msg.ThereWereSomeError'),"error");
              } else{
                this.showMessage(this.$t('discharge.AddDischarge'),error.response.data.errorMessage,"error");
              }   
              this.$emit('load', false);
          });
    },

    getDischarge(){
      DischargeService.getDischarge(this.$route.params.recordid)
        .then((response) => {
              if(response.status == '200'){
                this.discharge = response.data;
                this.DateTo = this.discharge.dateTo ? this.formatterDate(this.discharge.dateTo) : this.getDateTime();
                this.PersonalEffectDate = this.discharge.personalEffectSentDate ? this.formatterDate(this.discharge.personalEffectSentDate) : null;
                this.$emit('load', false); 
              }                             
            })
            .catch((error) => {  
               if (!error.response) {
                   this.showMessage(this.$t('discharge.GetDischarge'),this.$t('Msg.NotAbleToInsert'),"error");
                 }              
              else if(error.response.status === 400 ){
                 this.showMessage(this.$t('discharge.GetDischarge'),error.response.data.errorMessage,"error");
              } else{
                  this.showMessage(this.$t('discharge.GetDischarge'),error,"error");
              }  
              this.$emit('load', false);
          });
    },

    ChangeDropDownAssessement(e){
      this.discharge.assessmentCode = e !== null ? e.code : ""; 
    },

    ChangeDropDownStatus(e){
      this.discharge.statusCode = e !== null ? e.code : ""; 
    },

    ChangeDropDownPersonalEffects(e){
      this.discharge.patientContactId = e !== null ? e.code : ""; 
    },

    checkUserAction() {
        if (elemSaveBtn.disabled) {
          elemSaveBtn.removeAttribute('disabled');
        }
    }
   },

  async mounted() { 
    this.isReadOnly = await this.$store.state.isClinicalDischargeEHRReadOnly; 
    this.$emit('load', true);  
    this.DisabledBtnSaveDischarge = false; 
    this.OptionsRightMenu.visible = true;      
    this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems;
    this.$emit('getVisibleMenu', this.OptionsRightMenu);
    this.getLastTransactionDate();
    this.GetAssessementDischarge();
    this.GetStatusDischarge();
    this.GetPersonalEffects();
   
    // this.$emit('getBtnRightMenu', this.OptionsRightMenu);  

    this.RegistrationInfo = await this.getRegistrationInfoTitle(this.$route.params.recordid);
    if(this.RegistrationInfo.dateFrom)
			this.RegistrationInfo.dateFrom = moment(this.RegistrationInfo.dateFrom).format('MM/DD/YYYY');
		if(this.RegistrationInfo.dateTo)
			this.RegistrationInfo.dateTo = moment(this.RegistrationInfo.dateTo).format('MM/DD/YYYY');
      
    if(this.RegistrationInfo.dob)
			this.RegistrationInfo.dob =  moment(this.RegistrationInfo.dob).format('MM/DD/YYYY');

      this.RegistrationInfo.age = this.RegistrationInfo.age === 0 ? "0" : this.RegistrationInfo.age;


      this.DateFrom = this.RegistrationInfo.dateFrom;
      this.currentProgram = this.RegistrationInfo.programName;

    this.getDischarge();
   
    setTimeout( function() {
      elemSaveBtn = document.getElementById('btn_save_patient_discharge');
      elemSaveBtn.setAttribute('disabled','');
    }, 50, this );
   },

    created(){   
      this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);  
      EventBus.$on("onChanged", function (e) { 
         this.pendingChanges = e.Changed
         this.checkUserAction();
      }.bind(this));

     window.addEventListener('beforeunload', this.beforeWindowUnload)
    },

    beforeDestroy () {
      window.removeEventListener('beforeunload', this.beforeWindowUnload);
     // EventBus.$off('onChanged');
    },
};
</script>


