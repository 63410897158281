<template>
	
	<div class="page-height">
		<!--<div class="col-12">-->
			<!--<MdTitle 
			v-bind:Text="'PATIENT DASHBOARD - ' + this.PatientTitle.fullName + ' - ' + this.$route.params.id" 
			/>-->
			<h4 class="page-title text-truncate"> {{ $t('PatientSummary.PatientDashboard') }} -
              <span v-if="this.PatientTitle.fullName">{{this.PatientTitle.fullName+' '}}</span> -
              <span v-if="this.$route.params.id">{{this.$route.params.id}}</span>
              <span v-if="this.PatientTitle.dob"> - {{ $t('Shared.Dob') }} {{this.PatientTitle.dob}}</span>
              <span v-if="this.PatientTitle.ageFormatted"> {{ $t('Shared.Age') }} {{this.PatientTitle.ageFormatted}}</span>
            </h4>
			<div class="row">
				<MdPatientInfoBox
					v-bind:PatientCode="this.$route.params.id">
				</MdPatientInfoBox>

				<MdPatientAllergies						
					v-bind:PatientCode="this.$route.params.id">						
				</MdPatientAllergies>

				<MdPatientDiagnosis
					v-bind:PatientCode="this.$route.params.id">						
				</MdPatientDiagnosis>
			</div>
		<!--</div>-->
		<div class="space-actions"></div>
		<div class="page-actions">
			<MdButton 
			v-bind:Label="$t('Shared.Back')"
			v-bind:Name="'btnpatientlist'"
			v-bind:ClassProp="'btn btn-primary mx-2 btn-login'"
			v-bind:IconClass="'ti-back-left pr-1'"
			v-on:click="GoToPatientList"/>
		</div>
	</div>
</template>

<script>
import EnumService from '@/services/enum.service'
import Utils from '../../../common/utils'
import DBFunctions from '@/common/DBFunctions.js'
import moment from 'moment'

export default {
  name: 'PatientSummary',
    mixins: [DBFunctions],
    data() {
		return {		
			InfoPatient:{},
	PatientTitle:{      
       programName:'',
       fullName:'',
       admitDate:'',
       dischargeDate:'',
       dob:''
     }, 
			patient:{},
			VisibleH6:false,
			Allergies:[],
			Diagnosis:[],
			OptionsRightMenu:{
				visible:true,
				ItemsList:[],
				btnvisible:false,
				itemdisabled: false,
				PatientCode:'',
				Mode: this.$attrs.editMode,
			},
		}
	},

	async mounted() {
		this.GetPatientByCode();
		this.OptionsRightMenu.ItemsList = EnumService.PatientItems;
		this.OptionsRightMenu.Mode = this.edit_mode;
		this.OptionsRightMenu.visible = true;    
		this.OptionsRightMenu.btnvisible = true;
		this.OptionsRightMenu.itemdisabled = false;
		this.$emit('getVisibleMenu', this.OptionsRightMenu);
		this.$emit('getBtnRightMenu', this.OptionsRightMenu);
	},
	methods:{

	async GetPatientByCode(){
      var infoList = await this.getInfoTitle(this.$route.params.id);
		if(infoList.length > 0){
			this.PatientTitle.programName = '';
			this.PatientTitle.admitDate = '';
			this.PatientTitle.dischargeDate = '';
			this.PatientTitle.age = infoList[0].age;
			this.PatientTitle.ageFormatted = infoList[0].ageFormatted;
			if(infoList[0].dob){
				this.PatientTitle.dob = moment(infoList[0].dob).format('MM/DD/YYYY');
			}   
			this.PatientTitle.fullName = infoList[0].firstname + ' '+ infoList[0].lastname;
		}
    },

		GoToPatientInfo(){
		this.$router.push({ name: "patient-details", params: { id: this.$route.params.id } }).catch(()=>{});
		},

		GoToPatientList(){
			this.checkExistFavorite('/app/patients');
			this.$router.push('/app/patients').catch(()=>{});
		},

		VisibleAsam(){
			if(this.$store.state.isUseASAM) {
				return true;
			}
			return false;
		},
			
		formatterDate(value){
			return Utils.formatterDate(value);
		},
		formatterDateToString(date){
			return Utils.formatterDateToString(date);
		},
	}
}


</script>


