<template>
  <div id="app">   
    <vue-repeater v-model="fields"></vue-repeater>
  </div>
</template>
 
<script>
export default {    
  name: 'app',
  data () {
    return {
      fields: [
        {
          name: 'test-address',
          value: {}
        },
         {
          name: '',
          value: {}
        },
      ]
    }
  }
}
</script>