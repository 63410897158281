import ApiService from '@/services/api.service'


const OutComeStudyAnswersService = {
    getAll( parameter) { 
      const requestData = {
        method: 'get',
        url: "/MdOutComeStudyAnswers/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          TestCode : parameter.TestCode,
          SectionCode : parameter.SectionCode
        }
      }     
      return ApiService.customRequest(requestData);         
    },

    getTotalTestScore( parameter) { 
      const requestData = {
        method: 'get',
        url: "/MdOutComeStudyAnswers/GetTotalTestScore",
        headers: {
        },
        params: { 
          TestCode : parameter.TestCode,
        }
      }     
      return ApiService.customRequest(requestData);         
    },
    
  }
  
  export default OutComeStudyAnswersService;