import ApiService from '@/services/api.service'

const ProgramService = {
  getAll(parameter) { 
    const requestData = {
      method: 'get',
      url: "/Programs/",
      headers: {
      },
      params: { 
        SearchCriteria: parameter.SearchCriteria,
        PageSize: parameter.PageSize,          
        PageNumber : parameter.PageNumber,
        ProgramType: parameter.ProgramType          
      }
    }     
    return ApiService.customRequest(requestData);         
  }, 
  
  get(id) {
    return ApiService.get(`/Programs/${id}`);
  },
 
  getProgramsGroupNotes() {
    var param = {};
    var _Url = '/Programs/groupnotes/';   
  const requestData = {
    method: 'get',
    url: _Url,
    params: param
  }
  return ApiService.customRequest(requestData);
  },
  }
  
  export default ProgramService;