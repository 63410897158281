<template>
    <div class="col-md-4 grid-margin stretch-card">
                  <div class="card activeCard">
                      <div class="card-body">
                 <div class="d-flex align-items-start justify-content-between pointer">
                          <h6 class="card-title flex-grow lower-line text-truncate">{{ $t('MdAdmissionByDay.AdmissionsAt') }}</h6>  
                          <div class="text-center" v-if="VisibleSpinner" style="margin-top:-2px;">
                            <b-spinner variant="primary" small label="Text Centered"></b-spinner>
                          </div>                        
                  </div>                 
                  <div class="text-muted p-fixed cutescroll">
                     <p v-if="List.length === 0">{{ $t('MdAdmissionByDay.NoKnown') }}</p>
                      <ul>	
                          <li v-for="(h, index) in List" :key="`h.id-${index}`">
                            Program: {{h.programName}} / Patient: {{h.patientName}} / Room: {{h.roomName}} 
                            </li>											
                      </ul>
                     
                      </div>
                      </div>
                  </div>
                  </div>
</template>

<script>
import DBFunctions from '@/common/DBFunctions.js'
import ClinicalService from '@/services/clinical.service'
import Utils from '@/common/utils.js'
export default {
  props: {
   PatientCode: String,
 },
  mixins: [DBFunctions],
  data() {
    return {
      List:[],  
      VisibleSpinner:false,    
    }
  },
   
methods:{


  
  async getadmissions(){     
    var param = {		
      IsAdmissionsByDay: true,   
		};
    this.VisibleSpinner = true;
      await ClinicalService.getAllAdmissionByDay(param)
                .then((response) => {
                     if(response.status == '200' && response.data ){
                       this.List = response.data;    
                       this.List.forEach(function(element) {
                              if (element.dob){
                                element.dob = Utils.formatterDateToString(element.dob);                              
                              }	                                  
                            });	
                            this.VisibleSpinner = false;               
                     }    
                     this.VisibleSpinner = false;                    
                })
                .catch((error) => {
                  this.VisibleSpinner = false;         
                    if (error.response) {
                        this.showMessage('Error',error,"error");
                    }
                });  
    },
  },


mounted() {
 this.getadmissions();
}
}

</script>