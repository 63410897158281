import ApiService from '@/services/api.service'


const IncidentReportService = {
    getAll( parameter) { 
         
      const requestData = {
        method: 'get',
        url: "/IncidentReport/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          DateFrom : parameter.DateFrom,
          DateTo : parameter.DateTo,
          CategoryCode : parameter.Incident,
          ReporterCode : parameter.Reporter,
          SeverityCode : parameter.Severity,
          RoomCode : parameter.Building,
          Status : parameter.Status,
        }
      }     
      return ApiService.customRequest(requestData);         
    },

    Get(IncidentID) {
      return ApiService.get(`/IncidentReport/${IncidentID}`);
    },
    
    CanSeeIncidentAdmin() {
      return ApiService.get(`/IncidentReport/CanSeeIncidentAdmin/`);
    },

    IncidentStatus(Id) {
      return ApiService.get(`/IncidentReport/IncidentStatus?IncidentId=${Id}`);
    },

    AddIncident(data){
      return ApiService.post(`/IncidentReport`, data);
    },

    UpdateIncident(Id,data){        
      return ApiService.put(`/IncidentReport/${Id}`,data);
    }, 

    SetVoid(IncidentID) {
      return ApiService.put(`/IncidentReport/Void/${IncidentID}`);
    },

    getLocation(){
      return ApiService.get(`/ProviderCodes?TableName=LOCATION`); 
    },

    getParticipationTypeClients(){
      return ApiService.get(`/ProviderCodes?TableName=PPARTICIPATION`); 
    },

    getParticipationTypeEmployees(){
      return ApiService.get(`/ProviderCodes?TableName=EPARTICIPATION`); 
    },

    getParticipationTypeContacts(){
      return ApiService.get(`/ProviderCodes?TableName=CPARTICIPATION`); 
    },

    getParticipationTypeVisitors(){
      return ApiService.get(`/ProviderCodes?TableName=VPARTICIPATION`); 
    },

    createIncidentId(){
      return ApiService.get(`/Utils/GetTimedKey`);
    },

    completeDraft(IncidentID,Reporter){
      return ApiService.get(`/IncidentReport/CompleteDraft/${IncidentID}:${Reporter}`);
    },

    setBackToDraft(IncidentID){
      return ApiService.get(`/IncidentReport/SetBackToDraft/${IncidentID}`);
    },

    IncidentReportHeader(IncidentID){
      return ApiService.get(`/IncidentReport/ListingReportHeader?IncidentID=${IncidentID}`);
    },
  }
  
  export default IncidentReportService;