import ApiService from '@/services/api.service'

const ActivitiesService = {
    getAll( parameter) { 
      const requestData = {
        method: 'get',
        url: "/Activities/",
        headers: {
        },
        params: { 
          SearchCriteria: parameter.SearchCriteria,
          PageSize: parameter.PageSize,          
          PageNumber : parameter.PageNumber,
          PatientCode : parameter.PatientCode
        }
      }     
      return ApiService.customRequest(requestData);         
    },
  
    get(id) {
      return ApiService.get(`/Activities/${id}`);
    },

    add(data){
      return ApiService.post(`/Activities`,data)
    },

    delete(id){
      return ApiService.delete(`/Activities/${id}`)
    },

    update(id,data){
      return ApiService.put(`/Activities/${id}`,data)
    }
}
  
  export default ActivitiesService;