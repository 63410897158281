<template>
   <div id="ContainerClinicalPatientInfo">
       <Patient v-bind:editMode="this.$attrs.editMode" v-bind:HaveChanges="HaveChanges"/>
   </div>
</template>

<script>
import Patient from '@/components/pages/patients/Patient'
import EnumService from "@/services/enum.service";
import { EventBus } from '@/event-bus.js';
import DBFunctions from '@/common/DBFunctions.js'
export default {
  beforeRouteEnter(to, from, next) {
        next(async vm => {
            const iPI = await vm.CanView();
            if(iPI) next()
            else next('/accessdenied');
        }) 
  },

beforeRouteLeave(to, from, next) {
	if (this.HaveChanges) {
		this.$bvModal.msgBoxConfirm(this.$t('Msg.LeavingWithoutSaving'), {
          title: this.$tc('Shared.Patient'),
          okTitle: this.$t('Shared.Save'),
          cancelTitle: this.$t('Shared.BtnDismiss'),
          footerClass: 'p-2',
          titleClass: 'modal-title modal-type-warning'
        })
          .then(value => {
            if(!value){             
              next();
            } else {
              EventBus.$emit('onSave', true);
              next();              
            }
          })		
	} else {
		next()
	}
    },

  name: 'ClinicalPatientInfo',
  mixins: [DBFunctions],
     components:{Patient},
      data(){
    return{
        OptionsRightMenu:{
			visible:true,
			ItemsList:[],
			btnvisible:true,
			itemdisabled: false,
			PatientCode:''         
		},
		HaveChanges:false,
    permission:[],
    Menu : this.$store.state.leftMenu,
    }
  },

  methods:{
    CanView(){
      return this.permission[0].roleCanView;
  },
  },

	created(){
    this.getPermissionLeftMenu(this.Menu,this.$route.path,this.permission);
		EventBus.$on("HaveChanges", function (e) {  
          this.HaveChanges = e;
     }.bind(this));
	},

    mounted() {
		this.OptionsRightMenu.visible = true;    
		this.OptionsRightMenu.btnvisible = true;
		this.OptionsRightMenu.itemdisabled = false;
		this.$emit('getVisibleMenu', this.OptionsRightMenu);
		this.$emit('getBtnRightMenu', this.OptionsRightMenu);
		this.OptionsRightMenu.ItemsList = EnumService.ClinicalItems
		this.$emit('getVisibleMenu', this.OptionsRightMenu);		
		
	},
};
</script>


