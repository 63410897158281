import axios from 'axios'
import store from '@/globalvar.js'


// Esto puedo necesitarlo cuando implemente el login
// import { StorageService } from '../services/storage.service'

import StorageService from '@/services/storage.service'

const ApiService = {
  APIURL: '',  
  init(baseURL) {
    this.APIURL = baseURL
    axios.defaults.baseURL = baseURL;
    store.commit('setApiUrl',baseURL);
    axios.interceptors.request.use(config => {
      var token = StorageService.getOnlyToken();
      if (token) {
          config.headers.Authorization = `Bearer ${token}`;
      }      
      return config;
    });
    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if(error.response && 401 === error.response.status) {
        var uname = StorageService.getName();
        if ( uname == null ) {
          uname = "user";
        }
        // StorageService.removeToken(); 
        store.commit('setAccessToken','');
        localStorage.removeItem("vuex");
        localStorage.removeItem("_secure__ls__metadata");
        window.location = '/unauthorized?u='+uname;
        return error.response.statusText;
      } else {
          return Promise.reject(error);
      }
    });
  },

    setHeader() {

    },

    removeHeader() {
        axios.defaults.headers.common = {}
    },

    get(resource) { 
        return axios.get(resource,{
            headers: {}
          })
    },

    getData(resource, data) { 
        return axios.get(resource, data)
    },

    post(resource, data) {
        return axios.post(resource,data,{
          headers: {}
        })
    },

    put(resource, data) {
        return axios.put(resource, data,{
          headers: {}
        })
    },

    delete(resource, data) {
        return axios.delete(resource, { data: data },{
            headers: {}
          })
    },

      /**
     * Perform a custom Axios request.
     *
     * data is an object containing the following properties:
     *  - method
     *  - url
     *  - data ... request payload
     *  - auth (optional)
     *    - username
     *    - password
    **/
    customRequest(data) {
        var result = axios(data);
        return result;
    },

    parseParams(params) {
      const keys = Object.keys(params)
      let options = ''
    
      keys.forEach((key) => {
        const isParamTypeObject = typeof params[key] === 'object'
        const isParamTypeArray = isParamTypeObject && params[key] && params[key].length >= 0
    
        if (!isParamTypeObject) {
          options += `${key}=${params[key]}&`
        }
    
        if (isParamTypeObject && isParamTypeArray) {
          params[key].forEach((element) => {
            options += `${key}=${element}&`
          })
        }
      })    
      return options ? options.slice(0, -1) : options
    },

    getWithArray(resource,data) {     
      return axios.get(resource, {
        params: data,
          paramsSerializer: (params) => this.parseParams(params)
        }
      )
    },
}

export default ApiService