<template> 
      <div :id="targetId" oncontextmenu="return false;">
        <div v-if="!this.$attrs.control.isReadOnly && !NoteIsVoid && !this.$attrs.IsChartReview && !this.isReadOnly">
          <ejs-contextmenu :id="targetId" :target="menuItems.length > 0 ? menuTargetId : null" :items='menuItems' :select='onSelect'></ejs-contextmenu> 
        </div> 
        <input
            v-b-tooltip.hover :title="Hint"
            type="date"
            min="1900-01-01"
            class="cursor-context-menu"
            :maxlength="this.$attrs.control.controlMaxLenght"          
            :disabled="this.$attrs.control.isReadOnly === 'Y' || NoteIsVoid || this.$attrs.control.IsChartReview || this.isReadOnly"
            :class="classValue" 
            :formatter="OnFormatter"
            v-on:blur="OnBlur"
            v-on:change="OnChange"             
            v-on:keyup.enter="$emit('keyup', $event)"
            :aria-describedby="this.$attrs.control.controlID"           
            v-bind:value="this.Value"            
            v-on:keypress="$emit('keypress', $event)"
            v-on:input="Input"
            v-on:focus="$emit('focus', $event.target.value)"
            v-bind:style="style"
            :id="idControl"
            >
            <!-- <VueContext ref="menu">
                <a class="dropdown-item" @click="showModalGetLastAnswers()">
                  Get 10 Last Answers
                </a>
            </VueContext> -->
            <MdGetLastAnswers v-if="showGetLastAnswers"
              v-bind:ShowPopup="showGetLastAnswers"
              @getCancel="onCancelGetLastAnswers($event)"
              @getOK="onOKGetLastAnswers($event)"
              v-bind:ControlId="controlID"
              v-bind:PatientCode="PatientCode"
              v-bind:TransDate="TransDate"
            />            
     </div>         
</template>

<script>
import Utils from '@/common/utils.js'
import { EventBus } from '../../event-bus.js';
import Vue from 'vue';
import { ContextMenuPlugin } from "@syncfusion/ej2-vue-navigations";
import moment from 'moment'

Vue.use(ContextMenuPlugin);
export default {
  name: 'MdDateTimePicker',
  mixins: [EventBus],
   props: {     
     MaxLengthProp: Number,
     isReadOnly:Boolean,
     Formatter:Function,    
     Change:Function,
     Blur:Function,
     Name:String,
     KeyEnter:Function,
     KeyPress:Function,
     value:[String],
     TransDate: String,
     NoteId: String,
     id: [String, Number],
     PatientCode: String,
     IsCommonPart: Boolean     
   },

   data(){
     return{     
      classValue:"form-control",
      controlFont:[],
      NoteIsVoid:false,
      style:{
        position:'absolute',
        top: this.$attrs.control.controlTop+'px',
        width:this.$attrs.control.controlWidth+5+'px',
        height:this.$attrs.control.controlHeight+'px',
        left:this.$attrs.control.controlLeft+'px',
        fontSize:'',
        fontWeight: '',
        fontFamily:'',
        color:'',
        background:'',
        textDecorationLine:'',
        fontStyle:'',
        border: '',     
      },
      Hint: this.$attrs.control.controlHint,
      showGetLastAnswers: false,
      controlID:String(this.$attrs.control.controlID),
      //PatientCode: this.$route.params.id,
      Value: this.value,
      isRequired: this.$attrs.control.isRequired,
      menuItems:[],
      targetId: "target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
      menuTargetId:"#target-"+this.$attrs.HeaderId+"-"+this.$attrs.control.controlID,
      idControl:this.id.toString()
     }
   },

methods:{
    InitializeVariables(){
       if(this.$attrs.control.controlFontForHtml){         
          this.controlFont = this.$attrs.control.controlFontForHtml.split(',');
          //var size = this.controlFont[1].parseInt()+1;
          var size = parseInt(this.controlFont[1]) + 3;
          this.style.fontFamily = this.controlFont[0];
          this.style.fontSize = size+'px !important';
          this.style.color = this.controlFont[3];
          var Decorations = this.controlFont[2];
          this.style.fontWeight = Decorations.charAt(0) === '1'?'bold':'';
          this.style.fontStyle = Decorations.charAt(1) === '1'?'italic':'';
          this.style.textDecorationLine = Decorations.charAt(2) === '1'?'underline':'';
          this.style.textDecorationLine = Decorations.charAt(3) === '1'?this.style.textDecorationLine+ ' line-through':this.style.textDecorationLine;
       }
    },

    OnChange(evt){
        this.$emit('change',evt.target.value)
        this.style.border = !Utils.isValidDate(evt.target.value) && this.isRequired ? 'solid 1px #dc3545 !important': ''
     },

     OnFormatter(valueIn,valueOu) {
       this.Formatter(valueIn,valueOu);
       this.$emit('Formatter',valueOu)
     },

     OnBlur(evt){
       this.$emit('Blur',evt.target.value)

       let inputDate = moment(evt.target.value);
       if (inputDate.year() < 1900) {
         inputDate.set('year', 1900)
         this.Value = this.formatterDate(inputDate.format('yyyy-MM-DD'));
         this.$emit('input', this.Value);
       }
     },

    showModalGetLastAnswers() {
      this.showGetLastAnswers = true
    },

    onCancelGetLastAnswers(){
      this.showGetLastAnswers = false
    },

    onOKGetLastAnswers(value) {
      this.Value = this.formatterDate(value);
      this.$emit('input', this.formatterDate(value));
      this.setChangedStatus()
      this.showGetLastAnswers = false
    },

    handlerClick(evt){
      if(!this.$attrs.control.isReadOnly && !this.isReadOnly) {
        this.$refs.menu.open(evt)
      }
    },

    Input(w){     
      this.Value = w.target.value;
      this.$emit('input', w.target.value);
      this.setChangedStatus()
    },

    formatterDate(value){
        return Utils.formatterDate(value);
    },
    
    setValid(state){
        this.style.border = state ? 'solid 1px #dc3545 !important': ''
    },

    onSelect: function(args ) {
       if(args.item.id === 'gettenlastans') {
         this.showModalGetLastAnswers()
       }
    },

    loadMenuItems() {
      this.menuItems = []
      if (!this.IsCommonPart) {
        this.menuItems.push({text: this.$t('Shared.GetLast10'), id: "gettenlastans"})
      }
    },

    setChangedStatus(){
      var changed = {
        "ControlId":this.$attrs.control.controlID,
        "Changed":true,
        "NoteId":this.NoteId
      }
      EventBus.$emit('onControlWasChanged', changed);
    }
},
created(){
   EventBus.$on("onSetReadOnly", function (e) {
      this.NoteIsVoid = e;
      }.bind(this));
  if(this.$attrs.NoteIsVoid){
    this.NoteIsVoid = this.$attrs.NoteIsVoid;
  }

},
mounted(){
  this.InitializeVariables();
  this.loadMenuItems()
}
};

</script>

<style>
 @import '../../assets/css/Patients/Patient.css';
 .scroll{
	overflow: hidden !important;
}
</style>


