<template>    
        <b-row>
            <b-col lg="4" md="4" sm="12">
                         <div class="textlabel" data-label="Test"> 
                            <b-form-input 
                                maxlength="15"   
                            ></b-form-input>                      
                        </div>
                    </b-col>
        </b-row>   
</template>
<script>
export default {
  name: 'test-address',
  props: {
    value: {
      type: Object,
      required: true
    }
  },

}
</script>