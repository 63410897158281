import Vue from 'vue';
import VueRouter from 'vue-router';

import Layout from '@/components/template/layout/Layout';
import Login from '@/components/pages/login/Login';
import AccountExpired from '@/components/pages/login/AccountExpired';
import Provider from '@/components/pages/provider/SelectProvider';
import Unauthorized from '@/components/template/unauthorized/Unauthorized';
import Main from '@/components/pages/main/Main';
import PatientsList from '@/components/pages/patients/PatientsList';
import PatientSummary from '@/components/pages/patients/PatientSummary';
import Patient from '@/components/pages/patients/Patient';
import UserService from '@/services/user.service';
import GeneralNotes from '@/components/pages/patients/GeneralNotes';
import PatientAsam from '@/components/pages/patients/PatientAsam';
import ContactsList from '@/components/pages/patients/contacts/ContactsList';
import Contacts from '@/components/pages/patients/contacts/Contact';
import Insurance from '@/components/pages/patients/insurances/InsurancesList';
import InsuranceDetails from '@/components/pages/patients/insurances/Insurance';
import Clinical from '@/components/pages/clinical/Clinical';
import ClinicalDashBoard from '@/components/pages/clinical/Dashboard';
import ClinicalNotes from '@/components/pages/clinical/ClinicalNotes';
import ClinicalAsam from '@/components/pages/clinical/ClinicalAsam';
import ClinicalPatientInfo from '@/components/pages/clinical/PatientInfo';
import AccessDenied from '@/components/template/unauthorized/AccessDenied';
import AllergiesList from '@/components/pages/patients/allergies/AllergiesList';
import Allergy from '@/components/pages/patients/allergies/Allergy';
import VitalSign from '@/components/pages/clinical/vitalsign/VitalSign';
import VitalSignList from '@/components/pages/clinical/vitalsign/VitalSignList';

import MedicationsList from '@/components/pages/clinical/medications/MedicationsList';
import Medications from '@/components/pages/clinical/medications/Medications';

import Schedule from '@/components/pages/schedule/schedule_sample';
import AdmissionList from '@/components/pages/admission/AdmissionList';
import AdmissionAdd from '@/components/pages/admission/Admission';
import AdmissionClinical from '@/components/pages/admission/AdmissionClinical';
import ScheduleEditor from '@/components/pages/schedule/schedule-editor';
import ChartReviewClinical from '@/components/pages/chartreview/ClinicalChartReview';
import ChartReviewPatient from '@/components/pages/chartreview/PatientChartReview';
import Transfer from '@/components/pages/Transfer/transfer';
import Discharge from '@/components/pages/Discharge/discharge';
import Diagnosis from '@/components/pages/patients/Diagnosis';
import ClaimBatch from '@/components/pages/claimbatch/claimbatchList';
import ClaimBatchSummary from '@/components/pages/claimbatch/claimbatchSummary';
import InsuranceSetupList from '@/components/pages/setup/insuranceCompanies/insuranceSetupList.vue';
import InsuranceSetup from '@/components/pages/setup/insuranceCompanies/insuranceSetup.vue';
import DoctorSetup from '@/components/pages/setup/facilities/doctorSetup.vue';
import Doctor from '@/components/pages/setup/facilities/doctor.vue';
import ReportBuilder from '@/components/MedEZComponents/report/ReportBuilder';
import UserTemplateHeaderList from '@/components/pages/setup/users/userTemplate/userTemplateHeaderList.vue';
import UserRole from '@/components/pages/setup/users/userTemplate/userRoleDetails.vue';
import UserHeaderList from '@/components/pages/setup/users/user/userHeaderList.vue';
import UserHeader from '@/components/pages/setup/users/user/userHeader.vue';
import MethodNotAllowed from '@/components/template/notallowed/methodnotallowed';
import CreateClearingHF from '@/components/pages/claimbatch/createclearinghousefile';
import ManageClaimBatches from '@/components/pages/claimfile/manageclaimbatches';

import HcpcsCptRevCode from '@/components/pages/setup/billing/cptList';
import ModifiersList from '@/components/pages/setup/billing/modifiersList';
import PlaceofServiceList from '@/components/pages/setup/billing/placeofserviceList';
import PlaceofService from '@/components/pages/setup/billing/placeofservice';
import CPT from '@/components/pages/setup/billing/cpt';
import Modifiers from '@/components/pages/setup/billing/modifier';
import EmployeeTypeList from '@/components/pages/setup/employees/employeetypeList';
import EmployeeType from '@/components/pages/setup/employees/employeeType';
import ValueCodeList from '@/components/pages/setup/billing/valuecodeList';
import ValueCode from '@/components/pages/setup/billing/valuecode';
import RoomsList from '@/components/pages/setup/facilities/roomsList.vue';
import Rooms from '@/components/pages/setup/facilities/rooms.vue';
import BuildingList from '@/components/pages/setup/facilities/buildingList.vue';
import Building from '@/components/pages/setup/facilities/building.vue';

import EmployeeCredentialList from '@/components/pages/setup/employees/employeecredentialList';
import EmployeeCredential from '@/components/pages/setup/employees/employeecredential';

import EmployeeList from '@/components/pages/setup/employees/employeeList';
import Employee from '@/components/pages/setup/employees/employee';

import AppoinmentTypeList from '@/components/pages/setup/scheduler/appoinmentType/appoinmentTypeList';
import AppoinmentType from '@/components/pages/setup/scheduler/appoinmentType/appoinmentType';

import CalendarResourcesList from '@/components/pages/setup/scheduler/calendarResources/calendarResourcesList';
import CalendarResources from '@/components/pages/setup/scheduler/calendarResources/calendarResources';
import ReportClinicalNotes from '@/components/pages/reports/notes/rClinicalNotes';
import DocumentManagementList from '@/components/pages/patients/PatientDocumentList';
import DocumentManagement from '@/components/pages/patients/PatientDocument';

import ePharmacyPatient from '@/components/pages/ePharmacy/ePharmacyPatient';
import ePharmacyClinical from '@/components/pages/ePharmacy/ePharmacyClinical';
import DailyNotesList from '@/components/pages/clinical/dailynote/DailyNotesList';
import DailyNote from '@/components/pages/clinical/dailynote/DailyNote';
import ReportList from '@/components/pages/reports/ReportList'
import PowerBILayout from '@/components/pages/reports/powerbi/PowerBILayout'
import PowerBIReport from '@/components/pages/reports/powerbi/PowerBIReport'
import DailyServiceCharge from '@/components/pages/reports/dailyServiceCharge'
import DailyServiceLog from '@/components/pages/reports/dailyServiceLog'
import EmployeeInbox from '@/components/pages/clinical/employeeinbox/EmployeeInbox'

import GroupNotes from '@/components/pages/groupnotes/GroupNotes';
//import GroupNotesDashBoard from '@/components/pages/groupnotes/GroupNotesDashboard';
import GroupNotesAccordion from '@/components/pages/groupnotes/GroupNotesAccordion';

import LogNotesList from '@/components/pages/clinical/lognotes/LogNotesList';
import LogNote from '@/components/pages/clinical/lognotes/LogNotes';
import SchedulerDB from '@/components/pages/schedule/schedulerDB';
import AppointmentDashBoard from '@/components/pages/appointment/Dashboard';
import SchedulerNotes from '@/components/pages/appointment/SchedulerNotes';
import SchedulerDBActivitiesList from '@/components/pages/appointment/ActivitiesList';
import SchedulerDBActivities from '@/components/pages/appointment/Activities';
import IncidentReport from '@/components/pages/incidentReport/IncidentReport'
import IncidentReportContent from '@/components/pages/incidentReport/IncidentReportContent'
import DrugList from '@/components/pages/setup/drugs/drugList'
import Drug from '@/components/pages/setup/drugs/drug'
import PatientContactsRep from '@/components/pages/reports/patientContactsRep'
import PatientAllergyRep from '@/components/pages/reports/PatientAllergyRep'
import PatientInsurancesRep from '@/components/pages/reports/PatientInsurancesRep'
import ICRMultipleViewRep from '@/components/pages/reports/ICRMultipleViewRep'
import ReportEmployeeCaseLoad from '@/components/pages/reports/employeeCaseLoad';
import IncidentWorkflow from '@/components/pages/incidentWorkflow/IncidentWorkflow'
import DcsList from '@/components/pages/dcs/dcsListing'
import Dcs from '@/components/pages/dcs/dcs'
import WyTreatmentPlanList from '@/components/pages/wytreatmentplan/treatmentplanList'
import WyTreatmentPlan from '@/components/pages/wytreatmentplan/treatmentplan'
import MdTreatmentPlanList from '@/components/pages/mdtreatmentplan/treatmentplanList'
import MdTreatmentPlan from '@/components/pages/mdtreatmentplan/treatmentplan'
import SchedulerClinical from '@/components/pages/clinical/scheduler/SchedulerClinical'
import Appointments from '@/components/pages/patients/appointments/Appointments'

import ClinicalNotesContainer from '@/components/pages/clinicalNotesContainer/ClinicalNotesContainer';

Vue.use(VueRouter) 

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      public: true,  // Have access without be authenticated
      onlyWhenLoggedOut: true // not access to login if user is authenticated
    }
  },
  {
    path: '/AccountExpired',
    name: 'AccountExpired',
    component: AccountExpired,
    meta: {
      public: true,  // Have access without be authenticated
      onlyWhenLoggedOut: true // not access to login if user is authenticated
    }
  },
  {
    path: '/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
    meta: {
      public: true,  // Have access without be authenticated
      onlyWhenLoggedOut: true // not access to login if user is authenticated
    }
  },
  {
    path: '/accessdenied',
    name: 'AccessDenied',
    component: AccessDenied,
    meta: {
      public: true,
    }
  },
  {
    path: '/provider',
    name: 'Provider',
    component: Provider,
  },  
  {
    path: '/docs',
    name: 'docs', 
  },  

  {
    path: '/app',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: 'methodnotallowed',
        name: 'methodnotallowed',
        component: MethodNotAllowed,       
      },    
      {
        path: 'main',
        name: 'Main',
        component: Main,       
      },
      {
        path: 'patients',
        name: 'patients-list',
        component: PatientsList,
      },
      {
        path: 'dcs',
        name: 'dcs-list',
        component: DcsList,
      },
      {
        path: "dcs/add",
        name: 'dcs-add',
        component: Dcs,
        props: {editMode: false}
      },
      {
        path: 'insurances',
        name: 'insuranceSetupList',
        component: InsuranceSetupList,
      },
      {
        path: "insurances/:id/edit",
        name: 'insurances-details',
        component: InsuranceSetup,
        props: {editMode: true}
      },
      {
        path: "insurances/add",
        name: 'insurances-add',
        component: InsuranceSetup,
        props: {editMode: false}
      },
      {
        path: 'doctors',
        name: 'doctorSetup',
        component: DoctorSetup,
      },
      {
        path: "doctors/:id/edit",
        name: 'doctor-details',
        component: Doctor,
        props: {editMode: true}
      },
      {
        path: "doctors/add",
        name: 'doctor-add',
        component: Doctor,
        props: {editMode: false}
      },
      {
        path: 'claimbatch',
        name: 'claimbatch-list',
        component: ClaimBatch,
      },
      {
        path: 'billingclearinghousefile',
        name: 'createclearinghousefile-list',
        component: CreateClearingHF,
      },
      {
        path: "claimbatch/summary/:id",
        name: 'claimbatch-summary',
        component: ClaimBatchSummary,
        props: {editMode: true}
      },
      {
        path: 'manageclaimbatches',
        name: 'manageclaimbatches-list',
        component: ManageClaimBatches,
      },
      {
        path: 'cpt',
        name: 'hcpcscpt',
        component: HcpcsCptRevCode,
      },
      {
        path: 'modifiers',
        name: 'modifier-list',
        component: ModifiersList,
      },
      {
        path: 'placeofservices',
        name: 'placeofservice-List',
        component: PlaceofServiceList,
      },
      {
        path: "patients/:id/patientinfo",
        name: 'patient-details',
        component: Patient,
        props: {editMode: true}
      },
      {
        path: "patients/summary/:id",
        name: 'patient-summary',
        component: PatientSummary,
        props: {editMode: true}
      },
      {
        path: 'patients/add',
        name: 'patient-add',
        component: Patient,
        props: {editMode: false}
      },
      {
        path: 'patients/:id/generalnotes',
        name: 'patient-generalnotes',
        component: GeneralNotes,
        props: {editMode: true}
      },
      {
        path: 'patients/:id/asam',
        name: 'patient-asam',
        component: PatientAsam,
        props: {editMode: true}
      },
      {
        path: 'patients/:id/contacts',
        name: 'patient-contacts',
        component: ContactsList,
        props: {editMode: true}
      },
      {
        path: 'patients/:id/diagnosis',
        name: 'patient-diagnosis',
        component: Diagnosis,
       // props: {editMode: true}
      },
      {
        path: 'clinical/:id/diagnosis/:recordid',
        name: 'Clinical-diagnosis',
        component: Diagnosis,      
      },
      {
        path: 'clinical/:id/insurances/:recordid',
        name: 'Clinical-insurance',
        component: Insurance        
      },
      {
        path: 'clinical/insurance-details/:id/:recordid/:idInsurance',
        name: 'Clinical-insurance-details',
        component: InsuranceDetails,
        props: {editMode: true}        
      },
      {
        path: 'clinical/add-insurance/:id/:recordid',
        name: 'add-Clinical-insurance',
        component: InsuranceDetails,
        props: {editMode: false}        
      },     
      {
        path: 'clinical/:id/allergies/:recordid',
        name: 'Clinical-allergies',
        component: AllergiesList,   
      },

      {
        path: 'clinical/:id/allergies/:allergyid/:recordid',
        name: 'Clinical-allergies-details',
        component: Allergy,
        props: {editMode: true}        
      },
      {
        path: 'clinical/:id/allergies/:recordid',
        name: 'Clinical-allergies-Add-details',
        component: Allergy,
        props: {editMode: false}        
      },
      /*{
        path: 'clinical/add-insurance/:id/:recordid',
        name: 'add-Clinical-insurance',
        component: InsuranceDetails,
        props: {editMode: false}        
      },*/
      {
        path: 'clinical/:id/contacts/:recordid',
        name: 'Clinical-cont',
        component: ContactsList
      },
      {
        path: "clinical/:id/contacts/:contactid/:recordid",
        name: 'Clinical-cont-details',
        component: Contacts,
        props: {editMode: true}
      },
      {
        path: "clinical/:id/contact-add/:recordid",
        name: 'Clinical-cont-add',
        component: Contacts,
        props: {editMode: false}
      },
      {
        path: 'clinical/:id/document/:recordid',
        name: 'Clinical-document',
        component: DocumentManagementList      
      },
      {
        path: "clinical/:id/document/:seqNo/:recordid",
        name: 'Clinical-document-detail',
        component: DocumentManagement,
        props: {editMode: true}
      },
      {
        path: "clinical/:id/document-add/:recordid",
        name: 'Clinical-document-add',
        component: DocumentManagement,
        props: {editMode: false}
      },
      {
        path: 'patients/:id/admission',
        name: 'patient-admissionList',
        component: AdmissionList,
        props: {editMode: true}
      },
      {
        path: "patients/:id/admission/add",
        name: 'patient-admission-add',
        component: AdmissionAdd,
        props: {editMode: false}
      },
      {
        path: "patients/:id/admission/:recordid",
        name: 'patient-admission-edit',
        component: AdmissionAdd,
        props: {editMode: true}
      },
      {       
        path: 'clinical/:id/admission/:recordid',
        name: 'admission-clinical',
        component: AdmissionClinical,
        props: {editMode: true}
      },
      {
        path: "patients/:id/contacts/:contactid",
        name: 'contact-details',
        component: Contacts,
        props: {editMode: true}
      },
      {
        path: "patients/:id/contact-add",
        name: 'patient-contact-add',
        component: Contacts,
        props: {editMode: false}
      },
      {
        path: 'patients/:id/insurances',
        name: 'patient-insurance',
        component: Insurance,
        props: {editMode: true}
      },
      {
        path: 'patients/:id/document',
        name: 'patient-document',
        component: DocumentManagementList      
      },
      {
        path: "patients/:id/document/:seqNo",
        name: 'patient-document-detail',
        component: DocumentManagement,
        props: {editMode: true}
      },
      {
        path: "patients/:id/document-add",
        name: 'patient-document-add',
        component: DocumentManagement,
        props: {editMode: false}
      },
      {
        path: 'patients/insurance-details/:id/:idInsurance',
        name: 'patient-insurance-details',
        component: InsuranceDetails,
        props: {editMode: true}        
      },
      {
        path: 'patients/add-insurance/:id',
        name: 'add-patient-insurance',
        component: InsuranceDetails,
        props: {editMode: false}        
      },
      {
        path: 'clinical',
        name: 'Clinical',
        component: Clinical,       
      },
      {
        path: 'clinicalnotes',
        name: 'clinicalNotesContainer',
        component: ClinicalNotesContainer,       
      },
      {
        path: 'clinical/:id/dashboard/:recordid',
        name: 'Clinical-Dashboard',
        component: ClinicalDashBoard,       
      },
      {
        path: 'appointment/:id/dashboard/:actId',
        name: 'appointment-Dashboard',
        component: AppointmentDashBoard,       
      },
      {
        path: 'clinical/:id/patientinfo/:recordid',
        name: 'Clinical-PatientInfo',
        component: ClinicalPatientInfo,
        props: {editMode: true}       
      },
      {
        path: 'clinical/:id/notes/:recordid',
        name: 'Clinical-Notes',
        component: ClinicalNotes,       
      },
      {
        path: 'clinical/:id/chartreview/:recordid',      
        name: 'Clinicalchartreview',
        component: ChartReviewClinical,       
      },
      {
        path: 'patients/:id/chartreview',
        name: 'patientchartreview',
        component: ChartReviewPatient,       
      },
      {
        path: 'patients/:id/ePharmacy',
        name: 'patientePharmacy',
        component: ePharmacyPatient,       
      },
      {
        path: 'clinical/:id/ePharmacy/:recordid',
        name: 'clinicalePharmacy',
        component: ePharmacyClinical,       
      },
      {
        path: 'clinical/:id/asam/:recordid',
        name: 'Clinical-Asam',
        component: ClinicalAsam,       
      },
      {
        path: 'patients/:id/allergies',
        name: 'patient-allergies',
        component: AllergiesList,
      },
      {
        path: "patients/:id/allergies/:allergyid",
        name: 'allergy-details',
        component: Allergy,
        props: {editMode: true}
      },
      {
        path: 'clinical/:id/allergies/:recordid',
        name: 'Clinical-PatientAllergies',
        component: AllergiesList,   
      },
      {
        path: 'clinical/:id/transfer/:recordid',
        name: 'Clinical-Transfer',
        component: Transfer,   
      },
      // Wiley Treatment Plan
      {
        path: 'clinical/:id/wytreatmentplan/:recordid',
        name: 'WyTreatmentplan-Clinical',
        component: WyTreatmentPlanList,   
      },
      {
        path: "clinical/:id/wytreatmentplan/:recordid/:problemid",
        name: 'WyTreatmentplan-details-Clinical',
        component: WyTreatmentPlan,
        props: {editMode: true}
      },
      {
        path: "clinical/:id/wytreatmentplan/:recordid/add",
        name: 'WyTreatmentplan-add-Clinical',
        component: WyTreatmentPlan,
        props: {editMode: false}
      },
      // MedEZ Treatment Plan
      {
        path: 'clinical/:id/mdtreatmentplan/:recordid',
        name: 'MdTreatmentplan-Clinical',
        component: MdTreatmentPlanList,   
      },
      {
        path: "clinical/:id/mdtreatmentplan/:recordid/:problemid",
        name: 'MdTreatmentplan-details-Clinical',
        component: MdTreatmentPlan,
        props: {editMode: true}
      },
      {
        path: "clinical/:id/mdtreatmentplan/:recordid/add",
        name: 'MdTreatmentplan-add-Clinical',
        component: MdTreatmentPlan,
        props: {editMode: false}
      },

      {
        path: 'clinical/:id/discharge/:recordid',
        name: 'Clinical-Discharge',
        component: Discharge,   
      },
      {
        path: "clinical/:id/allergy-add/:recordid",
        name: 'Clinical-allergies-add',
        component: Allergy,
        props: {editMode: false}
      },
      {
        path: "patients/:id/allergy-add",
        name: 'patient-allergy-add',
        component: Allergy,
        props: {editMode: false}
      },
      {
        path: 'clinical/:id/vital/:recordid',
        name: 'Clinical-vital-sign',
        component: VitalSignList,       
      }
      ,{
        path: "clinical/:id/vs-add/:recordid",
        name: 'vs-add',
        component: VitalSign,
        props: {editMode: false}
      },
      {
        path: "clinical/:id/vital/:recordid/:vsid",
        name: 'vs-details',
        component: VitalSign,
        props: {editMode: true}
      },


      {
        path: 'clinical/:id/medications/:recordid',
        name: 'Clinical-Medications',
        component: MedicationsList,       
      }
      ,{
        path: "clinical/:id/addmedications/:recordid",
        name: 'medications-add',
        component: Medications,
        props: {editMode: false}
      },
      {
        path: "clinical/:id/medications/:recordid/code/:recId",
        name: 'medications-details',
        component: Medications,
        props: {editMode: true}
      },






     
      {
        path: 'clinical/:id/dailynote/:recordid',
        name: 'Clinical-daily-note',
        component: DailyNotesList,       
      },
      {
        path: "clinical/:id/dailynote/:recordid/:dailynoteid/:noteTypeName/:noteEmployeeCode",
        name: 'dailynote-details',
        component: DailyNote,
        props: {editMode: true}
      },
      {
        path: "clinical/:id/dailynote-add/:recordid/:notetype/:noteTypeName",
        name: 'dailynote-add',
        component: DailyNote,
        props: {editMode: false}
      },
      {
        path: 'clinical/:id/lognote/:recordid',
        name: 'Clinical-log-note',
        component: LogNotesList,       
      },
      {
        path: "clinical/:id/lognote/:recordid/:lognoteid/:intakedate/:noteTypeName",
        name: 'lognote-details',
        component: LogNote,
        props: {editMode: true}
      },
      {
        path: "clinical/:id/lognote-add/:recordid/:lognoteid/:noteTypeName",
        name: 'lognote-add',
        component: LogNote,
        props: {editMode: false}
      },
      {
        path: 'employeeinbox',
        name: 'Clinical-employee-inbox',
        component: EmployeeInbox,       
      },
      {
        path: 'scheduler',
        name: 'scheduler',
        component: Schedule,
      },
      {
        path: 'schedule-editor',
        name: 'schedule-editor',
        component: ScheduleEditor,
      },
      {
        path: 'reportbuilder',
        name: 'reportbuilder',
        component: ReportBuilder,
      },
      {
        path: 'roles',
        name: 'roles',
        component: UserTemplateHeaderList
      },
      {
        path: "roles/:id/edit",
        name: 'role-details',
        component: UserRole,
        props: {editMode: true}
      },
      {
        path: "roles/add",
        name: 'role-add',
        component: UserRole,
        props: {editMode: false}
      },{
        path: 'users',
        name: 'users',
        component: UserHeaderList
      },
      {
        path: "users/:id/edit",
        name: 'users-details',
        component: UserHeader,
        props: {editMode: true}
      },
      {
        path: "users/add",
        name: 'users-add',
        component: UserHeader,
        props: {editMode: false}
      },
      {
        path: "cpt/:id/edit",
        name: 'cpt-details',
        component: CPT,
        props: {editMode: true}
      },
      {
        path: "cpt/add",
        name: 'cpt-add',
        component: CPT,
        props: {editMode: false}
      },
      {
        path: "modifiers/:id/edit",
        name: 'modifiers-details',
        component: Modifiers,
        props: {editMode: true}
      },
      {
        path: "modifiers/add",
        name: 'modifiers-add',
        component: Modifiers,
        props: {editMode: false}
      },
      {
        path: "placeofservices/:id/edit",
        name: 'placeofservices-details',
        component: PlaceofService,
        props: {editMode: true}
      },
      {
        path: "placeofservices/add",
        name: 'placeofservices-add',
        component: PlaceofService,
        props: {editMode: false}
      },
      {
        path: 'employeetypes',
        name: 'employeeTypeList',
        component: EmployeeTypeList,
      },
      {
        path: "employeetypes/:id/edit",
        name: 'employeeType-details',
        component: EmployeeType,
        props: {editMode: true}
      },
      {
        path: "employeetypes/add",
        name: 'employeeType-add',
        component: EmployeeType,
        props: {editMode: false}
      },
      {
        path: 'valuecodes',
        name: 'valuecodesList',
        component: ValueCodeList,
      },
      {
        path: "valuecodes/:id/edit",
        name: 'valuecode-details',
        component: ValueCode,
        props: {editMode: true}
      },
      {
        path: "valuecodes/add",
        name: 'valuecode-add',
        component: ValueCode,
        props: {editMode: false}
      },
      {
        path: 'rooms',
        name: 'roomsList',
        component: RoomsList,
      },
      {
        path: "rooms/:id/edit",
        name: 'rooms-details',
        component: Rooms,
        props: {editMode: true}
      },
      {
        path: "rooms/add",
        name: 'rooms-add',
        component: Rooms,
        props: {editMode: false}
      },
      {
        path: 'buildings',
        name: 'buildingsList',
        component: BuildingList,
      },
      {
        path: "buildings/:id/edit",
        name: 'buildings-details',
        component: Building,
        props: {editMode: true}
      },
      {
        path: "buildings/add",
        name: 'buildings-add',
        component: Building,
        props: {editMode: false}
      },

      {
        path: 'employeecredentials',
        name: 'employeecredentialsList',
        component: EmployeeCredentialList,
      },
      {
        path: "employeecredentials/:id/edit",
        name: 'employeecredentials-details',
        component: EmployeeCredential,
        props: {editMode: true}
      },
      {
        path: "employeecredentials/add",
        name: 'employeecredentials-add',
        component: EmployeeCredential,
        props: {editMode: false}
      },

      {
        path: 'employees',
        name: 'employeeList',
        component: EmployeeList,
      },
      {
        path: "employees/:id/edit",
        name: 'employee-details',
        component: Employee,
        props: {editMode: true}
      },
      {
        path: "employees/add",
        name: 'employee-add',
        component: Employee,
        props: {editMode: false}
      },

      {
        path: 'appointmenttypes',
        name: 'appointmenttypesList',
        component: AppoinmentTypeList,
      },
      {
        path: "appointmenttypes/:id/edit",
        name: 'appointmenttypes-details',
        component: AppoinmentType,
        props: {editMode: true}
      },
      {
        path: "appointmenttypes/add",
        name: 'appointmenttypes-add',
        component: AppoinmentType,
        props: {editMode: false}
      },

      {
        path: 'calendarresources',
        name: 'calendarresourcesList',
        component: CalendarResourcesList,
      },
      {
        path: 'appointments',
        name: 'appointments',
        component: SchedulerDB,
      },
      {
        path: "calendarresources/:id/edit",
        name: 'calendarresources-details',
        component: CalendarResources,
        props: {editMode: true}
      },
      {
        path: "calendarresources/add",
        name: 'calendarresources-add',
        component: CalendarResources,
        props: {editMode: false}
      },
      {
        path: 'clinicalnotesreport',
        name: 'reportClinicalNotes',
        component: ReportClinicalNotes,
      },
      {
        path: 'reports/favorites',
        name: 'reportList',
        component: ReportList,
        props: {mode: 'favorites'}
      },
      {
        path: 'reports/notes',
        name: 'reportListNotes',
        component: ReportList,
        props: {category: 'Notes'}
      },
      {
        path: 'reports/billing',
        name: 'reportListBilling',
        component: ReportList,
        props: {category: 'Billing'}
      },
      {
        path: 'reports/patients',
        name: 'reportListPatient',
        component: ReportList,
        props: {category: 'Patients'}
      },
      {
        path: 'reports/powerbi',
        name: 'reportListPowerBI',
        component: PowerBILayout,
        props: {category: 'Power BI'}
      },
      {
        path:'reports/:type/:id/powerbi/:name',
        name: 'PowerBI-Report',
        component: PowerBIReport,
      },
      {
        path: 'dailyservicechargesreport',
        name: 'DailyServiceCharge',
        component: DailyServiceCharge,
      },
      {
        path: 'dailyservicelogreport',
        name: 'DailyServiceLog',
        component: DailyServiceLog,
      },
      {
        path: 'groupnotes',
        name: 'groupnotes',
        component: GroupNotes,
      },
      {
        path: 'groupnotes/:id',
        name: 'groupnotes-Dashboard',
        component: GroupNotesAccordion,       
      },
      {
        path: 'appointments/:id/schedulernotes/:actId',
        name: 'scheduler-appointment-notes',
        component: SchedulerNotes,       
      },
      {
        path: 'appointments/:id/activities/:actId',
        name: 'schedulerdb-activitiesList',
        component: SchedulerDBActivitiesList,       
      },  
      {
        path: 'appointments/:id/activities/:actId/add',
        name: 'schedulerdb-activitiesAdd',
        props: {editMode: false},
        component: SchedulerDBActivities,       
      },
      {
        path: 'appointments/:id/activities/:actId/:code/edit',
        name: 'schedulerdb-activitiesEdit',
        props: {editMode: true},
        component: SchedulerDBActivities,       
      },
      {
        path: 'incidents',
        name: 'incident-report',
        props: {keepFilter: false},
        component: IncidentReport,       
      },
      {
        path: 'incidents',
        name: 'incident-report-back',
        props: {keepFilter: true},
        component: IncidentReport,       
      },
      {
        path: 'incident-detail/:IncidentID',
        name: 'incident-content',
        props: {editMode: true},
        component: IncidentReportContent,       
      },
      {
        path: 'incident-detail',
        name: 'incident-report-general-info-add',
        props: {editMode: false},      
        component: IncidentReportContent,       
      },
      {
        path: 'druglist',
        name: 'DrugList',
        component: DrugList,       
      },
      {
        path: 'drug',
        name: 'Drug',
        component: Drug,       
      },
      {
        path: "drugs/:id/edit",
        name: 'drug-details',
        component: Drug,
        props: {editMode: true}
      },
      {
        path: "drugs/add",
        name: 'drug-add',
        component: Drug,
        props: {editMode: false}
      },
      {
        path: 'patientcontactsreport',
        name: 'PatientContactsRep',
        component: PatientContactsRep,
        props: {filterView: true, recNo: null, patientCode: null},
      },
      {
        path: 'patientallergiesreport',
        name: 'PatientAllergyRep',
        component: PatientAllergyRep,
        props: {filterView: true, recNo: null, patientCode: null},
      },
      {
        path: 'patientinsurancesreport',
        name: 'PatientInsurancesRep',
        component: PatientInsurancesRep,
        props: {showModal: true, RecNo: null, PatientCode: null},
      },
      {
        path: 'icrmultiplerep',
        name: 'ICRMultipleViewRep',
        component: ICRMultipleViewRep,
      },
      {
        path: 'employeecaseloadreport',
        name: 'reportEmployeeCaseLoad',
        component: ReportEmployeeCaseLoad,
      },
      {
        path: 'reports/employeecaseload',
        name: 'reportListEmployeeCaseLoad',
        component: ReportList,
        props: {category: 'EmployeeCaseLoad'}
      },
      {
        path: 'incidentsworkflow',
        name: 'IncidentWorkflow',
        component: IncidentWorkflow,
      },
      {
        path: 'clinical/:id/scheduler/:recordid',
        name: 'SchedulerClinical',
        component: SchedulerClinical,
      },
      {
        path: "patients/:id/appointments",
        name: 'patient-appoint',
        component: Appointments,
        props: {editMode: true}
      }, 
      {
        path: "clinical/:id/appointments/:recordid",
        name: 'clinical-appoint',
        component: Appointments,
        props: {clinical: true}
      },   
    ],
  },
]

// eslint-disable-next-line no-new
const router = new VueRouter({
  mode: 'history',
 // mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {  
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);
  const loggedIn = UserService.IsLoged();
  const isAuthenticated = UserService.IsAuthenticated();
  
  // Route it not public and user is not Authenticated
  if (!isPublic && !loggedIn) {
    return next('/login');
  }

  //User is loged but don´t have select provider
  if (loggedIn && !isAuthenticated && to.fullPath !== '/provider') {
    return next('/provider');
  }

  // if onlyWhenLoggedOut and user it logged reroute to root
  if (loggedIn && onlyWhenLoggedOut) {
    return next('/')
  }

  next();
})

export default router;