<template>
  <div class="row">
    <div class="col-md-12">
      <div class="textlabel" :data-label="this.Title"></div>
      <div style="border-color:black">
        <v-swatches           
          class="mx-2" 
          :show-checkbox="true" 
          v-on:input="onInput"
          v-bind:value="value"
          show-fallback 
         :trigger-style="triggerstyle"       
          fallback-input-type="color">       
      </v-swatches>
      </div>
   
    </div>
  </div>
</template>

<script>
import VSwatches from 'vue-swatches'

// Import the styles too, typically in App.vue or main.js
import 'vue-swatches/dist/vue-swatches.css'
/*
Events
#input
Emitted after the value changes. It's compatible with the v-model directive.

Payload: value
#open
Emitted when the popover is opened.

Payload: nothing
#close
Emitted when the popover is closed.

Payload: value



Slots
#trigger
Use it to replace the trigger. Useful for showing custom icons, buttons or inputs.
*/
export default {
  props:{
      Title:String, 
      value:[String, Number],     
      },
  components: { VSwatches },
  data() {
    return {
      //color: '#000000',
      triggerstyle:{
        width: '250px', borderRadius: '10px'  
      }
    }
  },
  methods:{
    onInput(evt){
      this.$emit('input', evt);
    }
  }
}
</script>


<style>
  .vue-vue-swatches__trigger{
    width:100% !important;
  }

  .custom-ui-class {
    width: 348px;
    height: 320px;
    margin-left: auto;
    margin-right: auto;
  }

</style>